
// This list is tightly coupled to account creation in lib/MA/Exchange/Agent/Advertiser.pm

const carrierObjs = [
    'AAA',
    'Allstate',
    'American Family',
    'Family First Life',
    'Farmers',
    'Liberty Mutual',
    'Prudential',
    'Renegade',
    'State Farm',
    'Travelers',
    'USHA'
]
    .map((x) => {
        return { label: x, value: x };
    })
    .sort((a, b) => {
        return a.label.localeCompare(b.label);
    });

const independentCarrier = {
    label: 'Independent',
    value: 'Independent Agent'
};

const carriers = carrierObjs.concat(independentCarrier).sort((a, b) => {
    return a.label.localeCompare(b.label);
});

const matchCarriers = [independentCarrier].concat(carrierObjs);

export { carriers as default, matchCarriers };
