import { Checkmark16, Phone16 } from '@carbon/icons-react';
import { Button, ButtonSkeleton, ToastNotification } from 'carbon-components-react';
import clsx from 'clsx';
import useAccount from 'hooks/use-account';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'util/axios';
import { formatError } from 'util/format-error';
import { dollarAmount } from 'util/format-number';
import styles from './CallNow.module.scss';
import CallNowModal from './CallNowModal/CallNowModal';

const defaultFn = () => {};

const DEFAULT_INTERVAL = 5000;
const DEFAULT_DURATION = 300000;

const errorTypes = {
    INSUFFICIENT_FUNDS: 'Insufficient funds',
    LEAD_UNAVAILABLE: 'Lead unavailable',
    ALREADY_PURCHASED: 'Already purchased',
    CALL_UNAVAILABLE: 'Call unavailable'
};

/**
 * eventNames used with onEvent(eventName, {data})
 * - CALL_STARTED
 *      - Runs after a call is successfully initialized (CallNow).
 * - CALL_FINISHED
 *      - Runs after a call ends (CallNowModal). Tied to user button click.
 * - QUALIFIED
 *      - Runs when checkCallStatus returns qualified (CallNowModal).
 *      - data: { adGroupId, leadId, lastCall, myCalls, purchasedBy }
 * - PURCHASED_CLICK
 *      - Callback for CallNow button click after a successful purchase.
 *      - data: { leadId, adGroupId }
 * - QUALIFIED_CLICK:
 *      - Tied to user button click after a qualified call (CallNowModal).
 *      - data: { leadId, adGroupId }
 * - UNQUALIFIED:
 *      - Runs when a call ends and is unqualified (CallNowModal).
 *      - data: { callDisabled, callDisabledReason, lastCall, leadId, myCalls }
 * - ERROR:
 *      - Error handling for when call fails to initialize.
 *      - data: { message, leadId, adGroupId }
 */
const eventNames = {
    CALL_STARTED: 'callStarted',
    CALL_FINISHED: 'callFinished',
    QUALIFIED: 'qualified',
    PURCHASED_CLICK: 'purchasedClick',
    QUALIFIED_CLICK: 'qualifiedClick',
    UNQUALIFIED: 'unqualified',
    ERROR: 'error'
};

const CallNow = ({
    adGroupId,
    buttonText = '',
    callDisabled,
    callDisabledReason,
    cost,
    contact,
    hideSecondarySuccessButton = false,
    leadId,
    myCalls,
    onEvent = defaultFn,
    purchased,
    showTooltip = false,
    tooltipPosition = 'top',
    ...rest
}) => {
    const { id } = useAccount();
    const [loading, setLoading] = useState(true);
    const [callNowError, setCallNowError] = useState({
        message: '',
        description: '',
        link: ''
    });
    const [callNowModalProps, setCallNowModalProps] = useState({});
    const formattedCost = dollarAmount(cost);
    const hasTooltip = showTooltip || callDisabled;

    useEffect(() => {
        cost && setLoading(false);
    }, [cost]);

    const defaultOnError = (err) => {
        handleCloseCallNowError();

        const { message, description, rest } = formatError(err);
        const { adGroupId, leadId } = rest;

        // if lead is already purchased, add hyperlink to Lead Details page
        let link = '';
        if (adGroupId || message === errorTypes.ALREADY_PURCHASED) {
            link = <Link to={`/p/leads/${leadId}/${adGroupId}`}>View Lead</Link>;
        }

        setCallNowError({ message, description, link });

        onEvent(eventNames.ERROR, { message, leadId, adGroupId });
    };

    const handleCallNow = async (leadId) => {
        try {
            setLoading(true);

            const { data } = await axios.post(`/lead-feed.json?action=call&advertiser_id=${id}&id=${leadId}`);

            if (data.successful) {
                const { leadAgentCallId, pollInterval: pi, pollDuration: pd } = data;

                setCallNowModalProps({
                    contact,
                    cost,
                    leadId,
                    pollingInterval: pi ? pi * 1000 : DEFAULT_INTERVAL,
                    pollingDuration: pd ? pd * 1000 : DEFAULT_DURATION,
                    leadAgentCallId,
                    myCalls
                });

                // This will disable the auto refresh of the feed while call is active
                onEvent(eventNames.CALL_STARTED);
            }
        }
        catch (err) {
            defaultOnError(err);
        }
        finally {
            setLoading(false);
        }
    };

    const handleClick = () => {
        if (callDisabled) {
            return;
        }
        else if (purchased) {
            onEvent(eventNames.PURCHASED_CLICK, { leadId, adGroupId });
        }
        else {
            handleCallNow(leadId);
        }
    };

    const handleCloseCallNowError = () => {
        setCallNowError({ message: '', description: '', link: '' });
    };

    let messageWithLink = '',
        updatedDescription = '';
    if (callNowError.message === errorTypes.INSUFFICIENT_FUNDS) {
        messageWithLink = <Link to="/p/account">Account Settings</Link>;
        updatedDescription = <>Please make a deposit in {messageWithLink}.</>;
    }

    const buttonTooltipProps = useMemo(() => {
        let iconDescription, hasIconOnly, renderIcon;

        if (!purchased) {
            iconDescription = hasTooltip
                ? callDisabledReason ||
                  `Click to call ${contact} - this call may be recorded. If the consumer picks up, your account will be charged ${formattedCost}.`
                : null;
            renderIcon = Phone16;
            hasIconOnly = hasTooltip ? true : false;
        }
        else {
            renderIcon = Checkmark16;
            hasIconOnly = false;
        }

        return { iconDescription, hasIconOnly, renderIcon };
    }, [purchased, cost, callDisabled]);

    return (
        <>
            {callNowError.message && (
                <ToastNotification
                    className={styles.errorNotification}
                    kind="error"
                    title={callNowError.message}
                    caption={updatedDescription || callNowError.description}
                    subtitle={callNowError.link}
                    timeout={5000}
                    hideCloseButton
                    onClose={handleCloseCallNowError}
                    lowContrast
                />
            )}
            {!isEmpty(callNowModalProps) && (
                <CallNowModal
                    hideSecondarySuccessButton={hideSecondarySuccessButton}
                    onEvent={onEvent}
                    setCallNowModalProps={setCallNowModalProps}
                    {...callNowModalProps}
                />
            )}
            <div>
                {loading ? (
                    <ButtonSkeleton className={clsx(styles.btnSkeleton, 'callNowButtonSkeleton')} />
                ) : (
                    <Button
                        className={clsx(
                            !hasTooltip && styles.noTooltip,
                            purchased && styles.callSuccess,
                            callDisabled && styles.callDisabled, // mock disabled button style so tooltip works
                            styles.button
                        )}
                        kind="primary"
                        onClick={handleClick}
                        tooltipPosition={tooltipPosition}
                        {...buttonTooltipProps}
                        {...rest}
                    >
                        <span className={styles.tooltip}>
                            {purchased ? `Purchased ${formattedCost}` : `${buttonText} ${formattedCost}`}
                        </span>
                    </Button>
                )}
            </div>
        </>
    );
};

export { CallNow as default, eventNames };
