import { Checkbox, Loading, PasswordInput, TextInput } from 'carbon-components-react';
import { motion } from 'framer-motion';
import useAuth from 'hooks/use-auth';
import useUser from 'hooks/use-user';
import Layout from 'layouts/Centered';
import { useState } from 'react';
import { Helmet as Head } from 'react-helmet-async';
import { Link, Redirect, useLocation } from 'react-router-dom';
import styles from 'styles/pages/SignIn.module.scss';
import { getEnvironment } from 'util/common';
import { ENVIRONMENTS } from 'util/constants';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    email: Yup.string().required('Please enter your email address').email('Please enter a valid email address'),
    password: Yup.string().required('Please enter your password'),
    totpRequired: Yup.boolean(),
    totpCode: Yup.string().when('totpRequired', {
        is: true,
        then: Yup.string().required('Please enter your MFA code')
    })
});

const SignIn = () => {
    const { signIn, loading, error, totpRequired } = useAuth();
    const { user } = useUser();
    const location = useLocation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [totpCode, setTotpCode] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [clientErrors, setClientErrors] = useState({});

    const attemptSignIn = async (evt) => {
        evt.preventDefault();

        try {
            await validationSchema.validate(
                {
                    email,
                    password,
                    totpRequired,
                    totpCode
                },
                { abortEarly: false }
            );
            setClientErrors({});
        }
        catch (errors) {
            const all = errors.inner.reduce((acc, curr) => {
                return { ...acc, [curr.path]: curr.message };
            }, {});
            setClientErrors(all);
            return;
        }

        signIn({
            email,
            password,
            isRemember: rememberMe,
            ...(totpRequired && { code: totpCode })
        });
    };

    if (user) {
        return <Redirect to={location?.state?.referrer || '/p'} />;
    }
    else if (getEnvironment() === ENVIRONMENTS.DEVELOPMENT) {
        // Comment this if you need to test log in on dev.
        window.location.href = `//exchange-beta.mediaalpha.com/?url=${encodeURIComponent(window.location.href)}`;
    }

    return (
        <>
            <Head>
                <title>Sign In</title>
            </Head>
            <Layout title="Sign In">
                <div className={styles.wrap}>
                    <motion.form className={styles.form} onSubmit={attemptSignIn} {...formAnim} noValidate>
                        <TextInput
                            id="email"
                            type="email"
                            labelText="Email"
                            placeholder="Enter your email address"
                            autoComplete="username"
                            onChange={(evt) => setEmail(evt.target.value)}
                            autoFocus
                            disabled={loading}
                        />
                        {clientErrors.email && (
                            <motion.p className={styles.error} {...errorAnim}>
                                {clientErrors.email}
                            </motion.p>
                        )}
                        <PasswordInput
                            id="password"
                            labelText="Password"
                            placeholder="Enter your password"
                            autoComplete="current-password"
                            onChange={(evt) => setPassword(evt.target.value)}
                            disabled={loading}
                        />
                        {clientErrors.password && (
                            <motion.p className={styles.error} {...errorAnim}>
                                {clientErrors.password}
                            </motion.p>
                        )}
                        {totpRequired && (
                            <>
                                <TextInput
                                    id="totpCode"
                                    type="tel"
                                    labelText=" Authentication Code"
                                    placeholder="Enter your MFA code"
                                    autoComplete="off"
                                    maxLength="6"
                                    onChange={(evt) => setTotpCode(evt.target.value)}
                                    autoFocus
                                    disabled={loading}
                                />

                                {clientErrors.totpCode && (
                                    <motion.p className={`${styles.error}`} {...errorAnim}>
                                        {clientErrors.totpCode}
                                    </motion.p>
                                )}
                            </>
                        )}
                        <Checkbox
                            id="rememberMe"
                            labelText="Remember this device"
                            checked={rememberMe}
                            onChange={(checked) => setRememberMe(checked)}
                            disabled={loading}
                        />
                        {error && (
                            <motion.div {...errorAnim}>
                                <div className={styles.error}>{error}</div>
                            </motion.div>
                        )}
                        <motion.button type="submit" className={styles.button} disabled={loading} {...buttonAnim}>
                            {loading ? <Loading className={styles.loading} small withOverlay={false} /> : 'Sign In'}
                        </motion.button>
                        <div className={styles.forgotPassword}>
                            <Link to="/forgot-password">Forgot Password</Link>
                        </div>
                    </motion.form>
                    <div className={styles.legal}>
                        <Link to="/terms" className={styles.link}>
                            Updated Agent Terms
                        </Link>
                        <Link to="/privacy" className={styles.link}>
                            Privacy Policy
                        </Link>
                    </div>
                </div>
            </Layout>
        </>
    );
};

const formAnim = {
    initial: {
        opacity: 0,
        y: 20
    },
    animate: {
        opacity: 1,
        y: 0
    },
    transition: {
        ease: 'easeOut',
        duration: 1
    }
};

const errorAnim = {
    initial: {
        opacity: 0,
        y: 5
    },
    animate: {
        opacity: 1,
        y: 0
    },
    transition: {
        ease: 'easeOut',
        duration: 0.5
    }
};

const buttonAnim = {
    whileTap: { scale: 0.98 }
};

export default SignIn;
