import { Button, Column, Grid, MultiSelect, Row, Select, SelectItem, TextInput } from 'carbon-components-react';
import clsx from 'clsx';
import { customTargetingOptions } from 'components/accounts/AccountForm';
import useOpportunitiesFilters from 'hooks/use-opportunities-filters';
import { useState } from 'react';
import { capitalizeFirstLetter } from 'util/format-text';
import { validateAge } from 'util/validate-form';
import styles from './ConsumerFilter.module.scss';

const ConsumerFilter = ({ setConsumerFilterOpen }) => {
    const { consumerFilter, setConsumerFilter } = useOpportunitiesFilters();

    const [filters, setFilters] = useState({ ...consumerFilter });
    const {
        homeOwnership,
        creditRating,
        continuousCoverage,
        licenseActive,
        numAccidentsAtFault,
        numTickets,
        dui,
        sr_22,
        numCars
    } = filters;

    const [error, setError] = useState({
        ticketsFrom: '',
        ticketsTo: '',
        accidentsFrom: '',
        accidentsTo: '',
        ageFrom: '',
        ageTo: '',
        vehiclesFrom: '',
        vehiclesTo: ''
    });
    const hasErrors = Object.values(error).some(Boolean);

    const handleCancel = () => {
        setConsumerFilterOpen(false);
    };

    const handleApply = () => {
        setConsumerFilter({ ...filters });
        setConsumerFilterOpen(false);
    };

    const getDefaultSelected = (option) => option.map((v) => ({ id: v, label: v }));

    const handleTicketsFromOnChange = (evt) => {
        const { value } = evt.target;
        if ((numTickets.to !== 'any' || value !== 'any') && Number(value) > Number(numTickets.to)) {
            setError({
                ...error,
                ticketsFrom: 'Min must be smaller than max'
            });
        }
        else {
            setError({ ...error, ticketsFrom: '' });
            setFilters({
                ...filters,
                numTickets: {
                    ...numTickets,
                    from: value
                }
            });
        }
    };

    const handleTicketsToOnChange = (evt) => {
        const { value } = evt.target;
        if ((numTickets.from !== 'any' || value !== 'any') && Number(value) < Number(numTickets.from)) {
            setError({
                ...error,
                ticketsTo: 'Max must be greater than min'
            });
        }
        else {
            setError({
                ...error,
                ticketsTo: ''
            });
            setFilters({
                ...filters,
                numTickets: {
                    ...numTickets,
                    to: value
                }
            });
        }
    };

    const handleAccidentsFromOnChange = (evt) => {
        const { value } = evt.target;
        if ((numAccidentsAtFault.to !== 'any' || value !== 'any') && Number(value) > Number(numAccidentsAtFault.to)) {
            setError({
                ...error,
                accidentsFrom: 'Min must be smaller than max'
            });
        }
        else {
            setError({
                ...error,
                accidentsFrom: ''
            });
            setFilters({
                ...filters,
                numAccidentsAtFault: {
                    ...numAccidentsAtFault,
                    from: evt.target.value
                }
            });
        }
    };

    const handleAccidentsToOnChange = (evt) => {
        const { value } = evt.target;
        if (
            (numAccidentsAtFault.from !== 'any' || value !== 'any') &&
            Number(value) < Number(numAccidentsAtFault.from)
        ) {
            setError({
                ...error,
                accidentsTo: 'Max must be greater than min'
            });
        }
        else {
            setError({
                ...error,
                accidentsTo: ''
            });
            setFilters({
                ...filters,
                numAccidentsAtFault: {
                    ...numAccidentsAtFault,
                    to: evt.target.value
                }
            });
        }
    };

    const handleVehiclesFromOnChange = (evt) => {
        const { value } = evt.target;
        if ((numCars.to !== 'any' || value !== 'any') && Number(value) > Number(numCars.to)) {
            setError({
                ...error,
                vehiclesFrom: 'Min must be smaller than max'
            });
        }
        else {
            setError({
                ...error,
                vehiclesFrom: ''
            });
            setFilters({
                ...filters,
                numCars: {
                    ...numCars,
                    from: evt.target.value
                }
            });
        }
    };

    const handleVehiclesToOnChange = (evt) => {
        const { value } = evt.target;
        if (
            (numCars.from !== 'any' || value !== 'any') &&
            Number(value) < Number(numCars.from)
        ) {
            setError({
                ...error,
                vehiclesTo: 'Max must be greater than min'
            });
        }
        else {
            setError({
                ...error,
                vehiclesTo: ''
            });
            setFilters({
                ...filters,
                numCars: {
                    ...numCars,
                    to: evt.target.value
                }
            });
        }
    };

    const { basic, credit, coverage, incidents, vehicles } = customTargetingOptions;

    return (
        <div className={clsx(styles.wrap, hasErrors && styles.wrapOnError)}>
            <div className={styles.filterItem}>
                <Grid>
                    <div className={styles.consumerTitle}>
                        <strong>Consumer Profile</strong>
                    </div>
                    <Row className={styles.consumerProfile}>
                        <Column lg={2} md={1}>
                            <label className="bx--label">Homeowner</label>
                            <div className={styles.homeowner}>
                                <Select
                                    id="homeowner"
                                    labelText=""
                                    defaultValue={homeOwnership}
                                    onChange={(evt) => {
                                        setFilters({ ...filters, homeOwnership: evt.target.value });
                                    }}
                                >
                                    <SelectItem disabled value="" text="Homeowner" />
                                    {basic.map((item, i) => (
                                        <SelectItem value={item.value} key={i} text={item.label} />
                                    ))}
                                </Select>
                            </div>
                        </Column>
                        <Age error={error} setError={setError} filters={filters} setFilters={setFilters} />
                        <Column lg={2} md={1}>
                            <label className="bx--label">Credit</label>
                            <div className={styles.credit}>
                                <MultiSelect
                                    id="credit"
                                    titleText=""
                                    items={credit.map((o) => ({ id: o, label: o }))}
                                    sortItems={(items) => items}
                                    label=""
                                    initialSelectedItems={getDefaultSelected(creditRating)}
                                    onChange={(e) =>
                                        setFilters({
                                            ...filters,
                                            creditRating: e.selectedItems.map((item) => item.label)
                                        })
                                    }
                                />
                            </div>
                        </Column>
                        <Column lg={2} md={1}>
                            <label className="bx--label">Insured</label>
                            <div className={styles.insured}>
                                <Select
                                    id="insured"
                                    labelText=""
                                    defaultValue={String(continuousCoverage)}
                                    onChange={(evt) => {
                                        setFilters({
                                            ...filters,
                                            continuousCoverage: evt.target.value
                                        });
                                    }}
                                >
                                    <SelectItem disabled value="" text="Insured" />
                                    {coverage.map((item, i) => (
                                        <SelectItem value={item.value} key={i} text={item.label} />
                                    ))}
                                </Select>
                            </div>
                        </Column>
                        <Column lg={2} md={1}>
                            <label className="bx--label">Licensed</label>
                            <div className={styles.licensed}>
                                <Select
                                    id="licensed"
                                    labelText=""
                                    defaultValue={String(licenseActive)}
                                    onChange={(evt) => {
                                        setFilters({
                                            ...filters,
                                            licenseActive: evt.target.value
                                        });
                                    }}
                                >
                                    <SelectItem disabled value="" text="Licensed" />
                                    {basic.map((item, i) => (
                                        <SelectItem value={item.value} key={i} text={item.label} />
                                    ))}
                                </Select>
                            </div>
                        </Column>
                        <Column lg={2} md={1}>
                            <label className="bx--label">Vehicles</label>
                            <div className={clsx(error.vehiclesFrom && styles.vehiclesFromError)}>
                                <Select
                                    id="vehiclesFrom"
                                    labelText="Min"
                                    options={vehicles}
                                    defaultValue={numCars.from}
                                    onChange={handleVehiclesFromOnChange}
                                    invalid={!!error.vehiclesFrom}
                                    invalidText={error.vehiclesFrom}
                                >
                                    {vehicles.map((item, i) => (
                                        <SelectItem value={item.value} key={i} text={item.label} />
                                    ))}
                                </Select>
                            </div>
                        </Column>
                        <Column lg={2} md={1}>
                            <div className={clsx(styles.incidentsTo, error.vehiclesTo && styles.vehiclesToError)}>
                                <Select
                                    id="vehiclesTo"
                                    labelText="Max"
                                    options={vehicles}
                                    defaultValue={numCars.to}
                                    onChange={handleVehiclesToOnChange}
                                    invalid={!!error.vehiclesTo}
                                    invalidText={error.vehiclesTo}
                                >
                                    {vehicles.map((item, i) => (
                                        <SelectItem value={item.value} key={i} text={item.label} />
                                    ))}
                                </Select>
                            </div>
                        </Column>
                    </Row>

                    <strong className={styles.consumerRiskTitle}>Consumer Risk</strong>
                    <Row className={styles.consumerRisk}>
                        <Column lg={2}>
                            <label className="bx--label">Tickets</label>
                            <div className={clsx(error.ticketsFrom && styles.ticketsFromError)}>
                                <Select
                                    id="ticketsFrom"
                                    labelText="Min"
                                    options={incidents}
                                    defaultValue={numTickets.from}
                                    onChange={handleTicketsFromOnChange}
                                    invalid={!!error.ticketsFrom}
                                    invalidText={error.ticketsFrom}
                                >
                                    {incidents.map((item, i) => (
                                        <SelectItem value={item.value} key={i} text={item.label} />
                                    ))}
                                </Select>
                            </div>
                        </Column>
                        <Column lg={2}>
                            <div className={clsx(styles.incidentsTo, error.ticketsTo && styles.ticketsToError)}>
                                <Select
                                    id="ticketsTo"
                                    labelText="Max"
                                    options={incidents}
                                    defaultValue={numTickets.to}
                                    onChange={handleTicketsToOnChange}
                                    invalid={!!error.ticketsTo}
                                    invalidText={error.ticketsTo}
                                >
                                    {incidents.map((item, i) => (
                                        <SelectItem value={item.value} key={i} text={item.label} />
                                    ))}
                                </Select>
                            </div>
                        </Column>

                        <Column lg={2}>
                            <label className="bx--label">Accidents</label>
                            <div className={clsx(error.accidentsFrom && styles.accidentsFromError)}>
                                <Select
                                    id="accidentsFrom"
                                    labelText="Min"
                                    options={incidents}
                                    defaultValue={numAccidentsAtFault.from}
                                    onChange={handleAccidentsFromOnChange}
                                    invalid={!!error.accidentsFrom}
                                    invalidText={error.accidentsFrom}
                                >
                                    {incidents.map((item, i) => (
                                        <SelectItem value={item.value} key={i} text={item.label} />
                                    ))}
                                </Select>
                            </div>
                        </Column>
                        <Column lg={2}>
                            <div className={clsx(styles.incidentsTo, error.accidentsTo && styles.accidentsToError)}>
                                <Select
                                    id="accidentsTo"
                                    labelText="Max"
                                    options={incidents}
                                    defaultValue={numAccidentsAtFault.to}
                                    onChange={handleAccidentsToOnChange}
                                    invalid={!!error.accidentsTo}
                                    invalidText={error.accidentsTo}
                                >
                                    {incidents.map((item, i) => (
                                        <SelectItem value={item.value} key={i} text={item.label} />
                                    ))}
                                </Select>
                            </div>
                        </Column>
                        <Column lg={2}>
                            <label className="bx--label">DUI</label>
                            <div className={styles.dui}>
                                <Select
                                    id="dui"
                                    labelText=""
                                    defaultValue={String(dui)}
                                    onChange={(evt) => {
                                        setFilters({ ...filters, dui: evt.target.value });
                                    }}
                                >
                                    <SelectItem disabled value="" text="DUI" />
                                    {basic.map((item, i) => (
                                        <SelectItem value={item.value} key={i} text={item.label} />
                                    ))}
                                </Select>
                            </div>
                        </Column>
                        <Column lg={2}>
                            <label className="bx--label">SR-22</label>
                            <div className={styles.sr22}>
                                <Select
                                    id="sr_22"
                                    labelText=""
                                    defaultValue={String(sr_22)}
                                    onChange={(evt) => {
                                        setFilters({ ...filters, sr_22: evt.target.value });
                                    }}
                                >
                                    <SelectItem disabled value="" text="SR-22" />
                                    {basic.map((item, i) => (
                                        <SelectItem value={item.value} key={i} text={item.label} />
                                    ))}
                                </Select>
                            </div>
                        </Column>
                    </Row>
                    <div className={styles.filterBtns}>
                        <Button className={styles.cancelBtn} kind="secondary" onClick={handleCancel}>
                            <strong>Cancel</strong>
                        </Button>
                        <Button disabled={hasErrors} onClick={handleApply}>
                            <strong>Apply</strong>
                        </Button>
                    </div>
                </Grid>
            </div>
        </div>
    );
};

const Age = ({ error, setError, filters, setFilters }) => {
    const { age } = filters;

    const handleAgeFromOnChange = (evt) => {
        const { value } = evt.target;
        const maxVal = age.to;
        const errorMsg = validateAge(value);

        if (errorMsg) {
            setError({ ...error, ageFrom: errorMsg });
        }
        else if (
            value &&
            (maxVal.toLowerCase() !== 'any' || value.toLowerCase() !== 'any') &&
            Number(value) > Number(maxVal)
        ) {
            setError({ ...error, ageFrom: 'Min must be smaller than max' });
        }
        else {
            setError({ ...error, ageFrom: '' });
            setFilters({
                ...filters,
                age: {
                    ...age,
                    from: value
                }
            });
        }
    };

    const handleAgeToOnChange = (evt) => {
        const { value } = evt.target;
        const minVal = age.from;
        const errorMsg = validateAge(value);

        if (errorMsg) {
            setError({ ...error, ageTo: errorMsg });
        }
        else if (
            value &&
            (minVal.toLowerCase() !== 'any' || value.toLowerCase() !== 'any') &&
            Number(value) < Number(minVal)
        ) {
            setError({ ...error, ageTo: 'Max must be greater than min' });
        }
        else {
            setError({ ...error, ageTo: '' });
            setFilters({
                ...filters,
                age: {
                    ...age,
                    to: value
                }
            });
        }
    };

    const handleAgeOnBlur = (evt, field) => {
        const val = evt.target.value;
        
        if (!val) {
            evt.target.value = 'Any';
        }
        else if (/^[0-9]+$/.test(Number(evt.target.value))) {
            evt.target.value = Math.trunc(Number(evt.target.value));
        }

        setFilters({
            ...filters,
            age: {
                ...age,
                [field]: evt.target.value
            }
        });
    };

    return (
        <>
            <div className={styles.ageFrom}>
                <label className="bx--label">Age</label>
                <TextInput
                    id="ageFrom"
                    labelText="Min"
                    defaultValue={capitalizeFirstLetter(age.from)}
                    onChange={handleAgeFromOnChange}
                    onBlur={(evt) => handleAgeOnBlur(evt, 'from')}
                    invalid={!!error.ageFrom}
                    invalidText={error.ageFrom}
                />
            </div>
            <div className={styles.ageTo}>
                <TextInput
                    id="ageTo"
                    labelText="Max"
                    defaultValue={capitalizeFirstLetter(age.to)}
                    onChange={handleAgeToOnChange}
                    onBlur={(evt) => handleAgeOnBlur(evt, 'to')}
                    invalid={!!error.ageTo}
                    invalidText={error.ageTo}
                />
            </div>
        </>
    );
};

export default ConsumerFilter;
