import { nanoid } from 'nanoid';

const compareLowerCase = (rowA, rowB, id) => {
    let nameA = rowA.original[id] || '';
    let nameB = rowB.original[id] || '';

    nameA = nameA.toLowerCase();
    nameB = nameB.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameB < nameA) return 1;
    return 0;
};

const setRowIds = (rows, property) => {
    rows.forEach((row) => {
        row.id = row[property];
    });

    return rows;
};

// returns a random 21 characters ID; size is optional
const getRandomId = (size) => {
    return nanoid(size);
};

/**
 * Handles custom sort if key exists in customSortCols or defaults to default Table sort
 * @param {any} cellA
 * @param {any} cellB
 * @param {object} param
 */
const handleCustomSort = (cellA, cellB, { compare, key, sortDirection, sortStates, locale }) => {
    // Activation Date column in Accounts List
    // Last Call column in Leads Feed List
    const customSortCols = ['activationTime', 'lastCall'];

    if (customSortCols.includes(key)) {
        if (cellA === null) return 1;
        if (cellB === null) return -1;
        if (cellA === cellB) return 0;
        if (sortDirection === sortStates.ASC) return cellA > cellB ? -1 : 1;
        if (sortDirection === sortStates.DESC) return cellA < cellB ? -1 : 1;
    }
    else {
        if (sortDirection === sortStates.ASC) {
            return compare(cellA, cellB, locale);
        }

        return compare(cellB, cellA, locale);
    }
};

export { compareLowerCase, setRowIds, getRandomId, handleCustomSort };
