import { CopyButton } from 'components/buttons';
import { PageSection } from 'components/common';
import referralIcon from 'img/referrals.svg';
import { Link } from 'react-router-dom';
import styles from './ReferralUrl.module.scss';

const ReferralUrl = ({ url }) => {
    return (
        <PageSection name="Program Overview" icon={referralIcon}>
            <div className={styles.referralsOverview}>
                <p>
                    It pays to share! Refer other agents and friends in your network and earn $50 in bonus cash for
                    every agent who signs up.
                </p>

                <p>Here's how to refer an agent:</p>

                <ol>
                    <li>
                        Send your referral link that takes them to a personalized sign up page&#42;:
                        <a href={url} className={styles.referralLink} target="_blank" rel="noopener noreferrer">
                            {url}
                        </a>
                        <CopyButton buttonText="Copy Link" copyText={url} tooltipMessage="Copied to Clipboard!" />
                    </li>
                    <li>Once they submit the form, we will reach out to help complete the registration.</li>
                    <li>When they activate their account and deposit funds, you will receive $50 in bonus credit!</li>
                </ol>

                <p>
                    &#42;Your referral page is personalized by displaying your first and last name so they know the
                    referral is from you.
                </p>

                <p>
                    To learn more about this referral program, review the{' '}
                    <Link to="/terms#agent-referral-program">Referral Program details</Link>. By using your referral
                    link you agree to our <Link to="/terms">Updated Agent Terms</Link>. Read our{' '}
                    <Link to="/privacy">Privacy Policy</Link> to learn more about how MediaAlpha handles your
                    information.
                </p>
            </div>
        </PageSection>
    );
};

export default ReferralUrl;
