import { FilterRemove16, Location16, Time16, UserMultiple16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import useOpportunitiesFilters from 'hooks/use-opportunities-filters';
import { useState } from 'react';
import ConsumerFilter from './ConsumerFilter';
import GeoFilter from './GeoFilter';
import HistoryFilter from './HistoryFilter';
import styles from './OpportunitiesFilters.module.scss';

const filterTypes = {
    GEO: 'geo',
    CONSUMER: 'consumer',
    HISTORY: 'history'
};

const OpportunitiesFilters = () => {
    const [geoFilterOpen, setGeoFilterOpen] = useState(false);
    const [consumerFilterOpen, setConsumerFilterOpen] = useState(false);
    const [historyFilterOpen, setHistoryFilterOpen] = useState(false);

    const { resetFilters, filtersApplied } = useOpportunitiesFilters();

    /**
     * Close open filters except the one currently selected
     * @param {String} presentFilterOpen filterTypes
     */
    const closeOpenFilters = (presentFilterOpen) => {
        if (presentFilterOpen === filterTypes.HISTORY) {
            setGeoFilterOpen(false);
            setConsumerFilterOpen(false);
        }
        else if (presentFilterOpen === filterTypes.CONSUMER) {
            setGeoFilterOpen(false);
            setHistoryFilterOpen(false);
        }
        else {
            setConsumerFilterOpen(false);
            setHistoryFilterOpen(false);
        }
    };

    return (
        <div className={styles.opportunitiesFilters}>
            <Button
                renderIcon={Location16}
                kind="tertiary"
                onClick={() => {
                    setGeoFilterOpen(!geoFilterOpen);
                    closeOpenFilters(filterTypes.GEO);
                }}
            >
                <strong>Geography</strong>
            </Button>
            <Button
                renderIcon={UserMultiple16}
                kind="tertiary"
                onClick={() => {
                    setConsumerFilterOpen(!consumerFilterOpen);
                    closeOpenFilters(filterTypes.CONSUMER);
                }}
            >
                <strong>Consumer Types</strong>
            </Button>
            <Button
                renderIcon={Time16}
                kind="tertiary"
                onClick={() => {
                    setHistoryFilterOpen(!historyFilterOpen);
                    closeOpenFilters(filterTypes.HISTORY);
                }}
            >
                <strong>History</strong>
            </Button>
            <Button
                renderIcon={FilterRemove16}
                iconDescription="Reset Filters"
                tooltipPosition="bottom"
                hasIconOnly
                kind="ghost"
                onClick={resetFilters}
                disabled={!filtersApplied}
            />
            <>
                {geoFilterOpen && <GeoFilter setGeoFilterOpen={setGeoFilterOpen} />}
                {consumerFilterOpen && <ConsumerFilter setConsumerFilterOpen={setConsumerFilterOpen} />}
                {historyFilterOpen && <HistoryFilter setHistoryFilterOpen={setHistoryFilterOpen} />}
            </>
        </div>
    );
};

export default OpportunitiesFilters;
