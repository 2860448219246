import { Loading } from 'carbon-components-react';
import { motion } from 'framer-motion';
import useAuth from 'hooks/use-auth';
import useQueryParams from 'hooks/use-query-params';
import useUser from 'hooks/use-user';
import Layout from 'layouts/Centered';
import { useEffect } from 'react';
import { Helmet as Head } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import styles from 'styles/pages/ReactivateUser.module.scss';

const ReactivateUser = () => {
    const { reactivateUser, loading, error } = useAuth();
    const { user } = useUser();
    const { encrypted } = useQueryParams();
    const history = useHistory();

    useEffect(() => {
        if (user) {
            return history.replace('/p');
        }
        else if (!encrypted) {
            return history.replace('/error');
        }
        reactivateUser({ encrypted });
    }, [encrypted]);

    return (
        <>
            <Head>
                <title>Reactivate User</title>
            </Head>
            <Layout title="Reactivate User">
                <div className={styles.wrap}>
                    {loading && (
                        <motion.div>
                            <Loading className={styles.spinner} withOverlay={false} small />
                        </motion.div>
                    )}
                    {!loading && (
                        <>
                            {error ? (
                                <div className={styles.error}>{error} Please contact your Client Success Manager.</div>
                            ) : (
                                <span className={styles.description}>
                                    Your account has successfully been reactivated. Click <Link to="/sign-in">here</Link> to
                                    login.
                                </span>
                            )}
                        </>
                    )}
                </div>
            </Layout>
        </>
    );
};

export default ReactivateUser;
