const { localStorage } = window;

const wrapErr = (fn) => {
    return (...args) => {
        try {
            return fn.apply(this, args);
        }
        catch (err) {
            return null;
        }
    };
};

const localStorageHelper = {
    set: wrapErr(({ key, value, parent }) => {
        if (parent) {
            const existing = JSON.parse(localStorage.getItem(parent));
            if (existing) {
                return localStorage.setItem(
                    parent,
                    JSON.stringify({
                        ...existing,
                        [key]: value
                    })
                );
            }
            else {
                return localStorage.setItem(
                    parent,
                    JSON.stringify({
                        [key]: value
                    })
                );
            }
        }
        else {
            return localStorage.setItem(key, JSON.stringify(value));
        }
    }),
    get: wrapErr(({ key, parent }) => {
        if (parent) {
            return JSON.parse(localStorage.getItem(parent))[key];
        }
        else {
            return JSON.parse(localStorage.getItem(key));
        }
    }),
    remove: wrapErr(({ key, parent }) => {
        if (parent) {
            const existing = JSON.parse(localStorage.getItem(parent));
            if (existing) {
                delete existing[key];
                return localStorage.setItem(parent, JSON.stringify(existing));
            }
        }
        else {
            return localStorage.removeItem(key);
        }
    }),
    clear: wrapErr(() => {
        return localStorage.clear();
    })
};

export { localStorageHelper as localStorage };
