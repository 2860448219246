import { CheckmarkOutline32, PhoneOff32, PhoneVoice32 } from '@carbon/icons-react';
import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader } from 'carbon-components-react';
import { eventNames } from 'components/opportunities/CallNow';
import { feedButtonTypes } from 'components/opportunities/OpportunitiesList';
import useAccount from 'hooks/use-account';
import { useEffect, useReducer, useRef } from 'react';
import axios from 'util/axios';
import { getServerTimestamp } from 'util/format-date';
import { dollarAmount } from 'util/format-number';
import styles from './CallNowModal.module.scss';

const pollStatus = {
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed'
};

const UNQUALIFIED = 'UNQUALIFIED';
const QUALIFIED = 'QUALIFIED';

const INITIAL_STATE = {
    icon: (
        <div className={styles.iconContainer}>
            <PhoneVoice32 className={styles.callingIcon} />
        </div>
    ),
    title: 'Call In Progress'
};

const callReducer = (state, action) => {
    if (action.type === UNQUALIFIED) {
        return {
            icon: <PhoneOff32 className={styles.icon} />,
            message: 'You have not been charged for this call.',
            primaryButton: {
                label: 'OK',
                onClick: action.primaryButtonAction
            },
            showButtons: true,
            title: 'Call Complete'
        };
    }

    if (action.type === QUALIFIED) {
        return {
            icon: <CheckmarkOutline32 className={styles.qualifiedIcon} />,
            message: `You've successfully purchased this lead for ${action.formattedCost}.`,
            primaryButton: {
                label: 'View Lead',
                onClick: action.primaryButtonAction
            },
            secondaryButton: {
                label: 'Dismiss',
                onClick: action.secondaryButtonAction
            },
            showButtons: true,
            title: 'Call Complete'
        };
    }

    return {
        ...state,
        message: (
            <>
                Connecting you to <span className={styles.contact}>{action.contact}</span>
                <br />
                Please wait...
            </>
        ),
        subtext: (
            <>
                <p>
                    You will recieve a call from <strong>(844) 292-6247</strong> shortly. This call may be recorded. The
                    consumer will see the Caller ID you configured in account settings.
                </p>
                <p>
                    You will only be charged <strong>{action.formattedCost}</strong> for this lead if{' '}
                    <strong>{action.contact}</strong> picks up.
                </p>
                <p className={styles.smallSubtext}>
                    This window may take a few extra seconds to update after your call has ended. Thank you for your
                    patience.
                </p>
            </>
        )
    };
};

const CallNowModal = ({
    contact,
    cost,
    hideSecondarySuccessButton = false,
    leadAgentCallId: callId,
    leadId,
    myCalls,
    pollingInterval,
    pollingDuration,
    onEvent = () => {},
    setCallNowModalProps
}) => {
    const { setAccount } = useAccount();
    const [state, dispatch] = useReducer(callReducer, INITIAL_STATE);

    const endPollingTimer = useRef(); // Timer for when we would stop polling completely
    const nextPollTimer = useRef(); // Timer for the next `check_call_status` API request
    const lastCall = useRef(); // Timestamp to update last call column

    const formattedCost = dollarAmount(cost);

    useEffect(() => {
        lastCall.current = getServerTimestamp();

        // We dispatch an update to INITIAL_STATE here to pass in cost, contact, etc.
        dispatch({
            type: 'default', // this value doesn't matter as long as it's not QUALIFIED/UNQUALIFIED
            formattedCost,
            contact
        });
        checkCallStatus(callId);

        endPollingTimer.current = setTimeout(() => {
            // Clear timers if we reach duration
            clearTimeout(nextPollTimer.current);
        }, pollingDuration);

        return () => clearTimers();
    }, []);

    const clearTimers = () => [endPollingTimer, nextPollTimer].forEach(({ current }) => clearTimeout(current));

    const checkCallStatus = async (callId) => {
        try {
            const { data } = await axios.get(`/lead-feed.json?action=check_call_status&lead_agent_call_id=${callId}`);

            if (data.qualified) {
                const { leadId, adGroupId, account } = data;

                // update lead disposition to contacted
                try {
                    await axios.post(`/lead.json?action=update_status`, {
                        leads: [
                            {
                                lead_id: leadId,
                                ad_group_id: adGroupId,
                                status: 'contacted'
                            }
                        ]
                    });
                }
                catch (err) {}

                dispatch({
                    type: QUALIFIED,
                    formattedCost,
                    primaryButtonAction: () => {
                        onEvent(eventNames.QUALIFIED_CLICK, { leadId, adGroupId });
                    },
                    secondaryButtonAction: () => {
                        onEvent(eventNames.CALL_FINISHED);
                        setCallNowModalProps({}); // Close modal
                    }
                });

                onEvent(eventNames.QUALIFIED, {
                    adGroupId,
                    leadId,
                    lastCall: lastCall.current, // (String) temporary lastCall timestamp, server date-time format
                    myCalls: myCalls + 1, // (Number) increment myCalls
                    purchasedBy: feedButtonTypes.CALL_NOW // (String) hides BuyNow button, changes CallNow to purchased
                });
                setAccount(account); // updates account balance
            }
            else if (!data.qualified && data.status === pollStatus.IN_PROGRESS) {
                nextPollTimer.current = setTimeout(() => checkCallStatus(callId), pollingInterval);
                return;
            }
            else {
                dispatch({
                    type: UNQUALIFIED,
                    primaryButtonAction: () => {
                        onEvent(eventNames.CALL_FINISHED);
                        setCallNowModalProps({});
                    }
                });

                onEvent(eventNames.UNQUALIFIED, {
                    callDisabled: 1, // (Number) disable button, match API return
                    callDisabledReason: 'This lead was recently contacted. Please try again in 15 minutes.', // (String) tooltip text
                    lastCall: lastCall.current, // (String) temporary lastCall timestamp, server date-time format
                    leadId,
                    myCalls: myCalls + 1 // (Number) increment myCalls column
                });
            }

            // Call is finished, stop polling
            clearTimers();
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <ComposedModal
            aria-label="Call Now Modal"
            className={styles.modal}
            open={true}
            preventCloseOnClickOutside
            size="sm"
        >
            <ModalHeader title={state.title} />
            <ModalBody>
                <div className={styles.modalContent}>
                    {state.icon}
                    <div className={styles.message}>{state.message}</div>
                    {state.subtext && <div className={styles.subtext}>{state.subtext}</div>}
                </div>
            </ModalBody>

            {state.primaryButton && (
                <ModalFooter>
                    {!hideSecondarySuccessButton && state.secondaryButton && (
                        <Button
                            kind="secondary"
                            children={state.secondaryButton.label}
                            onClick={state.secondaryButton.onClick}
                        />
                    )}
                    {state.primaryButton && (
                        <Button
                            children={state.primaryButton.label}
                            color="primary"
                            onClick={state.primaryButton.onClick}
                        />
                    )}
                </ModalFooter>
            )}
        </ComposedModal>
    );
};

export default CallNowModal;
