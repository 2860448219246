import { AddAlt32 } from '@carbon/icons-react';
import { Button, Column, FormGroup, Row, ToastNotification } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import axios from 'util/axios';
import { formatError } from 'util/format-error';
import AddPaymentMethod from '../AddPaymentMethod/AddPaymentMethod';
import PaymentMethod from './PaymentMethod';
import styles from './PaymentMethods.module.scss';

const PaymentMethods = () => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [showAddPaymentDialog, setShowAddPaymentDialog] = useState(false);

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const getPaymentMethods = async () => {
        try {
            const res = await axios.get('profile.json?action=get_payment_methods');
            setPaymentMethods(res.data.paymentMethods);
        }
        catch (err) {}
    };

    useEffect(() => {
        getPaymentMethods();
    }, []);

    const onPaymentMethodAdded = (newPaymentMethod) => {
        setShowAddPaymentDialog(false);
        setSuccess('Payment Method Added');
        setPaymentMethods((paymentMethods) => [...paymentMethods, newPaymentMethod]);
    };

    const onPaymentMethodClosed = () => {
        setShowAddPaymentDialog(false);
    };

    const onPaymentMethodError = (message) => {
        setShowAddPaymentDialog(false);
        setError(message);
    };

    const deletePaymentMethod = async (paymentMethodId) => {
        try {
            await axios.post('profile.json?action=delete_payment_method', {
                paymentMethodId
            });
            setSuccess('Payment Method Deleted');
        }
        catch (err) {
            const { message } = formatError(err);
            setError(message);
        }

        getPaymentMethods();
    };

    const handleClose = () => {
        setSuccess(null);
        setError(null);
    };

    const status = error ? 'Error' : 'Success';

    return (
        <>
            {(error || success) && (
                <ToastNotification
                    kind={error ? 'error' : 'success'}
                    onCloseButtonClick={handleClose}
                    title={status}
                    lowContrast
                    iconDescription="Close"
                    statusIconDescription={error ? 'Error' : 'Success'}
                    caption={error || success}
                    timeout={4000}
                />
            )}
            <FormGroup legendText="" className={styles.formGroup}>
                {Boolean(paymentMethods?.length) && (
                    <Row className={styles.rowItem}>
                        <Column lg={8}>
                            {paymentMethods.map((pm) => (
                                <PaymentMethod
                                    card={pm}
                                    canDelete={paymentMethods.length > 1}
                                    onConfirmDelete={deletePaymentMethod}
                                    key={pm.paymentMethodId}
                                />
                            ))}
                        </Column>
                    </Row>
                )}
                <Button onClick={() => setShowAddPaymentDialog(true)} renderIcon={AddAlt32}>
                    Add Payment Method
                </Button>
                {showAddPaymentDialog && (
                    <AddPaymentMethod
                        onClose={onPaymentMethodClosed}
                        onSuccess={onPaymentMethodAdded}
                        onError={onPaymentMethodError}
                    />
                )}
            </FormGroup>
        </>
    );
};

export default PaymentMethods;
