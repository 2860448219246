import { Checkmark16, Close16 } from '@carbon/icons-react';
import { Button, Form, InlineLoading, InlineNotification, Modal, ToastNotification } from 'carbon-components-react';
import clsx from 'clsx';
import { PageButtons, PageSection } from 'components/common';
import { FatalError } from 'components/errors';
import useAccount, { statuses } from 'hooks/use-account';
import useNavigationGuard from 'hooks/use-navigation-guard';
import useUser from 'hooks/use-user';
import difference from 'lodash/difference';
import mergeWith from 'lodash/mergeWith';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios from 'util/axios';
import { products } from 'util/constants';
import { formatError } from 'util/format-error';
import { dollarAmount } from 'util/format-number';
import styles from './AccountForm.module.scss';
import Agent, { initialValues as agentValues } from './Agent/Agent';
import { options } from './Campaigns/CustomAutoCampaigns';
import { initialValues as deliveryValues } from './Delivery/Delivery';
import { initialValues as geographyValues, healthLifeInitialValues } from './Geography/Geography';
import { PhoneSettings, phoneValues } from './PhoneSettings/PhoneSettings';
import ProductSwitcher from './ProductSwitcher/ProductSwitcher';
import { Products } from './Products/Products';

const getAccountDeliveryPayload = (values) => {
    const smsValue = values.smsTo
        ? values.smsTo
            .split(',')
            .map((phone) => phone.trim())
            .filter(Boolean)
        : [];
    return {
        ...values,
        smsTo: smsValue
    };
};

const AccountForm = ({ mode = modes.CREATE, carrier }) => {
    const [loading, setLoading] = useState(mode !== modes.CREATE);
    const [submitting, setSubmitting] = useState(false);
    const [accountData, setAccountData] = useState(null);
    const [accountError, setAccountError] = useState(null);
    const [enabledProducts, setEnabledProducts] = useState(null);
    const [submitError, setSubmitError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [deletedFields, setDeletedFields] = useState(false);

    const { id, setAccount, canManageAccount, status } = useAccount();
    const { employee } = useUser();
    const history = useHistory();
    const location = useLocation();

    const isCreateMode = mode === modes.CREATE;
    const isPending = status === statuses.PENDING;
    const formDisabled = !isCreateMode && !canManageAccount;

    const showAgentSection = isCreateMode || (employee && canManageAccount);

    const initialValues = getInitialValues(isCreateMode);
    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: initialValues
    });

    const {
        formState: { errors, isSubmitting, isSubmitted, dirtyFields },
        reset,
        handleSubmit
    } = methods;

    const isFormDirty = Boolean(Object.keys(dirtyFields).length) || deletedFields;
    const clean = !isFormDirty;
    const hasErrors = Boolean(Object.keys(errors).length);
    const hasUnsavedChanges = isFormDirty && !isSubmitted;
    const { isNavigationBlocked, confirmNavigation, cancelNavigation } = useNavigationGuard(hasUnsavedChanges);

    const getAccount = () => axios.get(`/account.json?action=select&id=${id}`);
    const getCampaigns = () => axios.get(`/campaign.json?action=select&id=${id}`);
    const createAccount = (payload) => axios.post(`/account.json?action=insert_account`, payload);
    const updateAccount = (payload) => axios.post(`/account.json?action=update_account&id=${id}`, payload);
    const updateCampaigns = (payload) => axios.post(`/campaign.json?action=update&id=${id}`, payload);
    const updateAccountDelivery = (payload) =>
        axios.post(`/account.json?action=update_account_delivery&id=${id}`, payload);

    const getAccountData = () => {
        setLoading(true);

        const account = getAccount();
        const campaigns = getCampaigns();

        Promise.all([account, campaigns])
            .then((values) => {
                const formData = {
                    agent: values[0]?.data?.agent || {},
                    campaigns: values[1]?.data || {}
                };

                // extract phone settings data from first product
                const { smsTo, verifiedCallerId, autoConnectPhoneNum } =
                    Object.values(formData.campaigns)?.[0]?.delivery?.delivery || {};

                formData.phone = {
                    smsTo,
                    verifiedCallerId,
                    autoConnectPhoneNum
                };

                // Removes initialValues for products not in API response
                const disabledProducts = difference(Object.values(products), Object.keys(formData.campaigns));
                disabledProducts.forEach((product) => {
                    delete initialValues?.campaigns?.[product];
                });

                const merged = mergeWith({}, initialValues, formData, (objValue, srcValue, key) => {
                    if (['age', 'creditRating'].includes(key)) {
                        return srcValue ?? objValue;
                    }

                    if (['dailyCap'].includes(key)) {
                        return srcValue ?? '';
                    }

                    if (['bid', 'initialDeposit', 'amount'].includes(key)) {
                        return dollarAmount(srcValue, { withSign: false });
                    }
                });

                setAccountData(merged);
                let campaignKeys = Object.keys(formData.campaigns);
                setEnabledProducts(campaignKeys);
            })
            .catch((err) => {
                setAccountError(formatError(err));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onSubmit = async (values) => {
        setSubmitting(true);

        const agentPayload = { agent: { ...values.agent } };
        const productPayload = { product: { ...values.product } };

        if (isCreateMode) {
            try {
                const { data } = await createAccount({ ...agentPayload, ...productPayload });
                if (data?.account?.[0]) setAccount(data.account[0]);
                setSubmitSuccess(true);
            }
            catch (err) {
                const { message } = formatError(err);
                setSubmitError(message);
            }
            finally {
                setSubmitting(false);
            }
            return; // end of account creation.
        }

        const campaignsPayload = { ...values.campaigns };
        const accountDeliveryPayload = getAccountDeliveryPayload(values.phone);

        try {
            // AG-1254: Account updates need to happen before campaign and delivery updates.
            if (showAgentSection) {
                const { data } = await updateAccount(agentPayload);
                if (data?.account?.[0]) setAccount(data.account[0]);
            }
            await Promise.all([updateCampaigns(campaignsPayload), updateAccountDelivery(accountDeliveryPayload)]);
            setSubmitSuccess(true);
        }
        catch (err) {
            const { message } = formatError(err);
            setSubmitError(message);
        }
        finally {
            setSubmitting(false);
        }
    };

    // check for custom errors, if exists scroll into view.
    const checkCustomErrors = () => {
        if (errors.noCampaignsEnabled || errors.advertiserDailyCapError) {
            const noCampaignsEnabledError = document.getElementById('noCampaignsEnabled');
            const advertiserDailyCapError = document.getElementById('accountDailyCap-error-msg');
            const errorElement = noCampaignsEnabledError || advertiserDailyCapError;
            if (errorElement) {
                errorElement.scrollIntoView({ block: 'center' });
            }
        }
    };

    // Load account data
    useEffect(() => {
        if (id && !isCreateMode) getAccountData();
    }, [mode, id]);

    // Set form data after account data loads.
    useEffect(() => {
        const scrollToHashElement = () => {
            if (!location.hash) return;

            const el = document.querySelector(location.hash);
            if (!el) return;

            const headerHeight = 80; // with 10px buffer
            const offsetTop = el.offsetTop;

            if (!offsetTop || offsetTop - headerHeight <= headerHeight) return;

            window.scrollTo(0, offsetTop - headerHeight);
        };

        if (!isCreateMode && accountData) {
            // reset deletedFields when resetting form data.
            setDeletedFields(false);
            reset({
                ...accountData
            });
            setTimeout(scrollToHashElement, 1000);
        }

        return () => reset(initialValues);
    }, [reset, accountData, mode]);

    // Create mode: Redirect user after a successful submission.
    // Update mode: Reload account data after a successful submission.
    useEffect(() => {
        if (isSubmitted && submitSuccess) {
            if (isCreateMode) {
                history.replace('/p/account');
            }
            else {
                window.scrollTo(0, 0);
                getAccountData();
            }
        }
    }, [isSubmitted, submitSuccess, isCreateMode]);

    if (loading) {
        return [...Array(5).keys()].map((i) => <PageSection skeleton key={i} />);
    }

    if (accountError) {
        return <FatalError error={accountError} />;
    }

    return (
        <>
            {submitting && <LoadingOverlay isCreateMode={isCreateMode} />}
            {submitError && (
                <ToastNotification
                    kind="error"
                    title="Error"
                    caption={<span>{submitError}</span>}
                    timeout={7000}
                    hideCloseButton
                    onClose={() => setSubmitError(null)}
                    lowContrast
                />
            )}
            {submitSuccess && (
                <ToastNotification
                    kind="success"
                    title="Success"
                    caption="Account updated successfully."
                    timeout={3000}
                    hideCloseButton
                    onClose={() => setSubmitSuccess(false)}
                    lowContrast
                />
            )}
            <Modal
                size="xs"
                open={isNavigationBlocked}
                modalHeading="Unsaved Changes"
                primaryButtonText="Keep Editing"
                secondaryButtonText="Discard Changes"
                onRequestClose={cancelNavigation}
                onRequestSubmit={cancelNavigation}
                onSecondarySubmit={confirmNavigation}
                preventCloseOnClickOutside
            >
                <p>You have unsaved changes. What would you like to do?</p>
            </Modal>
            <FormProvider {...methods} setDeletedFields={setDeletedFields}>
                <Form className={styles.accountForm} onSubmit={handleSubmit(onSubmit)} noValidate>
                    {isPending && <ActivationMessage />}
                    {showAgentSection && (
                        <Agent namespace={namespaces.AGENT} mode={mode} formDisabled={formDisabled} carrier={carrier} />
                    )}
                    {!isCreateMode && <PhoneSettings namespace={namespaces.PHONE} formDisabled={formDisabled} />}
                    {isCreateMode && (
                        <Products namespace={namespaces.PRODUCT} formDisabled={formDisabled} carrier={carrier} />
                    )}
                    {!isCreateMode && Boolean(enabledProducts?.length) && (
                        <ProductSwitcher
                            formDisabled={formDisabled}
                            carrier={carrier}
                            enabledProducts={enabledProducts}
                        />
                    )}
                    {!formDisabled && (
                        <>
                            <PageButtons justify="flex-end">
                                <Button
                                    type="button"
                                    kind="secondary"
                                    onClick={() => history.replace('/p/leads')}
                                    renderIcon={Close16}
                                    data-testid="account-form-cancel"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={
                                        clean || isSubmitting || (isCreateMode && Boolean(errors.noProductsEnabled))
                                    }
                                    renderIcon={Checkmark16}
                                    style={{ marginLeft: 1 }}
                                    data-testid="account-form-submit"
                                    onClick={() => hasErrors && checkCustomErrors()}
                                >
                                    {isCreateMode ? 'Complete Account Setup' : 'Update Account'}
                                </Button>
                            </PageButtons>
                        </>
                    )}
                </Form>
            </FormProvider>
        </>
    );
};

const modes = {
    CREATE: 'create',
    UPDATE: 'update'
};

const namespaces = {
    AGENT: 'agent',
    GEOGRAPHY: 'geography',
    CAMPAIGNS: 'campaign',
    DELIVERY: 'delivery',
    PRODUCT: 'product',
    PHONE: 'phone'
};

const getInitialValues = (isCreateMode) => {
    if (isCreateMode) {
        return {
            [namespaces.AGENT]: agentValues
        };
    }

    return {
        [namespaces.AGENT]: agentValues,
        [namespaces.PHONE]: phoneValues,
        campaigns: {
            [products.AUTO]: {
                [namespaces.GEOGRAPHY]: geographyValues,
                [namespaces.DELIVERY]: deliveryValues
            },
            [products.HOME]: {
                [namespaces.GEOGRAPHY]: geographyValues,
                [namespaces.DELIVERY]: deliveryValues
            },
            [products.HEALTH]: {
                [namespaces.GEOGRAPHY]: healthLifeInitialValues,
                [namespaces.DELIVERY]: deliveryValues
            },
            [products.LIFE]: {
                [namespaces.GEOGRAPHY]: healthLifeInitialValues,
                [namespaces.DELIVERY]: deliveryValues
            }
        }
    };
};

const ActivationMessage = () => (
    <InlineNotification
        kind="info"
        title=""
        subtitle={
            <span>
                Please review your account information, and go to <Link to="/p/deposits">Deposits</Link> to fund and
                activate your account.
            </span>
        }
        hideCloseButton
        lowContrast
    />
);

const LoadingOverlay = ({ isCreateMode }) => {
    return (
        <div className={clsx('bx--loading-overlay', styles.inlineLoading)}>
            <InlineLoading description={isCreateMode ? 'Creating account...' : 'Saving account settings...'} />
        </div>
    );
};

export { agentValues, options as customTargetingOptions, AccountForm as default, modes, namespaces };
