import { Announcement } from 'components/announcements';
import { PrivateRoute } from 'components/routes';
import useDevice from 'hooks/use-device';
import useUser from 'hooks/use-user';
import Layout from 'layouts/Platform';
import { Redirect, Route, Switch } from 'react-router-dom';
import Account from './Account';
import Accounts from './Accounts';
import Deposits from './Deposits';
import Features from './Features';
import Lead from './Lead';
import Leads from './Leads';
import NewAccount from './NewAccount';
import NewCarrierAccount from './NewCarrierAccount';
import Opportunities from './Opportunities';
import OpportunitiesDetail from './OpportunitiesDetail';
import Profile from './Profile';
import Referrals from './Referrals';
import Transactions from './Transactions';

const Platform = () => {
    const { admin } = useUser();
    const { desktop } = useDevice();

    return (
        <>
            {desktop && <Announcement />}
            <Layout>
                <Switch>
                    <PrivateRoute
                        path="/p/opportunities/:id"
                        meta={{
                            requiresLeadFeed: true
                        }}
                    >
                        <OpportunitiesDetail />
                    </PrivateRoute>

                    <PrivateRoute
                        path="/p/opportunities"
                        meta={{
                            requiresAccount: true,
                            requiresLeadFeed: true
                        }}
                    >
                        <Opportunities />
                    </PrivateRoute>

                    <PrivateRoute
                        path="/p/leads/purchased"
                        meta={{
                            requiresAccount: true
                        }}
                    >
                        <Leads type="purchased" key="purchased" />
                    </PrivateRoute>

                    <PrivateRoute
                        path="/p/leads/returned"
                        meta={{
                            requiresAccount: true
                        }}
                    >
                        <Leads type="returned" key="returned" />
                    </PrivateRoute>

                    <PrivateRoute path="/p/leads/:id/:adGroupId">
                        <Lead />
                    </PrivateRoute>

                    <Route path="/p/leads">
                        <Redirect to="/p/leads/purchased" />
                    </Route>

                    <PrivateRoute
                        path="/p/transactions"
                        meta={{
                            requiresAccount: true
                        }}
                    >
                        <Transactions />
                    </PrivateRoute>

                    <PrivateRoute
                        path="/p/account"
                        meta={{
                            requiresAccount: true
                        }}
                    >
                        <Account />
                    </PrivateRoute>

                    <PrivateRoute
                        path="/p/accounts/new-sf-subsidy"
                        meta={{
                            requiresAdmin: true,
                            requiresCanCreateAccount: true
                        }}
                    >
                        <NewCarrierAccount carrier="State Farm" />
                    </PrivateRoute>

                    <PrivateRoute
                        path="/p/accounts/new"
                        meta={{
                            requiresAdmin: true,
                            requiresCanCreateAccount: true
                        }}
                    >
                        <NewAccount />
                    </PrivateRoute>

                    <PrivateRoute
                        path="/p/accounts"
                        meta={{
                            requiresAdmin: true
                        }}
                    >
                        <Accounts />
                    </PrivateRoute>

                    <PrivateRoute path="/p/profile">
                        <Profile />
                    </PrivateRoute>

                    <PrivateRoute
                        path="/p/referrals"
                        meta={{
                            requiresAccount: true
                        }}
                    >
                        <Referrals />
                    </PrivateRoute>

                    <PrivateRoute
                        path="/p/deposits"
                        meta={{
                            requiresAccount: true
                        }}
                    >
                        <Deposits />
                    </PrivateRoute>

                    <PrivateRoute
                        path="/p/features"
                        meta={{
                            requiresAccount: true,
                            requiresAdmin: true,
                            requiresCanManageAccount: true
                        }}
                    >
                        <Features />
                    </PrivateRoute>

                    <Route path="/">{admin ? <Redirect to="/p/accounts" /> : <Redirect to="/p/leads" />}</Route>
                </Switch>
            </Layout>
        </>
    );
};

export default Platform;
