import { TooltipDefinition } from 'carbon-components-react';
import clsx from 'clsx';
import { DefaultTable } from 'components/tables';
import { subDays } from 'date-fns';
import useAccount from 'hooks/use-account';
import useApi from 'hooks/use-api';
import useQueryParams, { makeParamString } from 'hooks/use-query-params';
import { useEffect, useMemo } from 'react';
import { dateToString, relativePrettyString, toUserTimeZone } from 'util/format-date';
import { dollarAmount } from 'util/format-number';
import { capitalizeFirstLetter } from 'util/format-text';
import styles from './TransactionList.module.scss';

const TransactionList = ({ setExportData }) => {
    const { id } = useAccount();
    const { query } = useQueryParams();

    const dateFrom = query.get('date_from') || dateToString(subDays(new Date(), 30));
    const dateTo = query.get('date_to') || dateToString(new Date());
    const product = query.get('product');

    const params = useMemo(
        () => ({
            advertiser_id: id,
            date_from: dateFrom,
            date_to: dateTo
        }),
        [id, dateFrom, dateTo]
    );
    const [result, loading, error] = useApi(`/transaction.json?action=get&${makeParamString(params)}`);

    useEffect(() => {
        setExportData({
            visible: Boolean(result?.transactions?.length),
            product,
            params
        });
    }, [result, params, setExportData]);

    const data = useMemo(() => {
        if (result?.transactions) {
            const rows = result.transactions.map((t) => ({
                ...t,
                dollarAmount: getDollarAmount(t.amount)
            }));

            return rows;
        }

        return [];
    }, [result]);

    return (
        <DefaultTable
            data={data}
            error={error}
            filters={filters}
            headers={columns}
            loading={loading}
            outputModifiers={modifiers}
            record="transaction"
            initialDates={{ dateFrom, dateTo }}
        />
    );
};

const modifiers = {
    dollarAmount: ({ amount, dollarAmount }) => {
        return (
            <span className={clsx(styles.amount, amount < 0 ? styles.negative : styles.positive)}>{dollarAmount}</span>
        );
    },
    status: ({ status }) => <span className={styles.statusText}>{status}</span>,
    time: ({ time }) => (
        <TooltipDefinition
            align="center"
            children={relativePrettyString(time)}
            direction="top"
            tooltipText={toUserTimeZone(time)}
        />
    ),
    product: ({ product }) => capitalizeFirstLetter(product)
};

const getDollarAmount = (amount) => `${amount < 0 ? '-' : '+'} ${dollarAmount(Math.abs(amount))}`;

const columns = [
    {
        header: 'Type',
        key: 'type'
    },
    {
        header: 'Description',
        key: 'description'
    },
    {
        header: 'Product',
        key: 'product'
    },
    {
        header: 'Status',
        key: 'status'
    },
    {
        header: 'Date',
        key: 'time'
    },
    {
        header: 'Amount',
        key: 'dollarAmount'
    }
];

const filters = {
    product: {
        label: 'Product',
        type: 'dropdown',
        queryString: 'product',
        options: [
            { value: 'auto', label: 'Auto' },
            { value: 'home', label: 'Home' }
        ]
    }
};

export default TransactionList;
