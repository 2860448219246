import { TextInput } from 'carbon-components-react';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './Campaigns.module.scss';

const MIN_DAILY_CAP_LIMIT = 1;

const CampaignDailyCapField = ({ namespace, campaignKey, disabled }) => {
    const {
        register,
        formState: { errors },
        getValues,
        setValue
    } = useFormContext();
    const name = `${namespace}.${campaignKey}.dailyCap`;
    const [root, product, section] = namespace.split('.');
    const campaignErrors = errors[root]?.[product]?.[section] || {};

    const currentCampaign = getValues(`${namespace}.${campaignKey}`);

    const cap = register(name, {
        validate: (val) => {
            if (!currentCampaign?.enabled || val === '') return true;
            if (val < MIN_DAILY_CAP_LIMIT) return `Min Daily Cap: ${MIN_DAILY_CAP_LIMIT}`;
            return true;
        }
    });

    if (!currentCampaign) {
        return null;
    }

    const getInvalidState = () => {
        if (campaignErrors[campaignKey]) {
            return !!campaignErrors[campaignKey]?.dailyCap;
        }
        return false;
    };

    const getInvalidMessage = () => {
        if (campaignErrors[campaignKey]) {
            return campaignErrors[campaignKey]?.dailyCap?.message;
        }
        return '';
    };

    return (
        <fieldset className="bx--fieldset" disabled={!currentCampaign.enabled}>
            <div className={styles.dailyCapFieldContainer}>
                <TextInput
                    id={name}
                    className={styles.dailyCapCampaignField}
                    name={cap.name}
                    ref={cap.ref}
                    labelText=""
                    defaultValue={currentCampaign.dailyCap || ''}
                    onChange={(evt) => cap.onChange(evt)}
                    onBlur={(evt) => {
                        cap.onBlur(evt);
                        const value = evt.target.value;
                        setValue(name, value === '' ? '' : Number(value), { shouldDirty: true, shouldValidate: true });
                    }}
                    placeholder="Open"
                    disabled={disabled}
                    invalid={getInvalidState()}
                    invalidText={getInvalidMessage()}
                />
            </div>
        </fieldset>
    );
};

const AdvertiserDailyCap = ({ namespace }) => {
    const { getValues, setValue, setError, clearErrors } = useFormContext();
    const campaignsData = getValues(namespace);
    const dailyCapKeys = Object.keys(campaignsData).filter((key) => key !== 'dailyCapLevel');

    // set daily cap to first campaign daily cap value since all values will be the same
    const [dailyCap, setDailyCap] = useState(campaignsData[dailyCapKeys[0]]?.dailyCap || '');

    const getDailyCapError = () => {
        if (dailyCap === '') return '';
        if (dailyCap < MIN_DAILY_CAP_LIMIT) return `Min Daily Cap: ${MIN_DAILY_CAP_LIMIT}`;
        if (!Number.isInteger(Number(dailyCap))) return 'Invalid Daily Limit';
        return '';
    };

    useEffect(() => {
        // AdvertiserDailyCap updates all the individual campaign daily caps to be the same value
        // It is not a form field that gets submitted to the API, therefore we manage the error
        // and blocking submission manually.
        const error = getDailyCapError();
        const advertiserDailyCapError = Boolean(error);
        if (advertiserDailyCapError) {
            setError('advertiserDailyCapError', {
                type: 'manual',
                message: error
            });
        }
        else {
            clearErrors('advertiserDailyCapError');
        }
    }, [dailyCap]);

    const updateDailyCaps = () => {
        const cap = dailyCap === '' ? '' : Number(dailyCap);
        dailyCapKeys.forEach((key) =>
            setValue(`${namespace}.${key}.dailyCap`, cap, { shouldDirty: true, shouldValidate: true })
        );
    };

    return (
        <>
            <h4 className={styles.formGroupHeader}>Daily Limit</h4>
            <label className={styles.description} htmlFor='accountDailyCap'>
                Set the max number of leads to receive per day.
            </label>
            <div className="bx--fieldset">
                <div className={clsx(styles.dailyCapFieldContainer, styles.advertiserDailyCapContainer)}>
                    <TextInput
                        id="accountDailyCap"
                        className={styles.advertiserDailyCap}
                        defaultValue={dailyCap || ''}
                        labelText=""
                        onChange={(evt) => setDailyCap(evt.target.value)}
                        onBlur={updateDailyCaps}
                        placeholder="Open"
                        invalid={Boolean(getDailyCapError())}
                        invalidText={getDailyCapError()}
                    />
                </div>
            </div>
        </>
    );
};

export { AdvertiserDailyCap, CampaignDailyCapField };
