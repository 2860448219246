import { TermsContent } from 'components/legal';
import Layout from 'layouts/Centered';
import { Helmet as Head } from 'react-helmet-async';

const Terms = () => (
    <>
        <Head>
            <title>Terms and Conditions</title>
        </Head>
        <Layout title="MediaAlpha for Agents Terms">
            <TermsContent />
        </Layout>
    </>
);

export default Terms;
