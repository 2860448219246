import { Logout24, UserProfile24 } from '@carbon/icons-react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import useAuth from 'hooks/use-auth';
import useDevice from 'hooks/use-device';
import useUser, { groups } from 'hooks/use-user';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './AvatarMenu.module.scss';

const AvatarMenu = ({ onMenuToggle }) => {
    const { group } = useUser();
    const { signOut } = useAuth();
    const { mobile } = useDevice();

    useEffect(() => {
        document.addEventListener('click', onMenuToggle);
        return () => document.removeEventListener('click', onMenuToggle);
    }, [onMenuToggle]);

    const displayProfileLink = !mobile && group === groups.AGENT;

    return (
        <motion.div className={styles.wrap} {...menuAnim}>
            {displayProfileLink && (
                <Link to="/p/profile" className={styles.menuItem}>
                    <UserProfile24 className={styles.icon} />
                    My Profile
                </Link>
            )}
            <button className={clsx(styles.menuItem, styles.signOut)} onClick={() => signOut()}>
                <Logout24 className={styles.icon} />
                Sign Out
            </button>
        </motion.div>
    );
};

const menuAnim = {
    initial: {
        opacity: 0,
        scale: 0,
        originX: 1,
        originY: 0
    },
    animate: {
        opacity: 1,
        scale: 1
    },
    transition: {
        duration: 0.1
    }
};

export default AvatarMenu;
