import { Headset32 } from '@carbon/icons-react';
import styles from './AccountManager.module.scss';

const AccountManager = ({ accountManager, collapsed }) => {
    
    const Information = () => (
        <div className={styles.information}>
            <div className={styles.header}>Client Success Manager</div>
            <div className={styles.name}>{accountManager.name}</div>
            {accountManager.email && (
                <div className={styles.email}>
                    <a className={styles.link} href={`mailto:${accountManager.email}`}>
                        {accountManager.email}
                    </a>
                </div>
            )}
            {accountManager.phone && (
                <div className={styles.phone}>
                    <a className={styles.link} href={`tel:${accountManager.phone}`}>
                        {accountManager.phone}
                    </a>
                </div>
            )}
        </div>
    );

    const StaticDisplay = () => (
        <div className={styles.static}>
            <Information />
        </div>
    );

    const CollapsedDisplay = () => (
        <div className={styles.collapsed}>
            <Headset32 className={styles.icon} />
            <Information />
        </div>
    );

    return collapsed ? <CollapsedDisplay /> : <StaticDisplay />;
};

export default AccountManager;
