import { useEffect } from 'react';

/**
 * Custom hook to help detect a click outside of the React element useRef passed and call callback with false.
 * Helpful for closing of modals and dialogues on screen.
 *
 * @param {object} ref the main React element reference.
 * @param {boolean} currentOpenState current state of the menu/modal; true if open, false otherwise.
 * @param {function} callback the callback is called with false when it detects an outside click.
 * @param {Array} excludedClasses optional; classes that should be excluded/ avoided from being detected an outside click.
 */
const useOutsideClickToClose = (ref, currentOpenState, callback, excludedClasses = []) => {
    //avoid excludedClasses list
    const matchesExcludedClass = (evt) => {
        let matches = false;
        if (excludedClasses.length) {
            const classList = evt.target.getAttribute('class').split(' ');
            matches = classList.some((item) => excludedClasses.includes(item));
        }
        return matches;
    };

    useEffect(() => {
        /**
         * call callback function if clicked on outside of element
         */
        const handleClickOutside = (evt) => {
            if (ref.current && !ref.current.contains(evt.target) && !matchesExcludedClass(evt)) {
                callback(false, evt.target);
            }
        };
        // Bind the event listener if menu is open
        if (currentOpenState) {
            document.addEventListener('click', handleClickOutside);
        }
        else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside);
        };
    }, [ref, currentOpenState]);
};

export default useOutsideClickToClose;
