import clsx from 'clsx';
import { motion } from 'framer-motion';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styles from './PageSection.module.scss';

const PageSection = ({ children, className, contentClassName, icon, name, noBorder, noPadding, skeleton, ...rest }) => {
    if (skeleton) {
        return (
            <section className={clsx(styles.section, noBorder && styles.noBorder, className)}>
                <SkeletonTheme color="#eaeaea" highlightColor="#d9d9d9">
                    <div className={styles.sectionHeader}>
                        <Skeleton circle width={40} height={40} />
                        <Skeleton width={200} height={20} style={{ marginLeft: 10 }} />
                    </div>
                    <div className={styles.sectionContent}>
                        <Skeleton count={5} height={20} style={{ marginTop: 5 }} />
                    </div>
                </SkeletonTheme>
            </section>
        );
    }

    return (
        <motion.section
            initial={{
                opacity: 0,
                x: -20
            }}
            animate={{
                opacity: 1,
                x: 0
            }}
            transition={{
                ease: 'easeOut',
                duration: 0.5
            }}
            className={clsx(styles.section, noBorder && styles.noBorder, className)}
            {...rest}
        >
            {name && (
                <div className={styles.sectionHeader}>
                    {icon && <img src={icon} className={styles.headerIcon} alt={name} />}
                    <h3 className={styles.sectionTitle}>{name}</h3>
                </div>
            )}
            <div className={clsx(styles.sectionContent, noPadding && styles.noPadding, contentClassName)}>
                {children}
            </div>
        </motion.section>
    );
};

export default PageSection;
