import { useEffect, useReducer } from 'react';
import axios from 'util/axios';
import { formatError } from 'util/format-error';

const INITIAL_STATE = {
    result: null,
    loading: true,
    error: null
};

const LOADING = 'LOADING';
const COMPLETE = 'COMPLETE';
const ERROR = 'ERROR';

const fetchReducer = (state, action) => {
    if (action.type === 'LOADING') {
        return {
            result: null,
            loading: true,
            error: null
        };
    }

    if (action.type === 'COMPLETE') {
        return {
            result: action.payload.response,
            loading: false,
            error: null
        };
    }

    if (action.type === 'ERROR') {
        return {
            result: null,
            loading: false,
            error: action.payload.error
        };
    }

    return INITIAL_STATE;
};

/**
 * Sends GET and POST requests using axios. Returns a reduced state with the result, loading status, and error for the current request.
 * https://github.com/axios/axios
 * @param   {String}   url      Required – The URL passed through to axios.
 * @param   {Object}   data     Optional - The axios data object. If included, the request method is POST.
 * @param   {Object}   conf     Optional - The axios conf object.
 * @param   {Function} callback Optional - Callback to run against the returned data before the final dispatch.
 * @returns {Array}             [result, loading, error]
 */
const useApi = (url, data, conf, callback) => {
    const [state, dispatch] = useReducer(fetchReducer, INITIAL_STATE);

    useEffect(() => {
        if (!url) return;
        let ignore = false;

        dispatch({ type: LOADING });

        (async () => {
            try {
                const response = data ? await axios.post(url, data, conf) : await axios.get(url, conf);

                if (ignore) return;
                dispatch({
                    type: COMPLETE,
                    payload: {
                        response: typeof callback === 'function' ? callback(response.data) : response.data
                    }
                });
            }
            catch (error) {
                if (ignore) return;
                dispatch({
                    type: ERROR,
                    payload: {
                        error: formatError(error)
                    }
                });
            }
        })();

        return () => (ignore = true);
    }, [url, data, conf]);

    return [state.result, state.loading, state.error];
};

export default useApi;
