import { CaretDown16 } from '@carbon/icons-react';
import useAccount, { statuses } from 'hooks/use-account';
import useDevice from 'hooks/use-device';
import { useState } from 'react';
import AccountInfo from './AccountInfo';
import AccountMenu from './AccountMenu';
import styles from './AccountSwitcher.module.scss';

const AccountSwitcher = ({ closeMobileMenu }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { account, id, accounts } = useAccount();
    const { mobile } = useDevice();

    const onMenuToggle = () => (menuOpen ? handleMenuClose() : handleMenuOpen());
    const handleMenuOpen = () => setMenuOpen(true);
    const handleMenuClose = () => {
        setMenuOpen(false);
        if (mobile) closeMobileMenu();
    };

    const pendingAccounts = accounts?.length
        ? accounts.filter((acc) => (acc.status === statuses.PENDING || acc.status === statuses.UNSOLD) && acc.id !== id)
        : [];

    if (!account) return null;

    return (
        <div className={styles.wrap}>
            <div className={styles.accountSelect} onClick={onMenuToggle}>
                <AccountInfo account={account} />
                <CaretDown16 className={styles.expand} />
                <PendingAccountsIndicator pendingAccounts={pendingAccounts} />
            </div>
            {menuOpen && <AccountMenu onMenuToggle={onMenuToggle} />}
        </div>
    );
};

const PendingAccountsIndicator = ({ pendingAccounts }) => {
    if (!pendingAccounts.length) return null;
    return (
        <div className={styles.pendingAccounts}>
            {pendingAccounts.length} Pending Account{pendingAccounts.length > 1 ? 's' : ''}
        </div>
    );
};

export default AccountSwitcher;
