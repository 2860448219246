import { InlineNotification } from 'carbon-components-react';
import clsx from 'clsx';
import { LeadCallButton } from 'components/leads';
import useAccount from 'hooks/use-account';
import carIcon from 'img/car.svg';
import clockIcon from 'img/clock.svg';
import coverageIcon from 'img/continuous.svg';
import homeownerIcon from 'img/home.svg';
import mapMarkerIcon from 'img/map_marker.svg';
import emailIcon from 'img/paper_plane.svg';
import phoneIcon from 'img/phone.svg';
import riskIcon from 'img/risk_profile.svg';
import shieldIcon from 'img/shield.svg';
import halfShieldIcon from 'img/shield_half.svg';
import starShieldIcon from 'img/shield_star.svg';
import driverIcon from 'img/user.svg';
import vehicleIcon from 'img/vehicle.svg';
import isNil from 'lodash/isNil';
import { Fragment } from 'react';
import { DataBlock, fullAddress, renderName } from './index';
import styles from './LeadDetail.module.scss';

const AutoBasics = ({
    lead,
    formatters,
    isLeadPreview = false,
    callNowButton,
    adGroupId,
    leadId,
    advertiser,
    callDisabled,
    callDisabledReason
}) => {
    return (
        <div className={styles.basics}>
            <DataBlock value={fullAddress(lead)} label="address" icon={mapMarkerIcon} isBlurred={isLeadPreview}>
                <div>
                    <div className={clsx(isLeadPreview && styles.blurred)}>{lead.address || ''}</div>
                    <div>
                        {lead.city || ''}, {lead.state || ''} {lead.zip || ''}
                    </div>
                </div>
            </DataBlock>
            <DataBlock value={lead.phone} label="phone" icon={phoneIcon} isBlurred={isLeadPreview}>
                {isLeadPreview ? (
                    <span className={styles.blurred}>{lead.phone}</span>
                ) : (
                    <a href={`tel:${lead.phone}`}>{lead.phone}</a>
                )}
                {!isLeadPreview && callNowButton && (
                    <LeadCallButton
                        adGroupId={adGroupId}
                        lead={lead}
                        leadId={leadId}
                        advertiser={advertiser}
                        callDisabled={callDisabled}
                        callDisabledReason={callDisabledReason}
                        style={{ margin: '0 10px' }}
                    />
                )}
            </DataBlock>
            <DataBlock value={lead.email} label="email" icon={emailIcon} isBlurred={isLeadPreview}>
                {isLeadPreview ? (
                    <span className={styles.blurred}>{lead.email}</span>
                ) : (
                    <a href={`mailto:${lead.email}`} style={{ textTransform: 'lowercase' }}>
                        {lead.email}
                    </a>
                )}
            </DataBlock>
            <DataBlock value={lead.homeOwnership} label="homeowner" icon={homeownerIcon} format={formatters.yesNo} />
            <DataBlock value={lead.homeGarage} label="garage" icon={carIcon} format={formatters.yesNo} />
            <DataBlock
                value={lead.currentlyInsured}
                label="currently insured"
                icon={shieldIcon}
                format={formatters.yesNo}
            />
            <DataBlock
                value={lead.currentCompany}
                label="current insurer"
                icon={starShieldIcon}
                format={formatters.emptyNA}
            />
            <DataBlock
                value={lead.currentCustomer}
                label="with current insurer"
                icon={clockIcon}
                format={(val) => `${val} Months`}
            />
            <DataBlock
                value={lead.continuousCoverage}
                label="continuous coverage"
                icon={coverageIcon}
                format={(val) => `${val} Months`}
            />
            <DataBlock value={lead.coverageType} label="coverage type" icon={halfShieldIcon} />
            <DataBlock
                value={lead.biPerPerson}
                label="bodily injury per person"
                icon={riskIcon}
                format={formatters.currency}
            />
            <DataBlock
                value={lead.biPerIncident}
                label="bodily injury per incident"
                icon={riskIcon}
                format={formatters.currency}
            />
        </div>
    );
};

const AutoDrivers = ({ drivers, incidents, isLeadPreview, formatters }) => {
    const { account } = useAccount();
    const { subsidyAdvertiserId } = account;

    if (isNil(drivers) || drivers.length === 0) {
        return <div>No drivers to display</div>;
    }

    const renderBirthDate = (date) => {
        // show only the year for lead preview
        return (
            <>
                {date.slice(0, 4)}
                <span className={styles.blurred}>{date.slice(4)}</span>
            </>
        );
    };

    return (
        <div className={styles.drivers}>
            <div className={styles.title}>
                <img src={driverIcon} alt="drivers" />
                Driver(s) Information
            </div>
            {drivers.map((driver, i) => {
                const driverIncidents = incidents ? incidents.filter((inc) => +inc.driver === i) : [];
                return (
                    <div key={`driver${i}`}>
                        {i > 0 && <hr className={styles.divider} />}
                        <div className={styles.sectionTitle}>
                            Driver {i + 1} {i === 0 && <span>(primary)</span>}
                        </div>
                        <div className={styles.sectionData}>
                            <DataBlock
                                value={isLeadPreview ? renderName(driver.driver, i) : driver.driver}
                                label="name"
                                isBlurred={isLeadPreview}
                            />
                            <DataBlock value={driver.relationship} label="relation" />
                            <DataBlock
                                value={isLeadPreview ? renderBirthDate(driver.birthDate) : driver.birthDate}
                                label="dob"
                                isBlurred={isLeadPreview}
                            />
                            <DataBlock
                                value={driver.gender}
                                label="gender"
                                format={(val) => (val === 'M' ? 'Male' : val === 'F' ? 'Female' : 'Non-binary')}
                            />
                            <DataBlock value={driver.maritalStatus} label="marital status" />
                            {!Number(subsidyAdvertiserId) && <DataBlock value={driver.creditRating} label="credit" />}
                            <DataBlock value={driver.licenseStatus} label="license" />
                            <DataBlock value={driver.licenseState} label="license state" />
                            <DataBlock value={driver.sr_22} label="sr-22" format={formatters.yesNo}></DataBlock>
                            <DataBlock
                                value={driver.primaryVehicle}
                                label="primary vehicle"
                                format={formatters.addOne}
                            />
                            {driverIncidents.length > 0 &&
                                driverIncidents.map((incident, i) => (
                                    <Fragment key={i}>
                                        <InlineNotification
                                            title={`Incident ${i + 1}`}
                                            kind="warning-alt"
                                            className={styles.incidentTitle}
                                            lowContrast
                                            hideCloseButton
                                        />
                                        <DataBlock
                                            value={incident.type}
                                            label="type"
                                            format={(val) => (val.toLowerCase() === 'dui' ? val.toUpperCase() : val)}
                                        />
                                        <DataBlock value={incident.incidentDate} label="date" />
                                        <DataBlock value={incident.description} label="description" />
                                        <DataBlock
                                            value={incident.claimAtFault}
                                            label="claim at fault"
                                            format={formatters.yesNo}
                                        />
                                        <DataBlock
                                            value={incident.accidentAtFault}
                                            label="accident at fault"
                                            format={formatters.yesNo}
                                        />
                                    </Fragment>
                                ))}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const AutoVehicles = ({ vehicles, isLeadPreview, formatters }) => {
    if (isNil(vehicles) || vehicles.length === 0) {
        return <div className={styles.noData}>No vehicles to display.</div>;
    }

    return (
        <div className={styles.vehicles}>
            <div className={styles.title}>
                <img src={vehicleIcon} alt="vehicles" />
                Vehicle(s) Information
            </div>
            {vehicles.map((vehicle, i) => {
                return (
                    <div key={`vehicle${i}`}>
                        {i > 0 && <hr className={styles.divider} />}
                        <div className={styles.sectionTitle}>
                            Vehicle {i + 1} {i === 0 && <span>(primary)</span>}
                        </div>
                        <div className={styles.sectionData}>
                            <DataBlock value={vehicle.ownership} label="ownership" />
                            <DataBlock
                                value={<span className={clsx(isLeadPreview && styles.blurred)}>{vehicle.vin}</span>}
                                label="vin"
                                isBlurred={isLeadPreview}
                            />
                            <DataBlock value={vehicle.year} label="year" />
                            <DataBlock value={vehicle.make} label="make" format={formatters.lowerCase} />
                            <DataBlock value={vehicle.model} label="model" format={formatters.lowerCase} />
                            <DataBlock value={vehicle.primaryPurpose} label="purpose" />
                            <DataBlock
                                value={vehicle.annualMileage}
                                label="annual mileage"
                                format={formatters.number}
                            />
                            <DataBlock
                                value={vehicle.currentMileage}
                                label="current mileage"
                                format={formatters.number}
                            />
                            <DataBlock
                                value={vehicle.comprehensive}
                                label="comp. deductible"
                                format={formatters.currency}
                            />
                            <DataBlock
                                value={vehicle.collision}
                                label="coll. deductible"
                                format={formatters.currency}
                            />
                            <DataBlock
                                value={vehicle.primaryDriver}
                                label="primary driver"
                                format={formatters.addOne}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export { AutoBasics, AutoDrivers, AutoVehicles };
