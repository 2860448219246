import { Checkbox, Column, InlineNotification, Row, Select, SelectItem } from 'carbon-components-react';
import states from 'data/us-states';
import useAccount from 'hooks/use-account';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import axios from 'util/axios';
import { products } from 'util/constants';
import { capitalizeFirstLetter } from 'util/format-text';
import styles from './Products.module.scss';

const availableProducts = [products.AUTO, products.HOME];

const SubsidyProducts = ({ namespace, formDisabled, activatedProducts = [], isEdit }) => {
    const {
        clearErrors,
        formState: { submitCount },
        getValues,
        setError,
        setValue,
        unregister,
        register,
        getFieldState
    } = useFormContext();
    const { error: autoStateError } = getFieldState(`${namespace}.${products.AUTO}.state`);
    const { error: homeStateError } = getFieldState(`${namespace}.${products.HOME}.state`);

    const productValues = getValues(namespace);
    const isAccountCreation = !activatedProducts.length;
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [stateSelected, setStateSelected] = useState('');

    const watchAgentState = useWatch({
        name: `agent.agencyState`
    });

    useEffect(() => {
        handleStateChange({ target: { value: watchAgentState } });
    }, [watchAgentState]);

    useEffect(() => {
        if (!isAccountCreation) return;

        if (!productValues) {
            setError('noProductsEnabled', {
                type: 'manual'
            });
        }
        else {
            clearErrors('noProductsEnabled');
        }
    }, [productValues]);

    const { id } = useAccount();
    const getCampaigns = () => axios.get(`/campaign.json?action=select&id=${id}`);

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    const { data } = await getCampaigns();
                    const product = Object.entries(data)?.[0]?.[1];
                    setStateSelected(product?.geography?.state);
                }
                catch (err) {
                    // do nothing
                    console.error('Error getting campaign state', err);
                }
            })();
        }
    }, []);

    const handleStateChange = (event) => {
        const state = event.target.value;

        setStateSelected(state);
        selectedProducts.forEach((product) => {
            setValue(`${namespace}.${product}.state`, state);
        });
    };

    const showSection = (type) => selectedProducts.includes(type);
    const handleCheckboxClick = (checked, product) => {
        if (checked) {
            register(`${namespace}.${product}.state`, {
                required: 'State is required'
            });
            setValue(`${namespace}.${product}.state`, stateSelected, { shouldDirty: true });
            setSelectedProducts([...selectedProducts, product]);
        }
        else {
            unregister(`${namespace}.${product}`);
            const updated = selectedProducts.filter((item) => item !== product);
            setSelectedProducts(updated);
        }
    };

    return (
        <>
            {isAccountCreation && !selectedProducts.length && (
                <InlineNotification
                    id="noProductsEnabled"
                    kind={submitCount > 0 ? 'error' : 'info'}
                    title=""
                    subtitle="At least one product must be enabled."
                    hideCloseButton
                    style={{ marginTop: 0 }}
                    lowContrast
                />
            )}
            <Row className={styles.productFieldsRow}>
                <Column lg={3}>
                    <Select
                        id="StateDropdown"
                        value={stateSelected}
                        labelText="State"
                        invalid={!!autoStateError || !!homeStateError}
                        invalidText={autoStateError?.error?.message || homeStateError?.error?.message}
                        onChange={handleStateChange}
                        disabled={isEdit}
                    >
                        <SelectItem disabled value="" text="State" />
                        {states.map((state, i) => (
                            <SelectItem value={state.value} key={i} text={state.label} />
                        ))}
                    </Select>
                </Column>
            </Row>
            {availableProducts.map((product, index) => {
                const isActivated = activatedProducts.includes(product);
                const isDisabled = formDisabled || isActivated;

                return (
                    <div key={`row-${index}`}>
                        <Checkbox
                            checked={showSection(product) || isActivated}
                            disabled={isDisabled}
                            id={product}
                            labelText={capitalizeFirstLetter(product)}
                            onChange={handleCheckboxClick}
                        />
                    </div>
                );
            })}
        </>
    );
};

export { SubsidyProducts };
