import { PageHeader } from 'components/common';
import { OpportunitiesList } from 'components/opportunities';
import useAccount from 'hooks/use-account';
import { Helmet as Head } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const Opportunities = () => {
    const { account } = useAccount();

    const description = (
        <>
            <p>
                The leads below fall outside of your account settings, but might still be worth exploring. Cost per
                Connection lets you call a lead, and you're only charged if the consumer picks up. Cost per Lead lets
                you purchase the lead immediately.
            </p>
            {!Boolean(account?.verifiedCallerId) && (
                <p>
                    <strong>To get started:</strong> Visit <Link to="/p/account#callerId">Settings</Link> and configure
                    your Caller ID.
                </p>
            )}
        </>
    );

    return (
        <>
            <Head>
                <title>Opportunities</title>
            </Head>
            <PageHeader title="Opportunities" description={description} />
            <OpportunitiesList />
        </>
    );
};

export default Opportunities;
