// Avoid Organizing Imports in this file. Order is important.
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as Sentry from '@sentry/react';
import { Providers, NotSupported, ScrollToTop } from 'components/common';
import analytics from 'plugins/analytics';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { getEnvironment } from 'util/common';
import { ENVIRONMENTS } from 'util/constants';
import App from './App';

import './styles/theme.scss';
import './styles/global.css';

const ENV = getEnvironment();
const USER_AGENT = navigator.userAgent || navigator.vendor || window.opera;
const INTERNET_EXPLORER = /Trident/.test(USER_AGENT);

const RELEASE = process.env.REACT_APP_BUILD_TIME;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

// Include error reporting and analytics in Beta and Production
if (ENV === ENVIRONMENTS.BETA || ENV === ENVIRONMENTS.PRODUCTION) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: ENV === ENVIRONMENTS.BETA ? 'beta' : 'production',
        release: RELEASE,

        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
        integrations: [new Sentry.Replay()]
    });

    analytics();
}

ReactDOM.render(
    <StrictMode>
        {INTERNET_EXPLORER ? (
            <NotSupported />
        ) : (
            <BrowserRouter>
                <Providers>
                    <ScrollToTop />
                    <App />
                </Providers>
            </BrowserRouter>
        )}
    </StrictMode>,
    document.getElementById('ma')
);
