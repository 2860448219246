import { useState, useEffect, useContext } from 'react';
import DeviceContext from 'context/device';
import debounce from 'lodash/debounce';

const SMALL_MOBILE_BREAKPOINT = 320;
const MOBILE_BREAKPOINT = 576;
const DESKTOP_BREAKPOINT = 992;

const useDevice = () => useContext(DeviceContext);

const DeviceProvider = ({ children }) => {
    const [device, setDevice] = useState(getDevice());

    const updateDevice = () => setDevice(getDevice());

    useEffect(() => {
        const debounced = debounce(updateDevice, 150);
        window.addEventListener('resize', debounced);

        return () => window.removeEventListener('resize', debounced);
    }, []);

    const smallMobile = device === devices.SMALL_MOBILE;
    const mobile = smallMobile || device === devices.MOBILE;
    const tablet = device === devices.TABLET;
    const desktop = device === devices.DESKTOP;

    const value = { device, smallMobile, mobile, tablet, desktop };
    return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
};

const getDevice = () => {
    const width = window.innerWidth;

    if (width <= SMALL_MOBILE_BREAKPOINT) {
        return devices.SMALL_MOBILE;
    }
    else if (width <= MOBILE_BREAKPOINT) {
        return devices.MOBILE;
    }
    else if (width <= DESKTOP_BREAKPOINT) {
        return devices.TABLET;
    }
    else {
        return devices.DESKTOP;
    }
};

const devices = {
    SMALL_MOBILE: 'small_mobile',
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop'
};

export { useDevice as default, DeviceProvider, devices };
