import { TrashCan20 } from '@carbon/icons-react';
import { Modal } from 'carbon-components-react';
import americanexpress from 'img/credit_cards/americanexpress.svg';
import discover from 'img/credit_cards/discover.svg';
import generic from 'img/credit_cards/generic.svg';
import mastercard from 'img/credit_cards/mastercard.svg';
import visa from 'img/credit_cards/visa.svg';
import { useState } from 'react';
import styles from './PaymentMethods.module.scss';

const PaymentMethod = ({ card, canDelete, onConfirmDelete }) => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const { paymentMethodId, isAutoRefillPaymentMethod } = card;
    const autoRefill = Boolean(isAutoRefillPaymentMethod);

    const onCancel = () => {
        setShowDeleteConfirmation(false);
    };

    const onConfirm = () => {
        setShowDeleteConfirmation(false);
        onConfirmDelete(paymentMethodId);
    };

    return (
        <>
            {showDeleteConfirmation && (
                <DeleteConfirmationDialog
                    card={card}
                    autoRefill={autoRefill}
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                />
            )}
            <div className={styles.creditCard}>
                <img className={styles.cardLogo} src={getCreditCardLogo(card.cardType)} alt={card.cardType} />
                <div className={styles.cardText}>
                    <span className={styles.cardType}>{card.cardType}</span> ending in{' '}
                    <span className={styles.cardLastFour}>{card.cardLast_4}</span>
                </div>
                {autoRefill && <div className={styles.autoRefillBadge}>Auto-Refill</div>}
                {canDelete && (
                    <div title="Delete">
                        <TrashCan20
                            className={styles.deleteIcon}
                            aria-label="Delete"
                            onClick={() => setShowDeleteConfirmation(true)}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

const DeleteConfirmationDialog = ({ card, autoRefill, onCancel, onConfirm }) => {
    return (
        <Modal
            open
            modalHeading="Delete payment method?"
            primaryButtonText="Yes, Delete"
            secondaryButtonText="No, Cancel"
            onRequestClose={onCancel}
            onRequestSubmit={onConfirm}
            onSecondarySubmit={onCancel}
            preventCloseOnClickOutside
            size="xs"
        >
            <span>
                Are you sure you want to delete your <span className={styles.cardType}>{card.cardType}</span> ending in{' '}
                <span className={styles.cardLastFour}>{card.cardLast_4}</span>?
            </span>
            {autoRefill && (
                <span className={styles.autoRefillDisclaimer}>
                    This will disable auto-refill for your account. Please visit account settings to re-enable
                    auto-refill and choose a new auto-refill payment method.
                </span>
            )}
        </Modal>
    );
};

const getCreditCardLogo = (cardType) => {
    const cardName = cardType ? cardType.replace(/ /g, '').toLowerCase() : '';
    const logos = {
        visa,
        mastercard,
        americanexpress,
        discover,
        generic
    };

    return logos[cardName] || logos['generic'];
};

export default PaymentMethod;
