import { Warning32 } from '@carbon/icons-react';
import styles from './FatalError.module.scss';

const FatalError = ({ error, ...rest }) => {
    const { message, description } = error;
    return (
        <div className={styles.wrap} {...rest}>
            <Warning32 className={styles.errorIcon} />
            {message && (
                <div>
                    <div className={styles.message}>{message}</div>
                    {description && <div className={styles.description}>{description}</div>}
                </div>
            )}
        </div>
    );
};

export default FatalError;
