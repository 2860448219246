import { Information16 } from '@carbon/icons-react';
import {
    DataTable,
    InlineNotification,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableSelectAll,
    TableSelectRow,
    TooltipIcon
} from 'carbon-components-react';
import clsx from 'clsx';
import { namespaces } from 'components/accounts/AccountForm';
import { PageSection } from 'components/common';
import consumerIcon from 'img/risk.svg';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { products } from 'util/constants';
import { capitalizeFirstLetter } from 'util/format-text';
import { BidField, getMinBid } from './BidField';
import styles from './Campaigns.module.scss';
import CustomAutoCampaigns from './CustomAutoCampaigns';
import { CustomHealthCampaigns } from './CustomHealthCampaigns';
import { CustomLifeCampaigns } from './CustomLifeCampaigns';
import { AdvertiserDailyCap, CampaignDailyCapField } from './DailyCap';
import { getCampaignTargeting, headerData, tooltips } from './campaign-conf';

const ADVERTISER = 'advertiser';
const CAMPAIGN = 'campaign';

const shouldShowCustomCampaigns = (carrier, agentCarrier, lms, product) => {
    if (carrier || (agentCarrier === 'State Farm' && Boolean(lms))) return false;
    return [products.AUTO, products.HEALTH, products.LIFE].includes(product);
};

const Campaigns = ({ namespace, formDisabled, carrier, product }) => {
    const {
        register,
        setValue,
        setError,
        clearErrors,
        getValues,
        formState: { errors, submitCount },
        unregister
    } = useFormContext();
    const campaignsData = getValues(namespace);
    const campaignKeys = Object.keys(campaignsData).filter((key) => !!campaignsData[key]?.campaign);
    const campaigns = campaignKeys.map((key) => campaignsData[key]);
    const { dailyCapLevel } = campaignsData;

    const agentCarrier = getValues(`${namespaces.AGENT}.carrier`);
    const lms = getValues(`campaigns.${product}.delivery.delivery.lmsUrl`);
    const showCustomCampaigns = shouldShowCustomCampaigns(carrier, agentCarrier, lms, product);
    const isSubsidy = Boolean(carrier);
    const campaignTargeting = getCampaignTargeting(isSubsidy);

    const customCampaigns = campaigns.filter((c) => !!c.custom);
    const regularCampaigns = campaigns
        .filter((c) => !c.custom)
        .sort((a, b) => {
            const sortOrder = Object.keys(campaignTargeting);
            const aShortName = sortOrder.indexOf(a.shortName);
            const bShortName = sortOrder.indexOf(b.shortName);
            return aShortName - bShortName;
        });

    useEffect(() => {
        // register bid fields, custom campaigns handled the same as regular campaigns
        campaignKeys.forEach((key) => register(`${namespace}.${key}.bid`));
    }, [register, carrier]);

    useEffect(() => {
        if (!showCustomCampaigns && customCampaigns.length) {
            customCampaigns.forEach((campaign) => {
                const id = campaign.campaignId || campaign.customId;
                unregister(`${namespace}.${id}`);
            });
        }
    }, [showCustomCampaigns]);

    // Campaigns can be all paused status if this is regular account type.
    const checkCampaignsEnabled = !!carrier;
    let noCampaignsEnabled = false;
    if (checkCampaignsEnabled && campaignsData) {
        noCampaignsEnabled = campaignKeys.every((key) => !campaignsData[key].enabled);
    }

    // Sets a global error if no campaigns are enabled
    // Disables the form's submit button
    useEffect(() => {
        if (noCampaignsEnabled) {
            setError('noCampaignsEnabled', {
                type: 'manual',
                message: `If you wish to purchase ${product} leads, please enable at least one consumer type.`
            });
        }
        else {
            clearErrors('noCampaignsEnabled');
        }
    }, [noCampaignsEnabled, errors]);

    const handleCheckAll = () => {
        const someEnabled = campaignKeys.some((key) => campaignsData[key].enabled);

        campaignKeys.forEach((key) => {
            setValue(`${namespace}.${key}.enabled`, !someEnabled, { shouldDirty: true, shouldValidate: true });
        });
    };

    const createRow = (campaign) => {
        const { campaignId, enabled, campaign: name, shortName } = campaign;
        const targeting = campaignTargeting[shortName];
        const disabled = formDisabled;
        const minBid = getMinBid(shortName);

        return {
            id: campaignId,
            name,
            isSelected: Boolean(enabled), // initial value
            bid: campaignId && (
                <BidField
                    namespace={namespace}
                    disabled={disabled}
                    isSubsidy={Boolean(carrier)}
                    campaignKey={campaignId}
                    minBid={minBid}
                />
            ),
            dailyCap: <CampaignDailyCapField namespace={`${namespace}`} campaignKey={campaignId} disabled={disabled} />,
            ...targeting
        };
    };

    const rowData = useMemo(() => regularCampaigns.map((c) => createRow(c)), [regularCampaigns, formDisabled]);

    return (
        <PageSection
            name={`${capitalizeFirstLetter(product)} Consumer Types`}
            icon={consumerIcon}
            data-testid="section-campaigns"
        >
            <>
                {errors.noCampaignsEnabled && (
                    <InlineNotification
                        id={'noCampaignsEnabled'}
                        kind={submitCount > 0 ? 'error' : 'info'}
                        title=""
                        subtitle={<span>{errors.noCampaignsEnabled.message}</span>}
                        hideCloseButton
                        style={{ marginTop: 0 }}
                        lowContrast
                    />
                )}
                <DataTable rows={rowData} headers={getHeaderData(carrier, product, dailyCapLevel === CAMPAIGN)}>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getSelectionProps,
                        getRowProps,
                        getTableProps,
                        selectRow,
                        selectAll
                    }) => (
                        <TableContainer className={styles.campaignTable}>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        <TableSelectAll
                                            {...getSelectionProps()}
                                            onSelect={() => {
                                                handleCheckAll();
                                                selectAll();
                                            }}
                                            disabled={formDisabled}
                                        />
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({ header })}>
                                                <div className={clsx(styles.headerCell, { [header.key]: isSubsidy })}>
                                                    {header.header}
                                                    {tooltips[header.header] && (
                                                        <TooltipIcon
                                                            tooltipText={tooltips[header.header]}
                                                            direction="bottom"
                                                            align="end"
                                                        >
                                                            <Information16 />
                                                        </TooltipIcon>
                                                    )}
                                                </div>
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <RenderTableBody
                                    rows={rows}
                                    getRowProps={getRowProps}
                                    formDisabled={formDisabled}
                                    selectRow={selectRow}
                                    getSelectionProps={getSelectionProps}
                                    namespace={namespace}
                                    carrier={carrier}
                                />
                            </Table>
                        </TableContainer>
                    )}
                </DataTable>
            </>

            {showCustomCampaigns && product === products.AUTO && (
                <CustomAutoCampaigns
                    namespace={namespace}
                    customCampaigns={customCampaigns}
                    formDisabled={formDisabled}
                    product={product}
                />
            )}

            {showCustomCampaigns && product === products.HEALTH && (
                <CustomHealthCampaigns
                    namespace={namespace}
                    customCampaigns={customCampaigns}
                    formDisabled={formDisabled}
                    product={product}
                />
            )}

            {showCustomCampaigns && product === products.LIFE && (
                <CustomLifeCampaigns
                    namespace={namespace}
                    customCampaigns={customCampaigns}
                    formDisabled={formDisabled}
                    product={product}
                />
            )}

            {dailyCapLevel === ADVERTISER && <AdvertiserDailyCap namespace={namespace} />}
        </PageSection>
    );
};

const RenderTableBody = ({ rows, getRowProps, formDisabled, selectRow, getSelectionProps, namespace }) => {
    const { register, setValue, trigger } = useFormContext();

    const handleSelectCampaign = ({ target }, rowID) => {
        const { name } = target;
        setValue(name, target.checked, {
            shouldDirty: true,
            shouldValidate: true
        });
        trigger(`${namespace}.${rowID}`, { shouldFocus: true });
        selectRow(rowID);
    };

    return (
        <TableBody>
            {rows.map((row) => {
                const name = `${namespace}.${row.id}.enabled`;
                register(name);

                return (
                    <TableRow {...getRowProps({ row })}>
                        <TableSelectRow
                            {...getSelectionProps({ row })}
                            name={name}
                            checked={row.isSelected}
                            onSelect={(evt) => handleSelectCampaign(evt, row.id)}
                            disabled={formDisabled}
                        />
                        {row.cells.map((cell) => (
                            <TableCell key={cell.id}>
                                <div className={clsx(styles.dataCell, styles[cell.info.header])}>{cell.value}</div>
                            </TableCell>
                        ))}
                    </TableRow>
                );
            })}
        </TableBody>
    );
};

const getHeaderData = (carrier, product, showDailyCapLevel = false) => {
    let headers = headerData(Boolean(carrier))[product];

    return showDailyCapLevel ? headers : headers.filter((header) => header.key !== 'dailyCap');
};

export { BidField, CampaignDailyCapField, Campaigns as default };
