import { Button, Column, Grid, RadioButton, RadioButtonGroup, Row, Slider, TextInput } from 'carbon-components-react';
import clsx from 'clsx';
import useAccount from 'hooks/use-account';
import useOpportunitiesFilters from 'hooks/use-opportunities-filters';
import { makeParamString } from 'hooks/use-query-params';
import { useState } from 'react';
import axios from 'util/axios';
import { normalizeZipCode } from 'util/validate-form';
import styles from './GeoFilter.module.scss';

const GeoFilter = ({ setGeoFilterOpen }) => {
    const { geoFilter, setGeoFilter } = useOpportunitiesFilters();
    const {
        account: { stateName }
    } = useAccount();

    const [filters, setFilters] = useState({ ...geoFilter });
    const [error, setError] = useState('');

    const { coverageArea, radiusZipCode } = filters;

    const handleCancel = () => {
        setGeoFilterOpen(false);
    };

    const handleApply = () => {
        if (coverageArea === 'radius' && !radiusZipCode) {
            setError(`Please enter a valid ZIP code within ${stateName}.`);
        }
        else {
            setError('');
            setGeoFilter({ ...filters });
            setGeoFilterOpen(false);
        }
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.filterItem}>
                <Grid>
                    <div className={styles.coverageAreaTitle}>
                        <strong>Coverage Area</strong>
                    </div>
                    <Row className={styles.geoStateVal}>
                        <RadioButtonGroup
                            className={styles.coverageAreaRadioBtn}
                            name="coverageArea"
                            valueSelected={coverageArea}
                            orientation="vertical"
                            onChange={(val) => {
                                setFilters({ ...filters, coverageArea: val });
                            }}
                        >
                            <RadioButton labelText={`Entire State of ${stateName}`} value="state" />
                            <RadioButton labelText="Within Radius" value="radius" />
                        </RadioButtonGroup>
                    </Row>
                    <Row>
                        <Column md={4}>
                            {coverageArea === 'radius' && (
                                <RadiusSlider
                                    error={error}
                                    setError={setError}
                                    filters={filters}
                                    setFilters={setFilters}
                                />
                            )}
                        </Column>
                    </Row>

                    <div className={styles.filterBtns}>
                        <Button className={styles.cancelBtn} kind="secondary" onClick={handleCancel}>
                            <strong>Cancel</strong>
                        </Button>
                        <Button disabled={!!error} onClick={handleApply}>
                            <strong>Apply</strong>
                        </Button>
                    </div>
                </Grid>
            </div>
        </div>
    );
};

const MAX_ZIP_RADIUS = 1000;
const MIN_ZIP_RADIUS = 0;
const STEP_RADIUS = 5;

const RadiusSlider = ({ error, setError, filters, setFilters }) => {
    const { radius, radiusZipCode } = filters;
    const {
        account: { state, stateName }
    } = useAccount();

    const sliderLabelFormatter = (val) => (val === 0 ? 1 : val);

    const handleZipCodeOnChange = async (evt) => {
        evt.target.value = normalizeZipCode(evt.target.value);
        const params = {
            state: state.toLowerCase(),
            zip: evt.target.value
        };
        if (evt.target.value.length === 5) {
            try {
                const { data } = await axios.get(`/geo.json?action=validate_zip&${makeParamString(params)}`);
                if (data?.isValid) {
                    error && setError('');
                    setFilters({ ...filters, radiusZipCode: evt.target.value });
                }
                else {
                    setError(`Please enter a ZIP code within ${stateName}`);
                }
            }
            catch {}
        }
        else {
            setError(`Please enter a valid ZIP code within ${stateName}.`);
        }
    };

    return (
        <div className={clsx(styles.radiusLine, error && styles.invalidRadiusLine)}>
            <div className={styles.sliderLabels}>Within</div>
            <div className={styles.slider}>
                <Slider
                    ariaLabelInput="Radius slider"
                    name="radius"
                    max={MAX_ZIP_RADIUS}
                    min={MIN_ZIP_RADIUS}
                    step={STEP_RADIUS}
                    value={Number(radius)}
                    onChange={({ value }) => {
                        setFilters({ ...filters, radius: Number(value) });
                    }}
                    formatLabel={(val) => sliderLabelFormatter(val)}
                />
            </div>
            <div className={styles.inputLabel}>miles of</div>
            <div className={clsx(styles.zipCode, error && styles.invalidZipCode)}>
                <TextInput
                    className={styles.radiusZipCode}
                    defaultValue={radiusZipCode}
                    labelText="ZIP Code"
                    id="radiusZipCode"
                    onChange={handleZipCodeOnChange}
                    invalid={!!error}
                    invalidText={error}
                />
            </div>
        </div>
    );
};

export default GeoFilter;
