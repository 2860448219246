import { CheckmarkFilled16, CloseFilled16 } from '@carbon/icons-react';
import styles from './PasswordRequirements.module.scss';

const regex = {
    upperCase: /^(?=.*[A-Z]).*$/,
    lowerCase: /^(?=.*[a-z]).*$/,
    number: /^(?=.*\d).*$/,
    special: /^(?=.*\W).*$/
};

const PasswordRequirements = ({ password, password2, ...rest }) => {
    const length = password.length;
    const rules = {
        minLength: {
            message: 'At least 18 characters',
            test: () => length >= 18
        },
        upperCase: {
            message: 'At least one uppercase letter',
            test: () => regex.upperCase.test(password)
        },
        lowerCase: {
            message: 'At least one lowercase letter',
            test: () => regex.lowerCase.test(password)
        },
        number: {
            message: 'At least one number',
            test: () => regex.number.test(password)
        },
        special: {
            message: 'At least one special character (! @ # $)',
            test: () => regex.special.test(password)
        },
        matching: {
            message: 'Passwords match',
            test: () => password && password2 && password === password2
        },
        atLeastTwo: {
            message: 'At least two of the following: uppercase letter, lowercase letter, number, special character',
            test: () => rules.upperCase.test(password) + rules.lowerCase.test(password) + rules.number.test(password) + rules.special.test(password) >= 2
        }
    };

    const reqs = [rules.minLength, rules.atLeastTwo, rules.matching];

    return (
        <div className={styles.passwordReqs} {...rest}>
            <p>Password Requirements</p>
            <ul>
                {reqs.map((req, i) => (
                    <li key={`req${i}`}>
                        <span className={styles.passwordReqIcons}>
                            {req.test() ? (
                                <CheckmarkFilled16 className={styles.passwordReqMetIcon} />
                            ) : (
                                <CloseFilled16 className={styles.passwordReqUnmetIcon} />
                            )}
                        </span>
                        <span className={styles.reqText}>{req.message}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export { PasswordRequirements as default, regex };
