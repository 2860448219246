import { Checkmark16 } from '@carbon/icons-react';
import { Button, Checkbox, InlineLoading, RadioButton } from 'carbon-components-react';
import { PageSection } from 'components/common';
import { FatalError } from 'components/errors';
import useAccount from 'hooks/use-account';
import useApi from 'hooks/use-api';
import userIcon from 'img/user.svg';
import { useEffect, useState } from 'react';
import axios from 'util/axios';
import styles from './AccountFeatures.module.scss';

const ADVERTISER = 'advertiser';
const CAMPAIGN = 'campaign';

const DEFAULTS = {
    autoConnect: false,
    callNowButton: false,
    leadDedup: false,
    leadFeedCall: false,
    leadFeedLead: false,
    leadReturn: true,
    oneTimeDeposit: false,
    dailyCapLevel: ADVERTISER
};

const AccountFeatures = () => {
    const { account, id, setAccount, subsidyAccount } = useAccount();
    const [result, loading, error] = useApi(`account-feature.json?action=select&id=${id}`);

    const [values, setValues] = useState(DEFAULTS);
    const [saveStatus, setSaveStatus] = useState('');

    const setValue = (value, id) => {
        setValues({
            ...values,
            [id]: value
        });
    };

    const saveChanges = async () => {
        setSaveStatus(saveStatuses.ACTIVE);

        try {
            const { data } = await axios.post(`account-feature.json?action=update&id=${id}`, values);
            if (data?.error) throw new Error();

            setSaveStatus(saveStatuses.FINISHED);
            setAccount({
                ...account,
                features: data
            });
        }
        catch (err) {
            setSaveStatus(saveStatuses.ERROR);
        }
        finally {
            window.setTimeout(() => setSaveStatus(''), 1500);
        }
    };

    useEffect(() => {
        if (result) {
            /**
             * Products are enabled in a separate section, which means this data can get stale.
             * Deleting the product flags here ensures that we don't overwrite the correct flags in the
             * event a product is added.
             */
            delete result.productAuto;
            delete result.productHome;
            delete result.productHealth;
            delete result.productLife;
            setValues(result);
        }
    }, [result]);

    if (loading) {
        return <PageSection skeleton />;
    }

    if (error) {
        return <FatalError error={error} />;
    }

    return (
        <div className={styles.accountFeatures}>
            <PageSection name="Account Features" icon={userIcon}>
                <div className="bx--grid bx--grid--full-width">
                    <h4>Lead Returns</h4>
                    <div className={styles.description}>
                        Allow agents to return leads for up to 7 days after purchase.
                    </div>
                    <div className="bx--row">
                        <div className="bx--col-lg-8">
                            <Checkbox
                                id="leadReturn"
                                labelText="Enable Lead Returns"
                                checked={values.leadReturn}
                                onChange={setValue}
                            />
                        </div>
                    </div>

                    {!subsidyAccount && (
                        <>
                            <h4>Call Button on Purchased Leads</h4>
                            <div className={styles.description}>
                                Show a Call button to initiate a call with a purchased lead (Lead Detail page).
                                <br />
                                This does <strong>NOT</strong> impact the "Call Now" buttons on Opportunities pages.
                            </div>
                            <div className="bx--row">
                                <div className="bx--col-lg-8">
                                    <Checkbox
                                        id="callNowButton"
                                        labelText="Enable Call Button on Purchased Leads"
                                        checked={values.callNowButton}
                                        onChange={setValue}
                                    />
                                </div>
                            </div>

                            <h4>Auto-Connect</h4>
                            <div className={styles.description}>
                                Allow agents to configure Auto-Connect option in account settings.
                            </div>
                            <div className="bx--row">
                                <div className="bx--col-lg-8">
                                    <Checkbox
                                        id="autoConnect"
                                        labelText="Enable Auto-Connect Option"
                                        checked={values.autoConnect}
                                        onChange={setValue}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <h4>One-Time Deposit</h4>
                    <div className={styles.description}>Allow agents to make one-time deposits.</div>
                    <div className="bx--row">
                        <div className="bx--col-lg-8">
                            <Checkbox
                                id="oneTimeDeposit"
                                labelText="Enable One-Time Deposits"
                                checked={values.oneTimeDeposit}
                                onChange={setValue}
                            />
                        </div>
                    </div>

                    <h4>Click/Lead Deduplication</h4>
                    <div className={styles.description}>
                        Prevent selling lead to carrier that has already purchased click.
                    </div>
                    <div className="bx--row">
                        <div className="bx--col-lg-8">
                            <Checkbox
                                id="leadDedup"
                                labelText="Enable Click/Lead Deduplication"
                                checked={values.leadDedup}
                                onChange={setValue}
                            />
                        </div>
                    </div>

                    {!subsidyAccount && (
                        <>
                            <h4>Opportunities</h4>
                            <div className={styles.description}>Allow agents to access Opportunities.</div>
                            <div className="bx--row">
                                <div className="bx--col-lg-8">
                                    <Checkbox
                                        id="leadFeedLead"
                                        labelText="Enable Opportunities - Cost Per Lead"
                                        checked={values.leadFeedLead}
                                        onChange={setValue}
                                    />
                                </div>
                            </div>
                            <div className="bx--row">
                                <div className="bx--col-lg-8">
                                    <Checkbox
                                        id="leadFeedCall"
                                        labelText="Enable Opportunities - Cost Per Connection"
                                        checked={values.leadFeedCall}
                                        onChange={setValue}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <h4>Daily Caps</h4>
                    <div className={styles.description}>Enable daily caps at the Account or Consumer Type level.</div>
                    <div className="bx--row">
                        <div className="bx--col-lg-8">
                            <fieldset className={styles.radioButtonGroup}>
                                <RadioButton
                                    labelText="Account Daily Caps"
                                    value={ADVERTISER}
                                    checked={values.dailyCapLevel === ADVERTISER}
                                    onClick={() => setValue(ADVERTISER, 'dailyCapLevel')}
                                />
                                <RadioButton
                                    labelText="Consumer Type Daily Caps"
                                    value={CAMPAIGN}
                                    checked={values.dailyCapLevel === CAMPAIGN}
                                    onClick={() => setValue(CAMPAIGN, 'dailyCapLevel')}
                                />
                            </fieldset>
                        </div>
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    {saveStatus && (
                        <InlineLoading
                            status={saveStatus}
                            description={
                                saveStatus === saveStatuses.ACTIVE
                                    ? 'Saving...'
                                    : saveStatus === saveStatuses.ERROR
                                        ? 'Error, please try again...'
                                        : 'Saved'
                            }
                        />
                    )}
                    <Button renderIcon={Checkmark16} onClick={saveChanges} disabled={Boolean(saveStatus)}>
                        Save Changes
                    </Button>
                </div>
            </PageSection>
        </div>
    );
};

const saveStatuses = {
    ACTIVE: 'active',
    ERROR: 'error',
    FINISHED: 'finished'
};

export default AccountFeatures;
