import { CheckmarkOutline32, Warning32, WarningSquareFilled24 } from '@carbon/icons-react';
import {
    Button,
    ComposedModal,
    FormGroup,
    Loading,
    ModalBody,
    ModalFooter,
    ModalHeader,
    RadioButton
} from 'carbon-components-react';
import useAccount from 'hooks/use-account';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'util/axios';
import { formatError } from 'util/format-error';
import { dollarAmount } from 'util/format-number';
import styles from './LeadReturn.module.scss';

const DEFAULT_ERROR_MESSAGE =
    'You have exceeded your return allowance. Please contact your Client Success Manager for assistance with returns.';

const LeadReturn = ({ id, adGroupId, cost, salesTax, onClose }) => {
    const { subsidyAccount } = useAccount();
    const history = useHistory();

    const [reason, setReason] = useState('');
    const [processing, setProcessing] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const reasons = getReasons(subsidyAccount);

    const processRequest = async () => {
        setProcessing(true);

        try {
            await axios.post(`/lead.json?action=return&id=${id}&ad_group_id=${adGroupId}`, {
                reason
            });

            setSuccess(true);
        }
        catch (err) {
            const { message } = formatError(err);
            setError(message || DEFAULT_ERROR_MESSAGE);
        }
        finally {
            setProcessing(false);
        }
    };

    const handleChange = (reason) => {
        setReason(reason);
    };

    const handleSuccess = () => {
        history.replace('/p/leads');
    };

    const handleClose = (evt) => {
        if (evt.key === 'Escape' && (processing || success)) {
            return false;
        }
        onClose();
    };

    const handleEscape = (evt) => {
        if (evt.key === 'Escape') {
            handleClose(evt);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEscape);
        return () => document.removeEventListener('keydown', handleEscape);
    }, [handleEscape]);

    return (
        <ComposedModal
            size="sm"
            open={true}
            className={styles.modal}
            onClose={handleClose}
            preventCloseOnClickOutside
            aria-label="Lead Return Modal"
        >
            {(() => {
                if (error) {
                    return (
                        <>
                            <ModalHeader title="Returns Disabled" closeModal={onClose} />
                            <ModalBody>
                                <div className={styles.content}>
                                    <Warning32 className={styles.errorIcon} />
                                    <div className={styles.headline}>Something went wrong.</div>
                                    <div className={styles.message}>{error}</div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={onClose}>
                                    OK
                                </Button>
                            </ModalFooter>
                        </>
                    );
                }
                else if (success) {
                    return (
                        <>
                            <ModalHeader title="Return Approved" closeModal={handleSuccess} />
                            <ModalBody>
                                <div className={styles.content}>
                                    <CheckmarkOutline32 className={styles.successIcon} />
                                    <div className={styles.headline}>
                                        Your return has been approved and a credit of {dollarAmount(cost + salesTax)}{' '}
                                        has been issued to your account.
                                    </div>
                                    <div className={styles.message}>
                                        <WarningSquareFilled24 className={styles.warningIcon} />
                                        <span>
                                            Remember, for any copies you downloaded or exported, you and anyone you
                                            shared the lead with must stop using it and delete all lead information. To
                                            learn more about your obligations, read our{' '}
                                            <a href="/terms" target="_blank">
                                                Lead Return Policy
                                            </a>
                                            .
                                        </span>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={handleSuccess}>
                                    OK
                                </Button>
                            </ModalFooter>
                        </>
                    );
                }
                else {
                    return (
                        <>
                            {processing && <Loading />}
                            <ModalHeader title="Return Lead" closeModal={onClose} />
                            <ModalBody>
                                <p className={styles.reasonTitle}>
                                    Please select a reason for your lead return request:
                                </p>
                                <FormGroup legendText="">
                                    <div className="bx--form-item">
                                        <div className="bx--radio-button-group bx--radio-button-group--vertical">
                                            {reasons.map((r) => {
                                                return (
                                                    <Fragment key={r.value}>
                                                        <RadioButton
                                                            name="reason"
                                                            onChange={handleChange}
                                                            className={styles.reasonRadio}
                                                            labelText={r.title}
                                                            value={r.value}
                                                            checked={reason === r.value}
                                                        />
                                                        <RadioButtonDescription
                                                            description={r.description}
                                                            onClick={() => handleChange(r.value)}
                                                        />
                                                    </Fragment>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button kind="secondary" onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button kind="danger" onClick={processRequest} disabled={!reason}>
                                    Submit Request
                                </Button>
                            </ModalFooter>
                        </>
                    );
                }
            })()}
        </ComposedModal>
    );
};

const getReasons = (subsidyAccount) => [
    {
        value: 'Invalid Contact',
        title: 'Invalid Contact Information',
        description: (
            <span>
                Phone number and email address are <strong>both</strong> proven invalid (phone number is disconnected,
                wrong number, email bounced).
            </span>
        )
    },
    {
        value: 'Fictitious Lead',
        title: 'Fictitious Lead Information',
        description: 'Fake name and/or contact information (for example: Mickey Mouse, Jane Doe, Test Name, etc).'
    },
    {
        value: 'Filter Criteria',
        title: 'Filter Criteria Error',
        description:
            'The lead data you received does not match your account\'s settings (for example: your account filters only include consumers who are currently insured, yet you received a lead that says "Currently Insured: No". (Excluded information does not apply.)'
    },
    ...(subsidyAccount
        ? []
        : [
            {
                value: 'Duplicate Lead',
                title: 'Duplicate Lead',
                description: (
                    <span>
                          You received the same lead <strong>from MediaAlpha For Agents</strong> within the last 30
                          days.
                    </span>
                )
            }
        ])
];

const RadioButtonDescription = ({ description, onClick }) => {
    return (
        <div onClick={onClick} className={styles.reasonDescription}>
            {description}
        </div>
    );
};

export default LeadReturn;
