import { Repeat16 } from '@carbon/icons-react';
import { Button, InlineLoading, Modal, Select, SelectItem, TextInput } from 'carbon-components-react';
import clsx from 'clsx';
import { PageSection } from 'components/common';
import { FatalError } from 'components/errors';
import useAccount from 'hooks/use-account';
import useApi from 'hooks/use-api';
import IMask from 'imask';
import transferIcon from 'img/transfers.svg';
import { useEffect, useState } from 'react';
import axios from 'util/axios';
import { dollarAmount } from 'util/format-number';
import styles from './AccountTransfers.module.scss';

const MIN_AMOUNT = '1.00'; // Minimum transfer amount.

const AccountTransfers = () => {
    const { id: accountId, setAccounts: setCtxAccounts } = useAccount();
    const [result, loading, error] = useApi(`deposit.json?action=get_transferable_balance&id=${accountId}`);

    const [accounts, setAccounts] = useState([]);
    const [fromAccountId, setFromAccountId] = useState('');
    const [toAccountId, setToAccountId] = useState('');
    const [transferAmount, setTransferAmount] = useState(MIN_AMOUNT);

    const [confirmationModal, setConfirmationModal] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(submitStatuses.IDLE);

    const fromAccount = accounts.find((account) => account.id === fromAccountId);
    const toAccount = accounts.find((account) => account.id === toAccountId);

    const accountError =
        fromAccountId && toAccountId && fromAccountId === toAccountId
            ? 'Transfer From and To accounts must be different.'
            : false;

    const amountError =
        Number(transferAmount) < Number(MIN_AMOUNT)
            ? `Please enter a transfer amount of at least $${MIN_AMOUNT}`
            : Number(transferAmount) > Number(fromAccount?.balance)
                ? `Please enter a transfer amount up to ${dollarAmount(fromAccount?.balance)}`
                : false;

    const accountsValid = fromAccount && toAccount && !accountError;
    const formValid = accountsValid && !amountError;

    const submit = async () => {
        if (!formValid) return;

        setSubmitStatus(submitStatuses.ACTIVE);

        try {
            const { data } = await axios.post(`deposit.json?action=transfer_balance`, {
                balance: transferAmount,
                from_campaign_group_id: fromAccount.campaignGroupId,
                to_campaign_group_id: toAccount.campaignGroupId
            });

            // Update balances on this page to reflect the successful transfer.
            const updatedAccounts = accounts.map(
                (account) => data.accounts?.find((updated) => updated.id === account.id) ?? account
            );
            setAccounts(updatedAccounts);

            // Update balances in the account context.
            setCtxAccounts((ctxAccounts) =>
                ctxAccounts.map((ctxAccount) => {
                    const updatedAccount = updatedAccounts.find((account) => account.id === ctxAccount.id);
                    return updatedAccount
                        ? {
                            ...ctxAccount,
                            balance: updatedAccount.balance
                        }
                        : ctxAccount;
                })
            );

            // Reset the transfer amount after completion.
            setTransferAmount(MIN_AMOUNT);

            setSubmitStatus(submitStatuses.FINISHED);
        }
        catch (err) {
            setSubmitStatus(submitStatuses.ERROR);
        }
        finally {
            window.setTimeout(() => setSubmitStatus(submitStatuses.IDLE), 2000);
        }
    };

    useEffect(() => result && setAccounts(result.accounts || []), [result]);

    if (loading) {
        return <PageSection skeleton />;
    }

    if (error) {
        return <FatalError error={error} />;
    }

    if (accounts?.length < 2) {
        return null;
    }

    return (
        <>
            <PageSection name="Transfers" icon={transferIcon} className={styles.accountTransfers}>
                <h4>Transfer Funds</h4>
                <div className="bx--row">
                    <div className="bx--col-md-8 bx--col-lg-7">
                        <Select
                            id="fromAccountId"
                            name="fromAccountId"
                            labelText="From Account"
                            value={fromAccountId}
                            onChange={(evt) => setFromAccountId(evt.target.value)}
                            invalid={!!accountError}
                            invalidText={accountError}
                            disabled={submitStatus}
                        >
                            <SelectItem disabled value="" text="Select an account..." />
                            {accounts.map((account) => {
                                return (
                                    <SelectItem
                                        key={account.id}
                                        value={account.id}
                                        text={`${account.advertiser} | ${dollarAmount(account.balance)} Available`}
                                    />
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div className="bx--row">
                    <div className="bx--col-md-8 bx--col-lg-7">
                        <Select
                            id="toAccountId"
                            name="toAccountId"
                            labelText="To Account"
                            value={toAccountId}
                            onChange={(evt) => setToAccountId(evt.target.value)}
                            invalid={!!accountError}
                            invalidText={accountError}
                            disabled={submitStatus}
                        >
                            <SelectItem disabled value="" text="Select an account..." />
                            {accounts.map((account) => {
                                return (
                                    <SelectItem
                                        key={account.id}
                                        value={account.id}
                                        text={`${account.advertiser} | ${dollarAmount(account.balance)} Available`}
                                    />
                                );
                            })}
                        </Select>
                    </div>
                </div>
                {!submitStatus && (
                    <div className="bx--row">
                        <div className={`bx--col-lg-2 ${styles.currency}`}>
                            <TextInput
                                id="transferAmount"
                                name="transferAmount"
                                labelText="Transfer Amount"
                                defaultValue={transferAmount}
                                onChange={(evt) => {
                                    const formatted = amountMask(evt.target.value);
                                    evt.target.value = formatted;
                                    setTransferAmount(formatted.replaceAll(',', ''));
                                }}
                                onBlur={(evt) => {
                                    const formatted = paddedAmountMask(evt.target.value);
                                    evt.target.value = formatted;
                                    setTransferAmount(formatted.replaceAll(',', ''));
                                }}
                                invalid={!!amountError}
                                invalidText={amountError}
                                disabled={!accountsValid}
                            />
                        </div>
                    </div>
                )}
                <div className={clsx('bx--row', styles.submit)}>
                    <div className="bx--col-md-6 bx--col-lg-5">
                        <Button
                            renderIcon={Repeat16}
                            onClick={() => setConfirmationModal(true)}
                            disabled={!formValid || submitStatus}
                        >
                            Transfer Funds
                        </Button>
                        {submitStatus && (
                            <InlineLoading status={submitStatus} description={getSaveDescription(submitStatus)} />
                        )}
                    </div>
                </div>
            </PageSection>
            {confirmationModal && (
                <Modal
                    open={true}
                    modalHeading="Transfer Confirmation"
                    primaryButtonText="Confirm and Transfer"
                    secondaryButtonText="Cancel"
                    onRequestClose={() => {
                        setConfirmationModal(false);
                    }}
                    onRequestSubmit={() => {
                        submit();
                        setConfirmationModal(false);
                    }}
                    className={styles.modal}
                >
                    <p>Please review and confirm the following transfer details:</p>
                    <br />
                    <p>
                        <span>Transfer from:</span> <strong>{fromAccount.advertiser}</strong> |{' '}
                        {dollarAmount(fromAccount.balance)} Available
                    </p>
                    <p>
                        <span>Transfer to:</span> <strong>{toAccount.advertiser}</strong> |{' '}
                        {dollarAmount(toAccount.balance)} Available
                    </p>
                    <p>
                        <span>Transfer amount:</span> <strong>{dollarAmount(transferAmount)}</strong>
                    </p>
                </Modal>
            )}
        </>
    );
};

const submitStatuses = {
    IDLE: '',
    ACTIVE: 'active',
    ERROR: 'error',
    FINISHED: 'finished'
};

const amountMask = IMask.createPipe({
    mask: Number,
    scale: 2,
    thousandsSeparator: ',',
    signed: false,
    padFractionalZeros: false,
    normalizeZeros: false,
    radix: '.'
});

const paddedAmountMask = IMask.createPipe({
    mask: Number,
    scale: 2,
    thousandsSeparator: ',',
    signed: false,
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: '.'
});

const getSaveDescription = (submitStatus) => {
    if (submitStatus === submitStatuses.IDLE) return '';

    return submitStatus === submitStatuses.ACTIVE
        ? 'Transferring funds...'
        : submitStatus === submitStatuses.ERROR
            ? 'Error, please try again...'
            : 'Transfer Successful';
};

export default AccountTransfers;
