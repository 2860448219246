import { Phone32, PhoneOutgoing32 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { useState } from 'react';
import axios from 'util/axios';
import styles from './LeadCallButton.module.scss';

const LeadCallButton = ({ adGroupId, lead, leadId, advertiser, callDisabled, callDisabledReason, ...rest }) => {
    const [connected, setConnected] = useState(false);

    const handleClick = async () => {
        if (connected || callDisabled) return;
        try {
            const res = await axios.post(`/lead.json?action=connect&id=${leadId}&ad_group_id=${adGroupId}`);

            setConnected((c) => res.data?.successful);
            window.setTimeout(() => {
                setConnected(false);
            }, 15000);
        }
        catch (err) {}
    };

    return (
        <Button
            className={styles.callNowButton}
            onClick={handleClick}
            kind={connected || callDisabled ? 'secondary' : 'primary'}
            size="field"
            hasIconOnly
            tooltipAlignment="center"
            tooltipPosition="top"
            renderIcon={connected ? PhoneOutgoing32 : Phone32}
            iconDescription={
                connected
                    ? 'Connecting...'
                    : !callDisabled
                        ? `Connect to ${lead.contact}.\nThis call may be recorded.`
                        : callDisabledReason || "Please try again between 9am-9pm in the consumer's local timezone."
            }
            {...rest}
        />
    );
};

export default LeadCallButton;
