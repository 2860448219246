import useUser from 'hooks/use-user';
import { useState } from 'react';
import styles from './Avatar.module.scss';
import AvatarMenu from './AvatarMenu';

const Avatar = () => {
    const { user } = useUser();
    const [menuOpen, setMenuOpen] = useState(false);

    const onMenuToggle = () => setMenuOpen(!menuOpen);

    return (
        <>
            <div className={styles.wrap} onClick={onMenuToggle}>
                <div className={styles.text}>
                    <div>{user.name}</div>
                    <div className={styles.agency}>{user.company}</div>
                </div>
                <AvatarImage user={user} />
            </div>
            {menuOpen && <AvatarMenu onMenuToggle={onMenuToggle} />}
        </>
    );
};

const AvatarImage = ({ user }) => {
    let initials = '';

    if (user?.name) {
        const nameParts = user.name.split(' ');

        const first = nameParts[0];
        const last = nameParts.length > 1 ? nameParts.pop() : '';

        initials = `${first[0] || ''}${last[0] || ''}`;
    }

    return (
        <div className={styles.avatarImage}>
            <span>{initials}</span>
        </div>
    );
};

export default Avatar;
