import { PageSection } from 'components/common';
import { PaymentMethods } from 'components/deposits';
import passwordIcon from 'img/lock.svg';
import paymentIcon from 'img/payment.svg';
import contactIcon from 'img/user.svg';
import Contact from './Contact/Contact';
import UpdatePassword from './UpdatePassword/UpdatePassword';

const ProfileForm = () => {
    return (
        <>
            <PageSection name="Contact Information" icon={contactIcon}>
                <Contact />
            </PageSection>
            <PageSection name="Payment Methods" icon={paymentIcon}>
                <PaymentMethods />
            </PageSection>
            <PageSection name="Change Password" icon={passwordIcon}>
                <UpdatePassword />
            </PageSection>
            <div style={{ marginTop: 30, fontSize: 14 }}>
                To update additional profile information, please reach out to your Client Success Manager, at{' '}
                <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a>
            </div>
        </>
    );
};

export default ProfileForm;
