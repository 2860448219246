import { Download16 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import axios from 'util/axios';

const ExportButton = ({ api, params = {}, ...rest }) => {
    const urlParams = new URLSearchParams({
        action: 'export',
        ...params
    }).toString();
    const urlPath = `${api}.json${urlParams.length ? '?' + urlParams : ''}`;
    const exportUrl = `${axios.defaults.baseURL}/${urlPath}`;
    
    const exportFile = () => window.location.href = exportUrl;

    return (
        <Button
            onClick={exportFile}
            kind="ghost"
            hasIconOnly
            renderIcon={Download16}
            tooltipAlignment="center"
            tooltipPosition="bottom"
            iconDescription="Export"
            {...rest}
        />
    );
};

export default ExportButton;
