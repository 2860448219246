import { TrashCan16 } from '@carbon/icons-react';
import {
    Button,
    Checkbox,
    Column,
    Dropdown,
    MultiSelect,
    RadioButton,
    RadioButtonGroup,
    Slider,
    TextArea,
    TextInput,
    ToastNotification
} from 'carbon-components-react';
import clsx from 'clsx';
import { PageSection } from 'components/common';
import states from 'data/us-states';
import geoIcon from 'img/geography.svg';
import { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import axios from 'util/axios';
import { products } from 'util/constants';
import { getZips, normalizeZipCode } from 'util/validate-form';
import styles from './Geography.module.scss';

const initialValues = {
    radius: 100,
    radiusZipCode: '',
    excludeZipCodes: false,
    excludedZipCodes: '',
    includeZipCodes: false,
    includedZipCodes: '',
    specificZipCodes: ''
};

const healthLifeInitialValues = {
    excludeZipCodes: false,
    excludedZipCodes: '',
    includeZipCodes: false,
    includedZipCodes: ''
};

const MAX_ZIP_RADIUS = 1000;
const MIN_ZIP_RADIUS = 0;
const STEP_RADIUS = 5;

const zipCodeType = {
    SPECIFIC: 'specificZipCodes',
    INCLUDED: 'includedZipCodes',
    EXCLUDED: 'excludedZipCodes'
};

const coverageAreaType = {
    STATE: 'state',
    RADIUS: 'radius',
    SPECIFIC_ZIPCODES: 'specificZipCodes'
};

const Geography = ({ namespace, formDisabled, product }) => {
    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        unregister,
        watch
    } = useFormContext();

    const [root, , section] = namespace.split('.');
    const geoErrors = errors[root]?.[product]?.[section] || {};
    const isHealthLife = [products.HEALTH, products.LIFE].includes(product);

    const zipInitialState = {
        [zipCodeType.SPECIFIC]: [],
        [zipCodeType.INCLUDED]: [],
        [zipCodeType.EXCLUDED]: []
    };
    const [invalidZips, setInvalidZips] = useState(zipInitialState);

    const validateAllZipCodes = async (val, type) => {
        if (
            !getValues(`${namespace}.excludeZipCodes`) &&
            !getValues(`${namespace}.includeZipCodes`) &&
            getValues(`${namespace}.coverageArea`) !== 'specificZipCodes'
        ) {
            return true;
        }

        if (!val) {
            if (type === zipCodeType.SPECIFIC) {
                return 'Please enter specific ZIP code(s)';
            }
            return `Please enter ZIP code(s) to ${type === zipCodeType.INCLUDED ? 'include' : 'exclude'}`;
        }

        const zipCodes = getZips(val);

        // Only allow 5 digit strings as input
        const hasInvalidZip = zipCodes.some((zip) => !/^\d{5}$/.test(zip));
        if (hasInvalidZip) {
            return 'Please enter valid, comma separated 5-digit ZIP code(s)';
        }

        let err = '';

        try {
            const { data } = await axios.post('geo.json?action=check_zips', {
                zips: zipCodes,
                states: watch(`${namespace}.states`)
            });

            if (data?.invalidZips) {
                setInvalidZips((prevState) => ({
                    ...prevState,
                    [type]: [...data?.invalidZips]
                }));
                err = 'Invalid zips: ' + data?.invalidZips.join(', ');
            }
        }
        catch {}

        return err.length ? err : true;
    };

    const handleMultiSelectChange = (id, { selectedItems }) => {
        setValue(
            id,
            selectedItems.map((item) => item.value),
            { shouldDirty: true }
        );
    };

    const selectedStates = getValues(`${namespace}.states`);
    const initialSelectedStates = states.filter((state) => selectedStates.includes(state.value));
    const initialSelectedPrimaryState = states.find((state) => state.value === getValues(`${namespace}.state`));

    /**
     * Fields with custom onChange/onBlur handling
     */
    const coverageArea = register(`${namespace}.coverageArea`);
    const excludeZipCodesCheckbox = register(`${namespace}.excludeZipCodes`);
    const includeZipCodesCheckbox = register(`${namespace}.includeZipCodes`);

    return (
        <PageSection
            className={styles.geographyPageSection}
            name="Geography"
            icon={geoIcon}
            data-testid="section-geography"
        >
            <div className="bx--grid bx--grid--full-width">
                {isHealthLife ? (
                    <>
                        <h4>States</h4>
                        <div className="bx--row">
                            <Column md={2}>
                                <MultiSelect
                                    {...register(`${namespace}.states`, {
                                        required: 'At least one state is required'
                                    })}
                                    className={styles.multiSelect}
                                    initialSelectedItems={initialSelectedStates}
                                    id={`${namespace}.states`}
                                    items={states}
                                    label="States"
                                    onChange={(e) => handleMultiSelectChange(`${namespace}.states`, e)}
                                    titleText="States"
                                    invalid={!!geoErrors.states}
                                    invalidText={geoErrors?.states?.message}
                                />
                            </Column>
                        </div>
                    </>
                ) : (
                    <>
                        <h4>Primary State</h4>
                        <div className="bx--row">
                            <Column md={2}>
                                <Dropdown
                                    label="State"
                                    id="state"
                                    items={states}
                                    initialSelectedItem={initialSelectedPrimaryState}
                                    titleText="State"
                                    disabled
                                />
                            </Column>
                        </div>
                    </>
                )}
                {!isHealthLife && (
                    <>
                        <h4>Coverage Area</h4>
                        <div className="bx--row">
                            <div className="bx--col-lg-6">
                                <RadioButtonGroup
                                    name={coverageArea.name}
                                    ref={coverageArea.ref}
                                    valueSelected={getValues(coverageArea.name)}
                                    orientation="vertical"
                                    onChange={(val, name, evt) => {
                                        coverageArea.onChange(evt);
                                        setValue(coverageArea.name, val, { shouldDirty: true });
                                        if (val === coverageAreaType.STATE) {
                                            unregister([
                                                `${namespace}.radiusZipCode`,
                                                `${namespace}.includeZipCodes`,
                                                `${namespace}.includedZipCodes`,
                                                `${namespace}.specificZipCodes`
                                            ]);
                                        }
                                        else if (val === coverageAreaType.SPECIFIC_ZIPCODES) {
                                            unregister([
                                                `${namespace}.radiusZipCode`,
                                                `${namespace}.includeZipCodes`,
                                                `${namespace}.includedZipCodes`,
                                                `${namespace}.excludeZipCodes`,
                                                `${namespace}.excludedZipCodes`
                                            ]);
                                        }
                                        else if (val === coverageAreaType.RADIUS) {
                                            unregister([`${namespace}.specificZipCodes`]);
                                        }
                                    }}
                                    onBlur={coverageArea.onBlur}
                                >
                                    <RadioButton
                                        labelText="Entire State"
                                        value={coverageAreaType.STATE}
                                        disabled={formDisabled}
                                    />
                                    <RadioButton
                                        labelText="Within Radius"
                                        value={coverageAreaType.RADIUS}
                                        disabled={formDisabled}
                                    />
                                    <RadioButton
                                        labelText="Specific Zipcodes"
                                        value={coverageAreaType.SPECIFIC_ZIPCODES}
                                        disabled={formDisabled}
                                    />
                                </RadioButtonGroup>
                            </div>
                        </div>
                    </>
                )}
                {watch(coverageArea.name) === coverageAreaType.RADIUS && (
                    <WithinRadius namespace={namespace} formDisabled={formDisabled} geoErrors={geoErrors} />
                )}
                {watch(coverageArea.name) === coverageAreaType.SPECIFIC_ZIPCODES && (
                    <div className={clsx(styles.specificZipCodes, 'bx--row')}>
                        <div className="bx--col-lg-6">
                            <TextArea
                                {...register(`${namespace}.specificZipCodes`, {
                                    validate: (val) => validateAllZipCodes(val, zipCodeType.SPECIFIC)
                                })}
                                id="specificZipCodes"
                                labelText=""
                                aria-label="Specific ZIP Codes"
                                cols={125}
                                placeholder={'00000, 00000, 00000, ...'}
                                disabled={formDisabled}
                                invalid={!!geoErrors?.specificZipCodes}
                                invalidText={geoErrors?.specificZipCodes?.message}
                            />
                            <RemoveInvalidZips
                                invalidZips={invalidZips}
                                type={zipCodeType.SPECIFIC}
                                namespace={namespace}
                                setInvalidZips={setInvalidZips}
                            />
                        </div>
                    </div>
                )}
                {(watch(coverageArea.name) !== coverageAreaType.SPECIFIC_ZIPCODES || isHealthLife) && (
                    <>
                        <h4>Advanced</h4>
                        <div className="bx--row">
                            <div className="bx--col-lg-6">
                                <fieldset className="bx--fieldset">
                                    <Checkbox
                                        name={excludeZipCodesCheckbox.name}
                                        ref={excludeZipCodesCheckbox.ref}
                                        id={excludeZipCodesCheckbox.name}
                                        labelText="Exclude ZIP Code(s)"
                                        onChange={(val, id, evt) => {
                                            excludeZipCodesCheckbox.onChange(evt);
                                            setValue(excludeZipCodesCheckbox.name, val);
                                            if (!val) {
                                                setValue(`${namespace}.excludedZipCodes`, '', {
                                                    shouldDirty: true
                                                });
                                            }
                                        }}
                                        onBlur={excludeZipCodesCheckbox.onBlur}
                                        disabled={formDisabled}
                                    />
                                </fieldset>
                                {watch(excludeZipCodesCheckbox.name) && (
                                    <>
                                        <TextArea
                                            {...register(`${namespace}.excludedZipCodes`, {
                                                validate: (val) => validateAllZipCodes(val, zipCodeType.EXCLUDED)
                                            })}
                                            id="excludedZipCodes"
                                            labelText=""
                                            aria-label="Excluded ZIP Codes"
                                            cols={80}
                                            placeholder={'00000, 00000, 00000, ...'}
                                            disabled={formDisabled}
                                            invalid={!!geoErrors?.excludedZipCodes}
                                            invalidText={geoErrors?.excludedZipCodes?.message}
                                        />

                                        <RemoveInvalidZips
                                            invalidZips={invalidZips}
                                            type={zipCodeType.EXCLUDED}
                                            namespace={namespace}
                                            setInvalidZips={setInvalidZips}
                                        />
                                    </>
                                )}
                            </div>
                            {watch(coverageArea.name) === coverageAreaType.RADIUS && (
                                <div className="bx--col-lg-6">
                                    <fieldset className="bx--fieldset">
                                        <Checkbox
                                            name={includeZipCodesCheckbox.name}
                                            ref={includeZipCodesCheckbox.ref}
                                            id={includeZipCodesCheckbox.name}
                                            labelText="Include ZIP Code(s)"
                                            onChange={(val, id, evt) => {
                                                includeZipCodesCheckbox.onChange(evt);
                                                setValue(includeZipCodesCheckbox.name, val);
                                                if (!val) {
                                                    setValue(`${namespace}.includedZipCodes`, '', {
                                                        shouldDirty: true
                                                    });
                                                }
                                            }}
                                            onBlur={includeZipCodesCheckbox.onBlur}
                                            disabled={formDisabled}
                                        />
                                    </fieldset>
                                    {watch(includeZipCodesCheckbox.name) && (
                                        <>
                                            <TextArea
                                                {...register(`${namespace}.includedZipCodes`, {
                                                    validate: (val) => validateAllZipCodes(val, zipCodeType.INCLUDED)
                                                })}
                                                id="includedZipCodes"
                                                labelText=""
                                                aria-label="Included ZIP Codes"
                                                cols={80}
                                                placeholder={'00000, 00000, 00000, ...'}
                                                disabled={formDisabled}
                                                invalid={!!geoErrors?.includedZipCodes}
                                                invalidText={geoErrors?.includedZipCodes?.message}
                                            />

                                            <RemoveInvalidZips
                                                invalidZips={invalidZips}
                                                type={zipCodeType.INCLUDED}
                                                namespace={namespace}
                                                setInvalidZips={setInvalidZips}
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </PageSection>
    );
};

const WithinRadius = ({ namespace, formDisabled, geoErrors }) => {
    const { setValue, getValues, register, watch } = useFormContext();

    const radiusName = `${namespace}.radius`;

    useEffect(() => {
        register(radiusName);
    }, []);

    const geoFormValues = getValues(namespace);
    const { radius } = geoFormValues;

    const watchRadius = useWatch({
        name: radiusName,
        defaultValue: radius
    });

    const radiusZipCode = register(`${namespace}.radiusZipCode`, {
        validate: async (val) => {
            const state = watch(`${namespace}.state`);
            const stateName = states.find((s) => s.value === state)?.label;

            if (getValues(`${namespace}.coverageArea`) === coverageAreaType.RADIUS && !val) {
                return 'Please enter a 5-digit ZIP code';
            }
            const zipCode = getZips(val);
            let err = '';

            if (!/^\d{5}$/.test(zipCode.join())) return 'Please enter a valid ZIP code';

            try {
                const { data } = await axios.post('geo.json?action=check_zips', {
                    zips: zipCode,
                    states: [state]
                });

                if (data?.invalidZips) {
                    err = `Please enter a valid ZIP code within ${stateName}`;
                }
            }
            catch {}

            return err.length ? err : true;
        }
    });

    return (
        <div className={styles.radiusLine}>
            <div className={styles.sliderLabels}>Within</div>
            <div className={styles.slider}>
                <RadiusSlider
                    setValue={setValue}
                    watchRadius={watchRadius}
                    radiusName={radiusName}
                    formDisabled={formDisabled}
                />
            </div>
            <div className={styles.sliderLabels}>miles of</div>
            <div className={styles.zipCode}>
                <TextInput
                    name={radiusZipCode.name}
                    ref={radiusZipCode.ref}
                    className={styles.radiusZipCode}
                    labelText="ZIP Code"
                    id="radiusZipCode"
                    disabled={formDisabled}
                    onChange={(evt) => {
                        radiusZipCode.onChange(evt);
                        setValue(radiusZipCode.name, normalizeZipCode(evt.target.value));
                    }}
                    onBlur={radiusZipCode.onBlur}
                    invalid={!!geoErrors?.radiusZipCode}
                    invalidText={geoErrors?.radiusZipCode?.message}
                />
            </div>
        </div>
    );
};

const RadiusSlider = ({ setValue, watchRadius, radiusName, formDisabled }) => {
    const mounting = useRef(true);
    const [val, setVal] = useState(watchRadius || initialValues.radius);

    const sliderLabelFormatter = (val) => (val === 0 ? 1 : val);

    // Validate and recalibrate input for zip radius if higher or lower than MAX or MIN values
    useEffect(() => {
        // Avoid running effect on component mount
        if (mounting.current) {
            mounting.current = false;
            return;
        }
        if (val > MAX_ZIP_RADIUS) {
            setVal(MAX_ZIP_RADIUS);
        }
        else if (val <= MIN_ZIP_RADIUS) {
            setVal(1);
        }
        setValue(radiusName, val, { shouldDirty: true });
    }, [val]);

    return (
        <Slider
            ariaLabelInput="Radius slider"
            max={MAX_ZIP_RADIUS}
            min={MIN_ZIP_RADIUS}
            step={STEP_RADIUS}
            value={val}
            onChange={({ value }) => setVal(value)}
            disabled={formDisabled}
            formatLabel={(val) => sliderLabelFormatter(val)}
        />
    );
};

const RemoveInvalidZips = ({ invalidZips, type, namespace, setInvalidZips }) => {
    const {
        setValue,
        getValues,
        clearErrors,
        formState: { errors }
    } = useFormContext();
    const [showCopiedZips, setShowCopiedZips] = useState(false);

    const [root, product, section] = namespace.split('.');
    const geoErrors = errors[root]?.[product]?.[section] || {};

    const zipCodes = getZips(getValues(`${namespace}.${type}`));
    const validZips = zipCodes.filter((zip) => !invalidZips[type].includes(zip));

    const setZip = () => {
        setValue(`${namespace}.${type}`, validZips.join(', '), { shouldDirty: true });
        clearErrors(`${namespace}.${type}`);

        // copy invalid zips to clipboard
        navigator.clipboard.writeText(invalidZips[type].join(', '));
        setShowCopiedZips(true);
        setInvalidZips((prevState) => ({
            ...prevState,
            [type]: []
        }));
    };

    const removeInvalidZips = () => {
        switch (type) {
        case zipCodeType.EXCLUDED: {
            setZip();
            break;
        }
        case zipCodeType.INCLUDED: {
            setZip();
            break;
        }
        case zipCodeType.SPECIFIC: {
            setZip();
            break;
        }
        default:
        }
    };

    const isInvalidMsg = !!geoErrors?.[type]?.message.includes('Invalid zips');

    return (
        <>
            {showCopiedZips && (
                <ToastNotification
                    kind="info"
                    title="Invalid zips removed and copied to clipboard"
                    timeout={7000}
                    hideCloseButton
                    onClose={() => setShowCopiedZips(false)}
                    lowContrast
                />
            )}
            {isInvalidMsg && invalidZips[type].length > 0 && (
                <Button
                    className={styles.removeInvalidZips}
                    kind="tertiary"
                    renderIcon={TrashCan16}
                    onClick={removeInvalidZips}
                    hasIconOnly // for styling purposes only
                    iconDescription="Remove ZIPs and copy to clipboard"
                >
                    <span className={styles.removeBtnText}>Remove Invalid ZIPs</span>
                </Button>
            )}
        </>
    );
};

export { Geography as default, healthLifeInitialValues, initialValues };

