import Layout from 'layouts/Centered';

const Error = () => {
    return (
        <Layout>
            <div>Sorry, something went wrong.</div>
        </Layout>
    );
};

export default Error;
