import { Modal, Select, SelectItem } from 'carbon-components-react';
import { dropdownItems } from 'components/leads/LeadDisposition';
import isNil from 'lodash/isNil';
import { useState } from 'react';
import axios from 'util/axios';

const DispositionModal = ({ rows, type, onClose, modalHeading, setUpdatedLeads }) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const [error, setError] = useState(false);

    const processRequest = async () => {
        const key = type === 'status' ? 'status' : 'revisit_later';

        // Convert selectedValue to a Number if this is for revisit later
        const updatedValue = type === 'status' ? selectedValue : Number(selectedValue);

        // Filters out selectedRows that already have the updatedValue
        // to only submit changes with a different value
        const filteredRows = rows.filter((row) => row[type] !== updatedValue);

        const leads = filteredRows.map((row) => {
            return {
                lead_id: row.leadId,
                ad_group_id: row.adGroupId,
                [key]: updatedValue
            };
        });

        try {
            await axios.post(`/lead.json?action=update_status`, { action: 'update_status', leads });

            const updatedRows = rows.map((row) => Object.assign(row, { [type]: updatedValue }));
            setUpdatedLeads(updatedRows);
            onClose();
        }
        catch (err) {
            setError(true);
        }
    };

    const dropdownChildren =
        type === 'status' ? (
            dropdownItems
        ) : (
            <>
                <SelectItem text="Flag" value={1} />
                <SelectItem text="Remove Flag" value={0} />
            </>
        );

    const dropdown = (
        <Select
            defaultValue={rows.length === 1 ? rows[0][type] : null}
            id="lead-disposition"
            labelText={type === 'status' ? 'Update Status' : 'Revisit later?'}
            onChange={({ target }) => setSelectedValue(target.value)}
            hideLabel
        >
            {rows.length > 1 && <SelectItem text="Select an option" />}
            {dropdownChildren}
        </Select>
    );

    return (
        <Modal
            open
            modalHeading={modalHeading}
            onRequestClose={onClose}
            onRequestSubmit={processRequest}
            primaryButtonDisabled={error || isNil(selectedValue)}
            primaryButtonText="Update"
            secondaryButtonText="Cancel"
            size="sm"
        >
            {error ? <p>Something went wrong. Please try again later.</p> : dropdown}
        </Modal>
    );
};

export default DispositionModal;
