import { Filter32 } from '@carbon/icons-react';
import { Button, DropdownSkeleton } from 'carbon-components-react';
import { motion } from 'framer-motion';
import useQueryParams from 'hooks/use-query-params';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useRef, useState } from 'react';
import FilterItem from './FilterItem';
import styles from './TableFilters.module.scss';

const TableFilters = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const { filters, filterValues, filterItems } = props;
    const { updateQueryParam } = useQueryParams();
    const containerRef = useRef(null);

    const clearAllFilters = () => {
        if (isEmpty(filterValues)) return;

        Object.values(filters).forEach(({ queryString }) => updateQueryParam(queryString, '', { replace: true }));
        setReload(true);
    };

    useEffect(() => {
        if (reload) {
            setTimeout(() => {
                setReload(false);
            }, 300);
        }
    }, [reload]);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick, true);
        return () => {
            document.removeEventListener('click', handleOutsideClick, true);
        };
    }, []);

    const handleOutsideClick = (event) => {
        if (!isOpen && !containerRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const filterCount = useMemo(
        () =>
            Object.values(filterValues).reduce(
                (total, val) => (Array.isArray(val) ? total + val.length : total + 1),
                0
            ),
        [filterValues]
    );

    const filterProps = useMemo(() => {
        return Object.keys(filters).map((filterName, i) => {
            const { type, label, options, queryString, valueType } = filters[filterName];
            const items = options || filterItems[filterName];

            return {
                ariaLabel: label,
                filterName: filterName,
                id: `${filterName}Filter`,
                items,
                key: `Filter${i}`,
                label,
                queryString,
                titleText: label,
                type,
                valueType
            };
        });
    }, [filters, filterItems]);

    const values = useMemo(() => Object.keys(filters).map((name) => filterValues[name]), [filterValues]);

    return (
        <div ref={containerRef}>
            <Button kind="ghost" iconDescription="" onClick={() => setIsOpen(!isOpen)} renderIcon={Filter32}>
                <span className={styles.filtersButtonText}>Filters {filterCount ? `(${filterCount})` : null}</span>
            </Button>
            <motion.div
                animate={isOpen ? 'visible' : 'hidden'}
                className={styles.menu}
                initial="hidden"
                variants={menuVariants}
            >
                <div className={styles.header}>
                    <span>Filters</span>
                    <Button onClick={clearAllFilters}>Clear All</Button>
                </div>

                <div className={styles.filterList}>
                    {filterProps.map((props, i) =>
                        reload ? <FilterPlaceholder {...props} /> : <FilterItem {...props} filterValue={values[i]} />
                    )}
                </div>
            </motion.div>
        </div>
    );
};

const FilterPlaceholder = ({ label }) => {
    return (
        <div className={styles.filterPlaceholder}>
            <div className="bx--label">{label}</div>
            <DropdownSkeleton />
        </div>
    );
};

const menuVariants = {
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.1
        },
        display: 'block'
    },
    hidden: {
        opacity: 0,
        scale: 0,
        originX: 1,
        originY: 0,
        transition: {
            duration: 0.1
        },
        transitionEnd: {
            display: 'none'
        }
    }
};

export { TableFilters };
