import { Information16 } from '@carbon/icons-react';
import { TooltipIcon } from 'carbon-components-react';
import { products } from 'util/constants';

const propertyTypeOptionText = 'Single Family Home, Multi Family Home, Duplex, Townhome, Condominium';

const renderInsuredCell = (cellText, tooltipText) => (
    <>
        {cellText}
        <TooltipIcon tooltipText={tooltipText} direction="top">
            <Information16 />
        </TooltipIcon>
    </>
);

const renderSubsidyType = (type) => (
    <div>
        {type}
        <div>(SF Subsidy)</div>
    </div>
);

const headerData = (isSubsidyAccount) => ({
    [products.AUTO]: [
        {
            header: 'Type',
            key: 'name'
        },
        {
            header: 'Daily Cap',
            key: 'dailyCap'
        },
        {
            header: 'Bid',
            key: 'bid'
        },
        {
            header: 'Age',
            key: 'age'
        },
        {
            header: 'Insured',
            key: 'insured'
        },
        {
            header: 'Accidents',
            key: 'accidents'
        },
        {
            header: 'Tickets',
            key: 'tickets'
        },
        {
            header: 'Licensed',
            key: 'licensed'
        },
        {
            header: 'DUI',
            key: 'dui'
        },
        {
            header: 'SR-22',
            key: 'sr_22'
        },
        {
            header: 'Homeowner',
            key: 'homeowner'
        },
        ...(!isSubsidyAccount
            ? [
                {
                    header: 'Credit',
                    key: 'credit'
                }
            ]
            : [])
    ],
    [products.HOME]: [
        {
            header: 'Type',
            key: 'name'
        },
        {
            header: 'Daily Cap',
            key: 'dailyCap'
        },
        {
            header: 'Bid',
            key: 'bid'
        },
        {
            header: 'Property Type',
            key: 'propertyType'
        },
        ...(!isSubsidyAccount
            ? [
                {
                    header: 'Build Year',
                    key: 'buildYear'
                }
            ]
            : []),
        {
            header: 'Claims',
            key: 'claims'
        }
    ],
    ...(!isSubsidyAccount && {
        [products.HEALTH]: [
            {
                header: 'Type',
                key: 'name'
            },
            {
                header: 'Daily Cap',
                key: 'dailyCap'
            },
            {
                header: 'Bid',
                key: 'bid'
            },
            {
                header: 'Age',
                key: 'age'
            },
            {
                header: 'Household Income',
                key: 'income'
            },
            {
                header: 'Source',
                key: 'source'
            }
        ],
        [products.LIFE]: [
            {
                header: 'Type',
                key: 'name'
            },
            {
                header: 'Daily Cap',
                key: 'dailyCap'
            },
            {
                header: 'Bid',
                key: 'bid'
            },
            {
                header: 'Coverage Type',
                key: 'coverageType'
            },
            {
                header: 'Coverage Amount',
                key: 'coverageAmount'
            },
            {
                header: 'Age',
                key: 'age'
            }
        ]
    })
});

// use campaign.shortName to get targeting values
const regularCampaignTargeting = {
    premium: {
        age: '25 or older',
        insured: renderInsuredCell('Yes', 'Includes consumers who have at least 12 months of continuous coverage.'),
        accidents: 'No',
        tickets: 'Max 1',
        licensed: 'Yes',
        dui: 'No',
        sr_22: 'No',
        homeowner: 'Yes',
        credit: 'Excellent, Good'
    },
    preferred: {
        age: '25 or older',
        insured: renderInsuredCell('Yes', 'Includes consumers who have at least 12 months of continuous coverage.'),
        accidents: 'No',
        tickets: 'Max 1',
        licensed: 'Yes',
        dui: 'No',
        sr_22: 'No',
        homeowner: 'Any',
        credit: 'Any'
    },
    standard: {
        age: '20 or older',
        insured: 'Any',
        accidents: 'No',
        tickets: 'Max 2',
        licensed: 'Yes',
        dui: 'No',
        sr_22: 'No',
        homeowner: 'Any',
        credit: 'Any'
    },
    non_standard: {
        age: 'Any',
        insured: 'Any',
        accidents: 'Any',
        tickets: 'Any',
        licensed: 'Any',
        dui: 'Any',
        sr_22: 'Any',
        homeowner: 'Any',
        credit: 'Any'
    },
    home_premier: {
        propertyType: propertyTypeOptionText,
        buildYear: '2000 or newer',
        claims: '0'
    },
    home_choice: {
        propertyType: propertyTypeOptionText,
        buildYear: 'Any',
        claims: '1'
    },
    home_select: {
        propertyType: propertyTypeOptionText,
        buildYear: 'Any',
        claims: 'Any'
    },
    health_premium: {
        age: 'No Limit',
        income: 'No Limit',
        source: 'Tier 1'
    },
    health_preferred: {
        age: 'No Limit',
        income: 'No Limit',
        source: 'Tier 2'
    },
    health_standard: {
        age: 'No Limit',
        income: 'No Limit',
        source: 'All Sources'
    },
    final_expense: {
        age: '50+',
        coverageType: 'Final Expense',
        coverageAmount: 'No Limit'
    },
    'term/whole_life': {
        age: '18 - 49',
        coverageType: 'Term/Whole Life',
        coverageAmount: 'No Limit'
    }
};

const subsidyCampaignTargeting = {
    premium_sf_subsidy: {
        name: renderSubsidyType('Premium'),
        age: '25 or older',
        insured: renderInsuredCell(
            'Yes',
            'Includes consumers who have at least 6 months of continuous coverage, and not currently insured by State Farm.'
        ),
        accidents: 'No',
        tickets: 'Max 1',
        licensed: 'Yes',
        dui: 'No',
        sr_22: 'No',
        homeowner: 'Yes'
    },
    preferred_sf_subsidy: {
        name: renderSubsidyType('Preferred'),
        age: '22 or older',
        insured: renderInsuredCell('Yes', 'Includes consumers who are currently insured, and not by State Farm.'),
        accidents: 'No',
        tickets: 'Max 1',
        licensed: 'Yes',
        dui: 'No',
        sr_22: 'No',
        homeowner: 'Any'
    },
    home_premier_sf_subsidy: {
        name: renderSubsidyType('Home Premier'),
        propertyType: propertyTypeOptionText,
        claims: '0'
    },
    home_choice_sf_subsidy: {
        name: renderSubsidyType('Home Choice'),
        propertyType: propertyTypeOptionText,
        claims: '1'
    }
};

const getCampaignTargeting = (isSubsidyAccount) =>
    isSubsidyAccount ? subsidyCampaignTargeting : regularCampaignTargeting;

const tooltips = {
    Accidents: 'Includes At Fault Accidents which occurred in the last 3 years',
    Tickets: 'Includes tickets received in the last 3 years',
    Claims: 'Includes claims made in the last 3 years.'
};

export { getCampaignTargeting, headerData, tooltips };
