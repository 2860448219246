import { DatePicker, DatePickerInput, Dropdown } from 'carbon-components-react';
import { endOfMonth, isAfter, isBefore, isValid, startOfMonth, startOfYear, subDays, subMonths } from 'date-fns';
import useQueryParams from 'hooks/use-query-params';
import { useEffect, useRef, useState } from 'react';
import { dateToDatePickerString, dateToString, stringToDate } from 'util/format-date';
import styles from './DateFilter.module.scss';

const MIN_DATE = '01/01/2020';

const DateFilter = ({ initialDates, dateRangeOptions = defaultDateRanges }) => {
    const today = new Date();
    const minDate = new Date(MIN_DATE);
    const { updateQueryParam, query } = useQueryParams();

    const [dateFrom, setDateFrom] = useState(stringToDate(initialDates.dateFrom));
    const [dateTo, setDateTo] = useState(stringToDate(initialDates.dateTo));
    const [dateRange, setDateRange] = useState(initialDates.dateRange || query.get('date_sel') || '30');

    const mounting = useRef(true);

    const handleDateFrom = (date) => {
        if (!isValid(date)) return;

        setDateRange('custom');

        // Set the date even if it doesn't meet min/max
        // So the error message is displayed
        setDateFrom(date);

        if (isValidDate(date) && isAfter(date, dateTo)) {
            setDateTo(date);
        }
    };

    const handleDateTo = (date) => {
        if (!isValid(date)) return;

        setDateRange('custom');

        // Set the date even if it doesn't meet min/max
        // So the error message is displayed
        setDateTo(date);

        if (isValidDate(date) && isBefore(date, dateFrom)) {
            setDateFrom(date);
        }
    };

    const handleDateRange = ({ selectedItem }) => {
        const { value } = selectedItem;
        setDateRange(value);

        switch (value) {
        case 'custom':
            break;

        case 'today':
            setDateFrom(today);
            setDateTo(today);
            break;

        case 'yesterday':
            setDateFrom(subDays(today, 1));
            setDateTo(subDays(today, 1));
            break;

        case 'month':
            setDateFrom(startOfMonth(today));
            setDateTo(today);
            break;

        case 'last_month':
            setDateFrom(startOfMonth(subMonths(today, 1)));
            setDateTo(endOfMonth(subMonths(today, 1)));
            break;

        case 'year':
            setDateFrom(startOfYear(today));
            setDateTo(today);
            break;

        case 'all':
            setDateFrom(minDate);
            setDateTo(today);
            break;

        default:
            setDateFrom(subDays(today, Number(value)));
            setDateTo(today);
        }
    };

    useEffect(() => {
        // Avoid running effect on component mount
        if (mounting.current) {
            mounting.current = false;
            return;
        }

        if (isValidDate(dateFrom) && isValidDate(dateTo)) {
            updateQueryParam('date_from', dateToString(dateFrom), { replace: true });
            updateQueryParam('date_to', dateToString(dateTo), { replace: true });
            updateQueryParam('date_sel', dateRange, { replace: true });
        }
    }, [dateFrom, dateTo]);

    return (
        <div className={styles.dateFilter}>
            <Dropdown
                className={styles.dateRange}
                id="date-range"
                initialSelectedItem={dateRangeOptions.find((obj) => obj.value === dateRange)}
                items={dateRangeOptions}
                label="Date Range"
                onChange={(selectedItem) => handleDateRange(selectedItem)}
                selectedItem={dateRangeOptions.find((obj) => obj.value === dateRange)}
                size="sm"
                titleText="Date Range"
            />

            <DatePicker
                datePickerType="single"
                dateFormat="m/d/Y"
                maxDate={dateToDatePickerString(today)}
                minDate={MIN_DATE}
                onChange={(val) => handleDateFrom(val[0])}
                value={dateToDatePickerString(dateFrom)}
            >
                <DatePickerInput
                    hideLabel={true}
                    id="date-picker-from"
                    invalidText="Invalid Date"
                    labelText="Date From"
                    placeholder="mm/dd/yyyy"
                    size="sm"
                />
            </DatePicker>

            <DatePicker
                datePickerType="single"
                dateFormat="m/d/Y"
                maxDate={dateToDatePickerString(today)}
                minDate={MIN_DATE}
                onChange={(val) => handleDateTo(val[0])}
                value={dateToDatePickerString(dateTo)}
            >
                <DatePickerInput
                    hideLabel={true}
                    id="date-picker-to"
                    invalidText="Invalid Date"
                    labelText="Date To"
                    placeholder="mm/dd/yyyy"
                    size="sm"
                />
            </DatePicker>
        </div>
    );
};

const defaultDateRanges = [
    {
        label: 'Today',
        value: 'today'
    },
    {
        label: 'Yesterday',
        value: 'yesterday'
    },
    {
        label: 'Last 7 Days',
        value: '7'
    },
    {
        label: 'Last 14 Days',
        value: '14'
    },
    {
        label: 'Last 30 Days',
        value: '30'
    },
    {
        label: 'This Month',
        value: 'month'
    },
    {
        label: 'Last Month',
        value: 'last_month'
    },
    {
        label: 'This Year',
        value: 'year'
    },
    {
        label: 'All Time',
        value: 'all'
    },
    {
        label: 'Custom',
        value: 'custom'
    }
];

const isValidDate = (date) => {
    return isValid(date) && !isBefore(date, new Date(MIN_DATE)) && !isAfter(date, new Date());
};

export { DateFilter, defaultDateRanges };
