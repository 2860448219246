import { Checkmark16 } from '@carbon/icons-react';
import {
    Button,
    Form,
    StructuredListBody,
    StructuredListCell,
    StructuredListHead,
    StructuredListRow,
    StructuredListWrapper,
    TextInput,
    ToastNotification
} from 'carbon-components-react';
import { PageSection } from 'components/common';
import { FatalError } from 'components/errors';
import { ReferralUrl } from 'components/referrals';
import useAuth from 'hooks/use-auth';
import depositIcon from 'img/deposits.svg';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import axios from 'util/axios';
import { formatError } from 'util/format-error';
import { currencyInput } from 'util/validate-form';
import styles from './ReferralForm.module.scss';

const apiEndpoint = '/referral.json';

const ReferralForm = () => {
    const [defaultValues, setDefaultValues] = useState(null);
    const [allocated, setAllocated] = useState(null);
    const [url, setUrl] = useState('');
    const [submitError, setSubmitError] = useState(null);
    const [loading, setLoading] = useState(null);
    const [referralError, setReferralError] = useState(null);
    const { getUser } = useAuth();
    const history = useHistory();

    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isDirty, errors },
        getValues,
        setValue,
        reset,
        setError,
        watch,
        clearErrors
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: {
            ...defaultValues
        }
    });

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const res = await axios.get(`${apiEndpoint}?action=select`);

                setUrl(res.data.url);
                setAllocated({ total: 0, remainder: res.data.creditAllocation.credit });
                setDefaultValues(res.data.creditAllocation);
            }
            catch (err) {
                setReferralError(formatError(err));
            }

            setLoading(false);
        })();
    }, []);

    const accounts = getValues('accounts');
    // Creating another object to avoid sharing the same object reference
    const initialValues = { ...defaultValues };

    useEffect(() => {
        if (defaultValues) {
            reset({
                ...defaultValues
            });
        }
        // cleanup on unmount
        return () => {
            reset({ ...initialValues });
        };
    }, [reset, defaultValues]);

    const onSubmit = async (values) => {
        const allocation = {};
        values.accounts.forEach((acct) => {
            // filtering out "0.00" | "" | undefined
            if (!!Number(acct.allocationValue)) {
                allocation[acct.id] = acct.allocationValue;
            }
        });

        try {
            const endpoint = `${apiEndpoint}?action=allocate_credit`;
            await axios.post(endpoint, allocation);

            getUser();

            setTimeout(() => history.replace('/p/leads'), 0);
        }
        catch (err) {
            setSubmitError(formatError(err));
        }
    };

    const getRemainder = (credit, total) => Number(credit) - Number(total);

    const disableSubmit = useMemo(() => {
        const hasErrors = Boolean(Object.keys(errors).length);
        return Number(allocated?.total) === 0 || !isDirty || isSubmitting || hasErrors;
    }, [isDirty, isSubmitting, errors, allocated]);

    if (loading) {
        return [...Array(5).keys()].map((i) => <PageSection skeleton key={i} />);
    }

    if (referralError) {
        return <FatalError error={referralError} />;
    }

    return (
        <>
            <ReferralUrl url={url} />

            {(defaultValues?.accounts.length > 1 || defaultValues?.credit > 0) && (
                <PageSection name="Manage Referral Credits" icon={depositIcon}>
                    <p style={{ marginBottom: 15 }}>
                        Available Referral Credits: <strong>${defaultValues?.credit.toFixed(2)}</strong>
                    </p>

                    {submitError && (
                        <ToastNotification
                            title="Error"
                            caption={<span>{submitError.message}</span>}
                            timeout={7000}
                            hideCloseButton
                            onCloseButtonClick={() => setSubmitError(null)}
                        />
                    )}

                    {defaultValues?.credit > 0 && (
                        <Form
                            className={styles.referralCreditsForm}
                            data-testid="referral-credits-form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <StructuredListWrapper>
                                <StructuredListHead>
                                    <StructuredListRow head>
                                        <StructuredListCell head>Account</StructuredListCell>
                                        <StructuredListCell head>Current Balance</StructuredListCell>
                                        <StructuredListCell head>Credit Allocation</StructuredListCell>
                                    </StructuredListRow>
                                </StructuredListHead>
                                <StructuredListBody>
                                    {accounts &&
                                        accounts.map((row, i) => {
                                            const allocation = register(`accounts.${i}.allocationValue`, {
                                                validate: (val) => {
                                                    let total = 0;
                                                    const watchAccounts = watch('accounts');
                                                    watchAccounts.forEach((acct) => {
                                                        if (acct.allocationValue) {
                                                            total += Number(acct.allocationValue);
                                                        }
                                                    });
                                                    const remainder = getRemainder(getValues('credit'), total);
                                                    // update state
                                                    setAllocated({ total, remainder });
                                                    const hasError = remainder < 0;
                                                    // update form meta error
                                                    if (hasError) {
                                                        setError('exceededCredits', {
                                                            type: 'manual',
                                                            message: 'Credits exceeded'
                                                        });
                                                    }
                                                    // if there's no error, clear errors
                                                    return hasError ? 'Not enough referral credits' : clearErrors();
                                                }
                                            });
                                            const allocationErrors =
                                                errors?.accounts && errors?.accounts[i]?.allocationValue;
                                            return (
                                                <StructuredListRow key={`row-${i}`}>
                                                    <StructuredListCell>{row.name}</StructuredListCell>
                                                    <StructuredListCell>${row.balance.toFixed(2)}</StructuredListCell>
                                                    <StructuredListCell>
                                                        <TextInput
                                                            id={allocation.name}
                                                            name={allocation.name}
                                                            ref={allocation.ref}
                                                            labelText={`${row.name} Credit Allocation`}
                                                            hideLabel={true}
                                                            onChange={(evt) => {
                                                                allocation.onChange(evt);
                                                                const { value } = evt.target;
                                                                evt.target.value = currencyInput(value);
                                                            }}
                                                            onBlur={(evt) => {
                                                                allocation.onBlur(evt);
                                                                setValue(
                                                                    allocation.name,
                                                                    evt.target.value
                                                                        ? Number(evt.target.value).toFixed(2)
                                                                        : '',
                                                                    { shouldValidate: true }
                                                                );
                                                            }}
                                                            invalid={!!allocationErrors}
                                                            invalidText={allocationErrors?.message}
                                                        />
                                                    </StructuredListCell>
                                                </StructuredListRow>
                                            );
                                        })}
                                    <StructuredListRow className={styles.summaryRow}>
                                        <StructuredListCell>
                                            <strong>Total Allocated</strong>
                                        </StructuredListCell>

                                        <StructuredListCell />

                                        <StructuredListCell>
                                            <span
                                                className={
                                                    errors.exceededCredits ? styles['total--error'] : styles.total
                                                }
                                            >
                                                ${allocated.total.toFixed(2)}
                                            </span>

                                            {!errors.exceededCredits && (
                                                <span style={{ fontSize: '14px' }}>
                                                    (${allocated.remainder.toFixed(2)} remaining)
                                                </span>
                                            )}
                                            <br />
                                            <Button
                                                type="submit"
                                                kind="primary"
                                                disabled={disableSubmit}
                                                renderIcon={Checkmark16}
                                                style={{ marginTop: '25px' }}
                                            >
                                                Apply Credits
                                            </Button>
                                        </StructuredListCell>
                                    </StructuredListRow>
                                </StructuredListBody>
                            </StructuredListWrapper>
                        </Form>
                    )}
                </PageSection>
            )}
        </>
    );
};

export default ReferralForm;
