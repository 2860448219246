import { Purchase16 } from '@carbon/icons-react';
import { TooltipDefinition, TooltipIcon } from 'carbon-components-react';
import { AccountStatus, AccountStatusMenu } from 'components/accounts';
import { DefaultTable, defaultDateRanges } from 'components/tables';
import carriers from 'data/carriers';
import { subDays } from 'date-fns';
import useAccount from 'hooks/use-account';
import useApi from 'hooks/use-api';
import useDevice from 'hooks/use-device';
import useQueryParams from 'hooks/use-query-params';
import useStoredQuery from 'hooks/use-stored-query';
import useUser, { groups } from 'hooks/use-user';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'util/axios';
import { dateToPrettyString, dateToString, stringToDate } from 'util/format-date';
import { dollarAmount, formatNumber, percentage } from 'util/format-number';
import { handleCustomSort } from 'util/table-helpers';
import styles from './AccountList.module.scss';

const HIDDEN_COLUMNS = ['autoRefill'];

const AccountList = ({ setExportData }) => {
    const [result, loading, error] = useApi('/account.json?action=get_account_list');
    const [accounts, setAccounts] = useState(null);
    const [stats, setStats] = useState({});
    const [statsLoading, setStatsLoading] = useState(false);
    const [exportParams, setExportParams] = useState(null);

    const { mobile } = useDevice();
    const { canCreateAccount, group } = useUser();
    const { setAccount } = useAccount();
    const { query } = useQueryParams();

    const dateFrom = query.get('date_from') || dateToString(subDays(new Date(), 30));
    const dateTo = query.get('date_to') || dateToString(new Date());
    const dateRange = query.get('date_sel') || '30';

    useStoredQuery(filters);

    const accessAccount = useCallback(
        (advertiserId) => {
            const account = accounts.find((acc) => acc.advertiserId === advertiserId);
            setAccount(account);
        },
        [accounts, setAccount]
    );

    const getStats = async () => {
        setStatsLoading(true);

        const advertiserIds = result?.accounts.map((acc) => acc.advertiserId);
        try {
            const { data } = await axios.post('/account.json?action=get_account_stats', {
                advertiserIds,
                dateFrom,
                dateTo
            });
            if (data?.stats) setStats(data.stats);
        }
        catch (err) {
            setStats({});
        }
        finally {
            setStatsLoading(false);
        }
    };

    useEffect(() => {
        result?.accounts && setAccounts(result.accounts);
    }, [result]);

    useEffect(() => {
        result && getStats();
    }, [result, dateFrom, dateTo]);

    useEffect(() => {
        setExportData({
            visible: Boolean(accounts?.length),
            params: {
                action: 'export_accounts',
                ...exportParams
            }
        });
    }, [accounts, exportParams]);

    useEffect(() => {
        setExportParams((params) => ({
            ...params,
            date_from: dateFrom,
            date_to: dateTo,
            date_range: dateRange
        }));
    }, [dateFrom, dateTo, dateRange]);

    const columns = useMemo(() => getColumns(mobile), [mobile]);
    const data = useMemo(() => {
        if (accounts?.length) {
            const rows = accounts.map((acc) => ({
                ...acc,
                id: String(acc.id)
            }));

            // normalize for table search, filtering
            rows.forEach((row) => {
                const rowStats = stats[row.id] || null;
                if (rowStats) {
                    row.cost = rowStats.cost || 0;
                    row.numLeadSale = rowStats.numLeadSale || 0;
                    row.numLeadReturn = rowStats.numLeadReturn || 0;
                }

                const { name } = row.accountManager || { name: '' };
                const { numLeadReturn, numLeadSale } = row;

                row['accountManagerName'] = name;
                row['percentLeadReturn'] = parseInt(percentage(numLeadReturn, numLeadSale));
            });

            return rows;
        }
        return [];
    }, [accounts, stats]);

    const modifiers = {
        status: (account) => {
            const { id, status } = account;
            return (
                <div className={styles.statusModifier}>
                    {Boolean(canCreateAccount) ? (
                        <AccountStatusMenu
                            canCreateAccount={canCreateAccount}
                            id={id}
                            status={status}
                            setAccounts={setAccounts}
                        />
                    ) : (
                        <AccountStatus status={status} withTooltip />
                    )}
                </div>
            );
        },
        name: (account) => {
            const employee = group === groups.EMPLOYEE;
            const { name, status, advertiserId } = account;

            const handleClick = (evt) => {
                if (employee) {
                    // Prevent setting an account when opening in a new tab
                    if (!evt.ctrlKey && !evt.metaKey) {
                        accessAccount(advertiserId);
                    }
                }
                else {
                    navigator.clipboard.writeText(advertiserId);
                }
            };

            return mobile ? (
                <div onClick={handleClick} className={styles.mobileAccountNameContainer}>
                    <AccountStatus status={status} withTooltip />
                    <span className={styles.mobileAccountName}>
                        {employee ? (
                            <Link
                                to={`/p/leads?advertiser_id=${advertiserId}`}
                                className={styles.mobileAccountNameLink}
                            >
                                {name}
                            </Link>
                        ) : (
                            name
                        )}
                    </span>
                </div>
            ) : (
                <TooltipDefinition
                    align="center"
                    children={employee ? <Link to={`/p/leads?advertiser_id=${advertiserId}`}>{name}</Link> : name}
                    triggerClassName="use-pointer"
                    direction="top"
                    onClick={handleClick}
                    tooltipText={advertiserId}
                />
            );
        },
        agentName: ({ email, agentName }) => {
            if (!agentName) {
                return '';
            }

            return email ? (
                <TooltipDefinition
                    align="center"
                    children={agentName}
                    direction="top"
                    onClick={() => navigator.clipboard.writeText(email)}
                    tooltipText={email}
                />
            ) : (
                agentName
            );
        },
        accountManagerName: ({ accountManagerName, salesManager }) => {
            return (
                <div>
                    {accountManagerName && <div className="csm-sm">{accountManagerName}</div>}
                    {salesManager && <div className="csm-sm">{salesManager}</div>}
                </div>
            );
        },
        numLeadSale: ({ numLeadSale }) => formatNumber(numLeadSale),
        percentLeadReturn: ({ percentLeadReturn, numLeadReturn }) => {
            return (
                <TooltipDefinition
                    align="center"
                    children={`${percentLeadReturn}%`}
                    triggerClassName={percentLeadReturn >= 20 ? `high-return-rate` : null}
                    direction="top"
                    tooltipText={numLeadReturn}
                />
            );
        },
        cost: ({ cost }) => dollarAmount(cost),
        balance: ({ balance, autoRefill }) => {
            return (
                <div>
                    {dollarAmount(balance)}
                    {autoRefill ? (
                        <TooltipIcon className="icon-auto-renew" direction="top" tooltipText="Auto-Refill is enabled">
                            <Purchase16 />
                        </TooltipIcon>
                    ) : null}
                </div>
            );
        },
        activationTime: ({ activationTime }) => (activationTime ? dateToPrettyString(stringToDate(activationTime)) : '')
    };

    return (
        <DefaultTable
            data={data}
            dateRangeOptions={defaultDateRanges.filter((d) => d.value !== 'all')}
            error={error}
            filters={filters}
            headers={columns}
            hiddenColumns={HIDDEN_COLUMNS}
            initialDates={{ dateFrom, dateTo, dateRange }}
            loading={loading}
            outputModifiers={modifiers}
            record="account"
            setExportParams={setExportParams}
            sortRow={handleCustomSort}
            showLoading={statsLoading}
        />
    );
};

const filters = {
    carrier: {
        label: 'Carrier',
        type: 'multiSelect',
        queryString: 'carrier',
        options: carriers
    },
    state: {
        label: 'State',
        type: 'multiSelect',
        queryString: 'state'
    },
    status: {
        label: 'Status',
        type: 'multiSelect',
        queryString: 'status',
        options: [
            { value: 'enabled', label: 'Enabled' },
            { value: 'paused', label: 'Paused' },
            { value: 'pending', label: 'Pending' },
            { value: 'in_test', label: 'In Test' },
            { value: 'unsold', label: 'Unsold' },
            { value: 'canceled', label: 'Canceled' }
        ]
    },
    accountManagerName: {
        label: 'Client Success Manager',
        type: 'dropdown',
        queryString: 'csm'
    },
    salesManager: {
        label: 'Sales Manager',
        type: 'dropdown',
        queryString: 'sm'
    },
    autoRefill: {
        label: 'Auto-Refill',
        type: 'dropdown',
        valueType: 'number',
        queryString: 'auto_refill',
        options: [
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' }
        ]
    },
    subsidyAdvertiserId: {
        label: 'Type',
        type: 'dropdown',
        valueType: 'string',
        queryString: 'subsidy_advertiser_id',
        options: [
            { value: '0', label: 'Regular' },
            { value: '44183', label: 'SF Subsidy' }
        ]
    }
};

const getColumns = (mobile) => {
    const columns = {
        status: {
            header: 'Status',
            key: 'status'
        },
        carrier: {
            header: 'Carrier',
            key: 'carrier'
        },
        state: {
            header: 'State',
            key: 'state'
        },
        name: {
            header: 'Account Name',
            key: 'name'
        },
        agentName: {
            header: 'Agent',
            key: 'agentName'
        },
        numLeadSale: {
            header: 'Delivered',
            key: 'numLeadSale'
        },
        percentLeadReturn: {
            header: 'Returned',
            key: 'percentLeadReturn'
        },
        cost: {
            header: 'Cost',
            key: 'cost'
        },
        balance: {
            header: 'Balance',
            key: 'balance'
        },
        accountManagerName: {
            header: 'CSM/SM',
            key: 'accountManagerName'
        },
        autoRefill: {
            header: 'Auto Refill',
            key: 'autoRefill'
        },
        activationTime: {
            header: 'Activation Date',
            key: 'activationTime'
        }
    };

    const mobileFields = [
        'name',
        'agentName',
        'carrier',
        'state',
        'accountManagerName',
        'numLeadSale',
        'percentLeadReturn',
        'cost',
        'balance',
        'activationTime',
        'autoRefill'
    ];
    const defaultFields = [
        'status',
        'name',
        'agentName',
        'carrier',
        'state',
        'accountManagerName',
        'numLeadSale',
        'percentLeadReturn',
        'cost',
        'balance',
        'activationTime',
        'autoRefill'
    ];

    const fields = mobile ? mobileFields : defaultFields;
    return fields.map((field) => columns[field]);
};

export default AccountList;
