import * as Sentry from '@sentry/react';
import useAccount, { statuses } from 'hooks/use-account';
import useQueryParams from 'hooks/use-query-params';
import useUser from 'hooks/use-user';
import { Redirect, Route, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children, path, meta = {}, ...rest }) => {
    const {
        requiresAccount,
        requiresAdmin,
        requiresCanCreateAccount,
        requiresCanManageAccount,
        requiresEmployee,
        requiresLeadFeed
    } = meta;

    const { user, admin, employee, canCreateAccount } = useUser();
    const { account, id: accountID, features, status, canManageAccount } = useAccount();
    const { query } = useQueryParams();
    const location = useLocation();

    const authorized = () => {
        if (requiresAdmin && !admin) return false; // employee or consultant
        if (requiresEmployee && !employee) return false;
        if (requiresCanCreateAccount && !canCreateAccount) return false;
        if (requiresCanManageAccount && !canManageAccount) return false;
        if (requiresLeadFeed && !(features.leadFeedCall || features.leadFeedLead)) return false;

        return true;
    };

    // Attempting to access an account-specific page without an account selected.
    if (requiresAccount && !account) {
        // For employees, redirect to the accounts list.
        if (employee) {
            return <Redirect to="/p/accounts" />;
        }
        // For non-employees, this should not be possible.
        else {
            Sentry.captureException(new Error('non-employee without account'), {
                user
            });
            return <Redirect to="/error" />;
        }
    }

    // Append advertiser_id to the URL for account-specific pages.
    if (requiresAccount && !query.has('advertiser_id')) {
        query.set('advertiser_id', accountID);
        return <Redirect to={`${path}?${query.toString()}${location.hash}`} />;
    }

    // Attempting to access something other than the account page during the activation phase.
    const activationPaths = ['/p/account', '/p/deposits'];
    if (
        requiresAccount &&
        !employee &&
        [statuses.PENDING, statuses.UNSOLD].includes(status) &&
        !activationPaths.includes(path)
    ) {
        return <Redirect to="/p/account" />;
    }

    if (!user) {
        return (
            <Redirect
                to={{
                    pathname: '/sign-in',
                    state: { referrer: `${location.pathname}${location.search}` }
                }}
            />
        );
    }
    else if (!authorized()) {
        return <Redirect to="/error" />;
    }

    return <Route {...rest}>{children}</Route>;
};

export default PrivateRoute;
