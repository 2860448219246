import isNil from 'lodash/isNil';

const formatNumber = (number) => {
    if (typeof number !== 'number') return number;

    return Number(number).toLocaleString('en-US', {
        maximumFractionDigits: 2
    });
};

const dollarAmount = (amount, options = {}) => {
    if (isNil(amount)) return '';

    const { withSign = true } = options;

    return withSign
        ? Number(amount).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2
        })
        : Number(amount).toFixed(2);
};

const percentage = (numerator, denominator, options = {}) => {
    const { withSign = false, integer = false } = options;

    numerator = Number(numerator);
    denominator = Number(denominator);

    if (numerator === 0 || denominator === 0) return withSign ? '0%' : 0;

    let percent = (numerator / denominator) * 100;
    if (integer) percent = parseInt(percent);
    if (withSign) percent = percent + '%';

    return percent;
};

export { formatNumber, dollarAmount, percentage };
