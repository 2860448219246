import { ExportButton } from 'components/buttons';
import { PageHeader } from 'components/common';
import { LeadList } from 'components/leads';
import { useState } from 'react';
import { Helmet as Head } from 'react-helmet-async';

const Leads = ({ type }) => {
    const [exportData, setExportData] = useState({});
    const title = type === 'purchased' ? 'My Leads' : 'Returns';

    return (
        <>
            <Head>
                <title>{title}</title>
            </Head>
            <PageHeader title={title}>
                {exportData.visible && <ExportButton api="lead" params={exportData.params} />}
            </PageHeader>
            <LeadList type={type} setExportData={setExportData} />
        </>
    );
};

export default Leads;
