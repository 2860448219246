import { useEffect } from 'react';
import { Helmet as Head } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/routes';
import Error from './pages/Error';
import ForgotPassword from './pages/ForgotPassword';
import Platform from './pages/platform/Platform';
import Privacy from './pages/Privacy';
import ReactivateUser from './pages/ReactivateUser';
import SetPassword from './pages/SetPassword';
import SignIn from './pages/SignIn';
import Terms from './pages/Terms';

const PLATFORM_RELOAD_INTERVAL = 12 * 60 * 60 * 1000; // 12 hours

const App = () => {
    useEffect(reloadPlatformOnInterval, []);
    return (
        <>
            <Head titleTemplate="%s | MediaAlpha For Agents" defaultTitle="MediaAlpha For Agents" />
            <Switch>
                <PrivateRoute path="/p">
                    <Platform />
                </PrivateRoute>
                <Route path="/sign-in">
                    <SignIn />
                </Route>
                <Route path="/set-password">
                    <SetPassword />
                </Route>
                <Route path="/forgot-password">
                    <ForgotPassword />
                </Route>
                <Route path="/reactivate-user">
                    <ReactivateUser />
                </Route>
                <Route path="/terms">
                    <Terms />
                </Route>
                <Route path="/privacy">
                    <Privacy />
                </Route>
                <Route path="/error">
                    <Error />
                </Route>
                <Route path="/">
                    <Redirect to="/p" />
                </Route>
            </Switch>
        </>
    );
};

const reloadPlatformOnInterval = () => {
    const id = window.setInterval(() => window.location.reload(), PLATFORM_RELOAD_INTERVAL);
    return () => window.clearInterval(id);
};

export default App;
