import { AppLoading } from 'components/common';
import AuthContext from 'context/auth';
import useQueryParams, { makeParamString } from 'hooks/use-query-params';
import { useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'util/axios';
import { formatError } from 'util/format-error';

const INITIAL_STATE = {
    init: true,
    user: null,
    loading: false,
    error: false,
    totpRequired: false
};

const LOADING = 'LOADING';
const USER = 'USER';
const ERROR = 'ERROR';
const TOTP = 'TOTP';
const CLEAR = 'CLEAR';

const authReducer = (state, action) => {
    if (action.type === LOADING) {
        return {
            ...state,
            loading: true
        };
    }

    if (action.type === USER) {
        return {
            init: false,
            user: action.payload.user,
            loading: false,
            error: false,
            totpRequired: false
        };
    }

    if (action.type === ERROR) {
        return {
            ...state,
            loading: false,
            error: action.payload.error
        };
    }

    if (action.type === TOTP) {
        return {
            ...state,
            loading: false,
            error: false,
            totpRequired: true
        };
    }

    if (action.type === CLEAR) {
        return {
            init: false,
            user: null,
            loading: false,
            error: false,
            totpRequired: false
        };
    }

    return INITIAL_STATE;
};

const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, INITIAL_STATE);
    const { init, user, loading, error, totpRequired } = state;

    const history = useHistory();
    const { query } = useQueryParams();

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        dispatch({
            type: USER,
            payload: {
                user: await checkAuth()
            }
        });
    };

    const checkAuth = async () => {
        const authParams = new Map();
        for (const param of ['advertiser_id', 'announcement_id']) {
            if (query.has(param)) authParams.set(param, query.get(param));
        }
        const params = authParams.size ? `?${makeParamString(authParams)}` : '';

        try {
            const { data } = await axios.get(`/auth.json${params}`);

            return data;
        }
        catch (err) {
            return null;
        }
    };

    const signIn = async (credentials) => {
        dispatch({ type: LOADING });

        try {
            const { data } = await axios.post('/auth.json?action=signin', credentials);

            if (data.isTotpEnabled) {
                dispatch({
                    type: TOTP
                });
            }
            else {
                dispatch({
                    type: USER,
                    payload: {
                        user: data
                    }
                });
            }
        }
        catch (err) {
            dispatchError(err);
        }
    };

    const signOut = async () => {
        await axios.get('/auth.json?action=signout');

        if (user?.isLoggedInAs) {
            dispatch({
                type: USER,
                payload: {
                    user: await checkAuth()
                }
            });
            history.go(0);
            return;
        }

        dispatch({ type: CLEAR });
        history.replace('/');
    };

    const setPassword = async (credentials) => {
        dispatch({ type: LOADING });

        try {
            await axios.post('/auth.json?action=set_password', credentials);
            signOut();
        }
        catch (err) {
            dispatchError(err);
        }
    };

    const forgotPassword = async (email) => {
        try {
            await axios.post('/auth.json?action=forgot_password', email);
            dispatch({ type: CLEAR });
        }
        catch (err) {
            dispatchError(err);
        }
    };

    const reactivateUser = async (credentials) => {
        dispatch({ type: LOADING });

        try {
            await axios.post('/auth.json?action=reactivate_user', credentials);
            dispatch({ type: CLEAR });
        }
        catch (err) {
            dispatchError(err);
        }
    };

    const dispatchError = (err) => {
        const { message } = formatError(err);

        dispatch({
            type: ERROR,
            payload: {
                error: message || 'An error occurred. Please try again.'
            }
        });
    };

    const value = {
        user,
        getUser,
        signIn,
        signOut,
        setPassword,
        forgotPassword,
        reactivateUser,
        loading,
        error,
        totpRequired
    };

    if (init) {
        return <AppLoading />;
    }
    else {
        return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
    }
};

const useAuth = () => useContext(AuthContext);

export { useAuth as default, AuthProvider };
