import { Edit16, Flag16 } from '@carbon/icons-react';
import { TableBatchAction, TableBatchActions } from 'carbon-components-react';
import { LeadDispositionModal } from 'components/leads';
import { useState } from 'react';

const LeadBatchActions = ({ getBatchActionProps, rowsById, selectedRows, updateRowData }) => {
    const [batchModal, setBatchModal] = useState(null);

    return (
        <>
            {Boolean(batchModal) && (
                <LeadDispositionModal
                    {...batchModal}
                    setUpdatedLeads={updateRowData}
                    onClose={() => setBatchModal(null)}
                />
            )}
            <TableBatchActions {...getBatchActionProps()}>
                <TableBatchAction
                    onClick={() =>
                        setBatchModal({
                            modalHeading: 'Lead Status',
                            rows: selectedRows.map((row) => rowsById[row.id]),
                            type: 'status'
                        })
                    }
                    renderIcon={Edit16}
                    iconDescription="pencil icon"
                >
                    Change Status
                </TableBatchAction>
                <TableBatchAction
                    onClick={() =>
                        setBatchModal({
                            modalHeading: 'Revisit Later',
                            rows: selectedRows.map((row) => rowsById[row.id]),
                            type: 'revisitLater'
                        })
                    }
                    renderIcon={Flag16}
                    iconDescription="flag icon"
                >
                    Revisit Later
                </TableBatchAction>
            </TableBatchActions>
        </>
    );
};

export default LeadBatchActions;
