const ENVIRONMENTS = {
    PRODUCTION: 'production',
    BETA: 'beta',
    DEVELOPMENT: 'development'
};

const EXCHANGE_BASE_URLS = {
    production: '//insurance-exchange.mediaalpha.com',
    beta: '//insurance-exchange-beta.mediaalpha.com',
    development: `//insurance-exchange-beta.dev.mediaalpha.com`
};

const AGENT_BASE_URLS = {
    production: '//insurance-agent.mediaalpha.com',
    beta: '//insurance-agent-beta.mediaalpha.com'
};

const products = {
    AUTO: 'auto',
    HOME: 'home',
    HEALTH: 'health',
    LIFE: 'life'
};

export { ENVIRONMENTS, EXCHANGE_BASE_URLS, AGENT_BASE_URLS, products };
