import { TrashCan16 } from '@carbon/icons-react';
import {
    Button,
    ComposedModal,
    InlineLoading,
    Loading,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    TextInput,
    ToastNotification
} from 'carbon-components-react';
import { PageSection } from 'components/common';
import useAccount from 'hooks/use-account';
import { makeParamString } from 'hooks/use-query-params';
import phoneSettingsIcon from 'img/phone_settings.svg';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import axios from 'util/axios';
import { formatError } from 'util/format-error';
import { normalizePhoneNumber, validatePhoneNumber, validateSms } from 'util/validate-form';
import styles from '../Delivery/Delivery.module.scss';

const status = {
    IN_PROGRESS: 'in_progress',
    VERIFIED: 'verified'
};

const initialValues = {
    smsTo: '',
    autoConnectPhoneNum: '',
    verifiedCallerId: ''
};

const PhoneSettings = ({ namespace, formDisabled, product }) => {
    const {
        register,
        formState: { errors },
        setValue
    } = useFormContext();
    const { features } = useAccount();
    const isLeadFeedEnabled = features.leadFeedLead || features.leadFeadCall;
    const phoneErrors = errors[namespace] || {};

    const autoConnectPhoneNum = register(`${namespace}.autoConnectPhoneNum`, {
        validate: (val) => validatePhoneNumber(val)
    });

    const sms = register(`${namespace}.smsTo`, {
        validate: (val) => validateSms(val)
    });

    const validatePhoneNumbers = (val) => {
        const phoneList = val.split(',');
        let smsTo = phoneList.map((x) => normalizePhoneNumber(x)).join(', ');
        // allow deletion by replacing empty values
        if (phoneList[phoneList.length - 1] === '') smsTo = smsTo.replace(/, $/, ',');
        setValue(sms.name, smsTo, { shouldDirty: true });
    };

    // check if any of the features is enabled for Caller ID field to be displayed
    const showVerifiedCallerId = features.callNowButton || features.autoConnect || features.leadFeedLead;

    return (
        <PageSection name="Phone Settings" icon={phoneSettingsIcon}>
            <div className="bx--grid bx--grid--full-width">
                <div className="bx--row">
                    <div className="bx--col-lg-6">
                        <TextInput
                            ref={sms.ref}
                            id={sms.name}
                            name={sms.name}
                            labelText={<strong>SMS Text Message Notification</strong>}
                            helperText="Optional. Enter a cell phone number to receive a text message notification each time you win a new lead. Standard message rates may apply. To opt out at any time, remove your number from this field and save changes. This setting applies to campaigns across all products."
                            disabled={formDisabled}
                            invalid={!!phoneErrors?.smsTo}
                            invalidText={phoneErrors?.smsTo?.message}
                            onChange={(evt) => {
                                sms.onChange(evt);
                                validatePhoneNumbers(evt.target.value);
                            }}
                            onBlur={sms.onBlur}
                        />
                    </div>
                </div>

                {features.autoConnect && (
                    <div className="bx--row">
                        <div className="bx--col-lg-6">
                            <TextInput
                                id={autoConnectPhoneNum.name}
                                labelText={<strong>Auto-Connect</strong>}
                                name={autoConnectPhoneNum.name}
                                ref={autoConnectPhoneNum.ref}
                                onChange={(evt) => {
                                    autoConnectPhoneNum.onChange(evt);
                                    setValue(autoConnectPhoneNum.name, normalizePhoneNumber(evt.target.value));
                                }}
                                onBlur={autoConnectPhoneNum.onBlur}
                                helperText={
                                    <div>
                                        <div>
                                            Optional. Enter a phone number here to automatically receive a phone call
                                            when your account wins a new lead. Once you pick up, we will try to connect
                                            you with the consumer. Note: we do not initiate calls outside of 9am-9pm in
                                            the consumer’s local timezone in compliance with TCPA. These calls may be
                                            recorded.
                                        </div>
                                        {isLeadFeedEnabled && (
                                            <>
                                                <br />
                                                <div>
                                                    This feature does not apply to leads purchased via Opportunities.
                                                </div>
                                            </>
                                        )}
                                    </div>
                                }
                                disabled={formDisabled}
                                invalid={!!phoneErrors?.autoConnectPhoneNum}
                                invalidText={phoneErrors?.autoConnectPhoneNum?.message}
                            />
                        </div>
                    </div>
                )}

                {showVerifiedCallerId && (
                    <CallerId formDisabled={formDisabled} namespace={namespace} phoneErrors={phoneErrors} />
                )}
            </div>
        </PageSection>
    );
};

const CallerId = ({ formDisabled, namespace, phoneErrors }) => {
    const { setValue, getValues, register, watch } = useFormContext();
    const { id } = useAccount();
    const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
    const [validationCode, setValidationCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [verificationErr, setVerificationErr] = useState('');
    const [isRemoveCallerIdModalOpen, setIsRemoveCallerIdModalOpen] = useState(false);
    const [removeCallerIdStatus, setRemoveCallerIdStatus] = useState('inactive');

    const verifiedCallerIdName = `${namespace}.verifiedCallerId`;

    // check if callerId is verified already
    const [isCallerIdVerified, setIsCallerIdVerified] = useState(Boolean(getValues(verifiedCallerIdName)));
    const [callerIdBtnDisabled, setCallerIdBtnDisabled] = useState(true);

    const pollDuration = useRef();
    const pollInterval = useRef();
    const pollDurationTimer = useRef();
    const pollIntervalTimer = useRef();

    const verifiedCallerId = register(verifiedCallerIdName, {
        validate: (val) => validatePhoneNumber(val)
    });

    const verifyCallerId = async () => {
        try {
            setCallerIdBtnDisabled(true);

            const { data } = await axios.post(`/account.json?action=verify_caller_id&${makeParamString({ id })}`, {
                phone_num: getValues(verifiedCallerId.name)
            });

            if (data.status === status.VERIFIED) {
                setIsVerificationModalOpen(true);
                setIsCallerIdVerified(true);
                return;
            }

            pollDuration.current = data.pollDuration * 1000;
            pollInterval.current = data.pollInterval * 1000;

            startTimer();
            getCallStatus();
            // launch modal with verification code
            launchModal(data.validationCode);
        }
        catch (err) {
            const { message } = formatError(err);
            setVerificationErr(message || 'Something went wrong. Please try again later.');
            setCallerIdBtnDisabled(false);
            window.setTimeout(resetVerificationActions, 5000);
        }
    };

    const removeCallerId = async () => {
        setRemoveCallerIdStatus('active');

        try {
            await axios.post(`/account.json?action=remove_caller_id&${makeParamString({ id })}`);
            setRemoveCallerIdStatus('finished');
            window.setTimeout(() => {
                handleRemoveCallerIdModalClose();
                setIsCallerIdVerified(false);
                resetVerificationActions();
                window.setTimeout(() => setRemoveCallerIdStatus('inactive'), 1000);
            }, 3000);
        }
        catch (err) {
            setRemoveCallerIdStatus('error');
            window.setTimeout(() => {
                setRemoveCallerIdStatus('inactive');
            }, 1000);
        }
    };

    const startTimer = () => {
        pollDurationTimer.current && clearTimeout(pollDurationTimer.current);
        // after timeout stop all actions
        pollDurationTimer.current = window.setTimeout(resetVerificationActions, pollDuration.current);
    };

    /**
     * Function called when pollDurationTimer expires or modal is closed
     */
    const resetVerificationActions = () => {
        // clear out all timers
        pollDurationTimer.current && clearTimeout(pollDurationTimer.current);
        pollIntervalTimer.current && clearTimeout(pollIntervalTimer.current);

        // stop loading spinner and close modal
        loading && setLoading(false);
        setIsVerificationModalOpen(false);
    };

    const getCallStatus = async () => {
        // remove all non-digits before passing it as param to api
        const phoneNum = getValues(verifiedCallerId.name).replace(/\D/g, '');
        try {
            setLoading(true);
            const { data } = await axios.get(
                `/account.json?action=check_caller_id_status&${makeParamString({
                    id,
                    phone_num: phoneNum
                })}`
            );

            if (data.status === status.IN_PROGRESS) {
                clearTimeout(pollIntervalTimer.current);
                pollIntervalTimer.current = window.setTimeout(getCallStatus, pollInterval.current);
            }
            else if (data.status === status.VERIFIED) {
                setIsCallerIdVerified(true);
            }
            // if call failed
            else {
                resetVerificationActions();
            }
        }
        catch (err) {
            const { message } = formatError(err);
            resetVerificationActions();
            setCallerIdBtnDisabled(false);
            setVerificationErr(message || 'Something went wrong. Please try again later.');
        }
    };

    const launchModal = (validationCode) => {
        setValidationCode(validationCode);
        setIsVerificationModalOpen(true);
    };

    const successMessage = (
        <>
            <div>Success! Your phone number has been verified.</div>
            <div>
                This number is now set as your Caller ID, and will appear to consumers you call through MediaAlpha.
            </div>
        </>
    );

    useEffect(() => {
        if (isCallerIdVerified) return;
        // enable the button when a valid number is provided and there are no errors
        if (watch(verifiedCallerIdName) && !phoneErrors?.verifiedCallerId) {
            setCallerIdBtnDisabled(false);
        }
        else {
            setCallerIdBtnDisabled(true);
        }
    }, [watch(verifiedCallerIdName), phoneErrors]);

    useEffect(() => {
        // clean up timers on unmount
        return () => {
            pollDurationTimer.current && clearTimeout(pollDurationTimer.current);
            pollIntervalTimer.current && clearTimeout(pollIntervalTimer.current);
        };
    }, []);

    useEffect(() => {
        // Clear the Caller Id field if callerId is not verified.
        if (!isCallerIdVerified && watch(verifiedCallerIdName)) {
            setValue(verifiedCallerId.name, '', { shouldDirty: true });
            setCallerIdBtnDisabled(false);
        }
        else {
            setCallerIdBtnDisabled(true);
        }
        if (isCallerIdVerified) window.setTimeout(resetVerificationActions, 10 * 1000);
    }, [isCallerIdVerified]);

    const handleRemoveCallerIdModalClose = () => {
        if (removeCallerIdStatus === 'active') return false;
        setIsRemoveCallerIdModalOpen(false);
    };

    const handleEscape = (evt) => {
        if (evt.key === 'Escape') handleRemoveCallerIdModalClose();
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEscape);
        return () => document.removeEventListener('keydown', handleEscape);
    }, [handleEscape]);

    return (
        <>
            {verificationErr && (
                <ToastNotification
                    title="Error"
                    caption={<span>{verificationErr}</span>}
                    timeout={7000}
                    hideCloseButton
                    onClose={() => setVerificationErr('')}
                    lowContrast
                />
            )}
            <Modal
                size="xs"
                open={isVerificationModalOpen}
                onRequestClose={resetVerificationActions}
                passiveModal
                preventCloseOnClickOutside
            >
                <div className={styles.modalContent}>
                    {!isCallerIdVerified && loading && (
                        <div className={styles.verification}>
                            <div className={styles.instruction}>Enter the below verification code on your phone:</div>
                            <div className={styles.validationCode}>{validationCode}</div>
                        </div>
                    )}
                    {isCallerIdVerified && <strong>{successMessage}</strong>}

                    {!isCallerIdVerified && loading && (
                        <div className={styles.loading}>
                            <Loading active={loading} withOverlay={false} />
                            <div className={styles.message}>Waiting for verification</div>
                        </div>
                    )}
                </div>
            </Modal>
            <ComposedModal
                danger
                size="xs"
                open={isRemoveCallerIdModalOpen}
                preventCloseOnClickOutside={removeCallerIdStatus === 'active'}
                onClose={handleRemoveCallerIdModalClose}
            >
                <ModalHeader
                    label="Remove Caller ID"
                    title={
                        <>
                            Are you sure you want to remove{' '}
                            <span style={{ whiteSpace: 'nowrap' }}>{getValues(verifiedCallerId.name)}</span> as the
                            Caller ID for this account?
                        </>
                    }
                ></ModalHeader>
                <ModalBody>
                    <p>You'll be able to set up a new Caller ID after removing.</p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        kind="secondary"
                        disabled={removeCallerIdStatus === 'active'}
                        onClick={handleRemoveCallerIdModalClose}
                    >
                        Cancel
                    </Button>
                    {removeCallerIdStatus === 'inactive' ? (
                        <Button kind="danger" onClick={removeCallerId}>
                            Remove
                        </Button>
                    ) : (
                        <Button kind="ghost">
                            <InlineLoading
                                description={
                                    removeCallerIdStatus === 'active'
                                        ? 'Removing...'
                                        : removeCallerIdStatus === 'error'
                                            ? 'Failed'
                                            : 'Succeeded'
                                }
                                status={removeCallerIdStatus}
                            />
                        </Button>
                    )}
                </ModalFooter>
            </ComposedModal>
            <div className="bx--row" id="callerId">
                <div className="bx--col-lg-6">
                    <TextInput
                        name={verifiedCallerId.name}
                        id={verifiedCallerId.name}
                        ref={verifiedCallerId.ref}
                        labelText={<strong>Caller ID (Agency Phone Number)</strong>}
                        onChange={(evt) => {
                            verifiedCallerId.onChange(evt);
                            setValue(verifiedCallerId.name, normalizePhoneNumber(evt.target.value), {
                                shouldValidate: true,
                                shouldDirty: true
                            });
                        }}
                        onBlur={verifiedCallerId.onBlur}
                        helperText={getCallerIdHelperText(isCallerIdVerified)}
                        disabled={formDisabled}
                        readOnly={isCallerIdVerified}
                        invalid={!!phoneErrors?.verifiedCallerId}
                        invalidText={phoneErrors?.verifiedCallerId?.message}
                    />
                </div>
                {isCallerIdVerified ? (
                    <>
                        <Button className={styles.verifyCallBtn} disabled={callerIdBtnDisabled}>
                            Verified
                        </Button>
                        <Button
                            hasIconOnly
                            className={styles.removeCallerIdBtn}
                            renderIcon={TrashCan16}
                            iconDescription="Remove Caller ID"
                            onClick={() => setIsRemoveCallerIdModalOpen(true)}
                        />
                    </>
                ) : (
                    <Button className={styles.verifyCallBtn} onClick={verifyCallerId} disabled={callerIdBtnDisabled}>
                        Call me now to verify
                    </Button>
                )}
            </div>
        </>
    );
};

const getCallerIdHelperText = (verified) => {
    return verified
        ? 'This phone number will always appear as your Caller ID to consumers you call through this platform.'
        : 'To dial leads through MediaAlpha, please set up your Caller ID. This phone number will always appear as your Caller ID to consumers you call through this platform. When you click "call me now to verify", you will receive a call from Twilio at (415) 723-4000. Number must be verified in order to save changes.';
};

export { PhoneSettings, initialValues as phoneValues };
