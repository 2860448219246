import { AccountDeposits, AccountTransfers } from 'components/accounts';
import { PageHeader } from 'components/common';
import useUser from 'hooks/use-user';
import { Helmet as Head } from 'react-helmet-async';

const Deposits = () => {
    const { canManageBalances } = useUser();

    return (
        <>
            <Head>
                <title>Deposits</title>
                <script src={process.env.REACT_APP_MONETRA_URL}></script>
            </Head>
            <PageHeader title="Deposits" />
            <AccountDeposits />
            {canManageBalances && <AccountTransfers />}
        </>
    );
};

export default Deposits;
