import { AccountList, CreateAccountMenu } from 'components/accounts';
import { ExportButton } from 'components/buttons';
import { PageHeader } from 'components/common';
import useAccount from 'hooks/use-account';
import useDevice, { devices } from 'hooks/use-device';
import useUser from 'hooks/use-user';
import { useEffect, useState } from 'react';
import { Helmet as Head } from 'react-helmet-async';

const Accounts = () => {
    const [exportData, setExportData] = useState({});

    const { account, setAccount } = useAccount();
    const { device } = useDevice();
    const { canCreateAccount } = useUser();

    // Employees should have their selected account cleared when they visit this page.
    useEffect(() => {
        if (account) setAccount(null);
    }, []);

    return (
        <>
            <Head>
                <title>Accounts</title>
            </Head>
            <PageHeader title="Accounts">
                {exportData.visible && <ExportButton api="account" params={exportData.params} />}
                {device !== devices.MOBILE && canCreateAccount && <CreateAccountMenu />}
            </PageHeader>
            <AccountList setExportData={setExportData} />
        </>
    );
};

export default Accounts;
