import { PageHeader } from 'components/common';
import { ReferralForm } from 'components/referrals';
import { Helmet as Head } from 'react-helmet-async';

const Referrals = () => {
    return (
        <>
            <Head>
                <title>Referrals</title>
            </Head>
            <PageHeader title="Referrals" />
            <ReferralForm />
        </>
    );
};

export default Referrals;
