import { Currency24, List24, Settings24, Undo24, UserMultiple24 } from '@carbon/icons-react';
import { AccountManager, AccountSwitcher } from 'components/accounts';
import { LegalLinks } from 'components/layout/Sidebar';
import { motion } from 'framer-motion';
import useAccount, { statuses } from 'hooks/use-account';
import useUser from 'hooks/use-user';
import { NavLink } from 'react-router-dom';
import styles from './MobileMenu.module.scss';

const MobileMenu = ({ onMenuToggle }) => {
    const { account, status, accountManager } = useAccount();
    const { employee } = useUser();

    let links = account
        ? status === statuses.PENDING || status === statuses.UNSOLD
            ? LINKS.pending
            : LINKS.active
        : [];
    if (employee) links = [...links, ...LINKS.admin];

    return (
        <motion.div className={styles.wrap} {...menuAnim}>
            <AccountSwitcher closeMobileMenu={onMenuToggle} />
            <div onClick={onMenuToggle}>
                {links.map((link) => (
                    <MobileMenuLink {...link} key={link.to} />
                ))}
            </div>
            <div className={styles.bottom}>
                {accountManager && <AccountManager accountManager={accountManager} />}
                <LegalLinks />
            </div>
        </motion.div>
    );
};

const MobileMenuLink = ({ text, to, icon: Icon }) => {
    const { id } = useAccount();
    return (
        <NavLink
            to={to === '/p/accounts' ? to : `${to}?advertiser_id=${id}`}
            className={styles.link}
            activeClassName={styles.selected}
            exact
        >
            <Icon className={styles.icon} />
            <div className={styles.text}>{text}</div>
        </NavLink>
    );
};

const LINKS = {
    pending: [
        {
            text: 'Settings',
            to: '/p/account',
            icon: Settings24
        }
    ],
    active: [
        {
            text: 'Leads',
            to: '/p/leads/purchased',
            icon: UserMultiple24
        },
        {
            text: 'Returns',
            to: '/p/leads/returned',
            icon: Undo24
        },
        {
            text: 'Transactions',
            to: '/p/transactions',
            icon: Currency24
        }
    ],
    admin: [
        {
            text: 'All Accounts',
            to: '/p/accounts',
            icon: List24
        }
    ]
};

const menuAnim = {
    initial: {
        opacity: 0,
        y: -70
    },
    animate: {
        opacity: 1,
        y: 0
    },
    transition: {
        ease: 'easeOut',
        duration: 0.2
    }
};

export default MobileMenu;
