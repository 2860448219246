import { Copy16 } from '@carbon/icons-react';
import { Button, Tooltip } from 'carbon-components-react';
import { useState } from 'react';
import styles from './CopyButton.module.scss';

const CopyButton = (props) => {
    const { buttonText, copyText, tooltipMessage } = props;
    const [showCopied, setShowCopied] = useState(false);

    return (
        <>
            <Button
                id="copyLinkButton"
                size="small"
                kind="tertiary"
                renderIcon={Copy16}
                onClick={() => {
                    navigator.clipboard.writeText(copyText);
                    setShowCopied(true);
                    setTimeout(() => {
                        setShowCopied(false);
                    }, 2000);
                }}
                className={styles.copyLinkButton}
            >
                {buttonText}
            </Button>

            {showCopied && (
                <Tooltip
                    open={showCopied}
                    direction="right"
                    focusTrap={false}
                    showIcon={false}
                    className={styles.tooltipCopy}
                >
                    {tooltipMessage}
                </Tooltip>
            )}
        </>
    );
};

export default CopyButton;
