import { InlineNotification } from 'carbon-components-react';
import clsx from 'clsx';
import { namespaces } from 'components/accounts/AccountForm';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { capitalizeFirstLetter } from 'util/format-text';
import Campaigns from '../Campaigns/Campaigns';
import Delivery from '../Delivery/Delivery';
import Geography from '../Geography/Geography';
import styles from './ProductSwitcher.module.scss';

const getTabName = (tab) => `${capitalizeFirstLetter(tab)} Settings`;

const ProductSwitcher = ({ formDisabled, carrier, enabledProducts }) => {
    const [selectedProduct, setSelectedProduct] = useState(enabledProducts?.[0]);
    const [showError, setShowError] = useState(false);
    const {
        formState: { errors }
    } = useFormContext();

    const showTabs = Boolean(enabledProducts?.length > 1);
    const productErrors = Object.values(errors?.campaigns?.[selectedProduct] || {});
    const hasErrors = Boolean(productErrors.length || errors.advertiserDailyCapError);

    const geographyNamespace = `campaigns.${selectedProduct}.${namespaces.GEOGRAPHY}`;
    const campaignsNamespace = `campaigns.${selectedProduct}.${namespaces.CAMPAIGNS}`;
    const deliveryNamespace = `campaigns.${selectedProduct}.${namespaces.DELIVERY}`;

    const handleTabClick = (tab) => (!hasErrors ? setSelectedProduct(tab) : setShowError(true));

    useEffect(() => {
        if (!hasErrors && showError) setShowError(false);
    }, [hasErrors]);

    return (
        <>
            {showError && hasErrors && (
                <InlineNotification
                    id="campaignErrors"
                    kind="error"
                    title=""
                    subtitle={<span>Please correct form errors before changing tabs.</span>}
                    hideCloseButton
                    style={{ width: '50%' }}
                    lowContrast
                />
            )}
            {showTabs ? (
                <section className={styles.productSwitcher}>
                    <div className={clsx(styles.tabs, 'bx--tabs--scrollable bx--tabs--scrollable--container')}>
                        <ul className="bx--tabs--scrollable__nav" role="tablist">
                            {enabledProducts.map((product) => (
                                <li
                                    className={clsx(
                                        styles.tab,
                                        'bx--tabs--scrollable__nav-item',
                                        selectedProduct === product &&
                                            'bx--tabs--scrollable__nav-item--selected bx--tabs--scrollable__nav-item--selected'
                                    )}
                                    key={`${product}-tab-button`}
                                    role="presentation"
                                >
                                    <button
                                        className="bx--tabs--scrollable__nav-link"
                                        onClick={() => handleTabClick(product)}
                                        role="tab"
                                        tabIndex="0"
                                        type="button"
                                    >
                                        {getTabName(product)}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <div className="tab" key={selectedProduct}>
                            <Geography
                                namespace={geographyNamespace}
                                formDisabled={formDisabled}
                                carrier={carrier}
                                product={selectedProduct}
                            />
                            <Campaigns
                                namespace={campaignsNamespace}
                                formDisabled={formDisabled}
                                carrier={carrier}
                                product={selectedProduct}
                            />
                            <Delivery
                                namespace={deliveryNamespace}
                                formDisabled={formDisabled}
                                carrier={carrier}
                                product={selectedProduct}
                            />
                        </div>
                    </div>
                </section>
            ) : (
                <>
                    <Geography
                        namespace={geographyNamespace}
                        formDisabled={formDisabled}
                        carrier={carrier}
                        product={selectedProduct}
                    />
                    <Campaigns
                        namespace={campaignsNamespace}
                        formDisabled={formDisabled}
                        carrier={carrier}
                        product={selectedProduct}
                    />
                    <Delivery
                        namespace={deliveryNamespace}
                        formDisabled={formDisabled}
                        carrier={carrier}
                        product={selectedProduct}
                    />
                </>
            )}
        </>
    );
};

export { ProductSwitcher as default };
