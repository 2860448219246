import { Column, ComboBox, InlineNotification, Select, SelectItem, TextInput } from 'carbon-components-react';
import clsx from 'clsx';
import { modes, namespaces } from 'components/accounts/AccountForm';
import { PageSection } from 'components/common';
import carriers, { matchCarriers } from 'data/carriers';
import states from 'data/us-states';
import useAccount from 'hooks/use-account';
import useApi from 'hooks/use-api';
import useUser from 'hooks/use-user';
import readOnlyIcon from 'img/readOnly.svg';
import userIcon from 'img/user.svg';
import { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { normalizePhoneNumber, normalizeZipCode, validatePhoneNumber } from 'util/validate-form';
import { emailPattern } from 'util/validation';
import styles from './Agent.module.scss';
import { CheckForExistingUser } from './CheckForExistingUser';
import { SubsidyAgentLookup } from './SubsidyAgentLookup';

const initialValues = {
    accountName: '',
    accountManagerId: '',
    ownerId: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    carrier: '',
    company: '',
    agencyAddress: '',
    agencyAddress_2: '',
    agencyCity: '',
    agencyState: '',
    agencyZip: '',
    subsidyId: '',
    subsidyTier: '',
    subsidyAdvertiserId: ''
};

/**
 * This section is only visible to employees who can manage accounts
 */
const Agent = ({ namespace, mode, formDisabled, carrier }) => {
    const {
        register,
        formState: { errors },
        getValues,
        setValue
    } = useFormContext();

    const { id } = useAccount();
    const { employee } = useUser();

    const [accountManagers] = useApi(
        `account.json?action=get_account_manager_list`,
        null,
        null,
        (data) => data?.accountManagers || []
    );
    const [agents] = useApi(
        id ? `account.json?action=get_agent_list&id=${id}` : '',
        null,
        null,
        (data) => data?.agents || []
    );
    const subsidyTiers = employee && carrier?.subsidyTiers ? carrier.subsidyTiers : [];
    const [existingUser, setExistingUser] = useState('');
    const agentValues = getValues(namespace);

    const isCreateMode = mode === modes.CREATE;
    const agentErrors = errors[namespace];
    const carrierOptions = isCreateMode ? (carrier ? carrier.carrierSelectOptions : carriers) : matchCarriers;

    const disabledFields = getDisabledFields(mode, carrier);
    const isDisabledField = (field) => disabledFields.includes(field);

    const watchAutoState = useWatch({
        name: `${namespaces.PRODUCT}.auto.state`
    });

    const isSubsidy = Boolean(carrier);
    useEffect(() => {
        const autoState = getValues(`${namespaces.PRODUCT}.auto.state`);
        if (autoState) {
            updateAccountName({
                state: autoState
            });
        }
    }, [watchAutoState]);

    useEffect(() => {
        if (isCreateMode && carrier) {
            register(`${namespace}.carrier`);
            setValue(`${namespace}.carrier`, carrier.name);
            register(`${namespace}.subsidyAdvertiserId`);
            setValue(`${namespace}.subsidyAdvertiserId`, carrier.subsidyAdvertiserId);
        }
    }, [carrier]);

    const updateAccountName = ({ company, state }) => {
        company = company || getValues(`${namespace}.company`);
        const autoState = getValues(`${namespaces.PRODUCT}.auto.state`);
        state = autoState || state;
        const stateName = states.find((s) => s.value === state)?.label;

        if (company && state && stateName) {
            setValue(
                `${namespace}.accountName`,
                getAccountName({
                    company,
                    stateName
                })
            );
        }
    };

    const updateAgentInformation = (agentId) => {
        const agent = agents.find((agent) => agent.id === agentId);
        if (agent) {
            const { firstName, lastName, email, phone } = agent;
            setValue(`${namespace}.firstName`, firstName);
            setValue(`${namespace}.lastName`, lastName);
            setValue(`${namespace}.email`, email);
            setValue(`${namespace}.phone`, phone);
        }
    };

    /**
     * Fields with custom onChange/onBlur handling
     */
    const agencyState = register(`${namespace}.agencyState`, {
        required: 'State is required'
    });
    const agencyZip = register(`${namespace}.agencyZip`, {
        required: 'ZIP Code is required',
        pattern: {
            value: /^\d{5}$/,
            message: 'Please enter a 5-digit ZIP code'
        }
    });
    const email = register(`${namespace}.email`, {
        required: 'Email is required',
        pattern: {
            value: emailPattern,
            message: 'Please enter a valid email'
        }
    });
    const phone = register(`${namespace}.phone`, {
        required: 'Phone is required',
        validate: (v) => validatePhoneNumber(v)
    });
    const ownerIdRequired = mode === modes.UPDATE ? 'Please select a Primary Agent' : false;
    const ownerId = register(`${namespace}.ownerId`, {
        required: ownerIdRequired
    });

    return (
        <>
            {Boolean(isCreateMode && carrier) && (
                <CheckForExistingUser namespace={namespace} setExistingUser={setExistingUser} />
            )}
            <PageSection name="Basic Information" icon={userIcon} data-testid="section-basic-info">
                <div className={styles.agent}>
                    <div className="bx--grid bx--grid--full-width">
                        {Boolean(isCreateMode && carrier) && (
                            <>
                                <h4>Agent Lookup</h4>
                                <div className="bx--row no-margin">
                                    <Column lg={6} max={3}>
                                        <SubsidyAgentLookup carrier={carrier} namespace={namespace} />
                                    </Column>
                                </div>
                            </>
                        )}
                        <h4>Agent</h4>
                        <div className="bx--row no-margin">
                            <Column lg={3}>
                                <TextInput
                                    {...register(`${namespace}.firstName`, {
                                        required: 'First Name is required'
                                    })}
                                    id="firstName"
                                    labelText="First Name"
                                    readOnly={formDisabled || isDisabledField('firstName')}
                                    invalid={!!agentErrors?.firstName}
                                    invalidText={agentErrors?.firstName?.message}
                                />
                            </Column>
                            <Column lg={3}>
                                <TextInput
                                    {...register(`${namespace}.lastName`, {
                                        required: 'Last Name is required'
                                    })}
                                    id="lastName"
                                    labelText="Last Name"
                                    readOnly={formDisabled || isDisabledField('lastName')}
                                    invalid={!!agentErrors?.lastName}
                                    invalidText={agentErrors?.lastName?.message}
                                />
                            </Column>
                            <Column lg={3}>
                                <TextInput
                                    id="email"
                                    labelText="Email"
                                    name={email.name}
                                    ref={email.ref}
                                    onChange={email.onChange}
                                    onBlur={email.onBlur}
                                    readOnly={formDisabled || isDisabledField('email')}
                                    invalid={!!agentErrors?.email}
                                    invalidText={agentErrors?.email?.message}
                                />
                            </Column>
                            <Column lg={3}>
                                <TextInput
                                    id="phone"
                                    labelText="Phone"
                                    name={phone.name}
                                    ref={phone.ref}
                                    onChange={(evt) => {
                                        phone.onChange(evt);
                                        setValue(phone.name, normalizePhoneNumber(evt.target.value));
                                    }}
                                    onBlur={phone.onBlur}
                                    readOnly={formDisabled || isDisabledField('phone')}
                                    type="tel"
                                    invalid={!!agentErrors?.phone}
                                    invalidText={agentErrors?.phone?.message}
                                />
                            </Column>
                        </div>
                        {Boolean(existingUser) && (
                            <InlineNotification
                                kind="warning"
                                title="Existing User"
                                subtitle={
                                    <span>
                                        A new account will be created and linked to <strong>{existingUser}</strong>
                                    </span>
                                }
                                hideCloseButton
                                lowContrast
                            />
                        )}

                        <h4>Agency</h4>
                        <div className="bx--row no-margin">
                            <Column lg={3}>
                                <Company
                                    namespace={namespace}
                                    mode={mode}
                                    formDisabled={formDisabled}
                                    isDisabledField={isDisabledField}
                                    updateAccountName={updateAccountName}
                                />
                            </Column>
                            {isSubsidy && (
                                <Column lg={3}>
                                    <TextInput
                                        {...register(`${namespace}.agencyEmail`, {
                                            required: 'Agency Email is required'
                                        })}
                                        id="agencyEmail"
                                        labelText="Agency Email"
                                        readOnly={formDisabled || isDisabledField('agencyEmail')}
                                        invalid={!!agentErrors?.agencyEmail}
                                        invalidText={agentErrors?.agencyEmail?.message}
                                    />
                                </Column>
                            )}
                            <Column lg={3}>
                                <TextInput
                                    {...register(`${namespace}.agencyAddress`, {
                                        required: 'Address is required'
                                    })}
                                    id="agencyAddress"
                                    labelText="Address 1"
                                    readOnly={formDisabled || isDisabledField('agencyAddress')}
                                    invalid={!!agentErrors?.agencyAddress}
                                    invalidText={agentErrors?.agencyAddress?.message}
                                />
                            </Column>
                            <Column lg={3}>
                                <TextInput
                                    {...register(`${namespace}.agencyAddress_2`)}
                                    id="agencyAddress_2"
                                    labelText="Address 2"
                                    readOnly={formDisabled || isDisabledField('agencyAddress_2')}
                                />
                            </Column>
                            <Column lg={3}>
                                <TextInput
                                    {...register(`${namespace}.agencyCity`, {
                                        required: 'City is required'
                                    })}
                                    id="agencyCity"
                                    labelText="City"
                                    readOnly={formDisabled || isDisabledField('agencyCity')}
                                    invalid={!!agentErrors?.agencyCity}
                                    invalidText={agentErrors?.agencyCity?.message}
                                />
                            </Column>
                            <Column lg={3}>
                                <Select
                                    id="agencyState"
                                    labelText="State"
                                    name={agencyState.name}
                                    ref={agencyState.ref}
                                    onChange={(evt) => {
                                        agencyState.onChange(evt);
                                        isCreateMode && updateAccountName({ state: evt.target.value });
                                    }}
                                    onBlur={agencyState.onBlur}
                                    readOnly={formDisabled || isDisabledField('agencyState')}
                                    invalid={!!agentErrors?.agencyState}
                                    invalidText={agentErrors?.agencyState?.message}
                                >
                                    <SelectItem disabled value="" text="State" />
                                    {states.map((state, i) => (
                                        <SelectItem value={state.value} key={i} text={state.label} />
                                    ))}
                                </Select>
                            </Column>
                            <Column lg={3}>
                                <TextInput
                                    id="agencyZip"
                                    labelText="ZIP Code"
                                    name={agencyZip.name}
                                    ref={agencyZip.ref}
                                    onChange={(evt) => {
                                        agencyZip.onChange(evt);
                                        setValue(agencyZip.name, normalizeZipCode(evt.target.value));
                                    }}
                                    onBlur={agencyZip.onBlur}
                                    readOnly={formDisabled || isDisabledField('agencyZip')}
                                    invalid={!!agentErrors?.agencyZip}
                                    invalidText={agentErrors?.agencyZip?.message}
                                />
                            </Column>
                        </div>
                        <h4>Account</h4>
                        <div className="bx--row no-margin">
                            <Column lg={3}>
                                <TextInput
                                    {...register(`${namespace}.accountName`, {
                                        required: 'Account Name is required'
                                    })}
                                    id="accountName"
                                    labelText="Account Name"
                                    readOnly={formDisabled || isDisabledField('accountName')}
                                    invalid={!!agentErrors?.accountName}
                                    invalidText={agentErrors?.accountName?.message}
                                />
                            </Column>
                            <Column lg={3}>
                                <Select
                                    {...register(`${namespace}.carrier`, {
                                        required: 'Carrier is required'
                                    })}
                                    id="carrier"
                                    labelText="Carrier"
                                    readOnly={formDisabled || isDisabledField('carrier')}
                                    invalid={!!agentErrors?.carrier}
                                    invalidText={agentErrors?.carrier?.message}
                                >
                                    <SelectItem disabled value="" text="Carrier" />
                                    {carrierOptions.map((carrier, i) => (
                                        <SelectItem value={carrier.value} key={i} text={carrier.label} />
                                    ))}
                                </Select>
                            </Column>
                            {!!agents?.length && (
                                <div className="bx--col-lg-3">
                                    <Select
                                        ref={ownerId.ref}
                                        id={ownerId.name}
                                        labelText="Primary Agent"
                                        invalid={!!agentErrors?.ownerId}
                                        invalidText={agentErrors?.ownerId?.message}
                                        onChange={(evt) => {
                                            ownerId.onChange(evt);
                                            setValue(ownerId.name, evt.target.value, { shouldDirty: true });
                                            updateAgentInformation(evt.target.value);
                                        }}
                                        onBlur={ownerId.onBlur}
                                        readOnly={formDisabled || isDisabledField('ownerId')}
                                    >
                                        <SelectItem disabled value="" text="Primary Agent" />
                                        {agents.map((agent, i) => (
                                            <SelectItem value={agent.id} key={i} text={agent.name} />
                                        ))}
                                    </Select>
                                </div>
                            )}
                            {!!accountManagers?.length && (
                                <div className="bx--col-lg-3">
                                    <Select
                                        {...register(`${namespace}.accountManagerId`)}
                                        id="accountManagerId"
                                        labelText="Customer Success Manager"
                                        readOnly={formDisabled || isDisabledField('accountManagerId')}
                                        data-testid="customer-success-dropdown"
                                    >
                                        <SelectItem disabled value="" text="Customer Success Manager" />
                                        <SelectItem value={0} text="Unassigned" />
                                        {accountManagers.map((accountManager, i) => (
                                            <SelectItem value={accountManager.id} key={i} text={accountManager.name} />
                                        ))}
                                    </Select>
                                </div>
                            )}
                            {!!subsidyTiers?.length && (
                                <div className="bx--col-lg-3">
                                    <Select
                                        {...register(`${namespace}.subsidyTier`, {
                                            required: 'Subsidy Tier is required'
                                        })}
                                        id="subsidyTier"
                                        labelText="Subsidy Tier"
                                        readOnly={formDisabled || isDisabledField('subsidyTier')}
                                        invalid={!!agentErrors?.subsidyTier}
                                        invalidText={agentErrors?.subsidyTier?.message}
                                        data-testid="subsidy-tier-dropdown"
                                    >
                                        <SelectItem disabled value="" text="Subsidy Tier" />
                                        {subsidyTiers.map((tier, i) => (
                                            <SelectItem value={tier.id} key={i} text={tier.label} />
                                        ))}
                                    </Select>
                                </div>
                            )}
                            {isSubsidy && (
                                <>
                                    <Column lg={3}>
                                        <TextInput
                                            {...register(`${namespace}.subsidyId`, {
                                                required: 'Email ID is required'
                                            })}
                                            id="subsidyId"
                                            labelText="Email ID"
                                            readOnly={formDisabled || isDisabledField('subsidyId')}
                                            invalid={!!agentErrors?.subsidyId}
                                            invalidText={agentErrors?.subsidyId?.message}
                                        />
                                    </Column>
                                    <Column lg={3}>
                                        <TextInput
                                            {...register(`${namespace}.stcode`, {
                                                required: 'STCODE is required'
                                            })}
                                            id="stcode"
                                            labelText="STCODE"
                                            readOnly={formDisabled || isDisabledField('stcode')}
                                            invalid={!!agentErrors?.stcode}
                                            invalidText={agentErrors?.stcode?.message}
                                        />
                                    </Column>
                                </>
                            )}
                        </div>
                        {!isCreateMode && agentValues.license && (
                            <>
                                <h4>License</h4>
                                <div className="bx--row no-margin">
                                    <Column lg={3}>
                                        <Select
                                            {...register(`${namespace}.license.health.state`, {
                                                required: 'License state is required'
                                            })}
                                            id="license.health.state"
                                            labelText="State"
                                            readOnly={formDisabled}
                                            invalid={!!agentErrors?.license?.health?.state}
                                            invalidText={agentErrors?.license?.health?.state?.message}
                                        >
                                            <SelectItem disabled value="" text="State" />
                                            {states.map((state, i) => (
                                                <SelectItem value={state.value} key={i} text={state.label} />
                                            ))}
                                        </Select>
                                    </Column>
                                    <Column lg={3}>
                                        <TextInput
                                            {...register(`${namespace}.license.health.number`, {
                                                required: 'License number is required'
                                            })}
                                            id="license.health.number"
                                            labelText="Number"
                                            readOnly={formDisabled}
                                            invalid={!!agentErrors?.license?.health?.number}
                                            invalidText={agentErrors?.license?.health?.number?.message}
                                        />
                                    </Column>
                                    <Column lg={3}>
                                        <TextInput
                                            {...register(`${namespace}.license.health.nationalProducerNumber`, {
                                                required: 'National producer number is required'
                                            })}
                                            id="license.health.nationalProducerNumber"
                                            labelText="National Producer Number"
                                            readOnly={formDisabled}
                                            invalid={!!agentErrors?.license?.health?.nationalProducerNumber}
                                            invalidText={agentErrors?.license?.health?.nationalProducerNumber?.message}
                                        />
                                    </Column>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </PageSection>
        </>
    );
};

const Company = ({ namespace, mode, formDisabled, isDisabledField, updateAccountName }) => {
    const {
        register,
        formState: { errors },
        getValues,
        setValue,
        trigger
    } = useFormContext();

    const [companyInput, setCompanyInput] = useState(getValues(`${namespace}.company`) || '');
    const [companies, setCompanies] = useState({});
    const downshiftArgsRef = useRef();

    const isCreateMode = mode === modes.CREATE;
    const [companyList] = useApi(`account.json?action=get_company_list`);
    const agentErrors = errors[namespace];
    const address = getValues(`${namespace}.agencyAddress`);
    const state = getValues(`${namespace}.agencyState`);

    const company = register(`${namespace}.company`, {
        required: 'Agency Name is required'
    });

    const watchCompany = useWatch({
        name: `${namespace}.company`
    });

    useEffect(() => {
        const companyInfo = {};
        companyList?.companies?.forEach(({ companyName, billing }) => {
            companyInfo[companyName] = billing;
        });

        setCompanies(companyInfo);
    }, [companyList]);

    useEffect(() => {
        setCompanyInput(watchCompany);
        setValue(company.name, watchCompany);
    }, [watchCompany]);

    const setCompanyName = (value) => {
        setCompanyInput(value);
        if (value && !Object.keys(companies).includes(value) && downshiftArgsRef?.current) {
            setCompanies({ [value]: {}, ...companies });
            downshiftArgsRef.current.closeMenu();
        }
    };

    const updateAddress = (company) => {
        if (company && companies[company]) {
            setValue(`${namespace}.agencyAddress`, companies[company].address);
            setValue(`${namespace}.agencyAddress_2`, companies[company].address_2);
            setValue(`${namespace}.agencyCity`, companies[company].city);
            setValue(`${namespace}.agencyState`, companies[company].state);
            setValue(`${namespace}.agencyZip`, companies[company].zip);
        }
    };

    const handleCompanyNameOnBlur = (evt) => {
        company.onBlur(evt);
        const value = evt.target.value;
        if (companyInput !== value) {
            setCompanyName(value);
        }
        setValue(company.name, value);
        downshiftArgsRef?.current?.closeMenu();
        if (isCreateMode) {
            // only update address and state if there's no prior address
            if (value && !address) {
                updateAddress(value);
            }
            updateAccountName({ company: value, state: state || companies[value]?.state });
            // trigger validation again if there are any agent address field errors
            const { agencyAddress, agencyCity, agencyState, agencyZip, accountName } = agentErrors || {};
            if (agencyAddress || agencyCity || agencyState || agencyZip || accountName) {
                trigger([
                    `${namespace}.agencyAddress`,
                    `${namespace}.agencyZip`,
                    `${namespace}.agencyCity`,
                    `${namespace}.agencyState`,
                    `${namespace}.accountName`
                ]);
            }
        }
    };

    const handleCompanyNameOnChange = ({ selectedItem }) => {
        setCompanyInput(selectedItem);
    };

    const handleCompanyNameKeyUp = (evt) => {
        if (evt.key === 'Enter' && evt.target.value) {
            setCompanyName(evt.target.value);
        }
    };

    const isReadOnly = formDisabled || isDisabledField('company');

    return (
        <>
            {isReadOnly && <img src={readOnlyIcon} alt="read-only" className={styles.readOnlyIcon} />}
            <ComboBox
                onChange={handleCompanyNameOnChange}
                name={company.name}
                ref={company.ref}
                id="company"
                items={Object.keys(companies)}
                downshiftProps={{
                    onStateChange: (props, args) => {
                        // reference downshift helpers for custom behavior
                        downshiftArgsRef.current = args;
                    }
                }}
                onBlur={handleCompanyNameOnBlur}
                className={clsx(isReadOnly && styles.companyNameReadOnly, styles.company)}
                readOnly={isReadOnly}
                titleText="Agency Name"
                selectedItem={companyInput}
                onKeyUp={handleCompanyNameKeyUp}
                invalid={!!agentErrors?.company}
                invalidText={agentErrors?.company?.message}
            />
        </>
    );
};

const getDisabledFields = (mode, carrier) => {
    let fields = [];

    if (mode === modes.CREATE) {
        fields = carrier
            ? [
                'agencyEmail',
                'agencyState',
                'agencyZip',
                'carrier',
                'firstName',
                'lastName',
                'phone',
                'stcode',
                'subsidyTier',
                'subsidyId'
            ]
            : [];
    }

    if (mode === modes.UPDATE) {
        fields = [
            'accountName',
            'agencyAddress',
            'agencyAddress_2',
            'agencyCity',
            'agencyState',
            'agencyZip',
            'company',
            'email',
            'firstName',
            'lastName',
            'subsidyTier',
            'subsidyId'
        ];
        if (carrier) fields.push('carrier');
    }

    return fields;
};

const getAccountName = ({ company, stateName }) => {
    return `${company}-${stateName}`;
};

export { Agent as default, getAccountName, initialValues };
