import { PlayOutlineFilled24 } from '@carbon/icons-react';
import { TooltipDefinition } from 'carbon-components-react';
import { DefaultTable } from 'components/tables';
import { intervalToDuration } from 'date-fns';
import { useMemo } from 'react';
import { relativePrettyString, toUserTimeZone } from 'util/format-date';
import { getRandomId } from 'util/table-helpers';
import styles from './ContactHistory.module.scss';

const ContactHistory = ({ calls }) => {
    const columns = useMemo(
        () => [
            {
                header: 'Date',
                key: 'time'
            },
            {
                header: 'Path',
                key: 'path'
            },
            {
                header: 'Outcome',
                key: 'outcome'
            },
            {
                header: 'Duration',
                key: 'duration'
            },
            {
                header: 'Recording',
                key: 'recordingUrl'
            }
        ],
        []
    );

    const data = useMemo(
        () =>
            calls?.length
                ? calls
                    .map((c) => ({
                        ...c,
                        id: getRandomId()
                    }))
                    .reverse()
                : [],
        [calls]
    );

    if (!data.length) return <div className={styles.noRecords}>No history to display.</div>;

    return <DefaultTable headers={columns} data={data} outputModifiers={modifiers} record="call" />;
};

const modifiers = {
    path: ({ path }) => {
        return (
            {
                agent: 'Call Now Button',
                'auto-connect': 'Auto-Connect',
                'lead-feed': 'Opportunities'
            }[path] || path
        );
    },
    duration: ({ duration }) => {
        const { hours, minutes, seconds } = intervalToDuration({
            start: 0,
            end: duration * 1000
        });

        const hoursText = hours ? `${hours} ${hours > 1 ? 'hrs' : 'hr'}` : '';
        const minutesText = minutes ? `${minutes} ${minutes > 1 ? 'mins' : 'min'}` : '';
        const secondsText = seconds ? `${seconds} ${seconds > 1 ? 'secs' : 'sec'}` : '';

        return `${hoursText} ${minutesText} ${secondsText}`;
    },
    recordingUrl: ({ recordingUrl }) =>
        recordingUrl ? (
            <a href={recordingUrl} className={styles.recordingLink} target="_blank" rel="noopener noreferrer">
                <PlayOutlineFilled24 />
            </a>
        ) : null,
    time: ({ time }) => {
        return (
            <TooltipDefinition
                align="start"
                children={relativePrettyString(time)}
                direction="top"
                tooltipText={toUserTimeZone(time)}
            />
        );
    }
};

export default ContactHistory;
