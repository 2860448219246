import useQueryParams from 'hooks/use-query-params';
import useUser from 'hooks/use-user';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { localStorage } from 'util/storage-helpers';

const useStoredQuery = (filters) => {
    const { updateQueryParam } = useQueryParams();
    const { pathname } = useLocation();
    const { userPrefsKey } = useUser();
    const storageKey = { key: pathname, parent: userPrefsKey };

    // queryKeys example: ['lead_status', 'revisit_later']
    const queryKeys = useMemo(() => Object.values(filters).map(({ queryString }) => queryString), [filters]);
    const currentQueryHasKeys = useMemo(() => hasQueryKeys(queryKeys), [window.location.search]);

    useEffect(() => {
        if (!currentQueryHasKeys) {
            // Check for stored query if current query string has no relevant keys
            const storedQuery = localStorage.get(storageKey);

            if (storedQuery) {
                // Set stored query
                const params = new URLSearchParams(storedQuery);
                params.forEach((value, key) => updateQueryParam(key, value, { replace: true }));
            }
        }
        else {
            // Save query string to local storage because we have relevant keys
            localStorage.set({ ...storageKey, value: getQueryStringForKeys(queryKeys) });
        }
    }, []);

    useEffect(() => {
        // This callback will run whenever they change their filter selections
        if (currentQueryHasKeys) {
            // If they have relevant keys, update the stored query
            localStorage.set({ ...storageKey, value: getQueryStringForKeys(queryKeys) });
        }
        else {
            // If there are no relevant keys, it means they used the clear filters button
            localStorage.remove(storageKey);
        }
    }, [window.location.search]);
};

/**
 * Checks for relevant queryParam keys
 * @param {Array} keys An array of keys, like ["carrier", "state"]
 * @returns {Boolean}
 */
const hasQueryKeys = (keys) => {
    const params = new URLSearchParams(window.location.search);
    for (let i = 0; i < keys.length; i++) {
        if (params.has(keys[i])) {
            return true;
        }
    }
    return false;
};

/**
 * Get query string only for specific keys
 * @param {Array} keys An array of keys, like ["carrier", "state"]
 * @returns {String} Query string
 */
const getQueryStringForKeys = (keys) => {
    const params = new URLSearchParams(window.location.search);
    const entries = [...params.entries()];
    const filtered = entries.filter(([key]) => keys.includes(key));
    return new URLSearchParams(filtered).toString();
};

export default useStoredQuery;
