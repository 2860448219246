import { Rotate16 } from '@carbon/icons-react';
import { Button, Tab, Tabs } from 'carbon-components-react';
import { PageSection } from 'components/common';
import { FatalError } from 'components/errors';
import { LeadReturn } from 'components/leads';
import { CarrierLogos } from 'components/opportunities';
// import { publishers } from 'data/publishers'; Temporarily hide Source. OO-2987
import { Copy16 } from '@carbon/icons-react';
import useAccount from 'hooks/use-account';
import useApi from 'hooks/use-api';
import useUser from 'hooks/use-user';
import isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { dollarAmount, formatNumber } from 'util/format-number';
import { lowerCase } from 'util/format-text';
import { AutoBasics, AutoDrivers, AutoVehicles } from './Auto';
import ContactHistory from './ContactHistory';
import { HealthBasics, HealthDetails } from './Health';
import { HomeBasics, HomeProperties } from './Home';
import { LifeBasics, LifeDetails } from './Life';
import styles from './LeadDetail.module.scss';
import { products } from 'util/constants';

const LeadDetail = ({ id, adGroupId, setMeta, setShowExport, isLeadPreview = false }) => {
    const { id: accountID, features, setAccount } = useAccount();
    const { employee } = useUser();
    const history = useHistory();

    const [result, loading, error] = useApi(
        isLeadPreview
            ? `lead-feed.json?action=lead&id=${id}&advertiser_id=${accountID}`
            : `lead.json?action=lead&id=${id}&ad_group_id=${adGroupId}`
    );
    const [showReturnFlow, setShowReturnFlow] = useState(false);

    const {
        advertiser,
        callDisabled,
        callDisabledReason,
        calls,
        campaign,
        canReturn,
        cost,
        salesTax,
        data,
        isEcrmIntegration,
        leadId,
        publisher,
        returnStartTime: returned,
        revisitLater,
        sellTime,
        sharedWith,
        status
    } = result || {};

    const lead = data?.lead || {};
    const product = result?.campaign?.product || 'auto';

    const { callNowButton, autoConnect } = features;
    const showTabSection = employee || callNowButton || autoConnect;
    const showSharedWith = Boolean(isLeadPreview && sharedWith?.length);

    const hideReturnFlow = () => {
        setShowReturnFlow(false);
    };

    useEffect(() => {
        if (isLeadPreview && result) {
            const {
                callBid,
                callDisabled,
                callDisabledReason,
                campaign,
                leadBid,
                leadDisabled,
                leadDisabledReason,
                updateTime
            } = result;
            setMeta({
                callBid,
                callDisabled,
                callDisabledReason,
                campaign,
                leadBid,
                leadDisabled,
                leadDisabledReason,
                leadId,
                name: lead?.contact || '',
                product,
                time: updateTime
            });
        }
        else if (result) {
            setMeta({
                campaign: campaign?.campaign,
                cost,
                salesTax,
                leadId,
                name: lead?.contact || '',
                product,
                returned,
                revisitLater,
                sellTime,
                status
            });
        }
    }, [result, lead, cost, salesTax, sellTime, returned, campaign, status, revisitLater, id]);

    useEffect(() => {
        if (isLeadPreview && error) {
            const {
                rest: { adGroupId }
            } = error;
            // if there's an adGroupId it means that the lead has been already purchased by this account
            if (adGroupId && id) {
                history.replace(`/p/leads/${id}/${adGroupId}`);
            }
        }
        setShowExport && setShowExport(Boolean(result && !error));
    }, [result, error]);

    useEffect(() => {
        if (!advertiser) return;

        if (advertiser.advertiserId !== accountID.toString()) {
            setAccount(advertiser);
        }
    }, [advertiser]);

    if (loading) {
        return <PageSection skeleton />;
    }

    if (error) {
        return <FatalError error={error} />;
    }

    const Basics = () => {
        const props = {
            lead,
            formatters,
            isLeadPreview,
            callNowButton,
            adGroupId,
            leadId,
            advertiser,
            callDisabled,
            callDisabledReason
        };

        if (product === products.HEALTH) {
            return <HealthBasics {...props} />;
        }
        else if (product === products.LIFE) {
            return <LifeBasics {...props} />;
        }
        else if (product === products.HOME) {
            return <HomeBasics {...props} />;
        }
        else {
            return <AutoBasics {...props} />;
        }
    };

    const Details = () =>
        product === products.HEALTH ? (
            <HealthDetails lead={lead} isLeadPreview={isLeadPreview} formatters={formatters} />
        ) : product === products.LIFE ? (
            <LifeDetails lead={lead} isLeadPreview={isLeadPreview} formatters={formatters} />
        ) : product === products.HOME ? (
            <HomeProperties
                lead={lead}
                properties={lead.properties}
                isLeadPreview={isLeadPreview}
                formatters={formatters}
            />
        ) : (
            <>
                <AutoDrivers
                    drivers={lead.drivers}
                    incidents={lead.incidents}
                    isLeadPreview={isLeadPreview}
                    formatters={formatters}
                />
                <AutoVehicles vehicles={lead.vehicles} isLeadPreview={isLeadPreview} formatters={formatters} />
            </>
        );

    return (
        <>
            {showReturnFlow && (
                <LeadReturn id={id} adGroupId={adGroupId} cost={cost} salesTax={salesTax} onClose={hideReturnFlow} />
            )}
            <PageSection>
                <div className={styles.wrap}>
                    <Basics />
                    <Details />
                </div>
                <div className={styles.utilities}>
                    {canReturn && (
                        <Button
                            onClick={() => setShowReturnFlow((showReturnFlow) => !showReturnFlow)}
                            kind="ghost"
                            renderIcon={Rotate16}
                            disabled={isEcrmIntegration}
                        >
                            Request Return
                        </Button>
                    )}
                </div>
                {isEcrmIntegration && canReturn && (
                    <div className={styles.description}>Return leads through (or via) eCRM.</div>
                )}
            </PageSection>
            {showSharedWith && <SharedWith publisher={publisher} sharedWith={sharedWith} />}
            {showTabSection && (
                <PageSection className={styles.tabSection} noBorder noPadding>
                    <Tabs type="container" className={styles.tabs}>
                        <Tab label="History" className={styles.tab}>
                            <ContactHistory calls={calls} />
                        </Tab>
                    </Tabs>
                </PageSection>
            )}
        </>
    );
};

const renderName = (name, index = 0) => {
    if (!name) return '';
    const nameList = name.split(' ');

    // for lead contact name and 1st driver name,
    // show first name and last initial only,
    // and obscure the rest
    return index === 0 && nameList[0] && nameList[nameList.length - 1] ? (
        <>
            {nameList[0].charAt(0)}
            <span className={styles.blurred}>{nameList[0].slice(1)}</span>
            {nameList[nameList.length - 1].charAt(0)}
            <span className={styles.blurred}>{nameList[nameList.length - 1].slice(1)}</span>
        </>
    ) : (
        <span className={styles.blurred}>{name}</span>
    );
};

const fullAddress = (lead) => {
    const { address, city, state, zip } = lead;
    return address ? `${address}, ${city}, ${state} ${zip}` : '';
};

const DataBlock = ({ value, label, icon, format, children, isBlurred = false, ...rest }) => {
    if (isNil(value)) return null;
    const formatted = format ? format(value) : value;
    return (
        <div className={styles.dataBlock} {...rest}>
            <label className={styles.label}>
                {icon && <img src={icon} className={styles.icon} alt={label} />}
                <span className={styles.labelText}>{label}</span>
            </label>
            <div className={`${styles.data} ${icon ? `${styles.padLeft}` : ''}`}>
                {children || formatted}
                {!isBlurred && (
                    <Copy16
                        className={styles.copy}
                        onClick={() => {
                            navigator.clipboard.writeText(formatted);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

const SharedWith = ({ publisher, sharedWith }) => {
    // const pubConf = publishers[publisher?.toLowerCase()];
    const carriers = Array.isArray(sharedWith) && sharedWith.length ? sharedWith : [];

    return (
        <PageSection contentClassName={styles.sharedWithSection}>
            <div className={styles.sharedWith}>
                {/* {publisher && (
                    <div className={styles.section}>
                        <span className={styles.sectionLabel}>Source:</span>
                        {pubConf && <img className={styles.pubLogo} src={pubConf.logo} alt={publisher} />}
                        <span>{publisher}</span>
                    </div>
                )} */}
                {Boolean(carriers.length) && (
                    <div className={styles.section}>
                        <span className={styles.sectionLabel}>Shared With:</span>
                        <CarrierLogos carriers={sharedWith} />
                    </div>
                )}
            </div>
        </PageSection>
    );
};

const formatters = {
    yesNo: (val) => (Number(val) ? 'Yes' : 'No'),
    emptyNA: (val) => (val === '' ? 'N/A' : val),
    addOne: (val) => Number(val) + 1,
    number: (val) => formatNumber(val),
    currency: (val) => dollarAmount(val),
    lowerCase: (val) => lowerCase(val)
};

export { LeadDetail as default, DataBlock, renderName, fullAddress };
