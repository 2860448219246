import clsx from 'clsx';
import { LeadCallButton } from 'components/leads';
import { differenceInYears, parse } from 'date-fns';
import dobIcon from 'img/dob.svg';
import genderIcon from 'img/gender.svg';
import mapMarkerIcon from 'img/map_marker.svg';
import marriedIcon from 'img/married.svg';
import emailIcon from 'img/paper_plane.svg';
import phoneIcon from 'img/phone.svg';
import driverIcon from 'img/user.svg';
import { formatNumber } from 'util/format-number';
import styles from './LeadDetail.module.scss';
import { DataBlock, fullAddress } from './index';

const getAge = (dob) => {
    const date = parse(dob, 'yyyy-MM-dd', new Date());
    const age = differenceInYears(new Date(), date);
    return age;
};

const LifeBasics = ({
    lead,
    formatters,
    isLeadPreview = false,
    callNowButton,
    adGroupId,
    leadId,
    advertiser,
    callDisabled,
    callDisabledReason
}) => {
    return (
        <div className={styles.basics}>
            <DataBlock value={fullAddress(lead)} label="address" icon={mapMarkerIcon} isBlurred={isLeadPreview}>
                <div>
                    <div className={clsx(isLeadPreview && styles.blurred)}>{lead.address || ''}</div>
                    <div>
                        {lead.city || ''}, {lead.state || ''} {lead.zip || ''}
                    </div>
                </div>
            </DataBlock>
            <DataBlock value={lead.phone} label="phone" icon={phoneIcon} isBlurred={isLeadPreview}>
                {isLeadPreview ? (
                    <span className={styles.blurred}>{lead.phone}</span>
                ) : (
                    <a href={`tel:${lead.phone}`}>{lead.phone}</a>
                )}
                {!isLeadPreview && callNowButton && (
                    <LeadCallButton
                        adGroupId={adGroupId}
                        lead={lead}
                        leadId={leadId}
                        advertiser={advertiser}
                        callDisabled={callDisabled}
                        callDisabledReason={callDisabledReason}
                        style={{ margin: '0 10px' }}
                    />
                )}
            </DataBlock>
            <DataBlock value={lead.email} label="email" icon={emailIcon} isBlurred={isLeadPreview}>
                {isLeadPreview ? (
                    <span className={styles.blurred}>{lead.email}</span>
                ) : (
                    <a href={`mailto:${lead.email}`} style={{ textTransform: 'lowercase' }}>
                        {lead.email}
                    </a>
                )}
            </DataBlock>
            <DataBlock value={lead.birthDate} label="dob" icon={dobIcon} format={(val) => `${val}`} />
            <DataBlock value={lead.birthDate} label="age" icon={dobIcon} format={(val) => getAge(val)} />
            <DataBlock
                value={lead.gender}
                label="gender"
                icon={genderIcon}
                format={(val) => (val === 'M' ? 'Male' : val === 'F' ? 'Female' : 'Non-binary')}
            />
            <DataBlock value={lead.spouse ? 1 : 0} label="married" icon={marriedIcon} format={formatters.yesNo} />
        </div>
    );
};

const LifeDetails = ({ lead, isLeadPreview, formatters }) => {
    return (
        <div className={styles.drivers}>
            <div className={styles.title}>
                <img src={driverIcon} alt="drivers" />
                Consumer Information
            </div>
            <DataBlock value={lead.coverageAmount} label="coverage amount" format={(val) => formatNumber(val)} />
            <DataBlock value={lead.weight} label="weight" />
            <DataBlock value={lead.tobacco} label="tobacco use" format={formatters.yesNo} />
            <DataBlock value={lead.householdSize} label="residents" />
            <DataBlock value={lead.majorConditionOther} label="medical conditions" format={formatters.yesNo} />
        </div>
    );
};

export { LifeBasics, LifeDetails };
