import { Button, ComposedModal, ModalBody, ModalFooter } from 'carbon-components-react';
import useUser from 'hooks/use-user';
import lottie from 'lottie-web';
import lottieJson from 'lottie/announcement';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'util/axios';
import styles from './Announcement.module.scss';

const Announcement = () => {
    const history = useHistory();
    const { announcements, id } = useUser();

    const [index, setIndex] = useState(0);
    const [announcement, setAnnouncement] = useState(announcements?.[index]);

    const lottieContainer = useRef();

    const title = announcement?.title || '';
    const body = announcement?.body || '';
    const ctaText = announcement?.cta?.text || 'Dismiss';
    const ctaUrl = announcement?.cta?.url || '';
    const announcementId = announcement?.announcementId || '';

    const handleClose = () => {
        if (!announcement?.test) {
            try {
                axios.post(`/auth.json?action=announcement_seen`, {
                    admin_id: id,
                    announcement_id: announcementId
                });
            }
            catch (err) {}
        }
        setIndex((index) => ++index);
    };

    const handleCtaClick = () => {
        history.push(`/p${ctaUrl}`);
        handleClose();
    };

    const handleClick = ctaUrl ? handleCtaClick : handleClose;

    useEffect(() => setAnnouncement(announcements?.[index]), [index]);
    useEffect(() => {
        let lottieAnimation;

        if (announcement) {
            lottieAnimation = lottie.loadAnimation({
                container: lottieContainer.current,
                animationData: lottieJson,
                autoplay: true,
                loop: 1, // runs animation twice
                renderer: 'svg'
            });
        }

        return () => {
            lottieAnimation && lottieAnimation.destroy();
        };
    }, [announcement]);

    if (!announcement) return null;
    return (
        <>
            <ComposedModal
                size="sm"
                open={true}
                containerClassName={styles.modal}
                preventCloseOnClickOutside
                aria-label="Announcement Overlay"
            >
                <ModalBody>
                    <div className={styles.lottie} ref={lottieContainer}></div>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.body} dangerouslySetInnerHTML={{ __html: body }}></div>
                </ModalBody>
                <ModalFooter>
                    <Button kind="primary" className={styles.button} onClick={handleClick}>
                        <strong>{ctaText}</strong>
                    </Button>
                </ModalFooter>
            </ComposedModal>
        </>
    );
};

export default Announcement;
