import { AccountStatus } from 'components/accounts';
import { dollarAmount } from 'util/format-number';
import styles from './AccountInfo.module.scss';

const AccountInfo = ({ account }) => {
    const { name, status, balance = 0 } = account;

    return (
        <div className={styles.wrap}>
            <div className={styles.name}>{name}</div>
            <div className={styles.status}>
                <AccountStatus status={status} />
                <span className={styles.statusText}>{status.replace('_', ' ')}</span>
                <span className={styles.divider}>&nbsp;|&nbsp;</span>
                <span className={styles.balanceText}>{dollarAmount(balance)}</span>
            </div>
        </div>
    );
};

export default AccountInfo;
