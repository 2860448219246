import UserContext from 'context/user';
import useAuth from 'hooks/use-auth';
import { useContext } from 'react';

const useUser = () => useContext(UserContext);

const UserProvider = ({ children }) => {
    const { user } = useAuth();

    const id = user?.id;
    const group = user?.group;
    const announcements = user?.announcements || [];
    const canCreateAccount = user?.canCreateAccount === 1;
    const canManageBalances = user?.canManageBalances === 1;

    const admin = group === groups.CONSULTANT || group === groups.EMPLOYEE;
    const employee = group === groups.EMPLOYEE;
    const consultant = group === groups.CONSULTANT;

    // Browser Storage Key
    const userPrefsKey = user ? `user${user.id}` : '';

    const value = {
        admin,
        announcements,
        canCreateAccount,
        canManageBalances,
        consultant,
        employee,
        group,
        id,
        user,
        userPrefsKey
    };
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const groups = {
    AGENT: 'agent',
    CONSULTANT: 'consultant',
    EMPLOYEE: 'employee'
};

export { useUser as default, UserProvider, groups };
