import { subDays } from 'date-fns';
import useAuth from 'hooks/use-auth';
import isEqual from 'lodash/isEqual';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'util/axios';
import { getServerTimestamp } from 'util/format-date';
import AccountContext from '../context/account';

const useAccount = () => useContext(AccountContext);

const getUserAccounts = (user) => user?.accounts || [];
const getDefaultAccount = (user, location) => {
    if (!location) return null;

    // Prevent setting an account if entering the application
    // through the account list or new account page.
    if (location.pathname.startsWith('/p/accounts')) {
        return null;
    }
    else {
        return user?.defaultAccount || user?.accounts?.[0] || null;
    }
};
const setDefaultAccount = (id) => {
    try {
        axios.get(`account.json?action=set_default&id=${id}`);
    }
    catch (err) {}
};

const AccountProvider = ({ children }) => {
    const { user } = useAuth();
    const location = useLocation();

    const [accounts, setAccounts] = useState(getUserAccounts(user));
    const [account, setAccount] = useState(getDefaultAccount(user, location));
    const [recentAccounts, setRecentAccounts] = useState([]);

    const accountId = useRef();

    const stopEndTime = account?.stopEndTime || '';
    const status = account?.status || '';
    const id = account?.id || '';
    const features = account?.features || {};
    const accountManager = account?.accountManager || '';
    const canManageAccount = account?.canManage === 1;
    const subsidyAccount = Boolean(account?.subsidyAdvertiserId && account.subsidyAdvertiserId > 0);

    // Without any arguments, this toggles the current account between paused and enabled.
    const updateStatus = async (newStatus, accountId = id) => {
        newStatus = newStatus ?? (status === statuses.ENABLED ? statuses.PAUSED : statuses.ENABLED);

        try {
            const res = await axios.get(`account.json?action=update_status&id=${accountId}&status=${newStatus}`);
            setAccount(res.data);
        }
        catch (err) {
            return err;
        }
    };

    const addStop = async (unpauseDate, accountId = id) => {
        const startDate = getServerTimestamp()?.split(' ')?.[0];
        const startTime = `${startDate} 00:00:00`;

        const endDate = subDays(new Date(`${unpauseDate} 23:59:59`), 1);
        const endTime = getServerTimestamp(endDate);

        try {
            const res = await axios.get(
                `account.json?action=add_stop&id=${accountId}&start_time=${startTime}&end_time=${endTime}`
            );
            setAccount(res.data);
        }
        catch (err) {
            return err;
        }
    };

    const updateRecentAccounts = async () => {
        try {
            const {
                data: { accounts }
            } = await axios.get('account.json?action=get_recent_accounts');

            // Response contains the current account as well. This removes it.
            setRecentAccounts(accounts.filter((acc) => acc.id !== accountId.current));
        }
        catch (err) {}
    };

    useEffect(() => {
        setAccounts(getUserAccounts(user));
        setAccount(getDefaultAccount(user, location));

        if (!user) {
            setRecentAccounts([]);
            accountId.current = '';
        }
    }, [user]);

    useEffect(() => {
        if (!account) return;

        // After the active account is updated (e.g. account status), update the same account in the accounts array.
        // Otherwise, the same account in the accounts array will show stale data.
        if (accounts?.length) {
            const currAccount = accounts.find((a) => a.id === account.id);
            if (currAccount && !isEqual(currAccount, account)) {
                setAccounts(accounts.map((a) => (a.id === account.id ? account : a)));
            }
        }

        if (accountId.current !== account.id) {
            accountId.current = account.id;
            // After a new account is selected, set it as the default account so it persists across reloads.
            // The default account will be returned by auth.json.
            setDefaultAccount(account.id);
            // Also update the recent accounts list, which should now contain the previously selected account.
            // Recent accounts are only visible to employees.
            updateRecentAccounts();
        }
    }, [account]);

    useEffect(() => {
        if (!(account && accounts?.length)) return;

        // After the accounts array is updated, find the active account and update it as well.
        const updatedAccount = accounts.find((a) => a.id === account.id);
        if (updatedAccount && !isEqual(updatedAccount, account)) {
            setAccount(updatedAccount);
        }
    }, [accounts]);

    const value = {
        account,
        accountManager,
        accounts,
        canManageAccount,
        features,
        id,
        recentAccounts,
        setAccount,
        setAccounts,
        status,
        subsidyAccount,
        updateStatus,
        addStop,
        stopEndTime
    };
    return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
};

const statuses = {
    CANCELED: 'canceled',
    ENABLED: 'enabled',
    PAUSED: 'paused',
    PENDING: 'pending',
    TEST: 'in_test',
    UNSOLD: 'unsold'
};

export { AccountProvider, useAccount as default, statuses };
