import { formatDistanceToNowStrict } from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
const SERVER_TIME_ZONE = 'America/Los_Angeles';
const SERVER_DATE_FORMAT = 'yyyy-MM-dd';
const DEFAULT_DATE_FORMAT = 'MMM d, yyyy, h:mm a z';
const DATEPICKER_DATE_FORMAT = 'MM/dd/yyyy';
const PRETTY_DATE_FORMAT = 'MMM d, yyyy';
const TIME_FORMAT = 'h:mm a';
const WEEKDAY_TIME_FORMAT = 'EEEE, h:mm a';
const SHORT_DATE_FORMAT = 'MM/dd/yy';
const SHORT_YEAR_DATE_TIME_FORMAT = 'MM/dd/yy, h:mm a';
const NO_YEAR_DATE_FORMAT = 'MMMM d';
const NO_YEAR_DATE_TIME_FORMAT = 'MMMM d, h:mm a';
const SERVER_DATE_TIME_FORMAT = 'yyyy-MM-dd H:mm:ss';

const standardizeDateString = (datetime) => datetime.replace(/-/g, '/');

const getUserTimeZone = () => {
    if (window.Intl) {
        const { timeZone } = window.Intl.DateTimeFormat().resolvedOptions();
        return timeZone;
    }
    else {
        return SERVER_TIME_ZONE;
    }
};

const stringToDate = (datetime) => {
    if (!datetime) return datetime;

    return new Date(standardizeDateString(datetime));
};

const dateToString = (date) => {
    if (!date) return date;

    return format(date, SERVER_DATE_FORMAT);
};

const dateToDatePickerString = (date) => {
    if (!date) return date;

    return format(date, DATEPICKER_DATE_FORMAT);
};

const dateToPrettyString = (date) => {
    if (!date) return date;

    return format(date, PRETTY_DATE_FORMAT);
};

const getRelativeDays = (datetime) => {
    // We create a new date object here rather than passing one in
    // because we change the time to 00:00:00 for comparison
    const [date] = serverTimeToZonedTime(datetime);
    date.setHours(0, 0, 0, 0);

    // Happens in user's timezone so we don't need to call
    // serverTimeToZonedTime to convert
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const dayMilliseconds = 1000 * 60 * 60 * 24;

    // This will return a whole number that is the difference in days
    return (today - date) / dayMilliseconds;
};

const relativePrettyString = (datetime, isLeadFeed = false) => {
    if (!datetime) return datetime;

    const [date] = serverTimeToZonedTime(datetime);
    const relativeDays = getRelativeDays(datetime);

    if (relativeDays === 0) {
        return `Today, ${isLeadFeed ? `${formatDistanceToNowStrict(date)} ago` : format(date, TIME_FORMAT)}`;
    }
    else if (relativeDays === 1) {
        return `Yesterday, ${format(date, TIME_FORMAT)}`;
    }
    else if (relativeDays < 7) {
        return format(date, WEEKDAY_TIME_FORMAT);
    }
    else if (date.getFullYear() === new Date().getFullYear()) {
        return isLeadFeed ? format(date, NO_YEAR_DATE_TIME_FORMAT) : format(date, NO_YEAR_DATE_FORMAT);
    }
    else {
        return isLeadFeed ? format(date, SHORT_YEAR_DATE_TIME_FORMAT) : format(date, SHORT_DATE_FORMAT);
    }
};

const getServerTimestamp = (date) => {
    // This function can be used to generate a timestamp as if it were returned
    // by the API. Example format: 2021-12-02 13:45:25
    // Timezone is PST (SERVER_TIME_ZONE)
    date = date || new Date();
    const userTimeZone = getUserTimeZone();
    const utcDate = zonedTimeToUtc(date, userTimeZone);
    const serverTime = utcToZonedTime(utcDate, SERVER_TIME_ZONE);

    return format(serverTime, SERVER_DATE_TIME_FORMAT);
};

const serverTimeToZonedTime = (datetime) => {
    // This function converts our datetime strings into a
    // date object corrected for the user's timezone. We also
    // return the userTimeZone since it's needed with format
    if (!datetime) return datetime;

    const serverDate = stringToDate(datetime);
    const utcDate = zonedTimeToUtc(serverDate, SERVER_TIME_ZONE);
    const userTimeZone = getUserTimeZone();
    const userDate = utcToZonedTime(utcDate, userTimeZone);

    return [userDate, userTimeZone];
};

const toUserTimeZone = (datetime, dateFormat = DEFAULT_DATE_FORMAT) => {
    if (!datetime) return datetime;

    const [userDate, userTimeZone] = serverTimeToZonedTime(datetime);

    return format(userDate, dateFormat, { timeZone: userTimeZone });
};

export {
    toUserTimeZone,
    stringToDate,
    dateToString,
    dateToDatePickerString,
    dateToPrettyString,
    relativePrettyString,
    getServerTimestamp,
    serverTimeToZonedTime
};
