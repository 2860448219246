import { Checkmark16, Identification16 } from '@carbon/icons-react';
import { Button, ButtonSkeleton, Modal, ToastNotification } from 'carbon-components-react';
import clsx from 'clsx';
import { feedButtonTypes } from 'components/opportunities/OpportunitiesList';
import useAccount from 'hooks/use-account';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'util/axios';
import { formatError } from 'util/format-error';
import { dollarAmount } from 'util/format-number';
import styles from './BuyNow.module.scss';

const defaultFn = () => {};

// TODO: add different error types here, make sure these types do not change in BE
const errorTypes = {
    INSUFFICIENT_FUNDS: 'Insufficient funds',
    LEAD_UNAVAILABLE: 'Lead unavailable',
    ALREADY_PURCHASED: 'Already purchased'
};

const BuyNow = ({
    adGroupId,
    buttonText = '',
    cost,
    disabled = false,
    leadId,
    leadDisabled,
    leadDisabledReason,
    onSuccess = defaultFn,
    onPurchasedClick = defaultFn,
    onError = defaultFn,
    showTooltip = false,
    tooltipPosition = 'top',
    ...rest
}) => {
    const { id, setAccount } = useAccount();
    const [buyNowError, setBuyNowError] = useState({
        message: '',
        description: '',
        link: ''
    });
    const [isPurchased, setIsPurchased] = useState(false);
    const [loading, setLoading] = useState(!cost || false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    const hasTooltip = showTooltip || leadDisabled;

    useEffect(() => {
        cost && setLoading(false);
    }, [cost]);

    const defaultOnError = (err) => {
        handleCloseBuyNowError();
        const { message, description, rest } = formatError(err);
        const { adGroupId, leadId } = rest;

        // if lead is already purchased, add hyperlink to Lead Details page
        let link = '';
        if (adGroupId || message === errorTypes.ALREADY_PURCHASED) {
            link = <Link to={`/p/leads/${leadId}/${adGroupId}`}>View Lead</Link>;
        }

        setBuyNowError({ message, description, link });

        onError({ message, leadId, adGroupId });
    };

    const handleBuyNow = async (leadId) => {
        if (leadDisabled) return;

        try {
            setLoading(true);
            const { data } = await axios.post(`/lead-feed.json?action=buy&advertiser_id=${id}&id=${leadId}`);

            if (data.successful) {
                const { adGroupId, account } = data;
                onSuccess(leadId, {
                    adGroupId,
                    purchasedBy: feedButtonTypes.BUY_NOW
                });

                // this updates the account balance after successful transaction
                setAccount(account);
                setIsPurchased(true);
            }
        }
        catch (err) {
            defaultOnError(err);
        }
        finally {
            setLoading(false);
            setIsConfirmationModalOpen(false);
        }
    };

    const handleCloseBuyNowError = () => {
        setBuyNowError({ message: '', description: '', link: '' });
    };

    const formattedCost = dollarAmount(cost);

    const handleModalClose = () => {
        setIsConfirmationModalOpen(false);
    };

    const buttonTooltipProps = showTooltip
        ? {
            iconDescription: `Click to purchase this lead. Your account will be charged ${formattedCost}.`,
            hasIconOnly: true,
            tooltipPosition: 'bottom'
        }
        : leadDisabled
            ? {
                iconDescription: leadDisabledReason || '',
                hasIconOnly: true
            }
            : {};

    let messageWithLink = '',
        updatedDescription = '';

    if (buyNowError.message === errorTypes.INSUFFICIENT_FUNDS) {
        messageWithLink = <Link to="/p/account">Account Settings</Link>;
        updatedDescription = <>Please make a deposit in {messageWithLink}.</>;
    }

    return (
        <>
            {buyNowError.message && (
                <ToastNotification
                    className={styles.errorNotification}
                    kind="error"
                    title={buyNowError.message}
                    caption={updatedDescription || buyNowError.description}
                    subtitle={buyNowError.link}
                    timeout={5000}
                    hideCloseButton
                    onClose={handleCloseBuyNowError}
                    lowContrast
                />
            )}
            <Modal
                size="xs"
                open={isConfirmationModalOpen}
                modalHeading="Cost Per Lead"
                primaryButtonText="Purchase"
                secondaryButtonText="Cancel"
                onRequestClose={handleModalClose}
                onRequestSubmit={() => handleBuyNow(leadId)}
                onSecondarySubmit={handleModalClose}
            >
                <p className={styles.modalText}>Are you sure you want to purchase this lead for {formattedCost}?</p>
            </Modal>

            <div className={styles.leadBid}>
                {loading ? (
                    <ButtonSkeleton className={clsx(styles.btnSkeleton, 'buyNowButtonSkeleton')} />
                ) : (
                    <Button
                        className={clsx(
                            !hasTooltip && styles.buyNowButton,
                            isPurchased && styles.buySuccess,
                            leadDisabled && styles.leadDisabled
                        )}
                        data-testid="buyNowButton"
                        kind="tertiary"
                        renderIcon={isPurchased ? Checkmark16 : Identification16}
                        onClick={() =>
                            isPurchased ? onPurchasedClick({ leadId, adGroupId }) : setIsConfirmationModalOpen(true)
                        }
                        disabled={disabled}
                        tooltipPosition={tooltipPosition}
                        {...buttonTooltipProps}
                        {...rest}
                    >
                        <span className={styles.tooltip}>
                            {isPurchased ? `Purchased ${formattedCost}` : `${buttonText} ${formattedCost}`}
                        </span>
                    </Button>
                )}
            </div>
        </>
    );
};

export { BuyNow as default, errorTypes };
