import clsx from 'clsx';
import { motion } from 'framer-motion';
import styles from './PageHeader.module.scss';

const PageHeader = ({ title, subtitle, badges, description, mobileWrap, children, ...rest }) => {
    return (
        <motion.div className={styles.wrap} {...rest} {...anim}>
            <div className={clsx(styles.primary, mobileWrap && styles.mobileWrap)}>
                <div>
                    <h1 className={styles.title}>
                        {title}
                        {badges && <span className={styles.badges}>{badges}</span>}
                    </h1>
                    {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
                </div>
                <div className={styles.children}>{children}</div>
            </div>
            {description && <div className={styles.description}>{description}</div>}
        </motion.div>
    );
};

const anim = {
    initial: {
        opacity: 0,
        x: -20
    },
    animate: {
        opacity: 1,
        x: 0
    },
    transition: {
        ease: 'easeOut',
        duration: 0.5
    }
};

export default PageHeader;
