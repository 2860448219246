import { Information16 } from '@carbon/icons-react';
import { TooltipIcon } from 'carbon-components-react';
import { PageButtons, PageHeader } from 'components/common';
import { LeadDetail, LeadDetailNavigation } from 'components/leads';
import { renderName } from 'components/leads/LeadDetail';
import BuyNow, { errorTypes } from 'components/opportunities/BuyNow';
import CallNow, { eventNames } from 'components/opportunities/CallNow';
import { useEffect, useState } from 'react';
import { Helmet as Head } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import styles from 'styles/pages/OpportunitiesDetail.module.scss';
import { HeaderBadges, SubTitle } from './Lead';

const OpportunitiesDetail = () => {
    const { id } = useParams();
    const history = useHistory();

    const [meta, setMeta] = useState({});
    const [callNowDisabled, setCallNowDisabled] = useState({
        isDisabled: 0,
        reason: ''
    });
    const [buyNowDisabled, setbuyNowDisabled] = useState({
        isDisabled: 0,
        reason: ''
    });

    const { name, callBid, leadBid, callDisabled, callDisabledReason, leadDisabled, leadDisabledReason } = meta;

    const metaTitle = name ? `${name} | ${id}` : `${id}`;

    const subtitle = <SubTitle meta={meta} />;
    const badges = <HeaderBadges meta={meta} />;

    useEffect(() => {
        setCallNowDisabled({
            isDisabled: callDisabled,
            reason: callDisabledReason
        });
    }, [callDisabled, callDisabledReason]);

    useEffect(() => {
        setbuyNowDisabled({
            isDisabled: leadDisabled,
            reason: leadDisabledReason
        });
    }, [leadDisabled, leadDisabledReason]);

    const onError = ({ message, leadId, adGroupId }) => {
        if (adGroupId || message === errorTypes.ALREADY_PURCHASED) {
            redirectToLeadDetails({ leadId, adGroupId, delay: 2000 });
        }
        else if (message === errorTypes.LEAD_UNAVAILABLE) {
            // redirect to leads feed
            window.setTimeout(() => {
                history.replace('/p/opportunities');
            }, 2000);
        }
    };

    const onCallNowEvent = (name, data = {}) => {
        const { leadId, adGroupId, callDisabledReason } = data;
        switch (name) {
        case eventNames.QUALIFIED_CLICK:
            history.replace(`/p/leads/${leadId}/${adGroupId}`); // go to lead
            break;
        case eventNames.ERROR:
            onError(data); // toast error
            break;
        case eventNames.UNQUALIFIED:
            setCallNowDisabled({
                // disable CallNow button with default tooltip
                isDisabled: 1,
                reason: callDisabledReason
            });
            break;
        default:
        }
    };

    const onSuccess = (leadId, { adGroupId }) => redirectToLeadDetails({ leadId, adGroupId });

    const redirectToLeadDetails = ({ leadId, adGroupId, delay = 1000 }) => {
        window.setTimeout(() => {
            history.replace(`/p/leads/${leadId}/${adGroupId}`);
        }, delay);
    };

    return (
        <>
            <Head>
                <title>{metaTitle}</title>
            </Head>
            <PageHeader title={renderName(meta?.name) || 'Lead Details'} subtitle={subtitle} badges={badges}>
                {(callBid || leadBid) && (
                    <PageButtons justify="flex-end" noMargin>
                        <div className={styles.opportunitiesActions}>
                            <span className={styles.info}>Cost per Connection</span>
                            <TooltipIcon
                                align="center"
                                direction="bottom"
                                className={styles.infoIcon}
                                tooltipText="You will ONLY be charged for the lead if the consumer picks up. Calls may be recorded."
                            >
                                <Information16 />
                            </TooltipIcon>
                            <CallNow
                                callDisabled={callNowDisabled.isDisabled}
                                callDisabledReason={callNowDisabled.reason}
                                cost={callBid}
                                contact={name || ''}
                                hideSecondarySuccessButton={true}
                                leadId={id}
                                onEvent={onCallNowEvent}
                                tooltipPosition="bottom"
                            />
                        </div>
                        <div className={styles.opportunitiesActions}>
                            <span className={styles.info}>Cost per Lead</span>
                            <TooltipIcon
                                align="center"
                                direction="bottom"
                                className={styles.infoIcon}
                                tooltipText="You will be charged as soon as you click below."
                            >
                                <Information16 />
                            </TooltipIcon>
                            <BuyNow
                                cost={leadBid}
                                leadId={id}
                                leadDisabled={buyNowDisabled.isDisabled}
                                leadDisabledReason={buyNowDisabled.reason}
                                tooltipPosition="bottom"
                                onError={onError}
                                onSuccess={onSuccess}
                            />
                        </div>
                        <LeadDetailNavigation leadId={id} />
                    </PageButtons>
                )}
            </PageHeader>
            <LeadDetail id={id} isLeadPreview={true} setMeta={setMeta} />
        </>
    );
};

export default OpportunitiesDetail;
