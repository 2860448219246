import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Content.module.scss';

const TermsContent = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const section = document.querySelector(location.hash);
            section && section.scrollIntoView();
        }
    }, []);

    return (
        <div className={styles.content}>
            <p>UPDATED: August 22, 2024</p>
            <p>
                <strong>WELCOME AND IMPORTANT INFORMATION!</strong>
            </p>
            <p>
                Please read the following MediaAlpha for Agents Terms (which We refer to as “Terms” in this document)
                carefully as they set out the rules that will govern the relationship between You and Us. If You have
                any questions after reading these Terms, please contact customer support at{' '}
                <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or call us at
                1-855-959-4165.
            </p>
            <p>
                These Terms are a legally binding agreement between You, the individual or organization who is accepting
                these Terms, creating an account, and buying or receiving Leads (defined below) or who is receiving
                services from Us as described below (referred to you as "You", “Your”, or "Agent”), and Quotelab, LLC,
                which does business as MediaAlpha, with offices at 700 S. Flower St., Suite 640, Los Angeles, California
                90017 (referred to as “MediaAlpha”, "We", "Us", or “Our”). Acceptance of these Terms is a condition of
                Your use of Our Website (defined below) and any Leads, and our provision of the Leads and Our services
                to You. You accept these Terms by checking the select box below and then selecting "CONTINUE" below,
                which will constitute Your electronic signature on these Terms. You agree that each interaction between
                You, this Website, and MediaAlpha, and each request for or delivery of Leads or other services is
                subject to these Terms. If You do not agree to any or all of these Terms You may not use Our services or
                buy Leads or services from Us and You should not access this Website.
            </p>
            <p>
                ARBITRATION NOTICE: THESE TERMS CONTAIN AN <a href="#mandatory-arbitration">ARBITRATION CLAUSE</a>.
                EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED IN THAT ARBITRATION CLAUSE, YOU AND MEDIAALPHA AGREE THAT
                DISPUTES BETWEEN YOU AND MEDIAALPHA WILL BE RESOLVED BY MANDATORY BINDING ARBITRATION, AND YOU AND
                MEDIAALPHA WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
            </p>
            <p>
                <strong>CREATING YOUR ACCOUNT AND BUYING LEADS.</strong>
            </p>
            <p>
                You may request to buy Leads through Your online account with Us (“Your Account”). To create an account,
                contact customer support at <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a>{' '}
                or call Us at 1-855-959-4165. Our customer support team will collect from You, basic information needed
                to create and fund Your Account including Your first and last name, business address, phone number,
                business email address, carrier affiliation(s), and credit card information. To learn more about how We
                will handle Your information read our MediaAlpha for Agents Privacy Policy available at{' '}
                <a href="/privacy">https://insurance-agent.mediaalpha.com/privacy</a>. Once Your Account is created by
                our customer support team, you will receive an email with a link asking You to confirm Your Account by
                reviewing and agreeing to these Terms, changing Your password, and confirming Your information. Kindly
                note, You will not be able to buy Leads until You log in and confirm Your Account as described above.
                Once You have confirmed Your Account, to log in, simply go to Our portal at{' '}
                <a href="/">https://insurance-agent.mediaalpha.com/</a> (“Website”), select “SIGN IN”, and enter your
                username and password. In Your Account you may specify certain parameters for the buying of Leads
                including price bidding, geographic areas covered, and other available desired performance metrics.
            </p>
            <p>
                When You make a request to buy Leads from Us, You are agreeing to a limited right to use and to receive,
                subject to and as further described in these Terms, data collected from consumers who submit their
                contact information and their request for rates and quotes for insurance products and services when they
                complete an online webform through the websites we own and operate and our webforms that are embedded in
                certain third-party websites or third-party leads made available in Our services (referred to
                individually as a “Lead, and collectively as "Leads"). When the consumer completes Our webforms, the
                consumer instructs Us to share their information with businesses and agents (like You) offering various
                products and services that may be of interest to them, and with third parties who may be able to connect
                them with such businesses and agents (we call these business, agents, and third parties, “advertisers”)
                so they can learn more about such advertisers' products and services, to connect with them, and to
                receive a quote or rates from such advertisers. Each Lead will only include information that the
                consumer submitted via Our webform which may include, as available: (a) first and last name, (b)
                address, (c) email address, (d) phone number, and (e) other information specific to the request and type
                of insurance products and services inquired upon (e.g., health, automobile, etc.). MEDIAALPHA MAKES NO
                REPRESENTATIONS OR WARRANTY ABOUT THE QUANTITY, SUFFICIENCY, CORRECTNESS, ACCURACY, OR QUALITY OF THE
                LEADS THAT WE PROVIDE TO YOU.
            </p>
            <p>
                <strong>DELIVERY OF LEADS</strong>
            </p>
            <p>
                MediaAlpha will use commercially reasonable efforts to deliver to You, Your purchased Leads by placing
                them in Your Account or by transmitting them into Your Customer Relationship Management (also known as a
                “CRM”) database or similar system as specified by You and agreed to by Us. Although MediaAlpha will use
                commercially reasonable efforts to supply Leads to You via such alternate delivery methods, for the
                avoidance of doubt MediaAlpha is not responsible or liable for Leads not delivered to Your Account or
                other alternate system for any reason (including, without limitation, due to downtime of any such
                alternate system), and no credits or refunds will be issued for any such Leads that are placed in Your
                Account but not received by You for whatever reason via any requested alternate delivery method. If
                Agent requests that MediaAlpha deliver Leads to You via Your CRM database or similar system, then Agent
                represents, warrants, and covenants to MediaAlpha that You have all rights, licenses, and permissions to
                grant MediaAlpha access to such CRM database or similar system for the limited purpose of delivering
                Leads to Your Account, including Your sharing of any applicable passwords or account keys. To request
                support for any purchased Leads including delivery into Your Account or alternate system, please contact
                customer support at <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or call
                Us at 1-855-959-4165.
            </p>
            <p>
                <strong>YOUR ACCOUNT AND LEAD DELIVERY ALERTS</strong>
            </p>
            <p>
                We will send Lead Delivery Alerts and other Your Account alerts, notices, information, and news (e.g.,
                messages about Your Account balance, Lead notifications, product or services information and news,
                offers, payment transactions that have occurred, etc.) to the email(s) You have provided to Us. For any
                marketing emails We send to You, You may unenroll at any time by selecting the unsubscribe link in any
                of the marketing emails You receive from Us. Note, however, You will continue to receive emails with
                Lead Delivery Alerts and other Your Account alerts, notices, and information.
            </p>
            <p>
                Beyond receiving email notifications, You may also elect to receive Lead Delivery Alerts via text
                messages sent to Your phone when specified account activity occurs.
            </p>
            <p>
                Before You can register to receive text messages from Us, You must first create Your Account as
                described above and provide Your phone number where You would like the text messages sent. If You have
                already created Your Account but did not previously enroll in text messages and provide Your phone
                number (or wish to change it) simply log-in to Your Account and go to the settings tab to submit Your
                phone number. Once you created Your Account, You can register to receive text messages by entering one
                or more phone numbers in the SMS Text Message Notification field in Settings.
            </p>
            <p>
                By providing Your phone number and enrolling for text messages from Us, You expressly consent to receive
                text message communications to Your phone number from Us and others texting on Our behalf (as further
                described herein including receiving non-marketing and marketing text messages from Us and others
                texting on Our behalf) and You agree to the following terms and conditions applicable to the text
                messaging service:
            </p>
            <p>
                These MediaAlpha for Agents Terms and the MediaAlpha for Agents Privacy Policy available at{' '}
                <a href="/privacy">https://insurance-agent.mediaalpha.com/privacy</a> apply to Your use of the text
                messaging service. You represent and warrant to Us as follows: (a) that You are authorized to enroll the
                phone number You provide to Us in the text messaging service, (b) that You are authorized to incur any
                message or data charges that may be incurred by participating in such service, and (c) You are
                responsible for promptly notifying Us if You change Your phone number by updating Your Account with Your
                new number and, if You elect, enrolling Your new number in the text message service. You are solely
                responsible for managing the types of texts (whether SMS or MMS) You receive. We and Our independent
                service providers may use an automatic telephone dialing system or automatic dialing technology to
                deliver text messages to Your phone number (which may include the use of an artificial or pre-recorded
                voice) and You expressly consent to the use of such automatic dialing systems and technology. Message
                and data rates may apply to each text message sent or received as provided in Your service rate plan
                with your carrier, in addition to any applicable roaming charges. Check Your carrier's plan for details.
                You acknowledge and agree that You are solely responsible and liable for obtaining, maintaining, and
                paying all charges related to Your phone number and associated device(s). We impose no separate fees for
                Your participation in the text messaging service. You may opt-out of these communications at any time by
                texting STOP as a reply to any message You receive from Us, or by emailing Our support at{' '}
                <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or call Us at
                1-855-959-4165 to unenroll. Opting out of the text messages program only opts You out of receiving
                further text messages and does not opt You out of or otherwise close Your Account. You consent that
                following such a request to unsubscribe, You will receive one (1) final message from Us confirming that
                You have been inactivated in Our system. Please allow up to three (3) business days to process Your
                request. Text HELP for service information. Registering to receive text messages is not required to
                purchase any goods or services or Leads. The total number of text messages that You receive on a monthly
                basis for Your Lead notifications will vary depending on factors like Your Account activity (e.g.,
                number of bids / purchases, balances, service outages, support issues, or other related notifications).
                We are not responsible for incomplete, lost, late, or misdirected messages, including (but not limited
                to) undelivered messages resulting from any form of filtering by Your mobile carrier or service
                provider. We reserve the right, in Our sole discretion, to cancel or suspend the text messaging service,
                in whole or in part, for any reason, with or without notice to You.
            </p>
            <p>
                To learn more about how We handle Your information read our MediaAlpha for Agents Privacy Policy
                available at <a href="/privacy">https://insurance-agent.mediaalpha.com/privacy</a>.
            </p>
            <p>
                <strong>CALL NOW AND OTHER LEAD CALLING SERVICES</strong>
            </p>
            <p>
                <em>
                    Please Note that the Call Now and other Lead Calling Services are not currently available to State
                    Farm agents; accordingly, this section shall not be apply to such agents. Affected agents will be
                    notified if and when the Services and this Section become applicable.
                </em>
            </p>
            <p>
                We may provide certain functionality in Our Website and services that enables You to call Leads you have
                purchased, or call Leads You have not purchased utilizing a cost per connection program where You pay
                for the Lead if the Lead picks up and they start a conversation, or to receive a call from Us to connect
                You to a Lead when You win a Lead. If multiple agents who are opted into this service win the same Lead,
                whomever picks up Our call first will be the Agent we will attempt to connect to the Lead. Your use of
                the call services may be subject to additional terms and requirements communicated by Us to You
                including notices posted in Our services. By providing Your phone number and enrolling, You expressly
                consent to Us verifying Your phone number, for Your phone Number being displayed via Caller ID, and to
                receive calls to Your phone number from Us and others calling on Our behalf. We and Our independent
                service providers may use an automatic telephone dialing system or automatic dialing technology to
                deliver calls or to leave voice messages to Your phone number (which may include the use of an
                artificial or pre-recorded voice) and You expressly consent to the use of such automatic dialing systems
                and technology. You acknowledge and agree that You are solely responsible and liable for obtaining,
                maintaining, and paying all charges related to Your phone number and associated device(s). We impose no
                separate fees for Your participation in the call services. Although We do not charge You for these
                services, Your carrier's normal messaging, data, and other rates and fees will still apply to Your use
                of these services. You are responsible for checking with your carrier to determine if the services are
                available for or suitable to use with Your devices, what restrictions, if any, may be applicable to Your
                use of the services, and how much they will cost You. We are not responsible for incomplete, lost, late,
                or misdirected calls or messages, including (but not limited to) undelivered calls or voice messages
                resulting from any form of filtering by Your mobile carrier or service provider." You may opt-out of
                these call services at any time by removing Your phone number from each call service You wish to
                unenroll or by emailing Our support at{' '}
                <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a>. Opting out of these
                services only opts You out of using the call services functionality to call Leads or to receive calls
                from Us when You win a bid and does not opt You out of or otherwise close Your Account. We reserve the
                right, in Our sole discretion, to cancel or suspend the call services, in whole or in part, for any
                reason, with or without notice to You.
            </p>
            <p>
                To learn more about how We handle Your information read our MediaAlpha for Agents Privacy Policy
                available at <a href="/privacy">https://insurance-agent.mediaalpha.com/privacy</a>.
            </p>
            <p>
                <strong>YOUR USE OF THE LEADS, OUR WEBSITE, AND OUR SERVICES (SCOPE OF USE)</strong>
            </p>
            <p>
                The Leads, our Website, and our services are provided on a non-exclusive basis for each specific type or
                category of advertiser’s product or service for which the consumer submitted the webform (e.g., the
                consumer submitted a webform to learn more about, connect with, and get rates and/or quotes for auto
                insurance) (referred to as the “Consumer Request Type”). You may only use the Leads, Our Website, and
                Our services solely for Your internal marketing purposes for proposing to the consumer only Your (i.e.
                Your carrier’s or those carriers you represent) products and services applicable to the Consumer Request
                Type (“Purpose”). YOU SHALL NOT (I) SELL OR OFFER FOR SALE OR RESELL OR OFFER FOR RESELL ANY LEADS
                (INCLUDING ANY PERSONAL INFORMATION CONTAINED IN A LEAD), (II) USE, SHARE, OR OTHERWISE PROCESS ANY LEAD
                (INCLUDING ANY PERSONAL INFORMATION CONTAINED IN A LEAD) FOR THE PURPOSES OF CROSS-CONTEXT BEHAVIORAL
                ADVERTISING OR TARGETED ADVERTISING OR PROFILING (III) DISCLOSE, SHARE, OR OTHERWISE TRANSMIT ANY LEAD
                TO ANY THIRD PARTY OR PERSON OUTSIDE OF THE CARRIER OR CARRIERS YOU REPRESENT AT THE TIME OF THE
                PURCHASE OF SUCH LEAD, NOR (IV) USE THE LEADS, OUR WEBSITE, OR OUR SERVICES FOR ANY OTHER PURPOSE
                INCLUDING FOR MARKETING OR OFFERING PRODUCTS AND SERVICES OTHER THAN THOSE THAT THE CONSUMER HAS
                SPECIFICALLY INQUIRED ABOUT.
            </p>
            <p>
                The Leads, our Website, and our services are the Confidential Information (defined below) of MediaAlpha.
                FURTHERMORE, YOU REPRESENT, WARRANT, COVENANT, AND AGREE NOT TO DISCLOSE, TRANSFER, TRANSMIT, SELL,
                SHARE, LICENSE, RENT, RESELL, DISTRIBUTE, OR OTHERWISE AUTHORIZE OR PERMIT OTHERS TO USE ANY OR ALL OF
                THE LEADS, OUR WEBSITE, OR OUR SERVICES OR THE INFORMATION CONTAINED IN THEM, ALONE OR AS PART OF A LIST
                OR ANY OTHER DATA OR LEAD AGGREGATION, TO ANY THIRD PARTY FOR ANY PURPOSE, EXCEPT ONLY FOR THE PURPOSE
                DESCRIBED ABOVE TO AN ENTITY (I.E. CARRIER) ENGAGED BY YOU AS A CONTRACTOR OR AGENT FOR THE SOLE PURPOSE
                OF ASSISTING YOU IN CONNECTION WITH THE AUTHORIZED USE OF THE LEADS BY YOU IN COMPLIANCE WITH THESE
                TERMS.
            </p>
            <p>
                <strong>CONSENT TO CALL</strong>
            </p>
            <p>
                We made a reasonable effort, based on currently understood legal requirements, to obtain the consumer's
                online prior express written consent to be contacted by automatic telephone dialing systems on their
                phones pursuant to the Telephone Consumer Protection Act of 1991 (known and referred to as the “TCPA”)
                (to learn more read our consumer privacy policy located at{' '}
                <a href="https://mediaalpha.com/privacy-policy/">https://mediaalpha.com/privacy-policy/</a> (referred to
                as “Privacy Policy”) and Our Terms of Use located at{' '}
                <a href="https://mediaalpha.com/terms-of-use/">https://mediaalpha.com/terms-of-use/</a> (referred to as
                the “Terms of Use”), both of which are applicable to the use of our webforms and consumer facing
                websites) , and will, in response to Your written request to Us, provide You a copy of the consent
                language and mechanism We used for the applicable Lead. Please submit all such consent requests to Our
                customer support at <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or call
                Us at 1-855-959-4165. You are solely responsible for and should determine whether the consents We
                obtained are legally sufficient and comply with Applicable Laws and Your internal marketing policies
                including that of Your affiliated carriers. We make no representations or warranties regarding such
                consent, including but not limited that such consent complies with the TCPA requirements or other
                Applicable Laws, or that the Leads can all be called.
            </p>
            <p>
                <strong>YOUR COMPLIANCE WITH LAW, OUR PRIVACY POLICY, AND OUR TERMS OF USE</strong>
            </p>
            <p>
                You are solely responsible and liable for familiarizing Yourself and complying with all applicable
                federal, state, local, national, foreign, and industry laws, rules, regulations, guidelines, and
                ordinances of any jurisdiction throughout the world (referred to as “Applicable Law” or "Applicable
                Laws") that are applicable to You and Your (i) use of the Leads, Our Website, Our services (including
                but not limited all laws applicable to consumer information and truthful and fair business practices),
                and the receipt, use, storage, transfer (including international or cross border transfers), disclosure,
                processing, privacy, protection, and safeguarding of personal information (also known as personal data)
                (as such terms are defined by Applicable Laws including but not limited to the California Consumer
                Privacy Act (referred to as the “CCPA”), and (iv))) contained in the Leads or that We or a consumer
                otherwise provides to You, (ii) email marketing and telemarketing activities, and (iii) offer and sale
                of Your products and services, including obtaining and maintaining any and all licenses required to
                offer and sell Your products and services quoted by You. Without prejudice to any other terms contained
                herein, you will not use or process any Lead information nor any other personal information (also known
                as personal data) in a manner that will, or is likely to, result in MediaAlpha breaching its obligations
                under Applicable Laws and policies.
            </p>
            <p>
                You will implement and maintain all appropriate technical, administrative, physical, and organizational
                measures, controls, and safeguards (including, at a minimum, those measures required under Applicable
                Law) required and designed to (i) ensure a level of confidentiality and security appropriate to the
                risks represented by the processing and the nature of personal information; (ii) prevent unauthorized or
                unlawful processing of personal information, accidental loss, disclosure or destruction of, or damage
                to, personal information (including but not limited to protecting any consumer information received
                pursuant to these Terms from unauthorized use or disclosure, and to restrict access to the consumer data
                solely to carry out the Purpose for which it was disclosed); and (iii) responding to data subjects'
                requests relating to their rights of: (a) access; (b) rectification / modification; (c) erasure /
                deletion; (d) restriction of processing; (e) data portability; (f) objection to processing; (g) do not
                sell; (h) do not share; (i) limit the use or disclosure of sensitive personal information; and (j)
                avoiding automated individual decision making, including profiling.
            </p>
            <p>
                You will assist and cooperate with MediaAlpha in this regard for any consumer's rights request related
                to the Leads and any other personal information made available or provided to You by Us. For such
                requests MediaAlpha shall, in its sole judgment, determine whether or not the consumer making the
                request has a right to exercise any of the rights referenced above or under Applicable Laws and give
                instructions to You and to what extent Your assistance is required. Further, You will assist and
                cooperate with MediaAlpha with communicating requests to all recipients of such consumer's information,
                including but not limited to any carriers or other third parties to which You disclosed, transferred, or
                transmitted the consumer's information, and securing such parties' cooperation to address any such
                consumer's request.
            </p>
            <p>
                You acknowledge and agree that You read and understood MediaAlpha's Consumer Privacy Policy (located at{' '}
                <a href="https://mediaalpha.com/privacy-policy/">https://mediaalpha.com/privacy-policy/</a>) (“Our
                Consumer Privacy Policy”) and Our Consumer Terms of Use (located at{' '}
                <a href="https://mediaalpha.com/terms-of-use/">https://mediaalpha.com/terms-of-use/</a>) (“Our Consumer
                Terms of Use”). You are responsible for and will comply with Our Consumer Privacy Policy and Consumer
                Terms of Use, each as may be amended from time to time by Us. This means that Your use of the Leads, Our
                Website, and Our services, and Your receipt, use, storage, transfer (including international or cross
                border transfers), disclosure, processing, privacy, protection, and safeguarding of personal information
                (also known as personal data) will be at all times in compliance with Our Consumer Privacy Policy and
                Consumer Terms of Use.
            </p>
            <p>
                MediaAlpha will post any updates to the Consumer Privacy Policy and Consumer Terms of Use to the links
                provided above or such other replacement links as solely determined by MediaAlpha. You are responsible
                for periodically checking the Consumer Privacy Policy and Consumer Terms of Use for any updates.
                MediaAlpha will use reasonable efforts to notify You through pop-up notices and/or alerts Our Website of
                any material changes to the Consumer Privacy Policy or Consumer Terms of Use or links. All changes to
                the Consumer Privacy Policy and Consumer Terms of Use are effective upon posting (unless otherwise
                stated therein). If for any reason You do not agree to any or all of terms and conditions in the
                Consumer Privacy Policy or Consumer Terms of Use and updates to them, You should stop using Our services
                and stop buying Leads or services from Us and You should not access Our Website. Your continued use of
                the Leads (including buying Leads), Our Website, and Our services constitutes your acceptance of such
                updated Consumer Privacy Policy and Consumer Terms of Use subject to Your right to terminate Your use of
                the Leads, Our Website, and Our services in accordance with these Terms.
            </p>
            <p>
                Notwithstanding the foregoing, MediaAlpha will make reasonable attempts to secure all data on Our
                Website and services; provided, however, We are not responsible or liable for any lack of care by You to
                secure Your own data (for example, sharing of Your username, password, or Your Account information, not
                logging out of Your Account, etc.) or for any breach of security on Our Website or services. For the
                avoidance of doubt, You are responsible for any access to or use of Your Account or Our Website and
                services by You or any person or entity using Your username, password, or Your Account information,
                whether or not such access or use has been authorized by or on behalf of You, and whether or not such
                person or entity is Your employee, agent, service provider, or representative. You further acknowledge
                and agree that MediaAlpha has the right to deactivate, change, and/or delete Your username and/or
                password in Our sole discretion for any reason or no reason. YOU ARE RESPONSIBLE FOR AND AGREE THAT YOU
                WILL USE YOUR BEST EFFORTS TO MAINTAIN THE PRIVACY OF AND PROTECT THE PERSONAL AND CONFIDENTIAL
                INFORMATION OF EACH CONSUMER AND LEAD. YOU ARE SOLELY RESPONSIBLE AND LIABLE FOR ALL PERSONAL
                INFORMATION YOU COLLECT DIRECTLY FROM ANY CONSUMER THAT YOU ESTABLISH A BUSINESS RELATIONSHIP WITH.
            </p>
            <p>
                <strong>YOUR RESPONSIBILITIES AND OBLIGATIONS</strong>
            </p>
            <p>
                You are responsible for promptly initiating contact with the consumer and providing them with the
                requested quote and/or rates and other requested product and services information and paying for all
                Leads supplied to Your Account. You agree to initiate contact with each Lead within 48 hours (not
                including weekends or legal holidays) of the Lead's placement in Your Account to (i) verify the accuracy
                of the information provided by the consumer, (ii) provide the consumer with the requested quotation
                and/or other product or service information, and (iii) make and/or provide any other statements or
                notices required by MediaAlpha to the consumer. You further acknowledge and agree that MediaAlpha does
                not investigate or verbally screen or verify any consumer or their request in any manner whatsoever, and
                that MediaAlpha simply forwards the relevant information submitted by each consumer to You as part of
                the Lead buying process. You are responsible for exercising due care and prudence in dealing with all
                Leads and consumers, including all reasonable care necessary to determine each consumer's financial
                condition and to protect Your personal safety. Accordingly, You acknowledge and agree that You are
                solely responsible and liable for independently verifying all Lead and consumer information supplied by
                MediaAlpha for accuracy prior to issuing quotations or rates or any other information or writing any
                policy. You also agree to provide quotes or rates only from those underwriters or carriers for which You
                are authorized and/or licensed to sell their products and/or services.
            </p>
            <p>
                You will not represent to any consumer that You or the carrier(s) you represent are an employee of or
                otherwise represent MediaAlpha. Additionally, You will not use the intellectual property of MediaAlpha
                (including without limitation, MediaAlpha' trademarks, service marks, trade names, domain names and any
                other copyright-protected materials) without the prior written consent of MediaAlpha, which MediaAlpha
                may grant or withhold in its sole discretion. For the avoidance of doubt, the foregoing restricts and
                prohibits You from bidding on or otherwise using in commerce any internet search keywords or metatags
                that contain any of MediaAlpha' intellectual property, or otherwise attempting to divert internet
                traffic from Our Website to any of Your or Your carrier's sites through impersonation, deception, or any
                other similar tactics.
            </p>
            <p>
                IN THE EVENT YOU OFFER DISCOUNT PRODUCTS, SERVICES, OR POLICIES TO ANY CONSUMER, YOU AGREE TO DISCLOSE
                AND DESCRIBE CLEARLY TO EACH SUCH CONSUMER WHETHER SUCH ARE INSURANCE PRODUCTS, SERVICES, OR POLICIES OR
                NOT AND TO DESCRIBE FULLY THE MATERIAL DISTINCTIONS BETWEEN THE DISCOUNT PRODUCTS, SERVICES, OR
                POLICIES.
            </p>
            <p>
                <strong>OWNERSHIP OF THE LEADS, THE WEBSITE, OUR SERVICES, AND WEBFORMS</strong>
            </p>
            <p>
                MediaAlpha owns and retains sole and exclusive ownership of all Leads, Our Website, Our services, and
                any other information, products, or services owned and/or licensed by Us (including but not limited to
                all content, code, data, information, graphics, and other intellectual property used therein, the look
                and feel, design, and organization thereof, and all copyright, trademark, trade name, service mark,
                patent, trade secret, moral, database, and other intellectual property and proprietary rights inherent
                therein or appurtenant thereto). Subject to these Terms, including, without limitation, Your payment of
                all applicable fees, MediaAlpha hereby grants to You a limited, non-exclusive, non-assignable,
                non-transferable, revocable, license during the term of this Terms to access, view, and use Our Website
                and Our services, solely for buying Leads for the Purpose. You may only make such use by using Your
                Account's username and password. Any other use of Our Website, Our services, and any other products or
                services owned and/or licensed by Us including but not limited to Your Account or any content, code,
                information, data or other materials contained or available therein is strictly prohibited. Any third
                party's trademarks, logos, or other intellectual property that may appear on Our Website or Our services
                are the property of their respective owners and may not be used without such third party's written
                consent. Additionally, You acknowledge and agree that (i) You are being granted a limited,
                non-exclusive, limited, revocable, right, and license hereunder to use the Leads provided for thirty
                (30) days from the date of delivery for the Purpose by promptly contacting the consumer and providing
                them with the requested quote and/or rates and other requested product and services information; (ii)
                MediaAlpha does not guarantee any minimum quantity of Leads or the number of Leads as such is dependent
                on the consumer and factors beyond MediaAlpha's control or reasonable control; and (iii) MediaAlpha has
                no obligation to provide any Leads to You, including Leads that may satisfy all of Your specifications
                regarding acceptable Leads. Except for the foregoing, nothing in these Terms will be construed to grant
                You any ownership right in, or license to any the Leads, Our Website, Our services, Our webforms, or any
                other products, services, or property owned or license by Us. For the avoidance of doubt, shared Leads
                may be sold to You and up to 3 other agents or corporate carriers (even though they are more typically
                only sold to you and 2 others). While Leads may be sold to other agents or corporate carriers, no Lead
                will be sold to more than one agent who is affiliated with a particular carrier. ALL RIGHTS, TITLES, AND
                INTERESTS NOT EXPRESSLY GRANTED TO YOU HEREUNDER ARE RESERVED EXCLUSIVELY TO MEDIAALPHA.
            </p>
            <p>
                <strong>BIDDING FOR LEADS AND LEAD PRICING.</strong>
            </p>
            <p>
                You may bid on Leads through Your Account. The price for each Lead is set by MediaAlpha at a minimum bid
                price (which may be zero) based on various market factors (each minimum bid price is as reflected in
                Your Account). Of Course, You may set a bid price of Your choosing at or above the minimum bid price
                (meaning Your bid for a Lead must be at or above the minimum bid price and not below it). If You are
                successful in Your bid, You agree to pay for the Leads placed in Your Account at Your accepted bid
                price(s) for such Leads. Leads are subject to availability, and prices are subject to change at any time
                at MediaAlpha's sole discretion. Once You have submitted a bid for a Lead, Your bid is locked in and
                cannot be changed for the applicable Lead(s). You may, however, change Your bid price for any future
                bids at or above the then current minimum bid price.
            </p>
            <p>
                <strong>FUNDING YOUR ACCOUNT, MAINTAINING A MINIMUM BALANCE, AND PAYING FOR LEADS</strong>
            </p>
            <p>
                You agree to pay for all Leads where Your bid for such Leads are accepted and for any Leads supplied to
                Your Account. You agree to provide to and maintain with Us current and valid credit card information
                (i.e., card type, name, number, expiration date, etc.) and hereby authorize Us (directly or through our
                service providers or agents) to charge the credit card(s) You provide in the amount of all or part of
                the fees payable for the Leads bought plus any taxes We may be required to collect. We require that You
                fund Your Account in advance with a minimum balance in an amount determined by Us from time to time and
                that You maintain this minimum balance in Your Account (“Minimum Balance”). The Minimum Balance must be
                replenished to maintain the balance at the required minimum amounts established by Us from time to time
                for You to continue to bid on and receive Leads. The Minimum Balance will be determined by MediaAlpha,
                in MediaAlpha's sole discretion, and can be found in the Your Account. When the balance in Your Account
                falls below this Minimum Balance You authorize Us to make a charge against the credit card(s) listed in
                Your Account for the amount required to return Your Account balance to the Minimum Balance. You also
                authorize Us to make a charge against Your Account's Minimum Balance in the amount of the fees for the
                Leads where Your bid for such Lead is accepted and for any Leads supplied to You. We reserve the right
                to not deliver Leads if there is not a sufficient balance in Your Account to pay for the Leads. If We
                have extended credit to You (subject to the credit terms and conditions We have extended to You), You
                authorize Us to make a charge against the credit card(s) listed in Your Account once Your credit limit
                with Us is reached. Your credit limit will be determined by MediaAlpha, in MediaAlpha's sole discretion,
                and can be found in Your Account. Lead purchases are not cancellable, and, except as otherwise provided
                below fees paid are not refundable. You are not authorized to use any Leads that are not paid for by
                You.
            </p>
            <p>
                You authorize MediaAlpha to make the charges described in this section against Your Account's Minimum
                Balance and the credit card(s) in Your Account at any of the following intervals: (i) when Your Account
                balance falls below the Minimum Balance, or (ii) when Your bid for a Lead is accepted, or (iii) when a
                Lead is supplied to Your Account; (iii) when Your credit limit with Us is reached, (iv) in accordance
                with the terms of credit terms and conditions We have extended to You (if and as applicable), or (iv)
                when Your Account is terminated. You further agree that, by Your acceptance of these Terms, You
                authorize MediaAlpha to charge the credit card marked as “Active” within Your Account at the time of
                billing for all fees and charges incurred hereunder, and You further represent and warrant to MediaAlpha
                that, if You are not the account holder of such card, You have all requisite power and authority to use
                and incur charges on such credit card on behalf of the account holder. Although under normal
                circumstances MediaAlpha will only bill and charge You in accordance with the above-described policy,
                MediaAlpha reserves the right to charge Your “Active” credit card for any outstanding balance at any
                time with or without prior notice.
            </p>
            <p>
                If Your “Active” credit card is declined for any reason at the time of billing, You authorize MediaAlpha
                to process payment on any other credit card stored in Your Account. No Leads will be delivered to You
                when Your Account is not funded (pre-paid) at the minimum set by Us, or when the balance in Your Account
                drops below the Minimum Balance, or when any payment by You is past due, or when Your Account is
                otherwise in default. If any such payment remains past due or Your Account is otherwise in a default
                state for more than ten (10) business days, Your outstanding balance may be reported by MediaAlpha for
                collection by a third party and You and/or Your credit card account information may be reported to
                credit bureaus or for credit card fraud for non-payment of a debt. You are solely responsible for any
                fees that may be associated with MediaAlpha's collection attempts, including without limitation
                MediaAlpha's attorneys' fees.
            </p>
            <p>
                You are responsible and liable for all taxes, fees, and other charges that may be assessed against You
                in connection with Our provision of Our services and Leads to You including Your payment of fees to
                MediaAlpha for Leads provided to You. To the extent required by applicable law, MediaAlpha may collect
                sales taxes in addition to the fee per Lead, and remit such taxes to the appropriate tax authorities.
            </p>
            <p>
                For all accounts with a positive balance and with no Leads bought in the last 90 days, We will charge a
                monthly inactivity fee of the greater of 10% of the available account balance or $10 until no balance
                remains (referred to as the “Maintenance Fee”).
            </p>
            <p id="lead-return-policy">
                <strong>LEAD RETURN POLICY.</strong>
            </p>
            <p>
                <strong>
                    The following Lead Return Policy applies to Leads for Auto, Life and Home insurance only. Health
                    leads may not be returned.
                </strong>
            </p>
            <p>
                If any Lead provided to You by MediaAlpha contains Invalid Information (as defined below) and is thus
                not a marketable consumer prospect, Your exclusive remedy (subject to the Monthly Return Cap defined
                below) is to return the Lead and request from MediaAlpha a refund of the Lead price paid by You for such
                Lead by giving notice to MediaAlpha within seven (7) days from the date the Lead was delivered to Your
                Account. If MediaAlpha accepts Your return and request for refund, MediaAlpha will refund the Lead price
                paid by You as a credit to Your Account balance as further described below.{' '}
                <strong>
                    Up to 20% of Leads received by You monthly may be returned in accordance with this return policy
                </strong>{' '}
                (referred to as the “Monthly Return Cap”). A Lead is deemed to contain invalid information solely when
                any one or more of the following conditions exist or occur (referred to as “Invalid Information”): (i)
                the phone number and email provided by the consumer as detailed in the Lead are both invalid (meaning
                the phone number is disconnected and the email address produces a valid bounce back email stating the
                mail box does not exist); (ii) the consumer's contact information is fictitious or materially inaccurate
                or incorrect on its face upon Your initial inspection of the Lead, (iii) the Lead is duplicative with
                another Lead provided by MediaAlpha to Your Account in the past thirty (30) days; (iv) the consumer is
                identified as another agent testing the system; (v) the consumer is identified by You as a consumer that
                is currently a customer of Agent with an active policy that is identical to the consumer's request for a
                quote and/or rates and other requested product and services information in the Lead in question, or
                (vi), except for information excluded or not provided by the consumer in a Lead, the information in the
                Lead does not match Your Account's filtering parameters (e.g., You set Your Account filters to include
                only consumers who are currently insured yet You receive a Lead with “no” in the “currently insured
                filed”). All requests for return of a Lead and refund due to any Invalid Information is subject to
                verification and approval by MediaAlpha in MediaAlpha's sole discretion. For the avoidance of doubt, if
                MediaAlpha determines in its sole discretion that a Lead does not contain Invalid Information, then
                Agent is responsible for payment in full to MediaAlpha for such Lead.
            </p>
            <p>
                If MediaAlpha determines, in its sole discretion, that MediaAlpha owes a refund to Agent as described
                above, then MediaAlpha will credit to Your Account balance the amount of the Lead price paid by You for
                such returned Lead. Any determination by MediaAlpha in this regard will be made by MediaAlpha within
                five (5) business days of MediaAlpha completing Our review of Your Lead return and request for refund.
                To return a Lead simply log into Your Account, open the Lead You would like to return, select the
                “Return” button provided in that Lead, and follow the prompts to return the Lead to Us.
            </p>
            <p>
                <strong>
                    ONCE YOU RETURN A LEAD (I.E., ONCE YOU SELECT “RETURN”), YOU MUST IMMEDIATELY STOP USING THE LEAD
                    AND DELETE ANY COPIES OF THE LEAD IN YOUR POSSESSION OR UNDER YOUR REASONABLE CONTROL. THIS MEANS IF
                    YOU ARE USING A CRM OR HAVE OTHERWISE EXPORTED THE LEAD, YOU MUST PERMANENTLY DELETE OR REMOVE ALL
                    OF THE LEAD INFORMATION FROM YOUR RECORDS AND DATABASES AND INSTRUCT ANYONE YOU SHARED IT WITH TO DO
                    THE SAME INCLUDING TO STOP USING THE LEAD. YOU ARE SOLELY RESPONSIBLE AND LIABLE FOR USING THE LEAD
                    IN COMPLIANCE WITH THESE TERMS AND APPLICABLE LAW AND FOR PROTECTING AND MAINTAINING THE PRIVACY AND
                    CONFIDENTIALITY OF THE LEAD INFORMATION YOU RECEIVED (INCLUDING ALL PERSONAL INFORMATION CONTAINED
                    IN THE LEAD).
                </strong>
            </p>
            <p>
                For the avoidance of doubt, Your failure to receive a notification email from MediaAlpha regarding the
                placement of Leads by MediaAlpha into Your Account shall not provide Agent with any right to a return,
                refund, or credit with respect to such Leads.
            </p>
            <p>
                <strong>PAUSING LEAD DELIVERY AND TERMINATION OF YOUR ACCOUNT AND THESE TERMS</strong>
            </p>
            <p>
                You may temporarily pause delivery of Leads to Your Account through Your Account Setup at any time for
                any reason. You are responsible for payment for and solicitation of any Leads You bid on prior to
                pausing the delivery of Leads and for any Leads sent to Your Account during the period before You paused
                delivery of Leads to Your Account. You are also responsible for payment of any Maintenance Fees
                applicable to Your Account during the period before You paused delivery of Leads to Your Account and for
                any such fees that become applicable to Your Account during the pause period (i.e., if Your Account
                during the pause period has a positive balance with no Leads bought in the last 90 days, We will charge
                the Maintenance Fee on a monthly basis until the balance in Your Account reaches zero or You resume
                buying Leads).
            </p>
            <p>
                We may terminate these Terms and Your Account for any reason or no reason at any time with or without
                prior notice to You. While we hope You would never choose to leave Us, You may terminate Your Account
                for any reason or no reason by contacting customer support at{' '}
                <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or call Us at
                1-855-959-4165. At the time of any such termination, regardless of the reason, You authorize Us and We
                may charge any outstanding balances against the funds available in Your Account and against the credit
                card(s) provided in Your Account as described above in these Terms. Notwithstanding the foregoing, in
                all cases You are responsible for and agree to pay any outstanding balance on Your Account within 5
                business days from the date of such termination. Except as otherwise stated in these Terms, if after
                satisfaction of all billable and/or payable fees, charges, and/or expenses owed by You to Us, Your
                Account maintains a positive balance, We will refund to the amount of this positive balance to You via
                check, ACH payment, or such other payment method as determined by MediaAlpha.in its sole discretion.
            </p>
            <p>
                Upon the termination of Your Account and these Terms: (a) You will immediately stop accessing any all
                use of the Leads (whether in Our Website or on Your systems or in Your database), Our Website, and Our
                services, (b) as instructed by MediaAlpha, You will return or destroy all Confidential Information
                within Your possession or control and within Your reasonable possession or control; and (c) You will
                promptly permanently delete all copies of the Leads (and all associated personal information) from Your
                systems (including Your CRM database) and instruct and take all necessary action to ensure that such
                Leads are permanently deleted from any third party's systems to whom You shared such Leads, in
                accordance with Applicable Laws and in a manner that makes the information non-readable and
                non-retrievable. You will certify in writing Your compliance with the foregoing as MediaAlpha's request.
                Notwithstanding the foregoing, You are not required to delete any such information contained on Your
                backup or disaster recovery systems so long as such information is deleted in accordance with Your
                written data retention policy, is not accessed or accessible, and continues to be handled in accordance
                with these Terms.
            </p>
            <p>
                Those sections in these Terms that by their nature can or should survive the termination of Your Account
                and these Terms shall survive termination for any reason and continue to apply indefinitely to any Leads
                purchased or received hereunder and all matters relating to such Leads or their use, including without
                limitation the use restrictions, warranties, indemnification, limitation of liability and mandatory
                arbitration provided herein.
            </p>
            <p id="agent-referral-program">
                <strong>AGENT REFERRAL PROGRAM</strong>
            </p>
            <p>
                If Your Account is active and in good standing and You are a legal resident of the United States
                (“Referring Agent”), you may refer natural persons in your network who are insurance agents to join
                MediaAlpha for Agents (each a “Referred Agent”) to qualify for a referral credit for each Qualified
                Referral (“Referral Credit”). To make a referral, in Your Account, you will find a referral link that
                you may send to individuals you wish to refer which takes the Referred Agent to a signup page
                personalized with Your first and last name (so the Referred Agent knows the referral is from You) (“Your
                Referral Link” or “Referral Link”). Referral Credit is issued in specific dollars amounts (e.g., $50,
                $100, etc.) to the Referring Agent for each Qualified Referral. The applicable Referral Credit amount
                will be noted in Our services and/or in promotional materials made available to You. To qualify for the
                Referral Credit, the Referred Agent must (i) not be a previous or current customer of MediaAlpha, (ii)
                submit their information to MediaAlpha via Your Referral Link, (iii) create, confirm, and fund their
                account in compliance with these Terms, (iv) agree to and remain in compliance with these Terms, and (v)
                purchase $100 or more of Leads (net of returns under the{' '}
                <a href="#lead-return-policy">Lead Return Policy</a>) after confirming and funding the Referred Agent's
                account (“Referred Agent Lead Purchase Minimum”). A Referred Agent who satisfies the foregoing will be
                deemed a “Qualified Referral” seven (7) days after satisfying the Referred Agent Lead Purchase Minimum.
            </p>
            <p>
                By clicking on a Referral Link, the Referred Agent acknowledges that some of their personal information
                may be sent to the Referring Agent who provided the Referral Link as confirmation of the Referred Agent
                being a Qualified Referral and the Referring Agent receiving a Referral Credit. Accordingly, MediaAlpha
                strongly advises that the Referred Agent only use a Referral Link they receive from someone whom they
                personally know and trust. Any personal information collected, processed, and used as part of the
                referral program, including that of the Referred Agent and Referring Agent, will be used in accordance
                with the <a href="/privacy">Agents Privacy Policy</a> .
            </p>
            <p>
                Each Referral Credit is non-transferable, not valid for cash or cash equivalent, cannot be combined with
                other offers, and must be used in the currency in which it was received. Referring Agents are
                responsible for any and all tax liability resulting from any Referral Credit, if and to the extent
                applicable.
            </p>
            <p>
                Referral Credit may only be used for the purchase of Leads through Your Account. Referral Credit will be
                used only after You have spent Your initial deposit or $1,000, whichever is less, and then the Referral
                Credit will be used prior to any other funds, balances, or credit card(s) being used or charged for the
                purchase of Leads. Referral Credit may not be used for any other purpose and are not refundable under
                any circumstances (e.g., if You later decide to terminate Your Account and the Referral Credit has not
                been fully exhausted at the time of termination, such Referral Credit will be forfeited and You will
                only be refunded the unused balance in Your Account, if any, as further described in the termination
                section above).
            </p>
            <p>
                Referral Credit will typically be processed and sent out to the Referring Agent within 14 days of a
                Referral Agent becoming a Qualified Referral.
            </p>
            <p>
                MediaAlpha reserves the right to review, investigate, and disqualify any Referral Credit that violates
                these Terms, Applicable Laws (such as distributing a Referral Link via commercial advertising or spam),
                or is otherwise fraudulent as determined in MediaAlpha's sole discretion. MediaAlpha may delay any
                Referral Credit for the purposes of investigation and may refuse to verify and process any transaction
                for any reason.
            </p>
            <p>
                Referring Agent should only invite a Referred Agent after receiving prior permission from the Referred
                Agent to send. Referring Agent is solely responsible and liable for and must comply with all Applicable
                Laws including but not limited to “SPAM” laws and marketing regulations, which may include the laws
                where the intended Referred Agent lives. For example, emails must be created and distributed in a
                personal manner and bulk email distribution is prohibited. Any distribution of Your Referral Link that
                could constitute unsolicited commercial email or "spam" under any Applicable Laws or regulation is
                expressly prohibited and will be grounds for immediate termination and exclusion of Your participation
                in the referral program and/or Your Account. MediaAlpha automatically disqualifies suspicious referrals.
            </p>
            <p>
                MediaAlpha is not responsible for (a) Referred Agents not completing any steps in the referral process
                or not otherwise becoming a Qualified Referral, or (b) delays or failure to send Referral Credit due to
                any circumstances beyond our reasonable control or due to Your failure to comply with these Terms.
            </p>
            <p>
                MediaAlpha reserves the right to cancel the referral program or to change the applicable terms at any
                time and in its sole discretion. MediaAlpha will make all determinations regarding participation in the
                referral program. MediaAlpha reserves the right to terminate Your participation in the referral program
                or cancel the entire referral program at any time for any reason or no reason without notice in
                MediaAlpha's sole discretion, to the extent permitted by Applicable Laws. In the case of a termination,
                the status of pending Referral Credits will be communicated to You. Any written notices required or
                permitted to be given by MediaAlpha hereunder may be delivered by email to the email address we have on
                file for You. By participating in the referral program, You consent to the delivery of all referral
                program-related notices and information by email to the email address you have provided to MediaAlpha,
                and acknowledge that You have the necessary equipment (hardware and software) to receive and read such
                emails.
            </p>
            <p>
                <strong>YOUR CONSENT TO PUBLIC INFORMATION REQUEST</strong>
            </p>
            <p>
                FOR BUSINESS PURPOSES RELATED TO THESE TERMS AND FOR PROVIDING OUR SERVICES TO YOU, YOU CONSENT TO
                MEDIAALPHA OBTAINING A REPORT(S) FROM THIRD-PARTY SOURCES OF YOUR LICENSURE RECORDS AND ANY OTHER
                INFORMATION NECESSARY FOR US TO COMPLY WITH OUR OBLIGATIONS UNDER APPLICABLE LAW, AND USING INFORMATION
                OBTAINED FROM SUCH RECORDS IN DETERMINING WHETHER TO ENTER INTO THESE TERMS WITH YOU OR TO CONTINUE TO
                DO BUSINESS WITH YOU INCLUDING WHETHER TO ALLOW YOU ACCESS TO OR PROVIDE OUR SERVICES TO YOU. YOU
                FURTHER ACKNOWLEDGE AND AGREE THAT MEDIAALPHA MAY PROVIDE YOUR LICENSE NUMBER OR OTHER REQUESTED
                INFORMATION TO OUR BUSINESS PARTNERS AND AFFILIATES WHO HAVE A NEED TO KNOW SUCH INFORMATION FOR
                LEGITIMATE BUSINESS PURPOSES.
            </p>
            <p>
                <strong>YOUR PRIVACY</strong>
            </p>
            <p>
                You can learn how we handle Your information when you Call Us and when You use Our Website and Our
                services by reading our MediaAlpha for Agents Privacy Policy available at{' '}
                <a href="/privacy">https://insurance-agent.mediaalpha.com/privacy</a>. We encourage you to give the
                MediaAlpha for Agents <a href="/privacy">Privacy Policy</a> a careful look because, by calling Us and by
                using our Website, and Our services, you agree that MediaAlpha can collect, use, process, and share your
                information consistent with our policy.
            </p>
            <p>
                <strong>CONFIDENTIALITY</strong>
            </p>
            <p>
                You will not, without MediaAlpha's prior approval in each instance, either personally or by means of any
                third party or any device, make any disclosure or supply any Confidential Information to any third party
                or to the public. Notwithstanding the foregoing, You may disclose Confidential Information to Your
                consultants, vendors, subcontractors, and agents who have a need to know such information and are bound
                to You by legally binding obligations of confidentiality or terms of confidentiality at least as
                restrictive as those contained in this section. You will not use any Confidential Information for any
                purpose other than the Purpose or for the purpose for which the Confidential Information was disclosed,
                and You will take all precautions to prevent any unauthorized use or disclosure of any Confidential
                Information that You take with Your own confidential information of like importance, which in no event
                will be less than reasonable care. You may not disassemble, decompile, or otherwise reverse engineer the
                Confidential Information. If You are required by Applicable Laws or otherwise requested by a court,
                governmental entity, or regulatory authority to disclose any Confidential Information, then (to the
                extent permitted by Applicable Laws and order) You will provide advance notice to, and cooperate with,
                MediaAlpha in protecting the disclosure of the Confidential Information. In the event that We are unable
                to seek or obtain a protective order, You may only disclose the minimum amount of Confidential
                Information necessary to comply with such Applicable Laws or order and will use reasonable efforts to
                obtain confidential treatment of such information disclosed.
            </p>
            <p>
                As between MediaAlpha and You, all Confidential Information is and will remain the sole and exclusive
                property of MediaAlpha.
            </p>
            <p>
                We refer to and define “Confidential Information” in these Terms as any matter, information, or material
                relating to any MediaAlpha's affairs, whether or not arising under these Terms, coming within Your
                knowledge either in connection with Your relationship with Us, Our rendering the services (including
                providing Our Website to You), or otherwise, including, without limitation, any confidential or trade
                secret information of MediaAlpha. Confidential Information includes, without limitation, (i) items that
                are marked or identified, orally or in writing, as a Our confidential or proprietary information, and
                items which You should recognize from the circumstances surrounding the disclosure as being
                confidential, (ii) the Leads, (iii) all communications between You and Us related to these Terms, (iv)
                any copies, summaries, extracts, and other forms of the foregoing, (v) passwords, (vi) usernames, (vii)
                any personal information including that of a consumer, and (viii) pricing including minimum bid pricing
                and associated formulas. Confidential Information does not include any matter, information, or material
                that: (A) becomes available to the general public without Your breach of these Terms; (B) You lawfully
                receives from a third party without restriction on disclosure and without breach of any non-disclosure
                obligation; (C) was rightfully in Your possession without restriction prior to its disclosure by
                MediaAlpha to You; or (D) is independently developed by or on behalf of You without use of or reference
                to Confidential Information.
            </p>
            <p>
                <strong>REPRESENTATIONS, WARRANTIES, AND COVENANTS</strong>
            </p>
            <p>You represent, warrant, and covenant to Use the following:</p>
            <p>
                The use of the Leads, Our Website, and Our services and any calls and other interactions with and
                information provided to consumers resulting from the Leads will comply Applicable Laws including,
                without limitation, the TCPA and implementing regulations issued by Federal Communications Commission,
                the Telemarketing and Consumer Fraud and Abuse Prevention Act, the Federal Trade Commission's
                Telemarketing Sales Rule, the Controlling the Assault of Non-Solicited Pornography and Marketing Act,
                and other federal and state laws and regulations governing the marketing, promotion, and/or sales of
                goods or services, including general consumer protection laws and regulations, or other consumer
                protection laws that prohibit unfair, deceptive, or misleading acts or practices;
            </p>
            <p>
                Without limiting the above, You will not make any calls to any individual listed on any federal or state
                national Do-Not-Call (known and referred to as “DNC”) registry unless an exemption applies;
            </p>
            <p>
                You will not use any Lead information for purposes of determining a person's eligibility for insurance,
                credit, employment or otherwise in any manner that violates the Fair Credit Reporting Act;
            </p>
            <p>
                You have obtained any and all required licenses, permits, and other authorizations required by
                Applicable Laws or government or regulatory authority to conduct Your business as presently conducted,
                including offering and selling the products and services that are the subject of the Lead (referred to
                individual as a “License” and collectively as “Licenses”);
            </p>
            <p>All such Licenses are and will remain valid and in good standing;</p>
            <p>
                You have not been disciplined or threatened with discipline by any governmental authority or
                professional association for any violation or alleged violation of any Applicable Laws or ethical
                standard applicable to You, any License held by You or Your carrier or business, and no disciplinary
                proceeding involving You is pending or threatened;
            </p>
            <p>
                You have not, in the past 10 years, been convicted of any felony or misdemeanor (or comparable violation
                of law) involving a crime of moral turpitude anywhere in the world, and no such criminal or comparable
                proceeding is now pending against You anywhere in the world;
            </p>
            <p>
                You have never been disciplined or terminated for cause by any insurance brokerage or
                underwriter/carrier;
            </p>
            <p>
                You have disclosed the existence of any federal or state decrees, orders, or consent agreements, and any
                pending formal or informal government investigations;
            </p>
            <p>
                If You become involved or named in any action, investigation, complaint or other proceeding by or before
                any governmental or regulatory authority, or any private party, You will immediately provide notice to
                MediaAlpha of such action, investigation, complaint or other proceeding;
            </p>
            <p>
                You will ensure that Your carrier(s), employees, contractors, service providers, consultants, and agents
                comply with the foregoing; and
            </p>
            <p>
                All information provided by You to MediaAlpha relating to these Terms or Your Account is true, accurate,
                and complete in all respects.
            </p>
            <p>
                <strong>DISCLAIMERS</strong>
            </p>
            <p>
                YOU ACKNOWLEDGE AND AGREE THAT THE WEBSITE, LEADS, AND ALL SERVICES PROVIDED BY MEDIAALPHA HEREUNDER ARE
                PROVIDED “AS IS” AND “WHERE IS” WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER AND
                THAT YOUR USE OF SUCH WEBSITE, LEADS, AND SERVICES IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED
                BY APPLICABLE LAWS AND EXCEPT AS EXPRESSLY STATED OTHERWISE IN THESE TERMS, NEITHER MEDIAALPHA NOR ANY
                OF MEDIAALPHA'S AFFILIATES, LICENSORS OR SUPPLIERS MAKES, AND YOU ARE NOT RECEIVING, ANY WARRANTIES,
                EXPRESS, IMPLIED, OR OTHERWISE WITH RESPECT TO THE WEBSITE, THE LEADS, AND ALL SERVICES PROVIDED
                HEREUNDER. MEDIAALPHA AND ITS AFFILIATES, LICENSORS AND SUPPLIERS EXPRESSLY DISCLAIM ALL IMPLIED
                WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSES, TITLE, QUALITY,
                QUANTITY, ACCURACY, AND/OR NON-INFRINGEMENT. MEDIAALPHA IS NOT INVOLVED IN ANY TRANSACTION BETWEEN YOU
                AND ANY CONSUMER. AS A RESULT, MEDIAALPHA HAS NO CONTROL OVER THE INTEGRITY, QUALITY, SAFETY, OR
                LEGALITY OF ANY LEAD OR THE TRUTH OR ACCURACY THEREOF. BECAUSE USER AUTHENTICATION ON THE INTERNET IS
                DIFFICULT, MEDIAALPHA CANNOT AND DOES NOT CONFIRM THAT ANY CONSUMER IS WHO SUCH CONSUMER CLAIMS TO BE.
                IN THE EVENT YOU HAVE A DISPUTE WITH ANY CONSUMER, YOU HEREBY FULLY RELEASE AND SHALL INDEMNIFY, DEFEND,
                AND HOLD HARMLESS MEDIAALPHA AND ITS SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, YOUS AND AFFILIATES
                FROM ANY AND ALL CLAIMS, LIABILITIES, DEMANDS, AND DAMAGES (ACTUAL AND CONSEQUENTIAL, DIRECT AND
                INDIRECT OR OTHERWISE) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED
                AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY SUCH DISPUTE. YOU ARE SOLELY
                RESPONSIBLE AND LIABLE FOR YOUR RELATIONSHIP WITH ANY CONSUMER YOU CONTACT OR CONDUCT BUSINESS WITH AND
                FOR SEEKING ANY REMEDIES AND REDRESS DIRECTLY FROM ANY CONSUMER IN THE EVENT OF ANY SUCH DISPUTE.
            </p>
            <p>
                <strong>YOUR OBLIGATION TO KEEP YOUR ACCOUNT INFORMATION CURRENT</strong>
            </p>
            <p>
                You agree to keep Your Account information up-to-date, accurate, and complete. You are responsible for
                promptly logging in to Your Account (in all cases no later than 48 hours) following: (i) any change in
                the expiration date or status of any of Your credit card(s) on file with MediaAlpha; and/or (ii) any
                change in Your contact information, including address, telephone number, and email address.
            </p>
            <p>
                <strong>YOUR OBLIGATION TO NOTIFY US FOR ANY BREACHES OF THESE TERMS AND SECURITY ISSUES</strong>
            </p>
            <p>
                You are responsible for promptly notifying Us (in all cases no less than 48 hours) following: (i) any
                breach of any of Your representations, warranties, and covenants contained in these Terms; and/or (ii)
                any suspected or actual breach of security, such as loss, theft, or unauthorized disclosure or use of
                Your username, password, or Your Account information including any Leads delivered or otherwise
                available in Your Account.
            </p>
            <p>
                <strong>COMPLIANCE AND AUDITS</strong>. You will cooperate and comply with all requests by Us to enable
                Us to verify Your compliance with these Terms, Our instructions, and the Applicable Laws and assist Us
                in complying with our obligations under the Applicable Laws. We may request, upon ten (10) days prior
                written notice to You (unless a shorter period is required to meet a legal requirement or request by a
                government authority), access to Your facilities, systems, records, and supporting documentation in
                order to audit, itself or through an independent third-party auditor, Your compliance with Your
                obligations under or related to these Terms. Audits shall be subject to all applicable confidentiality
                obligations agreed to by MediaAlpha and You and shall be conducted in a manner that minimizes any
                disruption of Your performance of services and other normal operations. In the event that any such audit
                reveals material gaps or weaknesses in Your information security program or non-compliance with these
                Terms, We shall be entitled to take appropriate suspension action as outlined below until such issues
                are resolved. We may also require You to, upon request, make available to MediaAlpha, any information or
                certifications necessary to demonstrate compliance with the obligations set forth in these Terms.
            </p>
            <p>
                <strong>REGULATORY ASSISTANCE</strong>. You will, upon Our request and in compliance with Our
                instructions, provide reasonable assistance to Us to enable Us to respond to and comply with any
                correspondence, enquiry, request, complaint, or legal process received from a consumer, a State Attorney
                General, or any other federal, state, or local regulator, law enforcement, or judicial, government, or
                competent legal authority in connection with Your use and processing of Lead information and any other
                personal information you collect or process in connection with Our Services.
            </p>
            <p>
                <strong>SUSPEND PROCESSING</strong>. Notwithstanding anything contrary in these Terms, in the event that
                You notify Us about: (a) material gaps or weaknesses in Your or Your sub-processors' data security
                practices, (b) violation of these Terms or Applicable Laws; (c) a security incident; or (d) any formal
                complaints about Your or Your sub-processors' data protection or data security practices, We shall be
                entitled (in addition to any other remedies detailed in these Terms) to suspend the processing and/or
                transmission to You or Your sub-processors and terminate Your and Your sub-processors' processing of the
                data, at no cost, until such issues are resolved.
            </p>
            <p>
                <strong>LIMITATION OF LIABILITY</strong>. IN NO EVENT SHALL MEDIAALPHA, ITS SHAREHOLDERS, DIRECTORS,
                OFFICERS, EMPLOYEES, AGENTS, AFFILIATES, LICENSORS OR SUPPLIERS BE LIABLE HEREUNDER FOR ANY
                CONSEQUENTIAL, EXEMPLARY, SPECIAL, INDIRECT, INCIDENTAL OR PUNITIVE DAMAGES OR LOST REVENUE, LOST
                PROFITS OR ANTICIPATED BUSINESS (EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES)
                ARISING FROM OR RELATING TO THESE TERMS, THE SUBJECT MATTER HEREOF, OUR WEBSITE, THE LEADS, OR OUR
                SERVICES PROVIDED HEREUNDER, INCLUDING BUT NOT LIMITED TO DAMAGES ARISING FROM INFORMATION PROVIDED BY,
                TO OR THROUGH MEDIAALPHA, OR ANY OTHER DAMAGES, HOWEVER CAUSED, UNDER ANY THEORY OF LIABILITY, INCLUDING
                WITHOUT LIMITATION TORT (INCLUDING NEGLIGENCE), CONTRACT OR OTHERWISE. WITHOUT LIMITING THE GENERALITY
                OF THE FOREGOING, MEDIAALPHA DOES NOT WARRANT THAT THE SITES WILL OPERATE ERROR-FREE OR WITHOUT
                INTERRUPTION. THE TOTAL, CUMULATIVE, AND AGGREGATE LIABILITY OF MEDIAALPHA, ITS SHAREHOLDERS, DIRECTORS,
                OFFICERS, EMPLOYEES, AGENTS, AFFILIATES, LICENSORS AND SUPPLIERS TO AGENT FOR ANY DAMAGES ARISING FROM
                OR RELATING TO THIS AGREEMENT, THE SUBJECT MATTER HEREOF, OUR WEBSITE, THE LEADS, OR THE SERVICES
                PROVIDED HEREUNDER, HOWEVER CAUSED, UNDER ANY THEORY OF LIABILITY, SHALL NOT EXCEED THE GREATER OF (I)
                THE AMOUNT RECEIVED BY MEDIAALPHA DIRECTLY FROM YOU PURSUANT TO THESE TERMS DURING THE CALENDAR MONTH
                DURING WHICH SUCH CLAIM(S) ACCRUED, OR (II) $1000.00.
            </p>
            <p>
                <strong>INDEMNIFICATION</strong>
            </p>
            <p>
                You shall (in addition to and not in lieu of any other indemnification provided elsewhere these Terms)
                defend, indemnify and hold MediaAlpha and its shareholders, directors, officers, employees, agents,
                affiliates, licensors, and suppliers (referred to as the “Indemnified Parties”) harmless from and
                against any and all damages, losses, liabilities, costs, and expenses (including reasonable attorneys'
                fees, data breach notifications and reporting, mitigation costs, and regulatory penalties imposed on the
                Indemnified Parties) arising from any third-party claims, complaints, demands, suits, proceedings,
                formal or informal administrative, or regulatory proceedings or inquiries, and other actions due to,
                arising out of, or relating in any way to (a) Your or Your employees', agents', affiliates', licensors',
                carriers', and suppliers' misuse of Our Website, the Leads, or our services provided hereunder, (b) Your
                or Your employees', agents', affiliates', licensors', carriers', and suppliers' placement or
                transmission of any message, content, information, software or other materials through one or more of
                Our Website or services, (c) Your or Your employees', agents', affiliates', licensors', carriers', and
                suppliers' alleged or actual breach or violation of the Applicable Laws, these Terms, the Terms of Use,
                or the Privacy Policy, or (d) Your or Your employees', agents', affiliates', licensors', carriers', and
                suppliers' dispute with a consumer or other third party.
            </p>
            <p id="mandatory-arbitration">
                <strong>MANDATORY ARBITRATION</strong>
            </p>
            <p>
                PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY BECAUSE THEY REQUIRE YOU AND MEDIAALPHA TO AGREE TO
                RESOLVE ALL DISPUTES BETWEEN US THROUGH BINDING INDIVIDUAL ARBITRATION.
            </p>
            <p>
                You and MediaAlpha agree that all claims and disputes (whether contract, tort, or otherwise), including
                all statutory claims and disputes, arising out of or relating to these Terms or the use of Our Website,
                the Leads, or Our services that cannot be resolved in small claims court will be resolved by binding
                arbitration on an individual basis, except that you and MediaAlpha are not required to arbitrate any
                dispute in which either party seeks equitable relief for the alleged unlawful use of copyrights,
                trademarks, trade names, logos, trade secrets, or patents. For clarity, the phrase “all claims and
                disputes” also includes claims and disputes that arose between You and Us before the effective date of
                these Terms.
            </p>
            <p>
                The Federal Arbitration Act governs the interpretation and enforcement of this dispute-resolution
                provision. Arbitration will be initiated through the American Arbitration Association (“AAA”) and will
                be governed by the AAA Consumer Arbitration Rules, available here as of the date of these Terms, or by
                calling the AAA at 1-800-778-7879. If the AAA is not available to arbitrate, the parties will select an
                alternative arbitral forum. The rules of the arbitral forum will govern all aspects of this arbitration,
                except to the extent those rules conflict with these Terms. The arbitration will be conducted by a
                single neutral arbitrator. Any claims or disputes where the total amount sought is less than $10,000 USD
                may be resolved through binding non-appearance-based arbitration, at the option of the party seeking
                relief. For claims or disputes where the total amount sought is $10,000 USD or more, the right to a
                hearing will be determined by the arbitral forum's rules. Any judgment on the award rendered by the
                arbitrator may be entered in any court of competent jurisdiction.
            </p>
            <p>
                If non-appearance arbitration is elected, the arbitration will be conducted by telephone, online,
                written submissions, or any combination of the three; the specific manner will be chosen by the party
                initiating the arbitration. The arbitration will not involve any personal appearance by the parties or
                witnesses unless the parties mutually agree otherwise.
            </p>
            <p>
                If You choose to arbitrate with MediaAlpha, You will not have to pay any fees to do so. That is because
                MediaAlpha will reimburse you for your filing fee and the AAA's Consumer Arbitration Rules provide that
                any hearing fees and arbitrator compensation are our responsibility. To the extent another arbitral
                forum is selected, MediaAlpha will pay that forum's fees as well.
            </p>
            <p>
                The arbitrator will decide the jurisdiction of the arbitrator and the rights and liabilities, if any, of
                you and MediaAlpha The dispute will not be consolidated with any other matters or joined with any other
                cases or parties. The arbitrator will have the authority to grant motions dispositive of all or part of
                any claim or dispute. The arbitrator will have the authority to award monetary damages and to grant any
                non-monetary remedy or relief available to an individual under law, the arbitral forum's rules, and the
                Terms. The arbitrator will issue a written award and statement of decision describing the essential
                findings and conclusions on which the award is based, including the calculation of any damages awarded.
                The arbitrator has the same authority to award relief on an individual basis that a judge in a court of
                law would have. The award of the arbitrator is final and binding upon you and MediaAlpha.
            </p>
            <p>
                YOU AND MEDIAALPHA WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN
                FRONT OF A JUDGE OR A JURY. You and MediaAlpha are instead electing to have claims and disputes resolved
                by arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than
                rules applicable in court and are subject to very limited review by a court. In any litigation between
                You and MediaAlpha over whether to vacate or enforce an arbitration award, YOU AND MEDIAALPHA WAIVE ALL
                RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.
            </p>
            <p>
                ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED
                ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE PERSON OR USER CANNOT BE
                ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER PERSON OR USER. If, however,
                this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor we are
                entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth in the{' '}
                <a href="#governing-law-and-venue">Governing Law and Venue</a> section below.
            </p>
            <p>
                Any rights and limitations set forth in this arbitration agreement may be waived by the party against
                whom the claim is asserted. Such waiver will not waive or affect any other portion of this arbitration
                agreement.
            </p>
            <p>
                You may opt out of this arbitration agreement. If You do so, neither you nor MediaAlpha can force the
                other to arbitrate. To opt out, You must notify MediaAlpha in writing no later than 30 days after first
                becoming subject to this arbitration agreement. Your notice must include Your name and address, Your
                Account username and the email address You used to set up Your Account (if you have one), and an
                unequivocal statement that you want to opt out of this arbitration agreement. You must either mail Your
                opt-out notice to this address: MediaAlpha Attention: General Counsel, 700 S. Flower St., Suite 640, Los
                Angeles, CA 90017, or email the opt-out notice to{' '}
                <a href="mailto:agentprivacy@mediaalpha.com">agentprivacy@mediaalpha.com</a>.
            </p>
            <p>
                Notwithstanding the foregoing, neither You or MediaAlpha may bring an individual action in small claims
                court.
            </p>
            <p>
                This arbitration agreement will survive the termination of your relationship with MediaAlpha including
                the termination of Your Account and these Terms.
            </p>
            <p>
                <strong>NOTICES</strong>
            </p>
            <p>
                For any issues regarding Your Account including password resets, loss or misuse of Your Account
                credentials, loss or misuse of any Lead or Lead information, or any other technical issues, contact{' '}
                <a href="mailto:agentprivacy@mediaalpha.com">agentprivacy@mediaalpha.com</a> or call Us at
                1-855-959-4165.
            </p>
            <p>
                For all notices, consents, waivers, and other communications required or permitted by these Terms shall
                be in writing and shall be deemed given to a party when (a) delivered to the appropriate address by hand
                or by nationally recognized overnight courier service (costs prepaid); (b) sent by facsimile or email,
                with confirmation of transmission by the transmitting equipment and, if MediaAlpha is the intended
                recipient, delivery of a copy of any such notice or other communication to MediaAlpha by certified mail,
                return receipt requested; or (c) received or rejected by the addressee, if sent by certified mail,
                return receipt requested. MediaAlpha is authorized to send any notices to You hereunder to Your Account
                and to the mailing address, facsimile number and/or email address associated with Your Account, as such
                information may be updated by You from time to time. You are authorized to send any notices to
                MediaAlpha hereunder to the following mailing address, facsimile number, and email address:
            </p>
            <p>MediaAlpha</p>
            <p>Attention: General Counsel</p>
            <p>700 S. Flower St., Suite 640</p>
            <p>Los Angeles, CA 90017</p>
            <p>With a copy to info@mediaalpha.com</p>
            <p>
                <strong>PUBLICITY</strong>
            </p>
            <p>
                Neither party will make any public statement relating to these Terms or the business relationship
                between You and MediaAlpha, without the prior written approval of the other, provided that We may
                include Your name and logo on our marketing and promotional materials and customer lists.
            </p>
            <p>
                <strong>NO ASSIGNMENT</strong>
            </p>
            <p>
                You may not assign, sublicense, or transfer this Terms or any of Your rights or obligations hereunder to
                any third party, and any attempted assignment, sublicense, or transfer is hereby null and void.
            </p>
            <p>
                <strong>NO JOINT VENTURE</strong>
            </p>
            <p>
                These Terms create no partnership, joint venture, association, agency, franchise, contract of
                employment, or comparable relationship between You or Us, and neither party shall have the authority to
                bind the other party for any purpose not expressly set forth herein.
            </p>
            <p id="governing-law-and-venue">
                <strong>GOVERNING LAW AND VENUE</strong>
            </p>
            <p>
                This Terms, Our Website, the Leads, and Our Services will be governed by, construed under and enforced
                in accordance with the laws of the State of California, without regard to its conflict of laws rules or
                principles, and the prevailing party will be entitled to reasonable attorneys' fees and costs of suit,
                as well as all such fees associated with enforcing any judgment pursuant hereto. To the extent these
                Terms permit any action in a court of competent jurisdiction, then any lawsuit or action brought by the
                parties hereto shall be filed and adjudicated in the state and federal courts located in Los Angeles,
                California, which shall be the exclusive forum for any such dispute.
            </p>
            <p>
                <strong>INJUNCTIVE RELIEF</strong>
            </p>
            <p>
                Each party's obligations under the confidentiality and other provisions of these Terms are reasonable
                and essential to protect the disclosing party and its business. Due to the unique nature of the
                disclosing party's Confidential Information (including the Leads), monetary damages may be inadequate to
                compensate the disclosing party for any breach. Accordingly, except for equitable relief against Our
                Website, Our services, and any other products or services offered by MediaAlpha, the parties agree that
                any threatened or actual breach may cause irreparable harm to the other party and – in addition to any
                other remedies that may be available – the non-breaching party will be entitled to injunctive relief
                against the threatened or continued breach of the provisions of these Terms.
            </p>
            <p>
                <strong>NO WAIVER; SEVERABILITY; LIMITATIONS PERIOD</strong>
            </p>
            <p>
                No failure or delay by either party in exercising any of its rights, powers, or remedies under these
                Terms will operate as a waiver of that or any other right, power, or remedy. No waiver or modification
                of any term of these Terms will be effective unless in writing and signed by an officer of the party
                against whom such waiver or modification is sought to be enforced.
            </p>
            <p>
                If any provision of these Terms shall be held to be invalid, illegal, or unenforceable, the remaining
                provisions shall not in any way be affected or impaired thereby.
            </p>
            <p>
                Regardless of any statute or law to the contrary, any claim or cause of action arising out of or related
                to use of Our Website or Our services or these Terms must be filed within one (1) year after such claim
                or cause of action arose or be forever barred.
            </p>
            <p>
                <strong>CHANGES TO THESE TERMS</strong>
            </p>
            <p>
                MediaAlpha may amend these Terms from time to time by posting such amended Terms to Your Account and Our
                Website. MediaAlpha will post any updates to the Terms to Your Account and to the links provided in the
                footer of Our Website or such other replacement links as solely determined by MediaAlpha. You are
                responsible for periodically checking Your Account and the links for any updates to these Terms.
                MediaAlpha will use reasonable efforts to notify You by email (sent to the email You provide to us in
                Your Account), pop-up notices, and/or alerts (whether in Your Account or on Our Website) of any material
                changes to these Terms or links. All changes to these Terms are effective upon posting (unless otherwise
                stated therein). If for any reason You do not agree to any or all of the updated Terms and updates to
                them, You should stop using Our services and stop buying Leads or services from Us and You should not
                access Our Website. Your continued use of the Leads (including buying Leads), Our Website, and Our
                services constitutes your acceptance of such updated Terms of Use subject to Your right to terminate
                Your use of the Leads, Our Website, and Our services in accordance with these Terms. The then-current
                version of this Terms is always available for Your review via Your Account.
            </p>
            <p>
                <strong>ENTIRE AGREEMENT AND CONFLICTS</strong>
            </p>
            <p>
                These Terms, including all referenced or linked documents referenced in these Terms, represents the
                entire understanding and agreement of Agent and MediaAlpha with respect to the subject matter hereof and
                thereof. By signing and/or making an electronic affirmation of these Terms, Agent represents and
                warrants that any individual accepting these Terms on Your behalf is duly authorized to bind Agent to
                these Terms and acknowledges its acceptance of all terms and conditions of these Terms. By accepting
                Your initial deposit and providing Leads to Your Account, MediaAlpha indicates its acceptance of these
                Terms. To the extent that any referenced or linked document referenced in these Terms conflicts with
                these Terms, these Terms will control.
            </p>
            <p>
                Any term, provision, or condition in any purchase order, form, acknowledgement, online order form, or
                other agreement, or other documentation that may be provided by either party to these Terms to the other
                party which is in conflict with, or purports to add to or modify these Terms (including any response,
                bid proposal, or invoice), will not change, modify, or have any effect on these Terms and conditions
                contained in this Agreement and is rejected by You and MediaAlpha. Any such term, provision, or
                condition will not be binding upon MediaAlpha notwithstanding Our provision of or acceptance or
                acknowledgement of such documentation. The rights, duties, and obligations of the parties under these
                Terms are limited solely to those that are expressly set forth in these Terms. No rights, duties, or
                obligations may be read into these Terms or the relationship of the parties under any theory, such as
                custom, trade usage, course of dealing, course of performance, or implied contract.
            </p>
        </div>
    );
};

export { TermsContent };
