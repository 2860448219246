import { ComboBox } from 'carbon-components-react';
import states from 'data/us-states';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import axios from 'util/axios';
import { getAccountName } from './Agent';

const SubsidyAgentLookup = ({ carrier, namespace }) => {
    const { setValue } = useFormContext();

    const [query, setQuery] = useState('');
    const [items, setItems] = useState([]);
    const [item, setItem] = useState(null);

    useEffect(() => {
        query ? getItems(query, setItems, carrier) : setItems([]);
    }, [query]);

    const handleInputChange = (val) => {
        setQuery(val);
    };

    const handleChange = ({ selectedItem: item }) => {
        if (item) item.label = item.value.email;
        setItem(item);

        const {
            address = '',
            address_2 = '',
            carrier = 'State Farm',
            city = '',
            email = '',
            emailId = '',
            firstName = '',
            lastName = '',
            phoneNum = '',
            state = '',
            stcode = '',
            tier = '',
            zip = ''
        } = item?.value || {};

        const company = item ? `${firstName} ${lastName} State Farm` : ''; // Agency Name
        const stateName = item ? states.find((s) => s.value === state)?.label : '';
        const accountName = item ? `${getAccountName({ company, stateName })}` : '';
        const shouldValidate = Boolean(item);
        const shouldDirty = true;

        setValue(`${namespace}.accountName`, accountName, { shouldValidate, shouldDirty });
        setValue(`${namespace}.agencyAddress_2`, address_2, { shouldValidate, shouldDirty });
        setValue(`${namespace}.agencyAddress`, address, { shouldValidate, shouldDirty });
        setValue(`${namespace}.agencyCity`, city, { shouldValidate, shouldDirty });
        setValue(`${namespace}.agencyEmail`, email, { shouldValidate, shouldDirty });
        setValue(`${namespace}.agencyState`, state, { shouldDirty });
        setValue(`${namespace}.agencyZip`, zip, { shouldDirty });
        setValue(`${namespace}.carrier`, carrier, { shouldDirty });
        setValue(`${namespace}.company`, company, { shouldValidate, shouldDirty });
        setValue(`${namespace}.email`, email, { shouldValidate, shouldDirty }); // Prefill Agent Email
        setValue(`${namespace}.firstName`, firstName, { shouldDirty });
        setValue(`${namespace}.lastName`, lastName, { shouldDirty });
        setValue(`${namespace}.phone`, phoneNum, { shouldDirty });
        setValue(`${namespace}.stcode`, stcode, { shouldDirty });
        setValue(`${namespace}.subsidyId`, emailId, { shouldDirty });
        setValue(`${namespace}.subsidyTier`, tier, { shouldDirty });
    };

    return (
        <ComboBox
            id="agentLookup"
            titleText="STCODE or Email"
            items={items}
            selectedItem={item}
            onChange={handleChange}
            onInputChange={handleInputChange}
        />
    );
};

const getItems = debounce(async (query, setItems, carrier) => {
    try {
        const { data } = await axios.get('account.json?action=get_subsidy_agents', {
            params: {
                advertiser_id: carrier.subsidyAdvertiserId,
                q: query
            }
        });
        if (Array.isArray(data?.agents)) {
            setItems(
                data.agents.map((agent) => ({
                    label: `${agent.firstName} ${agent.lastName} (${agent.email})`,
                    value: agent
                }))
            );
        }
        else {
            setItems([]);
        }
    }
    catch (err) {
        setItems([]);
    }
}, 250);

export { SubsidyAgentLookup };
