import { Checkbox, Loading, PasswordInput } from 'carbon-components-react';
import clsx from 'clsx';
import PasswordRequirements, { regex } from 'components/common/PasswordRequirements';
import { motion } from 'framer-motion';
import useAuth from 'hooks/use-auth';
import useQueryParams from 'hooks/use-query-params';
import Layout from 'layouts/Centered';
import { useEffect, useState } from 'react';
import { Helmet as Head } from 'react-helmet-async';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styles from 'styles/pages/SetPassword.module.scss';
import axios from 'util/axios';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    password: Yup.string()
        .required('Please enter a new password')
        .min(8, 'Password must be at least 8 characters')
        .test('rules', 'Password requirements not met', function (val) {
            if (!val) return false;

            const { upperCase, lowerCase, number, special } = regex;
            if (val.length < 12) {
                return upperCase.test(val) && lowerCase.test(val) && number.test(val) && special.test(val);
            }
            else if (val.length < 16) {
                return upperCase.test(val) && lowerCase.test(val) && number.test(val);
            }
            else if (val.length < 20) {
                return upperCase.test(val) && lowerCase.test(val);
            }
            else {
                return true;
            }
        }),
    password2: Yup.string()
        .required('Please confirm your new password')
        .test('match', 'Passwords do not match', function (val) {
            return val === this.parent.password;
        }),
    terms: Yup.bool().oneOf([true], 'Please accept the Terms & Conditions')
});

const SetPassword = () => {
    const { setPassword: authSetPassword, loading, error } = useAuth();
    const { encrypted } = useQueryParams();

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [terms, setTerms] = useState(false);
    const [clientErrors, setClientErrors] = useState({});

    const [tokenLoading, setTokenLoading] = useState(true);
    const [tokenError, setTokenError] = useState(false);

    const attemptSetPassword = async (evt) => {
        evt.preventDefault();

        try {
            await validationSchema.validate(
                {
                    password,
                    password2,
                    terms
                },
                { abortEarly: false }
            );
            setClientErrors({});
        }
        catch (errors) {
            const all = errors.inner.reduce((acc, curr) => {
                if (acc[curr.path]) {
                    return { ...acc };
                }
                else {
                    return { ...acc, [curr.path]: curr.message };
                }
            }, {});
            setClientErrors(all);
            return;
        }

        authSetPassword({
            encrypted,
            password
        });
    };

    useEffect(() => {
        const validateToken = async (encrypted) => {
            try {
                const {
                    data: { isValid }
                } = await axios.post('/auth.json?action=validate_password_token', {
                    encrypted
                });
                setTokenError(!isValid);
            }
            catch (err) {
                setTokenError(true);
            }
            finally {
                setTokenLoading(false);
            }
        };

        if (!encrypted) {
            setTokenError(true);
            setTokenLoading(false);
        }
        else {
            validateToken(encrypted);
        }
    }, []);

    return (
        <>
            <Head>
                <title>Set Password</title>
            </Head>
            <Layout title="Set Password">
                <div className={styles.wrap}>
                    {tokenLoading || tokenError ? (
                        <div className={styles.form}>
                            {tokenLoading && (
                                <SkeletonTheme color="#eaeaea" highlightColor="#d9d9d9">
                                    <Skeleton width="100%" height={20} />
                                </SkeletonTheme>
                            )}
                            {tokenError && (
                                <div className={clsx(styles.error, styles.token)}>
                                    Activation link missing or expired. Please contact us to receive a new activation
                                    link:
                                    <br />
                                    <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a>
                                </div>
                            )}
                        </div>
                    ) : (
                        <motion.form className={styles.form} onSubmit={attemptSetPassword} {...formAnim} noValidate>
                            <PasswordInput
                                id="password"
                                labelText="New Password"
                                placeholder="Enter your new password"
                                autoFocus
                                autoComplete="new-password"
                                onChange={(evt) => setPassword(evt.target.value)}
                                disabled={loading}
                            />

                            {clientErrors.password && (
                                <motion.p className={styles.error} {...errorAnim}>
                                    {clientErrors.password}
                                </motion.p>
                            )}

                            <PasswordInput
                                id="password2"
                                labelText="Confirm New Password"
                                placeholder="Confirm your new password"
                                autoComplete="new-password"
                                onChange={(evt) => setPassword2(evt.target.value)}
                                disabled={loading}
                            />
                            {clientErrors.password2 && (
                                <motion.p className={styles.error} {...errorAnim}>
                                    {clientErrors.password2}
                                </motion.p>
                            )}
                            <PasswordRequirements password={password} password2={password2} />

                            <Checkbox
                                id="terms"
                                className={styles.terms}
                                labelText={
                                    <span className={styles.termsText}>
                                        By checking this box and selecting continue below, I confirm I have read,
                                        understand, and agree to MediaAlpha{' '}
                                        <a href="/terms" target="_blank">
                                            Updated Agent Terms
                                        </a>
                                        . Read our{' '}
                                        <a href="/privacy" target="_blank">
                                            Privacy Policy
                                        </a>{' '}
                                        to learn more about how we handle your information.
                                    </span>
                                }
                                checked={terms}
                                onChange={(checked) => setTerms(checked)}
                                disabled={loading}
                            />

                            {clientErrors.terms && (
                                <motion.p className={styles.error} {...errorAnim}>
                                    {clientErrors.terms}
                                </motion.p>
                            )}
                            {error && (
                                <motion.div {...errorAnim}>
                                    <div className={styles.error}>{error}</div>
                                </motion.div>
                            )}
                            <motion.button
                                type="submit"
                                className={styles.button}
                                style={{ marginTop: 20 }}
                                disabled={loading}
                                {...buttonAnim}
                            >
                                {loading ? (
                                    <Loading className={styles.loading} small withOverlay={false} />
                                ) : (
                                    'Continue'
                                )}
                            </motion.button>
                        </motion.form>
                    )}
                </div>
            </Layout>
        </>
    );
};

const formAnim = {
    initial: {
        opacity: 0,
        y: 20
    },
    animate: {
        opacity: 1,
        y: 0
    },
    transition: {
        ease: 'easeOut',
        duration: 1
    }
};

const errorAnim = {
    initial: {
        opacity: 0,
        y: 5
    },
    animate: {
        opacity: 1,
        y: 0
    },
    transition: {
        ease: 'easeOut',
        duration: 0.5
    }
};

const buttonAnim = {
    whileTap: { scale: 0.98 }
};

export default SetPassword;
