import { TooltipDefinition } from 'carbon-components-react';
import clsx from 'clsx';
import AAA from 'img/carriers/AAA.svg';
import Allstate from 'img/carriers/Allstate.svg';
import AmFam from 'img/carriers/AmFam.svg';
import Farmers from 'img/carriers/Farmers.svg';
import Independent from 'img/carriers/Indie.svg';
import LibertyMutual from 'img/carriers/LibertyMutual.svg';
import StateFarm from 'img/carriers/StateFarm.svg';
import { ReactComponent as Checkmark } from 'img/GreenCheckmark.svg';
import { useMemo } from 'react';
import styles from './CarrierLogos.module.scss';

const carriers = {
    2: AAA,
    4: Allstate,
    5: AmFam,
    17: Farmers,
    22: LibertyMutual,
    31: StateFarm
};

const CarrierLogos = ({ carriers }) => {
    if (carriers.length === 0) return null;

    return (
        <div className={styles.carrierLogos}>
            {carriers.map((carrier, i) => (
                <Badge {...carrier} key={`carrier${i}`} index={i} />
            ))}
        </div>
    );
};

const Badge = ({ carrier, carrierId, numContacted, index }) => {
    // return a matching carrier logo or Independent if no match
    const logo = useMemo(() => carriers[carrierId] || Independent, [carrierId]);

    return (
        <TooltipDefinition align="center" direction="top" tooltipText={carrier}>
            <div className={clsx(styles.badge, styles[`badge-${index}`])}>
                {numContacted > 0 && <Checkmark className={styles.checkmark} />}
                <img src={logo} alt={`${carrier} logo`} />
            </div>
        </TooltipDefinition>
    );
};

export { CarrierLogos };
