import clsx from 'clsx';
import { LeadDispositionModal } from 'components/leads';
import { dispositionLabels } from 'components/leads/LeadDisposition';
import { useState } from 'react';
import styles from './LeadStatusBadge.module.scss';

const LeadStatusBadge = ({ rowData, updateRowData, ...rest }) => {
    const { status } = rowData;
    const [modal, setModal] = useState(null);
    const label = dispositionLabels[status];

    return (
        <>
            {Boolean(modal) && (
                <LeadDispositionModal {...modal} setUpdatedLeads={updateRowData} onClose={() => setModal(null)} />
            )}
            <div
                className={clsx(styles.statusBadge, styles[status])}
                onClick={() => {
                    setModal({
                        modalHeading: 'Lead Status',
                        rows: [rowData],
                        type: 'status'
                    });
                }}
                {...rest}
                data-suppress-navigation
            >
                {label}
            </div>
        </>
    );
};

export default LeadStatusBadge;
