import { Add16 } from '@carbon/icons-react';
import {
    Button,
    InlineLoading,
    InlineNotification,
    Modal,
    RadioButton,
    Select,
    SelectItem,
    TextInput,
    ToastNotification
} from 'carbon-components-react';
import clsx from 'clsx';
import { PageSection } from 'components/common';
import { AddPaymentMethod } from 'components/deposits';
import { FatalError } from 'components/errors';
import useAccount, { statuses } from 'hooks/use-account';
import useApi from 'hooks/use-api';
import useUser from 'hooks/use-user';
import IMask from 'imask';
import depositIcon from 'img/deposits.svg';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'util/axios';
import { dollarAmount } from 'util/format-number';
import styles from './AccountDeposits.module.scss';

// MIN and MAX amounts for both Auto-Refill and One-time Deposits.
const REFILL_MIN_AMOUNT = '100.00';
const DEPOSIT_MIN_AMOUNT = '250.00';
const MAX_AMOUNT = '5000.00';
const REFILL_THRESHOLD = '50.00';

const AccountDeposits = () => {
    const { id: userId, employee } = useUser();
    const { id: accountId, features, status, setAccount, canManageAccount } = useAccount();
    const [result, loading, error] = useApi(`deposit.json?action=select&id=${accountId}`);
    const history = useHistory();

    const [paymentType, setPaymentType] = useState('');
    const [confirmationModal, setConfirmationModal] = useState(false);

    const [refill, setRefill] = useState({});
    const [refillEnabled, setRefillEnabled] = useState('');

    const [depositAmount, setDepositAmount] = useState(DEPOSIT_MIN_AMOUNT);
    const [refillAmount, setRefillAmount] = useState(REFILL_MIN_AMOUNT);
    const [initialDepositAmount, setInitialDepositAmount] = useState(DEPOSIT_MIN_AMOUNT);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentMethods, setPaymentMethods] = useState([]);

    const [showAddPayment, setShowAddPayment] = useState(false);
    const [addPaymentToast, setAddPaymentToast] = useState(null);

    const [submitStatus, setSubmitStatus] = useState(submitStatuses.IDLE);
    const [redirectOnSuccess, setRedirectOnSuccess] = useState(false);

    const isPending = status === statuses.PENDING || status === statuses.UNSOLD;
    const isSubmitting = submitStatus !== submitStatuses.IDLE;
    const hasPaymentMethods = Boolean(paymentMethods?.length);

    const depositError = validateAmount(paymentTypes.DEPOSIT, depositAmount);
    const refillError = validateAmount(paymentTypes.REFILL, refillAmount);
    const initialDepositError = validateAmount(paymentTypes.DEPOSIT, initialDepositAmount);

    const paymentMethodError = Boolean(paymentMethods.find((method) => method.paymentMethodId === paymentMethod))
        ? false
        : 'Please select a payment method.';

    const formValid =
        paymentType === paymentTypes.REFILL
            ? isPending
                ? !refillError && !paymentMethodError && !initialDepositError
                : !refillError && !paymentMethodError
            : paymentType === paymentTypes.DEPOSIT
                ? !depositError && !paymentMethodError
                : false;

    const submit = async () => {
        if (employee || !formValid) return;

        setSubmitStatus(submitStatuses.ACTIVE);

        try {
            if (paymentType === paymentTypes.DEPOSIT) {
                const { data } = await axios.post(`deposit.json?action=make_deposit&id=${accountId}`, {
                    amount: depositAmount,
                    payment_method_id: paymentMethod
                });

                setAccount(data);
                setRedirectOnSuccess(isPending);
            }
            else if (paymentType === paymentTypes.REFILL) {
                const { data } = await axios.post(`deposit.json?action=update_auto_refill&id=${accountId}`, {
                    status: 'enabled',
                    amount: refillAmount,
                    payment_method_id: paymentMethod,
                    ...(isPending && { deposit_amount: initialDepositAmount })
                });

                setAccount(data);
                setRefillEnabled(true);
                setRedirectOnSuccess(isPending);
            }

            setSubmitStatus(submitStatuses.FINISHED);
        }
        catch (err) {
            setSubmitStatus(submitStatuses.ERROR);
        }
    };

    const disableAutoRefill = async () => {
        try {
            await axios.post(`deposit.json?action=update_auto_refill&id=${accountId}`, {
                status: 'paused'
            });

            setPaymentType(paymentTypes.DEPOSIT);
            setRefillEnabled(false);
        }
        catch (err) {}
    };

    const onAddPaymentSuccess = (newPaymentMethod) => {
        setShowAddPayment(false);
        setPaymentMethods([newPaymentMethod]);
        setAddPaymentToast({ type: 'success', caption: 'Payment method successfully added.' });
    }

    const onAddPaymentError = (message) => {
        setShowAddPayment(false);
        setAddPaymentToast({ type: 'error', caption: message })
    }

    useEffect(() => {
        if (!result) return;

        setPaymentType(result.paymentType || '');
        setPaymentMethods(result.paymentMethods || []);
        setRefill(result.refill || {});
        setRefillAmount(result.refill?.amount || REFILL_MIN_AMOUNT);
        setRefillEnabled(result.paymentType === paymentTypes.REFILL);
    }, [result]);

    useEffect(() => {
        if (paymentType === paymentTypes.REFILL) {
            const method =
                paymentMethods.find((method) => method.isAutoRefillPaymentMethod) ||
                paymentMethods.find((method) => method.defaultPaymentMethod);

            setPaymentMethod(method?.paymentMethodId || '');
        }
        else if (paymentType === paymentTypes.DEPOSIT) {
            setPaymentMethod(paymentMethods.find((method) => method.defaultPaymentMethod)?.paymentMethodId || '');
        }
    }, [paymentType]);

    useEffect(() => {
        let id;

        if ([submitStatuses.FINISHED, submitStatuses.ERROR].includes(submitStatus)) {
            id = window.setTimeout(() => setSubmitStatus(submitStatuses.IDLE), 2000);
        }

        return () => id && clearTimeout(id);
    }, [submitStatus]);

    useEffect(() => {
        if (redirectOnSuccess) window.setTimeout(() => history.push('/p/leads'), 750);
    }, [redirectOnSuccess]);

    if (loading) {
        return <PageSection skeleton />;
    }

    if (error) {
        return <FatalError error={error} />;
    }

    return (
        <>
            {confirmationModal && (
                <Modal
                    open={true}
                    modalHeading="Disable Auto-Refill"
                    primaryButtonText="Continue"
                    secondaryButtonText="Cancel"
                    onRequestClose={() => {
                        setPaymentType(paymentTypes.REFILL);
                        setConfirmationModal(false);
                    }}
                    onRequestSubmit={() => {
                        disableAutoRefill();
                        setConfirmationModal(false);
                    }}
                    className={styles.modal}
                >
                    <p>
                        Switching to <em>One-Time Deposit</em> will <strong>disable Auto-Refill</strong> for this
                        account.
                    </p>
                    <p>
                        To re-enable Auto-Refill, select the <em>Auto-Refill</em> option, and submit the form with a
                        refill amount and a chosen payment method.
                    </p>
                </Modal>
            )}

            {addPaymentToast && (
                <ToastNotification
                    kind={addPaymentToast.type}
                    onCloseButtonClick={() => setAddPaymentToast(null)}
                    onClose={() => setAddPaymentToast(null)}
                    title="Add Payment Method"
                    lowContrast
                    iconDescription="Close"
                    statusIconDescription={addPaymentToast.type === 'error' ? 'Error' : 'Success'}
                    caption={addPaymentToast.caption}
                    timeout={4000}
                />
            )}  
            
            {isPending && (
                <InlineNotification
                    kind="info"
                    title=""
                    subtitle="Please enter your payment information to fund and activate your account."
                    hideCloseButton
                    lowContrast
                    style={{ maxWidth: 'none' }}
                />
            )}
            <PageSection name="Deposits" icon={depositIcon} className={styles.accountDeposits}>
                {showAddPayment && (
                    <AddPaymentMethod 
                        onClose={() => setShowAddPayment(false)}
                        onSuccess={onAddPaymentSuccess}
                        onError={onAddPaymentError}
                    />
                )}
                {!!features.oneTimeDeposit && (
                    <>
                        <h4>Payment Type</h4>
                        <div className="bx--row">
                            <div className="bx--col-lg-6">
                                <fieldset
                                    className={styles.radioButtonGroup}
                                    disabled={isSubmitting || !canManageAccount}
                                >
                                    <RadioButton
                                        labelText="Auto-Refill"
                                        value={paymentTypes.REFILL}
                                        checked={paymentType === paymentTypes.REFILL}
                                        onClick={() => setPaymentType(paymentTypes.REFILL)}
                                        disabled={employee}
                                    />
                                    <RadioButton
                                        labelText="One-Time Deposit"
                                        value={paymentTypes.DEPOSIT}
                                        checked={paymentType === paymentTypes.DEPOSIT}
                                        onClick={() =>
                                            refillEnabled
                                                ? setConfirmationModal(true)
                                                : setPaymentType(paymentTypes.DEPOSIT)
                                        }
                                    />
                                </fieldset>
                            </div>
                        </div>
                    </>
                )}
                {paymentType === paymentTypes.REFILL && (
                    <>
                        <h4>Auto-Refill</h4>
                        {isPending && (
                            <div className="bx--row">
                                <div className={`bx--col-lg-2 ${styles.currency}`}>
                                    <TextInput
                                        id="initialDepositAmount"
                                        name="initialDepositAmount"
                                        labelText="Initial Deposit Amount"
                                        defaultValue={initialDepositAmount}
                                        onChange={(evt) => {
                                            const formatted = amountMask(evt.target.value);
                                            evt.target.value = formatted;
                                            setInitialDepositAmount(formatted.replaceAll(',', ''));
                                        }}
                                        onBlur={(evt) => {
                                            const formatted = paddedAmountMask(evt.target.value);
                                            evt.target.value = formatted;
                                            setInitialDepositAmount(formatted.replaceAll(',', ''));
                                        }}
                                        invalid={!!initialDepositError}
                                        invalidText={initialDepositError}
                                        disabled={employee || isSubmitting}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="bx--row">
                            <div className={`bx--col-lg-2 ${styles.currency}`}>
                                <TextInput
                                    id="refillAmount"
                                    name="refillAmount"
                                    labelText="Refill Amount"
                                    defaultValue={refillAmount}
                                    onChange={(evt) => {
                                        const formatted = amountMask(evt.target.value);
                                        evt.target.value = formatted;
                                        setRefillAmount(formatted.replaceAll(',', ''));
                                    }}
                                    onBlur={(evt) => {
                                        const formatted = paddedAmountMask(evt.target.value);
                                        evt.target.value = formatted;
                                        setRefillAmount(formatted.replaceAll(',', ''));
                                    }}
                                    helperText={`When your balance drops below ${dollarAmount(
                                        refill.threshold ?? REFILL_THRESHOLD
                                    )}, your selected payment method will automatically be charged this amount.`}
                                    invalid={!!refillError}
                                    invalidText={refillError}
                                    disabled={employee || isSubmitting}
                                />
                            </div>
                        </div>
                    </>
                )}
                {paymentType === paymentTypes.DEPOSIT && (
                    <>
                        <h4>One-Time Deposit</h4>
                        <div className="bx--row">
                            <div className={`bx--col-lg-2 ${styles.currency}`}>
                                <TextInput
                                    id="depositAmount"
                                    name="depositAmount"
                                    labelText="Amount"
                                    defaultValue={depositAmount}
                                    onChange={(evt) => {
                                        const formatted = amountMask(evt.target.value);
                                        evt.target.value = formatted;
                                        setDepositAmount(formatted.replaceAll(',', ''));
                                    }}
                                    onBlur={(evt) => {
                                        const formatted = paddedAmountMask(evt.target.value);
                                        evt.target.value = formatted;
                                        setDepositAmount(formatted.replaceAll(',', ''));
                                    }}
                                    invalid={!!depositError}
                                    invalidText={depositError}
                                    disabled={employee || isSubmitting}
                                />
                            </div>
                        </div>
                    </>
                )}
                {hasPaymentMethods ? (
                    <>
                        <div className="bx--row">
                            <div className="bx--col-md-6 bx--col-lg-5">
                                <Select
                                    id="paymentMethod"
                                    name="paymentMethod"
                                    labelText="Payment Method"
                                    value={paymentMethod}
                                    onChange={(evt) => setPaymentMethod(evt.target.value)}
                                    invalid={!!paymentMethodError}
                                    invalidText={paymentMethodError}
                                    disabled={employee || isSubmitting}
                                    helperText={
                                        <span>
                                            Please visit the <Link to="/p/profile">My Profile</Link> page to add or
                                            remove payment methods.
                                        </span>
                                    }
                                >
                                    <SelectItem disabled value="" text="Payment Method" />
                                    {paymentMethods.length > 0 &&
                                        paymentMethods.map((paymentMethod) => (
                                            <SelectItem
                                                key={paymentMethod.paymentMethodId}
                                                value={paymentMethod.paymentMethodId}
                                                text={`${paymentMethod.card} (Added by ${paymentMethod.ownerName})`}
                                                disabled={String(paymentMethod.adminId) !== String(userId)}
                                            />
                                        ))}
                                </Select>
                            </div>
                        </div>
                        <div className={clsx('bx--row', styles.submit)}>
                            <div className="bx--col-md-6 bx--col-lg-5">
                                <Button
                                    renderIcon={Add16}
                                    iconDescription="Submit"
                                    onClick={submit}
                                    disabled={!formValid || employee || isSubmitting}
                                >
                                    {getButtonText(refillEnabled, paymentType, isPending)}
                                </Button>
                                {submitStatus && (
                                    <InlineLoading
                                        status={submitStatus}
                                        description={getSaveDescription(submitStatus, paymentType, isPending)}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="bx--row">
                        <div className="bx--col-md-6 bx--col-lg-5">
                            <div className="bx--label">Payment Methods</div>
                            <div>
                                <Button
                                    renderIcon={Add16}
                                    iconDescription="Add Payment Method"
                                    onClick={() => setShowAddPayment(true)}
                                    disabled={employee}
                                    style={{ marginTop: 5 }}
                                >
                                    Add Payment Method
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </PageSection>
        </>
    );
};

const paymentTypes = {
    REFILL: 'refill',
    DEPOSIT: 'deposit'
};

const submitStatuses = {
    IDLE: '',
    ACTIVE: 'active',
    ERROR: 'error',
    FINISHED: 'finished'
};

const amountMask = IMask.createPipe({
    mask: Number,
    scale: 2,
    thousandsSeparator: ',',
    signed: false,
    padFractionalZeros: false,
    normalizeZeros: false,
    radix: '.',
    max: MAX_AMOUNT
});

const paddedAmountMask = IMask.createPipe({
    mask: Number,
    scale: 2,
    thousandsSeparator: ',',
    signed: false,
    padFractionalZeros: true,
    normalizeZeros: true,
    radix: '.',
    max: MAX_AMOUNT
});

const validateAmount = (type, amount) => {
    let min_amount = DEPOSIT_MIN_AMOUNT;
    if (type === paymentTypes.REFILL) {
        min_amount = REFILL_MIN_AMOUNT;
    }
    return Number(amount) < Number(min_amount)
        ? `Please enter an amount of at least $${min_amount}`
        : Number(amount) > Number(MAX_AMOUNT)
            ? `Please enter an amount below $${MAX_AMOUNT}`
            : false;
};

const getButtonText = (refillEnabled, paymentType, isPending) => {
    if (isPending) return 'Make Deposit & Activate Account';

    if (paymentType === paymentTypes.REFILL) {
        return refillEnabled ? 'Update Auto-Refill Settings' : 'Enable Auto-Refill';
    }
    else if (paymentType === paymentTypes.DEPOSIT) {
        return 'Make a Deposit';
    }
};

const getSaveDescription = (submitStatus, paymentType, isPending) => {
    if (submitStatus === submitStatuses.IDLE) return '';

    if (isPending || paymentType === paymentTypes.DEPOSIT) {
        return submitStatus === submitStatuses.ACTIVE
            ? 'Making Deposit...'
            : submitStatus === submitStatuses.ERROR
                ? 'Error, please try again...'
                : 'Deposit Successful';
    }
    else if (paymentType === paymentTypes.REFILL) {
        return submitStatus === submitStatuses.ACTIVE
            ? 'Updating...'
            : submitStatus === submitStatuses.ERROR
                ? 'Error, please try again...'
                : 'Success';
    }
};

export default AccountDeposits;
