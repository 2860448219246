/* eslint-disable */

const phonePattern =
    /^(20[1-9]|21[02-9]|22[03-689]|23[1469]|24[089]|25[0-46]|26[0234789]|27[02469]|28[1349]|30[1-9]|31[02-9]|32[013569]|33[0124679]|34[013567]|35[124]|36[0134578]|38[02567]|40[1-9]|41[02-9]|42[3458]|43[0124578]|44[0-3578]|45[08]|46[3489]|47[034589]|48[04]|50[1-9]|51[02-9]|52[0]|53[0149]|54[018]|55[179]|56[1-47]|57[0-59]|58[0124-7]|60[1-9]|61[02-9]|62[03689]|63[0169]|64[01679]|65[01679]|66[012479]|67[01289]|68[0-49]|70[1-9]|71[02-9]|72[014-7]|73[01247]|74[0237]|75[3478]|76[02-579]|77[0-589]|78[0124-7]|80[1-9]|81[02-9]|82[05689]|83[012589]|84[0345789]|85[046-9]|86[01-5789]|87[023689]|90[1-9]|91[02-9]|92[05789]|93[014-9]|94[01357-9]|95[12469]|97[0-3589]|98[03-69])([1-9](?!11)\d\d\d\d\d\d)$/;

const emailPattern =
    /^\s*[a-z0-9!#$%&'*+\/=?^_\`{|}~-]+(\.[a-z0-9!#\$%&'*+\/=?^_\`{|}~-]+)*@([a-z0-9]([a-z0-9-]*[a-z0-9])?\.)+([a-z]+)\s*$/i;

const trackingUrlPattern =
    /^(?:lead:\w+(?:\?(?!\bmail_to=)[^;&\s]+=(?:[^;&]+|)(?:&(?!\bmail_to\=)[^;&\s]+=(?:[^;&=]+|))*)?;?)$/;

export { phonePattern, emailPattern, trackingUrlPattern };
