import clsx from 'clsx';
import { AccountSwitcher } from 'components/accounts';
import { MediaAlphaLogo, Sidebar } from 'components/layout';
import { MobileMenu } from 'components/mobile';
import { Avatar } from 'components/profiles';
import { motion } from 'framer-motion';
import useAccount from 'hooks/use-account';
import useDevice from 'hooks/use-device';
import useUser from 'hooks/use-user';
import alpha from 'img/alpha.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from 'styles/layouts/Platform.module.scss';
import { localStorage as ls } from 'util/storage-helpers';

const Layout = ({ children }) => {
    const { mobile } = useDevice();

    return mobile ? <MobileLayout>{children}</MobileLayout> : <DesktopLayout>{children}</DesktopLayout>;
};

const DesktopLayout = ({ children }) => {
    const STORAGE_KEY = 'sidebarCollapsed';

    const { userPrefsKey: USER_PREFS } = useUser();
    const { account } = useAccount();

    const [sidebarCollapsed, setCollapsed] = useState(
        ls.get({
            key: STORAGE_KEY,
            parent: USER_PREFS
        })
    );

    const onCollapseToggle = () => (sidebarCollapsed ? handleExpand() : handleCollapse());
    const handleExpand = () => {
        setCollapsed(false);
        ls.remove({
            key: STORAGE_KEY,
            parent: USER_PREFS
        });
    };
    const handleCollapse = () => {
        setCollapsed(true);
        ls.set({
            key: STORAGE_KEY,
            value: true,
            parent: USER_PREFS
        });
    };

    const showCollapsedSidebar = account && sidebarCollapsed;
    const hideSidebar = !account;

    return (
        <div
            className={clsx(
                styles.desktop,
                showCollapsedSidebar && styles.sidebarCollapsed,
                hideSidebar && styles.noSidebar
            )}
        >
            <header className={styles.header}>
                <Link to="/p" className={styles.logo}>
                    {showCollapsedSidebar ? (
                        <img src={alpha} className={styles.alpha} alt="MediaAlpha" />
                    ) : (
                        <MediaAlphaLogo
                            width="100%"
                            fill={hideSidebar ? styles.orange : styles.white}
                            className={styles.mediaAlpha}
                        />
                    )}
                </Link>
                <div className={styles.right}>
                    <AccountSwitcher />
                    <Avatar />
                </div>
            </header>
            {account && <Sidebar collapsed={sidebarCollapsed} onCollapseToggle={onCollapseToggle} />}
            <main className={styles.main}>{children}</main>
        </div>
    );
};

const MobileLayout = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const onMenuToggle = () => setMenuOpen(!menuOpen);

    return (
        <div className={styles.mobile}>
            <header className={styles.header}>
                <MenuToggle className={styles.menuToggle} open={menuOpen} onClick={onMenuToggle} />
                <Link to="/p">
                    <img src={alpha} className={styles.alpha} alt="MediaAlpha" />
                </Link>
                {!menuOpen && <Avatar />}
            </header>
            <main className={styles.main}>{children}</main>
            {menuOpen && <MobileMenu onMenuToggle={onMenuToggle} />}
        </div>
    );
};

const MenuToggle = ({ open, ...rest }) => {
    return (
        <motion.div animate={open ? 'open' : 'closed'} variants={container} initial="closed" {...rest}>
            <motion.span variants={top}></motion.span>
            <motion.span variants={bottom}></motion.span>
        </motion.div>
    );
};

const container = {
    closed: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        transition: {
            duration: 0.1
        }
    },
    open: {
        backgroundColor: 'rgba(0, 0, 0, 1)',
        transition: {
            duration: 0.1
        }
    }
};

const top = {
    closed: {
        y: -4,
        rotate: 0
    },
    open: {
        y: 0,
        rotate: -45
    }
};

const bottom = {
    closed: {
        y: 4,
        rotate: 0
    },
    open: {
        y: 0,
        rotate: 45
    }
};

export default Layout;
