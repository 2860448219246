import { InlineLoading, TableToolbarContent, TableToolbarSearch } from 'carbon-components-react';
import { DateFilter, TableFilters } from 'components/tables';
import useDevice from 'hooks/use-device';
import useQueryParams from 'hooks/use-query-params';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import styles from './SearchToolbar.module.scss';

const SearchToolbar = ({
    dateRangeOptions,
    filterOptions,
    filterValues,
    filters,
    firstRowIndex,
    initialDates,
    onInputChange,
    record,
    rows,
    setFirstRowIndex,
    showDateFilters,
    showLoading
}) => {
    const { desktop } = useDevice();
    const { updateQueryParam, query } = useQueryParams();
    const search = query.get('search');
    const placeholder = `Search ${rows.length} ${rows.length > 1 || rows.length === 0 ? `${record}s` : record}...`;
    const showTableFilters = !isEmpty(filters);

    const onChange = debounce((evt) => {
        const { value } = evt;

        // Show page 1 results when search/clear
        if (firstRowIndex) setFirstRowIndex(0);

        // Handles clear search field click
        if (Boolean(evt) && !value) {
            return onInputChange(evt, '');
        }

        // Handles search
        onInputChange(evt, search);
    }, 800);

    return (
        <TableToolbarContent>
            <TableToolbarSearch
                defaultValue={search}
                expanded={true}
                onChange={onChange}
                onBlur={({ target }) => {
                    updateQueryParam('search', target.value, { replace: true });
                }}
                placeholder={placeholder}
            />
            {showLoading && <InlineLoading status="active" className={styles.toolbarLoading} />}
            {showDateFilters && desktop && (
                <DateFilter initialDates={initialDates} dateRangeOptions={dateRangeOptions} />
            )}
            {showTableFilters && (
                <TableFilters filters={filters} filterValues={filterValues} filterItems={filterOptions} />
            )}
        </TableToolbarContent>
    );
};

export default SearchToolbar;
