import OpportunitiesFilterContext from 'context/opportunities';
import { subDays } from 'date-fns';
import isEqual from 'lodash/isEqual';
import { useContext, useState } from 'react';
import { dateToString } from 'util/format-date';

const useOpportunitiesFilters = () => useContext(OpportunitiesFilterContext);

const initialGeoFilterValues = {
    coverageArea: 'state',
    radius: 100,
    radiusZipCode: ''
};

const initialConsumerFilterValues = {
    age: {
        from: 'any',
        to: 'any'
    },
    continuousCoverage: 'any',
    creditRating: ['Excellent', 'Good', 'Average', 'Below average', 'Poor'],
    numAccidentsAtFault: {
        from: 'any',
        to: 'any'
    },
    numTickets: {
        from: 'any',
        to: 'any'
    },
    licenseActive: 'any',
    dui: 'any',
    sr_22: 'any',
    homeOwnership: 'any',
    numCars: {
        from: 'any',
        to: 'any'
    }
};

const initialHistoryFilterValues = {
    sharedWithAgents: {
        from: 0,
        to: 3
    },
    myCalls: {
        from: 0,
        to: 3
    },
    dateFrom: dateToString(subDays(new Date(), 30)),
    dateTo: dateToString(new Date())
};

const filterTypes = {
    GEO: 'geo',
    CONSUMER: 'consumer',
    HISTORY: 'history'
};

const filterOperations = {
    FILTERS_APPLIED: 'filtersApplied',
    FILTERS_RESET: 'filtersReset'
};

const OpportunitiesFilterProvider = ({ children }) => {
    const [geoFilter, setGeoFilter] = useState(initialGeoFilterValues);
    const [consumerFilter, setConsumerFilter] = useState(initialConsumerFilterValues);
    const [historyFilter, setHistoryFilter] = useState(initialHistoryFilterValues);
    const [lastOperation, setLastOperation] = useState('');

    const { GEO, CONSUMER, HISTORY } = filterTypes;
    const leadFeedFilters = { [GEO]: geoFilter, [CONSUMER]: consumerFilter, [HISTORY]: historyFilter };
    const initialLeadFeedFilters = {
        [GEO]: initialGeoFilterValues,
        [CONSUMER]: initialConsumerFilterValues,
        [HISTORY]: initialHistoryFilterValues
    };

    const resetFilters = () => {
        setLastOperation(filterOperations.FILTERS_RESET);
        setGeoFilter(initialGeoFilterValues);
        setConsumerFilter(initialConsumerFilterValues);
        setHistoryFilter(initialHistoryFilterValues);
    };

    const customSetGeoFilter = (geoFilter) => {
        setLastOperation(filterOperations.FILTERS_APPLIED);
        setGeoFilter(geoFilter);
    };

    const customSetConsumerFilter = (consumerFilter) => {
        setLastOperation(filterOperations.FILTERS_APPLIED);
        setConsumerFilter(consumerFilter);
    };

    const customSetHistoryFilter = (historyFilter) => {
        setLastOperation(filterOperations.FILTERS_APPLIED);
        setHistoryFilter(historyFilter);
    };

    const filtersApplied = !isEqual(initialLeadFeedFilters, leadFeedFilters);

    const value = {
        geoFilter,
        setGeoFilter: customSetGeoFilter,
        consumerFilter,
        setConsumerFilter: customSetConsumerFilter,
        historyFilter,
        setHistoryFilter: customSetHistoryFilter,
        leadFeedFilters,
        resetFilters,
        lastOperation,
        filtersApplied
    };

    return <OpportunitiesFilterContext.Provider value={value}>{children}</OpportunitiesFilterContext.Provider>;
};

export { useOpportunitiesFilters as default, OpportunitiesFilterProvider, filterOperations };
