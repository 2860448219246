import { Add16, Subtract16 } from '@carbon/icons-react';
import { Button, FormGroup, TextInput } from 'carbon-components-react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { currencyInput } from 'util/validate-form';
import styles from './BidField.module.scss';

const BidField = ({ namespace, disabled, isSubsidy, campaignKey, minBid }) => {
    const {
        register,
        formState: { errors },
        getValues,
        setValue
    } = useFormContext();
    const name = `${namespace}.${campaignKey}.bid`;
    const [root, product, section] = namespace.split('.');
    const campaignErrors = errors[root]?.[product]?.[section] || {};
    const currentCampaign = getValues(`${namespace}.${campaignKey}`);
    const currentBid = Number(getValues(name));

    // Field with custom onChange and onBlur events
    const bid = register(name, {
        validate: (val) => {
            // only validate if the bid is enabled
            if (!currentCampaign?.enabled) return true;

            if (val === '') return `Bid is required.`;

            if (val < minBid) return `Min Bid: $${minBid.toFixed(2)}`;

            return true;
        }
    });

    if (!currentCampaign) {
        return null;
    }

    const getInvalidState = () => {
        if (campaignErrors[campaignKey]) {
            return !!campaignErrors[campaignKey]?.bid;
        }
        return false;
    };

    const getInvalidMessage = () => {
        if (campaignErrors[campaignKey]) {
            return campaignErrors[campaignKey]?.bid?.message;
        }
        return '';
    };

    const updateBidValue = (updateAction) => {
        const nextBid = updateAction === BID_ACTION.INCREASE ? currentBid + 0.25 : currentBid - 0.25;
        setValue(name, nextBid.toFixed(2), { shouldDirty: true, shouldValidate: true });
    };

    return (
        <FormGroup
            className={styles.fieldset}
            disabled={!currentCampaign.enabled}
            legendText={`${currentCampaign.campaign} bid amount`}
        >
            {isSubsidy && (
                <Button
                    className={clsx(styles.bidActionButton, styles['bidActionButton--decrement'])}
                    hasIconOnly
                    iconDescription="Decrease bid $0.25"
                    disabled={currentBid <= minBid || disabled}
                    onClick={() => updateBidValue(BID_ACTION.DECREASE)}
                    renderIcon={Subtract16}
                />
            )}
            <TextInput
                className={styles.bidInput}
                id={name}
                name={bid.name}
                ref={bid.ref}
                labelText={''}
                defaultValue={currentCampaign.bid}
                onChange={(evt) => {
                    bid.onChange(evt);
                    const { value } = evt.target;
                    evt.target.value = currencyInput(value);
                }}
                onBlur={(evt) => {
                    bid.onBlur(evt);
                    setValue(name, evt.target.value ? Number(evt.target.value).toFixed(2) : '', {
                        shouldDirty: true
                    });
                }}
                disabled={disabled}
                readOnly={isSubsidy}
                invalid={getInvalidState()}
                invalidText={getInvalidMessage()}
            />
            {isSubsidy && (
                <Button
                    disabled={disabled}
                    className={clsx(styles.bidActionButton, styles['bidActionButton--increment'])}
                    hasIconOnly
                    iconDescription="Increase bid by $0.25"
                    onClick={() => updateBidValue(BID_ACTION.INCREASE)}
                    renderIcon={Add16}
                />
            )}
        </FormGroup>
    );
};

// Min bid constants
const AUTO_AND_HEALTH_MIN_BID = 1; //Non subsidy min bid for auto & health
const HOME_PREMIER_MIN_BID = 14;
const HOME_CHOICE_MIN_BID = 12;
const HOME_SELECT_MIN_BID = 10;
const LIFE_TERM_WHOLE_LIFE_MIN_BID = 16;
const LIFE_FINAL_EXPENSE_MIN_BID = 12;
const LIFE_ANY_SOURCE_MIN_BID = 10;
const AUTO_SF_PREMIUM_MIN_BID = 11;
const AUTO_SF_PREFERRED_MIN_BID = 10;

const minBids = {
    home_premier: HOME_PREMIER_MIN_BID,
    home_choice: HOME_CHOICE_MIN_BID,
    home_select: HOME_SELECT_MIN_BID,
    'term/whole_life': LIFE_TERM_WHOLE_LIFE_MIN_BID,
    final_expense: LIFE_FINAL_EXPENSE_MIN_BID,
    term_whole_life: LIFE_TERM_WHOLE_LIFE_MIN_BID,
    any: LIFE_ANY_SOURCE_MIN_BID,
    premium_sf_subsidy: AUTO_SF_PREMIUM_MIN_BID,
    preferred_sf_subsidy: AUTO_SF_PREFERRED_MIN_BID,
    home_premier_sf_subsidy: HOME_PREMIER_MIN_BID,
    home_choice_sf_subsidy: HOME_CHOICE_MIN_BID
};

// key will be shortName (default), channel (health), or channelType (life).
const getMinBid = (key) => minBids[key] || AUTO_AND_HEALTH_MIN_BID;

const BID_ACTION = {
    INCREASE: 'increase',
    DECREASE: 'decrease'
};

export { BidField, getMinBid };
