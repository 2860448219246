import { CircleFillGlyph, PauseFilled16, Restart16 } from '@carbon/icons-react';
import { Modal, ToastNotification, TooltipIcon } from 'carbon-components-react';
import { motion } from 'framer-motion';
import { statuses } from 'hooks/use-account';
import useOutsideClickToClose from 'hooks/use-outside-click-to-close';
import { useRef, useState } from 'react';
import axios from 'util/axios';
import { formatError } from 'util/format-error';
import { capitalizeFirstLetter } from 'util/format-text';
import styles from './AccountStatus.module.scss';

const AccountStatus = ({ onClick, status, withTooltip, withStatusText }) => {
    const displayStatus = status.replace('_', ' ');
    const tooltipStatus = displayStatus.toUpperCase();
    const hasClickHandler = Boolean(onClick) && ![statuses.PENDING, statuses.TEST, statuses.PENDING].includes(status);

    const StatusText = () => <span className={styles.statusText}>{displayStatus}</span>;
    const StatusIcon = () => (
        <span className={styles.statusIcon}>
            {status === statuses.PAUSED ? (
                <PauseFilled16 className={styles[status]} />
            ) : (
                <CircleFillGlyph className={styles[status]} />
            )}
            {withStatusText && <StatusText />}
        </span>
    );

    const tooltipProps = {
        tooltipText: tooltipStatus,
        align: 'center',
        direction: 'top'
    };

    if (hasClickHandler) {
        tooltipProps.onClick = onClick;
    }

    return withTooltip ? (
        <TooltipIcon {...tooltipProps}>
            <StatusIcon />
        </TooltipIcon>
    ) : (
        <StatusIcon />
    );
};

const AccountStatusMenu = ({ id, setAccounts, status }) => {
    const ref = useRef();
    const [menuOpen, setMenuOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const options = [statuses.ENABLED, statuses.PAUSED, statuses.CANCELED].filter((opt) => opt !== status);

    useOutsideClickToClose(ref, menuOpen, setMenuOpen);

    const handleStatusClick = (newStatus) => {
        setMenuOpen(false);

        if (newStatus === statuses.CANCELED) {
            setShowModal(true);
            return;
        }

        updateStatus(id, newStatus);
    };

    const onSubmitCancelStatus = () => {
        setShowModal(false);
        updateStatus(id, statuses.CANCELED);
    };

    const onStatusUpdated = (updated) =>
        setAccounts((accounts) => accounts.map((acct) => (acct.id === updated.id ? updated : acct)));

    const updateStatus = async (id, status) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/account.json?action=update_status&id=${id}&status=${status}`);
            onStatusUpdated(data);
        }
        catch (err) {
            const { message } = formatError(err);
            setError(message);
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <>
            {Boolean(error) && (
                <ToastNotification
                    title="Error"
                    caption={<span>{error}</span>}
                    timeout={7000}
                    onCloseButtonClick={() => setError('')}
                    lowContrast
                />
            )}
            {showModal && (
                <AccountCancellationModal
                    onRequestClose={() => setShowModal(false)}
                    onRequestSubmit={onSubmitCancelStatus}
                />
            )}
            <div className={styles.statusContainer}>
                {loading ? (
                    <Restart16 className={styles.loadingIcon} />
                ) : (
                    <AccountStatus onClick={() => setMenuOpen(true)} status={status} withTooltip />
                )}

                {menuOpen && (
                    <motion.div className={styles.pulldown} {...menuAnimation} ref={ref}>
                        <ul>
                            {options.map((opt) => (
                                <li className={styles.statusListItem} key={opt}>
                                    <button
                                        className={styles.changeStatusButton}
                                        onClick={() => handleStatusClick(opt)}
                                        type="button"
                                    >
                                        <AccountStatus status={opt} />
                                        <span className={styles.buttonText}>{capitalizeFirstLetter(opt)}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </motion.div>
                )}
            </div>
        </>
    );
};

const AccountCancellationModal = ({ onRequestClose, onRequestSubmit }) => {
    return (
        <Modal
            modalHeading="Confirm Account Cancellation"
            onRequestClose={onRequestClose}
            onRequestSubmit={onRequestSubmit}
            primaryButtonText="Confirm"
            secondaryButtonText="Cancel"
            size="sm"
            open
            danger
        >
            <p>Set this account's status to canceled?</p>
        </Modal>
    );
};

const menuAnimation = {
    initial: {
        opacity: 0,
        scale: 0,
        originX: 0,
        originY: 0
    },
    animate: {
        opacity: 1,
        scale: 1
    },
    transition: {
        duration: 0.1
    }
};

export { AccountCancellationModal, AccountStatusMenu, AccountStatus as default };
