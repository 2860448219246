import clsx from 'clsx';
import styles from './PageButtons.module.scss';

/**
 * Component to help create a button group and apply some default styles.
 * For example: aligning Cancel and Submit buttons at the bottom of a form.
 *
 * @param {object} Options children;
 *                         justify: any justify styles passed by the consumer;
 *                         className: style class passed by the consumer
 * @returns ReactElement
 */
const PageButtons = ({ children, justify, className = '', position = 'bottom', noMargin = false }) => {
    return (
        <section
            className={clsx(styles.wrap, className)}
            style={{
                justifyContent: justify || 'space-between',
                margin: noMargin ? 0 : position === 'top' ? '30px 0' : '50px 0'
            }}
        >
            {children}
        </section>
    );
};

export default PageButtons;
