import { Handles, Rail, Slider, Tracks } from 'react-compound-slider';
import styles from './RangeSlider.module.scss';
import { Handle, TooltipRail, Track } from './RangeSliderHelpers';

const sliderStyle = {
    position: 'relative',
    width: '60%'
};

const RangeSlider = ({ domain, values, setValues, labelTextRight = '', labelTextLeft = '' }) => {
    const onChange = (values) => {
        setValues(values);
    };

    return (
        <>
            <div className={styles.label}>
                {domain[0]} {labelTextRight}
            </div>
            <Slider
                mode={2}
                step={1}
                domain={domain}
                rootStyle={sliderStyle}
                onChange={onChange}
                values={values}
                className={styles.slider}
            >
                <Rail>{(railProps) => <TooltipRail {...railProps} />}</Rail>
                <Handles>
                    {({ handles, activeHandleID, getHandleProps }) => (
                        <div>
                            {handles.map((handle) => (
                                <Handle
                                    className={styles.thumb}
                                    key={handle.id}
                                    handle={handle}
                                    domain={domain}
                                    isActive={handle.id === activeHandleID}
                                    getHandleProps={getHandleProps}
                                />
                            ))}
                        </div>
                    )}
                </Handles>
                <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                        <div className={styles.sliderTracks}>
                            {tracks.map(({ id, source, target }) => (
                                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                            ))}
                        </div>
                    )}
                </Tracks>
            </Slider>
            <div className={styles.label}>
                {domain[1]} {labelTextLeft}
            </div>
        </>
    );
};

export default RangeSlider;
