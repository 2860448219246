import { AddAlt20, TrashCan20 } from '@carbon/icons-react';
import {
    Button,
    Checkbox,
    Column,
    InlineNotification,
    Select,
    SelectItem,
    Slider,
    TextInput
} from 'carbon-components-react';
import clsx from 'clsx';
import { PageSection } from 'components/common';
import useUser from 'hooks/use-user';
import scheduleIcon from 'img/schedule.svg';
import { Fragment, useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { products } from 'util/constants';
import { validateEmails, validateUrl } from 'util/validate-form';
import styles from './Delivery.module.scss';

const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const MIN_OFF_HOURS_BID_PCT = 50;
const MAX_OFF_HOURS_BID_PCT = 99;
const LOW_BID_THRESHOLD = 1;

const lowBidCases = {
    ALL: 'all',
    SOME: 'some'
};

const lowBidMessages = {
    all: 'All of your bids will be less than $1 with your current Off Hours percentage. Bids under $1 may not be eligible to win leads.',
    some: 'Please note: one or more of your bids will be less than $1 with your current Off Hours percentage. Bids under $1 may not be eligible to win leads.'
};

export const OFF_HOURS_LABEL_TEXT =
    'Buy leads outside of my scheduled hours (only applies to the hours of 6:00pm to 6:00am of the checked days above).';

const initialValues = {
    delivery: {
        email: '',
        lmsUrl: ''
    }
};

const Delivery = ({ namespace, formDisabled, carrier, product }) => {
    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        watch
    } = useFormContext();
    const { employee } = useUser();
    const [root, , section] = namespace.split('.');
    const deliveryErrors = errors[root]?.[product]?.[section] || {};
    const [offHoursBidWarning, setOffHoursBidWarning] = useState('');

    useEffect(() => {
        !carrier && register(`${namespace}.schedule.offHoursBidding.percentage`);
    }, [carrier]);

    /**
     * Fields with custom onChange/onBlur handling
     */

    const offHoursBiddingCheckbox = !carrier && register(`${namespace}.schedule.offHoursBidding.enabled`);

    const showOffHoursSection =
        carrier?.showDeliveryOffHoursSection ?? ![products.HOME, products.HEALTH, products.LIFE].includes(product);

    // fetch campaign values to be able to check if off-hours bids are under $1.00
    const campaigns = getValues(`${root}.${product}.campaign`);
    const campaignBids = Object.keys(campaigns)
        .flatMap((campaign) => {
            if (campaign === 'dailyCapLevel') {
                return false;
            }
            else {
                return campaigns[campaign]?.enabled && campaigns[campaign]?.bid;
            }
        })
        .filter(Boolean);

    const offHoursBidding = watch(offHoursBiddingCheckbox.name);
    const offHoursBidPercentage = watch(`${namespace}.schedule.offHoursBidding.percentage`);

    // Show a warning if some/all off-hours bids are below $1.00
    useEffect(() => {
        const hasWarning = Boolean(offHoursBidWarning);
        if (!offHoursBidding || !offHoursBidPercentage) return hasWarning && setOffHoursBidWarning('');

        const lowBids = campaignBids.filter(
            (bid) => (Number(bid) * offHoursBidPercentage) / 100 < LOW_BID_THRESHOLD // Bids under $1 off-hours
        );

        const lowBidCase = !lowBids.length
            ? false
            : lowBids.length < campaignBids.length
                ? lowBidCases.SOME
                : lowBidCases.ALL;

        if (lowBidCase) {
            setOffHoursBidWarning(lowBidMessages[lowBidCase]);
        }
        else {
            hasWarning && setOffHoursBidWarning('');
        }
    }, [offHoursBidding, offHoursBidPercentage, campaignBids]);

    return (
        <PageSection name="Schedule and Delivery" icon={scheduleIcon} data-testid="section-delivery">
            <div className="bx--grid bx--grid--full-width">
                <h4>Time Zone</h4>
                <div className="bx--row">
                    <Column md={2}>
                        <Select
                            {...register(`${namespace}.schedule.timezone`, {
                                required: 'Time Zone is required'
                            })}
                            id="schedule.timezone"
                            labelText="Time Zone"
                            disabled={formDisabled}
                            invalid={!!deliveryErrors?.schedule?.timezone}
                            invalidText={deliveryErrors?.schedule?.timezone?.message}
                        >
                            <SelectItem disabled value="" text="Time Zone" />
                            {options.timezones.map((timezone, i) => (
                                <SelectItem value={timezone.value} key={i} text={timezone.label} />
                            ))}
                        </Select>
                    </Column>
                </div>

                <h4>Schedule</h4>
                {DAYS.map((day, i) => (
                    <ScheduleDay
                        namespace={namespace}
                        day={day}
                        key={i}
                        formDisabled={formDisabled}
                        product={product}
                    />
                ))}

                {showOffHoursSection && (
                    <>
                        <h4>Off-Hours</h4>
                        <div className="bx--row">
                            <div className="bx--col">
                                <Checkbox
                                    name={offHoursBiddingCheckbox.name}
                                    ref={offHoursBiddingCheckbox.ref}
                                    id={offHoursBiddingCheckbox.name}
                                    labelText={OFF_HOURS_LABEL_TEXT}
                                    disabled={formDisabled}
                                    onChange={(isChecked, id, evt) => {
                                        offHoursBiddingCheckbox.onChange(evt);
                                        setValue(offHoursBiddingCheckbox.name, isChecked, { shouldDirty: true });
                                    }}
                                    onBlur={offHoursBiddingCheckbox.onBlur}
                                />
                            </div>
                        </div>

                        {getValues(offHoursBiddingCheckbox.name) && (
                            <div className="bx--row">
                                <div className="bx--col-lg-6">
                                    <Slider
                                        {...register(`${namespace}.schedule.offHoursBidding.percentage`, {
                                            validate: (val) =>
                                                val >= MIN_OFF_HOURS_BID_PCT && val <= MAX_OFF_HOURS_BID_PCT
                                                    ? true
                                                    : `Off-Hours percentage needs to be within the range of ${MIN_OFF_HOURS_BID_PCT} to ${MAX_OFF_HOURS_BID_PCT}`
                                        })}
                                        ariaLabelInput="Off-hours bidding slider"
                                        labelText="Bid this percentage of my regular bid prices:"
                                        minLabel="%"
                                        maxLabel="%"
                                        max={MAX_OFF_HOURS_BID_PCT}
                                        min={MIN_OFF_HOURS_BID_PCT}
                                        value={
                                            getValues(`${namespace}.schedule.offHoursBidding.percentage`) ||
                                            initialValues.offHoursBidding.percentage
                                        }
                                        disabled={formDisabled}
                                        // does not expose evt object
                                        onChange={({ value }) => {
                                            setValue(`${namespace}.schedule.offHoursBidding.percentage`, value, {
                                                shouldDirty: true,
                                                shouldValidate: true
                                            });
                                        }}
                                        invalid={!!deliveryErrors?.schedule?.offHoursBidding?.percentage}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
                {Boolean(deliveryErrors?.schedule?.offHoursBidding?.percentage) && (
                    <div aria-live="polite" className={styles.offHoursBiddingError}>
                        {deliveryErrors?.schedule?.offHoursBidding?.percentage?.message}
                    </div>
                )}
                {Boolean(offHoursBidWarning) && (
                    <InlineNotification
                        id="invalidOffHoursBid"
                        kind="warning"
                        title=""
                        subtitle={<span>{offHoursBidWarning}</span>}
                        hideCloseButton
                        lowContrast
                    />
                )}

                <h4>Delivery Method</h4>
                <div className="bx--row">
                    <div className="bx--col-lg-6">
                        <TextInput
                            {...register(`${namespace}.delivery.email`, {
                                required: getValues(`${namespace}.delivery.lmsUrl`)
                                    ? false
                                    : 'Delivery Email is required',
                                validate: (val) => validateEmails(val)
                            })}
                            id={`${namespace}.delivery.email`}
                            labelText={<b>Email Notification</b>}
                            helperText={`${
                                !!carrier ? 'Optional. ' : ''
                            }To send lead delivery notification to multiple email addresses, please input comma-delimited list of email addresses.`}
                            disabled={formDisabled}
                            invalid={!!deliveryErrors?.delivery?.email}
                            invalidText={deliveryErrors?.delivery?.email?.message}
                        />
                    </div>
                </div>
                <div className="bx--row">
                    <div className="bx--col-lg-6">
                        <TextInput
                            {...register(`${namespace}.delivery.lmsUrl`, {
                                required: !!carrier ? 'Please enter a Lead Management System URL' : false,
                                validate: (val) => validateUrl(val)
                            })}
                            id={`${namespace}.delivery.lmsUrl`}
                            labelText={<strong>Lead Management System</strong>}
                            helperText={
                                !!carrier
                                    ? 'Delivery via State Farm eCRM is required for subsidy.'
                                    : 'Optional. Contact your Client Success Manager to setup delivery to your preferred Lead Management System.'
                            }
                            disabled={formDisabled || !employee}
                            invalid={!!deliveryErrors?.delivery?.lmsUrl}
                            invalidText={deliveryErrors?.delivery?.lmsUrl?.message}
                        />
                    </div>
                </div>
            </div>
        </PageSection>
    );
};

const ScheduleDay = ({ namespace, day, formDisabled, product }) => {
    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        control,
        clearErrors
    } = useFormContext();
    const { enabled } = getValues(`${namespace}.schedule.${day}`);
    const daySelectionCheckbox = register(`${namespace}.schedule.${day}.enabled`);
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${namespace}.schedule.${day}.hours`
    });
    const [root, , section] = namespace.split('.');
    const deliveryErrors = errors[root]?.[product]?.[section] || {};

    const addSegment = () => {
        const start = getValues(`${namespace}.schedule.${day}.hours.${fields.length - 1}.end`);
        append({ start, end: '24' });
    };
    const removeSegment = () => remove(fields.length - 1);

    const finalSegmentEnd = useWatch({
        name: `${namespace}.schedule.${day}.hours.${fields.length - 1}.end`
    });
    const disableAddSegment = Number(finalSegmentEnd) === 24 || formDisabled;

    const uncheckDay = () => {
        clearErrors();
        setValue(`${namespace}.schedule.${day}.hours`, [{ start: '0', end: '24' }]);
    };

    const validateStartTime = (day, segment) => {
        if (
            Number(getValues(`${namespace}.schedule.${day}.hours.${segment}.start`)) >=
            Number(getValues(`${namespace}.schedule.${day}.hours.${segment}.end`))
        ) {
            return 'Start Time must be earlier than End Time';
        }
        return true;
    };

    const validateEndTime = (day, segment) => {
        if (
            Number(getValues(`${namespace}.schedule.${day}.hours.${segment}.end`)) <=
            Number(getValues(`${namespace}.schedule.${day}.hours.${segment}.start`))
        ) {
            return 'End Time must be later than Start Time';
        }
        return true;
    };

    const validateIfEnabled = (day, segment, isStart = false) => {
        return {
            validate: () => (isStart ? validateStartTime(day, segment) : validateEndTime(day, segment))
        };
    };

    return (
        <div className="bx--row">
            <div className={clsx('bx--col-lg-1', styles.checkboxCol)}>
                <Checkbox
                    name={daySelectionCheckbox.name}
                    ref={daySelectionCheckbox.ref}
                    id={daySelectionCheckbox.name}
                    className={styles.dayCheckbox}
                    labelText={day.substring(0, 3)}
                    disabled={formDisabled}
                    onChange={(isChecked, id, evt) => {
                        daySelectionCheckbox.onChange(evt);
                        setValue(daySelectionCheckbox.name, isChecked, {
                            shouldDirty: true,
                            shouldValidate: true
                        });
                        if (!isChecked) {
                            uncheckDay();
                        }
                    }}
                    onBlur={daySelectionCheckbox.onBlur}
                />
            </div>
            {fields.map((field, index) => {
                const startTime = register(
                    `${namespace}.schedule.${day}.hours.${index}.start`,
                    validateIfEnabled(day, index, true)
                );
                const endTime = register(
                    `${namespace}.schedule.${day}.hours.${index}.end`,
                    validateIfEnabled(day, index)
                );
                const showAndColumn = Boolean(index < fields.length - 1);
                const optionStartTime =
                    index === 0 ? null : getValues(`${namespace}.schedule.${day}.hours.${index - 1}.end`);

                const isLastSegment = index === fields.length - 1;
                const hoursOptions = getHoursOptions(optionStartTime);

                return (
                    <Fragment key={field.id}>
                        <div>
                            <Select
                                id={field.id}
                                name={startTime.name}
                                ref={startTime.ref}
                                labelText="Start Time"
                                hideLabel={true}
                                aria-label={`${day} Start Time`}
                                disabled={formDisabled || !enabled || !isLastSegment}
                                invalid={!!deliveryErrors?.schedule?.[day]?.hours?.[index]?.start}
                                invalidText={deliveryErrors?.schedule?.[day]?.hours?.[index]?.start?.message}
                                onChange={(evt) => {
                                    startTime.onChange(evt);
                                    setValue(startTime.name, evt.target.value);
                                    if (Number(getValues(endTime.name)) > Number(evt.target.value)) {
                                        clearErrors([endTime.name, startTime.name]);
                                    }
                                }}
                                onBlur={startTime.onBlur}
                            >
                                {hoursOptions
                                    .filter((h) => h.value !== 24)
                                    .map((hour, i) => (
                                        <SelectItem value={hour.value} key={i} text={hour.label} />
                                    ))}
                            </Select>
                        </div>
                        <div className={styles.toColumn}>to</div>
                        <div className={styles.endTime}>
                            <Select
                                id={field.id}
                                name={endTime.name}
                                ref={endTime.ref}
                                labelText="End Time"
                                hideLabel={true}
                                aria-label={`${day} End Time`}
                                disabled={formDisabled || !enabled || !isLastSegment}
                                invalid={!!deliveryErrors?.schedule?.[day]?.hours?.[index]?.end}
                                invalidText={deliveryErrors?.schedule?.[day]?.hours?.[index]?.end?.message}
                                onChange={(evt) => {
                                    endTime.onChange(evt);
                                    setValue(endTime.name, evt.target.value);
                                    if (Number(evt.target.value) > Number(getValues(startTime.name))) {
                                        clearErrors([endTime.name, startTime.name]);
                                    }
                                }}
                                onBlur={endTime.onBlur}
                            >
                                {hoursOptions.map((hour, i) => (
                                    <SelectItem value={hour.value} key={i} text={hour.label} />
                                ))}
                            </Select>
                        </div>
                        {showAndColumn && <div className={styles.andColumn}>and</div>}
                    </Fragment>
                );
            })}
            {enabled && (
                <div className={styles.segmentControls}>
                    {Boolean(fields.length < 2) && (
                        <Button
                            className={styles.addSegment}
                            onClick={addSegment}
                            type="button"
                            renderIcon={AddAlt20}
                            iconDescription="Add Schedule Segement"
                            disabled={disableAddSegment}
                            hasIconOnly
                        />
                    )}
                    {Boolean(fields.length > 1) && (
                        <Button
                            className={styles.removeSegment}
                            disabled={formDisabled}
                            onClick={removeSegment}
                            renderIcon={TrashCan20}
                            iconDescription="Remove Schedule Segment"
                            type="button"
                            hasIconOnly
                        />
                    )}
                </div>
            )}
        </div>
    );
};

const getHoursOptions = (startTime) => {
    const getHour = (val) => {
        if (val === 0) return 12;
        if (val === 24) return 11;
        return val % 12 || val;
    };

    const hours = [];
    for (let i = 0; i <= 24; i++) {
        const hour = getHour(i);
        const suffix = i < 12 ? 'AM' : 'PM';
        hours.push({
            label: `${hour}:${i === 24 ? '59' : '00'} ${suffix}`,
            value: i
        });

        if (i !== 24) {
            hours.push({
                label: `${hour}:30 ${suffix}`,
                value: i + 0.5
            });
        }
    }

    if (!startTime) {
        return hours;
    }

    return hours.filter((h) => h.value >= startTime);
};

const options = {
    timezones: [
        {
            label: 'Eastern',
            value: 'eastern'
        },
        {
            label: 'Central',
            value: 'central'
        },
        {
            label: 'Mountain, no DST (Arizona)',
            value: 'mountain_no_dst'
        },
        {
            label: 'Mountain',
            value: 'mountain'
        },
        {
            label: 'Pacific',
            value: 'pacific'
        },
        {
            label: 'Alaska',
            value: 'alaska'
        },
        {
            label: 'Hawaii (no DST)',
            value: 'hawaii_no_dst'
        }
    ]
};

export { DAYS, Delivery as default, initialValues };
