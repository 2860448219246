import { InlineNotification, Loading, Modal } from 'carbon-components-react';
import { useEffect, useRef, useState } from 'react';
import { formatError } from 'util/format-error';
import axios from 'util/axios';
import styles from './AddPaymentMethod.module.scss';

const MONETRA_ORIGIN = window.location.origin;
const MONETRA_CSS_URL = `${MONETRA_ORIGIN}/monetra.css`;
const MONETRA_IFRAME_TITLE = 'monetra';

// Adds a payment method, returns newPaymentMethod on success.
const AddPaymentMethod = ({ onClose, onSuccess, onError }) => {
    const [processing, setProcessing] = useState(false);
    const [paymentFrameError, setPaymentFrameError] = useState(null);
    const [paymentFrameAttrs, setPaymentFrameAttrs] = useState(null);

    const iframeRef = useRef();
    const paymentFrameRef = useRef();

    const getPaymentFrameAttrs = async () => {
        try {
            const res = await axios.get(
                `/payment.json?action=init_frame&domain=${MONETRA_ORIGIN}&css-url=${MONETRA_CSS_URL}&include-submit-button=no`
            );

            setPaymentFrameAttrs(res.data);
        }
        catch (err) {
            console.error(err);
        }
    };

    // TODO: We could remove getNewPaymentMethod here if we update add_payment_method API
    // to return the same card response we get from get_payment_methods.
    const getNewPaymentMethod = async (paymentMethodId) => {
        try {
            const { data } = await axios.get('profile.json?action=get_payment_methods');
            const newPaymentMethod = data?.paymentMethods?.find((pm) => pm.paymentMethodId === paymentMethodId);
            onSuccess(newPaymentMethod);
        }
        catch(err) {
            const { message } = formatError(err);
            onError(message);
        }
    };

    // Get iframe attributes on mount
    useEffect(() => {
        getPaymentFrameAttrs();

        return () => {
            if (paymentFrameRef.current) {
                paymentFrameRef.current.setPaymentSubmittedCallback(null);
            }
        };
    }, []);

    // Render iframe and call monetra after receiving attributes
    useEffect(() => {
        if (paymentFrameAttrs) {
            callMonetra(paymentFrameAttrs);
        }
    }, [paymentFrameAttrs]);

    const callMonetra = ({ id, url }) => {
        if (typeof window.PaymentFrame !== 'function') {
            return onError('Something went wrong. Please try again later.');
        }

        paymentFrameRef.current = new window.PaymentFrame(id, url);
        paymentFrameRef.current.setPaymentSubmittedCallback((response) => {
            const { code, ticket } = response;

            if (code !== 'AUTH' || !ticket) {
                setPaymentFrameError('Credit Card Verification Failed');
                paymentFrameRef.current.enableSubmitButton();
                return;
            }

            savePaymentMethod(ticket);
        });
        paymentFrameRef.current.request();
    };

    const savePaymentMethod = async (ticket) => {
        setProcessing(true);

        try {
            const { data } = await axios.post('profile.json?action=add_payment_method', {
                monetraTicket: ticket
            });

            getNewPaymentMethod(data?.paymentMethod?.paymentMethodId);
        }
        catch (err) {
            const { message } = formatError(err);
            onError(message);
        }
    };

    const submitIframe = () => {
        const iframe = iframeRef.current;
        iframe instanceof HTMLIFrameElement &&
            iframe.contentWindow.postMessage(JSON.stringify({ type: 'submitPaymentData' }), '*');
    };

    const AddPaymentMethodInProgress = () => (
        <Modal open passiveModal size="xs" preventCloseOnClickOutside>
            <div className={styles.processing}>
                <Loading active withOverlay={false} />
                <div className={styles.message}>Adding Payment Method</div>
            </div>
        </Modal>
    );

    return (
        <>
            {processing ? (
                <AddPaymentMethodInProgress />
            ) : (
                <Modal
                    open
                    modalHeading="Add Payment Method"
                    primaryButtonText="Add Credit Card"
                    secondaryButtonText="Cancel"
                    onRequestClose={onClose}
                    onRequestSubmit={submitIframe}
                    onSecondarySubmit={onClose}
                    preventCloseOnClickOutside
                    size="sm"
                >
                    <div>Please enter your credit card information:</div>
                    {paymentFrameAttrs && (
                        <>
                            <iframe
                                {...formatIframeAttrs(paymentFrameAttrs)}
                                title={MONETRA_IFRAME_TITLE}
                                ref={iframeRef}
                                style={{ border: 'none' }}
                            ></iframe>
                            {paymentFrameError && (
                                <InlineNotification
                                    kind={'error'}
                                    title={paymentFrameError}
                                    hideCloseButton
                                    lowContrast
                                />
                            )}
                        </>
                    )}
                </Modal>
            )}
        </>
    );
};

const formatIframeAttrs = ({ id, attrs }) => {
    const iframeAttrs = attrs.reduce(
        (acc, curr) => {
            let [k, v] = curr.split('=');
            return { ...acc, [k]: v.replace(/"/gi, '') };
        },
        { id }
    );

    return iframeAttrs;
};

export default AddPaymentMethod;
