import clsx from 'clsx';
import { LeadCallButton } from 'components/leads';
import dobIcon from 'img/dob.svg';
import genderIcon from 'img/gender.svg';
import mapMarkerIcon from 'img/map_marker.svg';
import marriedIcon from 'img/married.svg';
import emailIcon from 'img/paper_plane.svg';
import phoneIcon from 'img/phone.svg';
import propertyIcon from 'img/property_info.svg';
import isNil from 'lodash/isNil';
import { DataBlock, fullAddress } from './index';
import styles from './LeadDetail.module.scss';

const HomeBasics = ({
    lead,
    formatters,
    isLeadPreview = false,
    callNowButton,
    adGroupId,
    leadId,
    advertiser,
    callDisabled,
    callDisabledReason
}) => {
    return (
        <div className={styles.basics}>
            <DataBlock value={fullAddress(lead)} label="address" icon={mapMarkerIcon} isBlurred={isLeadPreview}>
                <div>
                    <div className={clsx(isLeadPreview && styles.blurred)}>{lead.address || ''}</div>
                    <div>
                        {lead.city || ''}, {lead.state || ''} {lead.zip || ''}
                    </div>
                </div>
            </DataBlock>
            <DataBlock value={lead.phone} label="phone" icon={phoneIcon} isBlurred={isLeadPreview}>
                {isLeadPreview ? (
                    <span className={styles.blurred}>{lead.phone}</span>
                ) : (
                    <a href={`tel:${lead.phone}`}>{lead.phone}</a>
                )}
                {!isLeadPreview && callNowButton && (
                    <LeadCallButton
                        adGroupId={adGroupId}
                        lead={lead}
                        leadId={leadId}
                        advertiser={advertiser}
                        callDisabled={callDisabled}
                        callDisabledReason={callDisabledReason}
                        style={{ margin: '0 10px' }}
                    />
                )}
            </DataBlock>
            <DataBlock value={lead.email} label="email" icon={emailIcon} isBlurred={isLeadPreview}>
                {isLeadPreview ? (
                    <span className={styles.blurred}>{lead.email}</span>
                ) : (
                    <a href={`mailto:${lead.email}`} style={{ textTransform: 'lowercase' }}>
                        {lead.email}
                    </a>
                )}
            </DataBlock>
            <DataBlock value={lead.birthDate} label="dob" icon={dobIcon} format={(val) => `${val}`} />
            <DataBlock
                value={lead.gender}
                label="gender"
                icon={genderIcon}
                format={(val) => (val === 'M' ? 'Male' : val === 'F' ? 'Female' : 'Non-binary')}
            />
            <DataBlock value={lead.married} label="married" icon={marriedIcon} format={formatters.yesNo} />
        </div>
    );
};

const HomeProperties = ({ lead, properties, isLeadPreview, formatters }) => {
    if (isNil(properties) || properties.length === 0) {
        return <div className={styles.noData}>No properties to display.</div>;
    }

    const claims = isNil(lead.claims) ? 0 : lead.claims.length;

    return (
        <div className={styles.properties}>
            <div className={styles.title}>
                <img src={propertyIcon} alt="properties" />
                Property Information
            </div>
            {properties.map((property, i) => {
                return (
                    <div key={`property${i}`}>
                        {i > 0 && <hr className={styles.divider} />}
                        <div className={styles.sectionTitle}>
                            Property {i + 1} {i === 0 && <span>(primary)</span>}
                        </div>
                        <div className={styles.sectionData}>
                            <DataBlock value={property.propertyType} label="property type" />
                            <DataBlock value={property.squareFootage} label="square footage" />
                            <DataBlock value={property.construction} label="construction type" />
                            <DataBlock value={property.yearBuilt} label="year built" />
                            <DataBlock value={property.numStories} label="number of stories" />
                            <DataBlock value={claims} label="claims in the last 3 years" />
                            <DataBlock value={property.numBedrooms} label="number of bedrooms" />
                            <DataBlock
                                value={lead.currentlyInsured}
                                label="currently insured"
                                format={formatters.yesNo}
                            />
                            <DataBlock value={property.numBathrooms} label="number of bathrooms" />
                            <DataBlock
                                value={lead.currentCompany}
                                label="current insurer"
                                format={formatters.emptyNA}
                            />
                            <DataBlock value={property.garage} label="garage type" />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export { HomeBasics, HomeProperties };
