import { Checkmark32 } from '@carbon/icons-react';
import {
    Button,
    Column,
    Form,
    FormGroup,
    Row,
    Select,
    SelectItem,
    TextInput,
    ToastNotification
} from 'carbon-components-react';
import states from 'data/us-states';
import useAuth from 'hooks/use-auth';
import useUser from 'hooks/use-user';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'util/axios';
import { formatError } from 'util/format-error';
import { normalizePhoneNumber, normalizeZipCode, validatePhoneNumber } from 'util/validate-form';
import styles from './Contact.module.scss';

const Contact = () => {
    const { user } = useUser();
    const { getUser } = useAuth();
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const {
        firstName = '',
        lastName = '',
        email = '',
        phone = '',
        company = '',
        agencyAddress = '',
        agencyAddress_2 = '',
        agencyCity = '',
        agencyState = '',
        agencyZip = ''
    } = user;

    const initialValues = {
        firstName,
        lastName,
        email,
        phone,
        company,
        agencyAddress,
        agencyAddress_2,
        agencyCity,
        agencyState,
        agencyZip
    };

    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isDirty, errors },
        setValue
    } = useForm({
        mode: 'onTouched',
        defaultValues: {
            ...initialValues
        }
    });

    const onSubmit = async (values) => {
        setSuccess(false);
        setError(false);

        try {
            await axios.post('profile.json?action=update', values);

            setSuccess('Contact Information Updated Successfully');
            getUser(); // Refetch global user to populate new data
        }
        catch (err) {
            const { message } = formatError(err);
            setError(message);
        }
    };

    const handleClose = () => {
        setSuccess(null);
        setError(null);
    };

    const clean = !isDirty;
    const hasErrors = Boolean(Object.keys(errors).length);
    const status = error ? 'Error' : 'Success';

    /**
     * Fields with custom onChange/onBlur handling
     */
    const agentPhone = register('phone', {
        required: 'Phone is required',
        validate: (val) => validatePhoneNumber(val)
    });

    const zipCode = register('agencyZip', {
        pattern: {
            value: /^\d{5}$/,
            message: 'Please enter a valid zip code'
        }
    });

    return (
        <>
            {(error || success) && (
                <ToastNotification
                    kind={error ? 'error' : 'success'}
                    onCloseButtonClick={handleClose}
                    title={status}
                    lowContrast
                    iconDescription="Close"
                    statusIconDescription={error ? 'Error' : 'Success'}
                    caption={error || success}
                    timeout={4000}
                />
            )}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup legendText="">
                    <Row>
                        {agentFields.map((field, i) => (
                            <Column lg={3} key={i}>
                                <TextInput
                                    labelText={field.label}
                                    disabled={true}
                                    {...register(field.name)}
                                    id={field.name}
                                />
                            </Column>
                        ))}
                        <Column lg={3}>
                            <TextInput
                                name={agentPhone.name}
                                ref={agentPhone.ref}
                                labelText="Phone"
                                type="tel"
                                id="phone"
                                invalid={!!errors.phone}
                                invalidText={errors.phone?.message}
                                onChange={(evt) => {
                                    agentPhone.onChange(evt);
                                    setValue(agentPhone.name, normalizePhoneNumber(evt.target.value));
                                }}
                                onBlur={agentPhone.onBlur}
                            />
                        </Column>
                    </Row>
                    <h4>Company</h4>
                    <Row>
                        {agencyFields.map((field, i) => (
                            <Column lg={3} key={i} className={styles.companyInputs}>
                                {field.name !== 'agencyState' && (
                                    <TextInput
                                        labelText={field.label}
                                        disabled={field.name === 'company'}
                                        {...register(field.name)}
                                        id={field.name}
                                        defaultValue={initialValues[field.name]}
                                    />
                                )}
                                {field.name === 'agencyState' && (
                                    <Select
                                        className={styles.companyInputs}
                                        labelText="State"
                                        id="agencyState"
                                        {...register('agencyState')}
                                    >
                                        {states.map((state, i) => (
                                            <SelectItem value={state.value} key={i} text={state.label} />
                                        ))}
                                    </Select>
                                )}
                            </Column>
                        ))}
                        <Column lg={3} className={styles.companyInputs}>
                            <TextInput
                                labelText="ZIP Code"
                                ref={zipCode.ref}
                                name={zipCode.name}
                                id="agencyZip"
                                invalid={!!errors.agencyZip}
                                invalidText={errors.agencyZip?.message}
                                onChange={(evt) => {
                                    zipCode.onChange(evt);
                                    setValue(zipCode.name, normalizeZipCode(evt.target.value));
                                }}
                                onBlur={zipCode.onBlur}
                            />
                        </Column>
                    </Row>
                    <Button type="submit" renderIcon={Checkmark32} disabled={clean || isSubmitting || hasErrors}>
                        Update Information
                    </Button>
                </FormGroup>
            </Form>
        </>
    );
};

const agentFields = [
    {
        label: 'First Name',
        name: 'firstName'
    },
    {
        label: 'Last Name',
        name: 'lastName'
    },
    {
        label: 'Email',
        name: 'email'
    }
];

const agencyFields = [
    { label: 'Agency Name', name: 'company' },
    { label: 'Address 1', name: 'agencyAddress' },
    { label: 'Address 2', name: 'agencyAddress_2' },
    { label: 'City', name: 'agencyCity' },
    {
        label: 'State',
        name: 'agencyState'
    }
];

export default Contact;
