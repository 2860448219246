import { Flag16, FlagFilled16 } from '@carbon/icons-react';
import { Button, ButtonSkeleton, ToastNotification } from 'carbon-components-react';
import { useState } from 'react';
import axios from 'util/axios';

const LeadFlag = ({ flagged, rowData, onLeadUpdated, ...rest }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const updateFlag = async () => {
        setLoading(true);

        const newValue = Number(!flagged);
        const payload = {
            lead_id: rowData.leadId,
            ad_group_id: rowData.adGroupId,
            revisit_later: newValue
        };

        try {
            await axios.post(`/lead.json?action=update_status`, {
                action: 'update_status',
                leads: [payload]
            });

            const updatedRow = Object.assign(rowData, { revisitLater: newValue });
            onLeadUpdated(updatedRow);
        }
        catch (err) {
            setError(true);
        }
        finally {
            setLoading(false);
        }
    };

    if (loading) return <ButtonSkeleton style={{ width: 48 }} />;

    return (
        <>
            {error && (
                <ToastNotification
                    title="Error"
                    caption="Something went wrong. Please try again later."
                    kind="error"
                    onClose={() => setError(false)}
                    timeout={3000}
                />
            )}
            <Button
                renderIcon={flagged ? FlagFilled16 : Flag16}
                iconDescription={flagged ? 'Remove Flag' : 'Flag'}
                hasIconOnly
                tooltipAlignment="center"
                tooltipPosition="top"
                kind="ghost"
                onClick={updateFlag}
                {...rest}
            />
        </>
    );
};

export default LeadFlag;
