import { Modal } from 'carbon-components-react';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import axios from 'util/axios';
import { emailPattern } from 'util/validation';

const CheckForExistingUser = ({ namespace, setExistingUser }) => {
    const { setValue } = useFormContext();

    const [modal, setModal] = useState({
        open: false,
        email: ''
    });

    const email = useWatch({
        name: `${namespace}.email`
    });

    useEffect(async () => {
        // manual onChange validation since the form validates onBlur
        if (email && emailPattern.test(email)) {
            checkForExistingUser(email, onExisting, onNonExisting);
        }
        else {
            setExistingUser('');
        }
    }, [email]);

    const closeModal = () => {
        setModal({
            open: false,
            email: ''
        });
    };

    const onExisting = (email) => {
        setModal({
            open: true,
            email
        });
    };

    const onNonExisting = () => {
        setExistingUser('');
        closeModal();
    };

    const onConfirm = () => {
        setExistingUser(modal.email);
        closeModal();
    };

    const onCancel = () => {
        setValue(`${namespace}.email`, '', { shouldValidate: true });
        onNonExisting();
    };

    return (
        <Modal
            open={modal.open}
            modalHeading="Existing User"
            primaryButtonText="Yes"
            secondaryButtonText="No, enter a different email"
            onRequestClose={onCancel}
            onRequestSubmit={onConfirm}
        >
            <p>
                A user with this email (<strong>{modal.email}</strong>) already exists. Would you like to create a new
                account and link it to this existing user?
            </p>
        </Modal>
    );
};

const checkForExistingUser = debounce(async (email, onExisting, onNonExisting) => {
    try {
        const { data } = await axios.get('account.json?action=check_agent_exists', {
            params: {
                email
            }
        });
        if (data?.exists) {
            onExisting(email);
        }
        else {
            onNonExisting();
        }
    }
    catch (err) {
        onNonExisting();
    }
}, 250);

export { CheckForExistingUser };
