const STATE_FARM = 'State Farm';

const carrierConf = {
    [STATE_FARM]: {
        name: STATE_FARM,
        title: 'Create New State Farm Subsidy Account',
        subsidyAdvertiserId: '44183', // State Farm Agent Subsidy Program
        subsidyTiers: [0, 1, 2, 3, 4].map((x) => {
            const label = `Tier ${x} Agents${x === 0 ? ' (Premier)' : ''}`;
            return { label, id: x };
        }),
        carrierSelectOptions: [{ label: STATE_FARM, value: STATE_FARM }],
        showDeliveryOffHoursSection: false,
        dailyCapMaxLimit: 100
    }
};

export default carrierConf;
