import { ArrowLeft16, ArrowRight16 } from '@carbon/icons-react';
import { Button, Loading, ToastNotification } from 'carbon-components-react';
import clsx from 'clsx';
import useAccount from 'hooks/use-account';
import useOpportunitiesFilters from 'hooks/use-opportunities-filters';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'util/axios';
import styles from './LeadDetailNavigation.module.scss';

const buttons = {
    PREVIOUS: 'previous',
    NEXT: 'next'
};

const TIMER_INTERVAL = 60 * 1000; // 60 seconds

const LeadDetailNavigation = ({ leadId, tooltipPosition = 'bottom', isLeadFeed = true }) => {
    const { id } = useAccount();
    const history = useHistory();
    const { leadFeedFilters } = useOpportunitiesFilters();
    const [adjacentIds, setAdjacentIds] = useState({
        previous: null,
        next: null
    });
    const [loading, setLoading] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const timer = useRef();

    const getAdjacentLeadIds = async (dataOnly = false) => {
        // Reset timer and prev/next before API call
        if (timer.current) clearTimeout(timer.current);
        resetButtons();

        try {
            const { data } = isLeadFeed
                ? await axios.post(
                      `/lead-feed.json?action=get_adjacent_lead_ids&id=${leadId}&advertiser_id=${id}`,
                      leadFeedFilters
                )
                : {}; // update API call when this can be used with lead details

            if (dataOnly) {
                // if dataOnly we don't need to update state or set timer again
                return data;
            }

            setAdjacentIds(data);
            timer.current = window.setTimeout(() => getAdjacentLeadIds(), TIMER_INTERVAL);
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        return () => {
            if (timer.current) clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {
        getAdjacentLeadIds();
    }, [leadId]);

    // handleClick will wait for another call to getAdjacentLeadIds to complete before redirecting
    const handleClick = async (button) => {
        setLoading(button);
        const ids = (await getAdjacentLeadIds(true)) || {};

        // If the lead they were trying to navigate to is unavailable, show toast message
        if (!ids[button]) {
            setShowToast(true);
        }
        else {
            history.push(`/p/opportunities/${ids[button]}`);
            setLoading(null);
        }
    };

    const onClose = () => {
        setLoading(null);
        setShowToast(false);
        resetButtons();
        getAdjacentLeadIds();
    };

    const resetButtons = () =>
        setAdjacentIds({
            previous: null,
            next: null
        });

    const spinnerProps = {
        className: styles.spinner,
        small: true,
        withOverlay: false
    };

    return (
        <>
            {showToast && (
                <ToastNotification
                    kind="error"
                    title="Lead unavailable"
                    subtitle={`The ${loading} lead is unavailable. Please try again.`}
                    timeout={5000}
                    onClose={onClose}
                    hideCloseButton
                    lowContrast
                />
            )}

            {loading === buttons.PREVIOUS ? (
                <div className={clsx(styles.spinnerContainer, styles.previousSpinner)}>
                    <Loading {...spinnerProps} />
                </div>
            ) : (
                <Button
                    className={styles.previous}
                    disabled={!adjacentIds[buttons.PREVIOUS]} // Disabled handles both loading and no prev/next lead
                    iconDescription="Previous Lead"
                    onClick={() => handleClick(buttons.PREVIOUS)}
                    renderIcon={ArrowLeft16}
                    tooltipPosition={tooltipPosition}
                    kind="ghost"
                    hasIconOnly
                />
            )}

            {loading === buttons.NEXT ? (
                <div className={styles.spinnerContainer}>
                    <Loading {...spinnerProps} />
                </div>
            ) : (
                <Button
                    disabled={!adjacentIds[buttons.NEXT]}
                    iconDescription="Next Lead"
                    onClick={() => handleClick(buttons.NEXT)}
                    renderIcon={ArrowRight16}
                    tooltipPosition={tooltipPosition}
                    kind="ghost"
                    hasIconOnly
                />
            )}
        </>
    );
};

export default LeadDetailNavigation;
