import { emailPattern, phonePattern, trackingUrlPattern } from 'util/validation';

const MAX_AGE = 99;
const MIN_AGE = 16;

/**
 * Only allow the first 5 digits in zip code
 *
 * @param {string} zipCode
 * @returns
 */
const normalizeZipCode = (zipCode) => {
    return zipCode.replace(/\D/g, '').substring(0, 5);
};

/**
 * Normalizes phone number for masking in the format: (000) 000-0000
 * @param {number} phoneNumber
 * @returns
 */
// https://stackoverflow.com/questions/17651207/mask-us-phone-number-string-with-javascript
const normalizePhoneNumber = (phoneNumber) => {
    let x = phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
};

const validatePhoneNumber = (val) => {
    if (!val) return true;
    if (!phonePattern.test(val.replace(/\D/g, ''))) {
        return 'Please enter a valid phone number';
    }
    return true;
};

const currencyInput = (value) => {
    let str = value.replace(/[^0-9.]/g, '').replace(/\.{2,}/g, '.');
    let last;
    while (str.indexOf('.') !== (last = str.lastIndexOf('.'))) {
        str = str.substring(0, last) + str.substring(last + 1);
    }
    return str;
};

const validateEmails = (val) => {
    const emailList = val
        .split(',')
        .map((item) => item.trim())
        .filter((item) => item.length);
    let msg = '';
    emailList.forEach((email) => {
        if (!emailPattern.test(email)) {
            msg = `${email} is not a valid email`;
            return;
        }
    });
    return msg || true;
};

const validateSms = (val) => {
    const phoneNumList = val
        .split(',')
        .map((item) => item.trim())
        .filter((item) => item.length);
    let msg = '';
    phoneNumList.forEach((phoneNum) => {
        // check if it returned an error
        if (typeof validatePhoneNumber(phoneNum) === 'string') {
            msg = `${phoneNum} is not a valid US phone number`;
        }
    });
    return msg || true;
};

const validateUrl = (val) => {
    if (!val) {
        return true;
    }
    if (!trackingUrlPattern.test(val)) {
        return 'Please enter a valid URL scheme';
    }
    return true;
};

/**
 * Returns valid zips in an array
 * Filtering out all empty strings after delimiting by commas
 *
 * @param {string} zips Zipcodes in comma-separated string format '90001, 90002'.
 * @returns Array ['90001', '90002']
 */
const getZips = (zips) => {
    if (Array.isArray(zips) && zips.length) {
        return zips;
    }

    if (typeof zips === 'string' && zips?.length) {
        return zips
            .split(',')
            .map((item) => item.trim())
            .filter((item) => item.length);
    }

    return [];
};

const validateAge = (val) => {
    if (!val) return '';
    if (
        (!/^[A-Z]+$/i.test(val) && !/^[0-9]+$/.test(Number(val))) ||
        (/^[A-Z]+$/i.test(val) && val.toLowerCase() !== 'any')
    ) {
        return 'Please enter a valid age';
    }
    if (Number(val) < MIN_AGE) {
        return `Min age is ${MIN_AGE}`;
    }
    if (Number(val) > MAX_AGE) {
        return `Max age is ${MAX_AGE}`;
    }
    return '';
};

export {
    normalizeZipCode,
    normalizePhoneNumber,
    validatePhoneNumber,
    currencyInput,
    validateEmails,
    validateSms,
    validateUrl,
    getZips,
    validateAge
};
