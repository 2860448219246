import { Checkmark16 } from '@carbon/icons-react';
import { Button, InlineLoading } from 'carbon-components-react';
import { Products } from 'components/accounts/AccountForm/Products/Products';
import styles from 'components/accounts/AccountForm/Products/Products.module.scss';
import carrierConf from 'data/carrier-conf';
import useAccount from 'hooks/use-account';
import camelCase from 'lodash/camelCase';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import axios from 'util/axios';
import { products } from 'util/constants';

const AccountProducts = () => {
    const { account, id, features, setAccount, subsidyAccount } = useAccount();
    const [saveStatus, setSaveStatus] = useState('');
    const activatedProducts = Object.values(products).filter((product) => features?.[camelCase(`product_${product}`)]);
    const allProductsActivated = activatedProducts.length === Object.keys(products).length;

    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: {}
    });

    const {
        formState: { errors, dirtyFields, isSubmitting },
        handleSubmit,
        reset
    } = methods;

    const cleanForm = !Boolean(Object.keys(dirtyFields).length);
    const hasErrors = Boolean(Object.keys(errors).length);

    const addProducts = async (values) => {
        setSaveStatus(saveStatuses.ACTIVE);

        try {
            const { data } = await axios.post(`account.json?action=update_account_product&id=${id}`, values.product);
            if (data?.error) throw new Error();

            setSaveStatus(saveStatuses.FINISHED);
            setAccount({
                ...account,
                features: data.features
            });
            reset({});
        }
        catch (err) {
            setSaveStatus(saveStatuses.ERROR);
        }
        finally {
            window.setTimeout(() => setSaveStatus(''), 1500);
        }
    };

    const SubmitButton = () => (
        <div className={styles.buttonContainer}>
            {saveStatus && (
                <InlineLoading
                    status={saveStatus}
                    description={
                        saveStatus === saveStatuses.ACTIVE
                            ? 'Adding products...'
                            : saveStatus === saveStatuses.ERROR
                                ? 'Error, please try again...'
                                : 'Success'
                    }
                />
            )}
            <Button
                renderIcon={Checkmark16}
                type="submit"
                disabled={cleanForm || hasErrors || allProductsActivated || isSubmitting}
            >
                Add Products
            </Button>
        </div>
    );

    return (
        <div className={styles.accountProducts}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(addProducts)}>
                    <Products
                        carrier={subsidyAccount && carrierConf['State Farm']}
                        namespace="product"
                        formDisabled={false}
                        activatedProducts={activatedProducts}
                        submitButton={<SubmitButton />}
                    />
                </form>
            </FormProvider>
        </div>
    );
};

const saveStatuses = {
    ACTIVE: 'active',
    ERROR: 'error',
    FINISHED: 'finished'
};

export { AccountProducts };
