import { PageSection } from 'components/common';
import productsIcon from 'img/products.svg';
import { NonSubProducts } from './NonSubProducts';
import styles from './Products.module.scss';
import { SubsidyProducts } from './SubsidyProducts';

const Products = ({ namespace, formDisabled, carrier, activatedProducts = [], submitButton }) => {
    const isAccountCreation = !activatedProducts.length;
    const isSubsidy = Boolean(carrier);
    const isEdit = !isAccountCreation;

    return (
        <PageSection className={styles.productPageSection} name="Products" icon={productsIcon}>
            <div className="bx--grid bx--grid--full-width">
                {isSubsidy ? (
                    <SubsidyProducts
                        namespace={namespace}
                        formDisabled={formDisabled}
                        activatedProducts={activatedProducts}
                        isEdit={isEdit}
                    />
                ) : (
                    <NonSubProducts
                        namespace={namespace}
                        formDisabled={formDisabled}
                        activatedProducts={activatedProducts}
                    />
                )}
                {isEdit && submitButton}
            </div>
        </PageSection>
    );
};

export { Products };
