import { TooltipDefinition } from 'carbon-components-react';
import { LeadMetrics } from 'components/leads';
import { leadDispositions, LeadFlag, LeadStatusBadge } from 'components/leads/LeadDisposition';
import { DefaultTable } from 'components/tables';
import { subDays } from 'date-fns';
import useAccount from 'hooks/use-account';
import useApi from 'hooks/use-api';
import useQueryParams, { makeParamString } from 'hooks/use-query-params';
import useStoredQuery from 'hooks/use-stored-query';
import useUser from 'hooks/use-user';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { products } from 'util/constants';
import { dateToString, relativePrettyString, toUserTimeZone } from 'util/format-date';
import { dollarAmount } from 'util/format-number';
import { capitalizeFirstLetter, truncateText } from 'util/format-text';
import { setRowIds } from 'util/table-helpers';

// Hide these columns
// Keep them in the table so they are searchable
const HIDDEN_COLUMNS = ['leadId', 'email', 'cost', 'salesTax'];

const LeadList = ({ type, setExportData }) => {
    const { id } = useAccount();
    const { query } = useQueryParams();
    const { employee } = useUser();

    const isPurchased = type === types.PURCHASED;
    const filters = getFilters(isPurchased);
    useStoredQuery(filters);

    const dateFrom = query.get('date_from') || dateToString(subDays(new Date(), 30));
    const dateTo = query.get('date_to') || dateToString(new Date());

    const leadStatus = query.get('lead_status');
    const revisitLater = query.get('revisit_later');
    const product = query.get('product');

    const params = useMemo(
        () => ({
            advertiser_id: id,
            date_from: dateFrom,
            date_to: dateTo,
            ...(!isPurchased && { type: 'return' })
        }),
        [id, dateFrom, dateTo, type]
    );
    const [result, loading, error] = useApi(`/lead.json?action=get_leads&${makeParamString(params)}`);

    useEffect(() => {
        setExportData({
            visible: Boolean(result?.leads?.length),
            params: {
                action: 'export_leads',
                lead_status: leadStatus?.split(',') || [],
                product,
                ...(revisitLater && { revisit_later: revisitLater }),
                ...params
            }
        });
    }, [result, params, setExportData]);

    const agentReturnsView = useMemo(() => !isPurchased && !employee, [type, employee]);

    const columns = useMemo(() => getColumns(type, agentReturnsView), [type, agentReturnsView]);

    const data = useMemo(() => {
        if (result?.leads) {
            let rows = setRowIds(result.leads, 'leadId');

            return rows;
        }
        return [];
    }, [result]);

    const metrics = useMemo(
        () => ({
            spend: result?.spend || '0.00',
            lsale: result?.count || 0,
            cpl: result?.cpl || '0.00',
            returnRate: result?.returnRate || 0
        }),
        [result]
    );

    const modifiers = {
        status: (rowData, updateRowData, i) => {
            return <LeadStatusBadge rowData={rowData} updateRowData={updateRowData} />;
        },
        contact: (rowData) => {
            const { contact, email } = rowData;
            return (
                <>
                    {agentReturnsView ? (
                        <div>{truncateText(contact)}</div>
                    ) : (
                        <DetailsPageLink rowData={rowData}>
                            <div>{truncateText(contact)}</div>
                        </DetailsPageLink>
                    )}
                    <div>{truncateText(email)}</div>
                </>
            );
        },
        campaign: ({ campaign, cost, salesTax }) => {
            return (
                <>
                    <div>{campaign}</div>
                    <div>{dollarAmount(cost)}</div>
                    {salesTax > 0 && <div>Sales Tax: {dollarAmount(salesTax)}</div>}
                </>
            );
        },
        revisitLater: (rowData, updateRowData, i) => {
            const { revisitLater } = rowData;
            return (
                <LeadFlag
                    rowData={rowData}
                    onLeadUpdated={updateRowData}
                    flagged={revisitLater}
                    data-supress-navigation
                />
            );
        },
        sellTime: ({ sellTime }) => (
            <TooltipDefinition
                align="center"
                children={relativePrettyString(sellTime)}
                direction="top"
                tooltipText={toUserTimeZone(sellTime)}
            />
        ),
        returnStartTime: ({ returnStartTime }) => (
            <TooltipDefinition
                align="center"
                children={relativePrettyString(returnStartTime)}
                direction="top"
                tooltipText={toUserTimeZone(returnStartTime)}
            />
        ),
        product: ({ product }) => capitalizeFirstLetter(product),
        cost: ({ cost }) => dollarAmount(cost),
        salesTax: ({ salesTax }) => dollarAmount(salesTax)
    };

    return (
        <>
            {isPurchased && <LeadMetrics name="My Stats" data={metrics} />}

            <DefaultTable
                data={data}
                error={error}
                filters={filters}
                hasBatchActions={isPurchased}
                headers={columns}
                hiddenColumns={HIDDEN_COLUMNS}
                loading={loading}
                outputModifiers={modifiers}
                record="lead"
                initialDates={{ dateFrom, dateTo }}
            />
        </>
    );
};

const getFilters = (isPurchased) => (isPurchased ? filters : {});

const filters = {
    product: {
        label: 'Product',
        type: 'dropdown',
        queryString: 'product',
        options: [
            { label: 'Auto', value: products.AUTO },
            { label: 'Home', value: products.HOME },
            { label: 'Health', value: products.HEALTH },
            { label: 'Life', value: products.LIFE }
        ]
    },
    status: {
        label: 'Status',
        type: 'multiSelect',
        queryString: 'lead_status',
        options: leadDispositions
    },
    revisitLater: {
        label: 'Revisit Later',
        type: 'dropdown',
        queryString: 'revisit_later',
        valueType: 'number',
        options: [
            { label: 'Yes', value: 1 },
            { label: 'No', value: 0 }
        ]
    }
};

const getColumns = (type) => {
    const columns = {
        leadId: {
            header: 'Lead ID',
            key: 'leadId'
        },
        contact: {
            header: 'Name',
            key: 'contact'
        },
        campaign: {
            header: 'Consumer Type',
            key: 'campaign'
        },
        email: {
            header: 'Email',
            key: 'email'
        },
        phone: {
            header: 'Phone',
            key: 'phone'
        },
        sellTime: {
            header: 'Received',
            key: 'sellTime'
        },
        cost: {
            header: 'Cost',
            key: 'cost'
        },
        salesTax: {
            header: 'Sales Tax',
            key: 'salesTax'
        },
        returnStartTime: {
            header: 'Returned',
            key: 'returnStartTime'
        },
        returnReason: {
            header: 'Reason',
            key: 'returnReason'
        },
        status: {
            header: 'Status',
            key: 'status'
        },
        product: {
            header: 'Product',
            key: 'product'
        },
        revisitLater: {
            header: 'Revisit',
            key: 'revisitLater'
        }
    };

    const purchased = [
        'leadId',
        'contact',
        'email',
        'phone',
        'product',
        'campaign',
        'sellTime',
        'cost',
        'salesTax',
        'status',
        'revisitLater'
    ];
    const returned = [
        'leadId',
        'contact',
        'product',
        'campaign',
        'returnReason',
        'sellTime',
        'returnStartTime',
        'cost',
        'salesTax'
    ];

    const fields = type === 'returned' ? returned : purchased;
    return fields.map((field) => columns[field]);
};

const types = { PURCHASED: 'purchased', RETURNED: 'returned' };

const DetailsPageLink = ({ rowData, children }) => (
    <Link to={getDetailsPagePath(rowData)} data-suppress-navigation>
        {children}
    </Link>
);

const getDetailsPagePath = ({ leadId, adGroupId }) => `/p/leads/${leadId}/${adGroupId}`;

export default LeadList;
