import { Add16, Information16, TrashCan16 } from '@carbon/icons-react';
import {
    Button,
    DataTable,
    Modal,
    MultiSelect,
    Select,
    SelectItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableSelectAll,
    TableSelectRow,
    TextInput,
    TooltipIcon
} from 'carbon-components-react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { capitalizeFirstLetter } from 'util/format-text';
import { getRandomId } from 'util/table-helpers';
import { validateAge } from 'util/validate-form';
import { BidField, getMinBid } from './BidField';
import { CampaignDailyCapField } from './Campaigns';
import styles from './Campaigns.module.scss';
import { tooltips } from './campaign-conf';

const CUSTOM_LIMIT = 5;
const any = capitalizeFirstLetter('any');
const CAMPAIGN = 'campaign';

const getHeaderData = (showDailyCapLevel = false) =>
    showDailyCapLevel ? headerData : headerData.filter((col) => col.key !== 'dailyCap');

const CustomAutoCampaigns = ({ namespace, formDisabled, product }) => {
    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        clearErrors,
        unregister,
        setDeletedFields
    } = useFormContext();

    const campaignsData = getValues(namespace);
    const customs = Object.values(campaignsData).filter((c) => !!c.custom);

    const [root, , section] = namespace.split('.');
    const customErrors = errors[root]?.[product]?.[section] || {};
    const dailyCapLevel = getValues(`${namespace}.dailyCapLevel`);

    const [deleteModal, setDeleteModal] = useState({ open: false, campaignKey: null, campaignName: '' });

    const handleOpen = (campaignKey, campaignName) => {
        setDeleteModal({ open: true, campaignName, campaignKey });
    };

    const handleClose = () => {
        setDeleteModal({ open: false, campaignKey: null, campaignName: '' });
    };

    const deleteCustom = () => {
        setDeletedFields(true);
        unregister(deleteModal.campaignKey);
        handleClose();
    };

    const handleCheckAll = () => {
        const someEnabled = customs.some((c) => c.enabled);
        customs.forEach((c) => {
            const { campaignId, customId } = c;
            setValue(`${namespace}.${campaignId ? campaignId : customId}.enabled`, !someEnabled, {
                shouldDirty: true,
                shouldValidate: true
            });
        });
    };

    const createRow = (custom) => {
        const { enabled, campaignId, customId, targeting } = custom;
        // existing custom campaigns will have a campaignId
        // new custom campaigns will have a customId
        const customKey = campaignId || customId;
        const disabled = formDisabled || !enabled;
        const currentCampaign = `${namespace}.${customKey}`;
        const minBid = getMinBid();

        const handleSelectChange = (name, { selectedItems }) => {
            setValue(
                name,
                selectedItems.map((item) => item.label),
                { shouldDirty: true }
            );
        };

        const getDefaultCreditRating = () => {
            if (targeting && targeting?.creditRating?.length) {
                return targeting.creditRating.map((v) => ({ id: v, label: v }));
            }
            return ['Excellent', 'Good', 'Average', 'Below average', 'Poor'];
        };

        const validateName = (val) => {
            if (!enabled) {
                return true;
            }
            if (val.length > 25) {
                return 'Max 25 characters';
            }
            return true;
        };

        // registering custom onChange inputs
        const fromNumAccidentsAtFault = register(`${currentCampaign}.targeting.numAccidentsAtFault.from`, {
            validate: (val) => {
                if (
                    (toNumAccidentsAtFaultValue !== 'any' || val !== 'any') &&
                    Number(val) > Number(toNumAccidentsAtFaultValue)
                ) {
                    return 'Min must be smaller than max';
                }
                clearErrors([fromNumAccidentsAtFault.name, toNumAccidentsAtFault.name]);
                return true;
            }
        });

        const toNumAccidentsAtFault = register(`${currentCampaign}.targeting.numAccidentsAtFault.to`, {
            validate: (val) => {
                if (
                    (fromNumAccidentsAtFaultValue !== 'any' || val !== 'any') &&
                    Number(val) < Number(fromNumAccidentsAtFaultValue)
                ) {
                    return 'Max must be greater than min';
                }
                clearErrors([fromNumAccidentsAtFault.name, toNumAccidentsAtFault.name]);
                return true;
            }
        });

        const fromNumTickets = register(`${currentCampaign}.targeting.numTickets.from`, {
            validate: (val) => {
                if ((toNumTicketsValue !== 'any' || val !== 'any') && Number(val) > Number(toNumTicketsValue)) {
                    return 'Min must be smaller than max';
                }
                clearErrors([fromNumTickets.name, toNumTickets.name]);
                return true;
            }
        });

        const toNumTickets = register(`${currentCampaign}.targeting.numTickets.to`, {
            validate: (val) => {
                if ((fromNumTicketsValue !== 'any' || val !== 'any') && Number(val) < Number(fromNumTicketsValue)) {
                    return 'Max must be greater than min';
                }
                clearErrors([fromNumTickets.name, toNumTickets.name]);
                return true;
            }
        });

        const fromNumCars = register(`${currentCampaign}.targeting.numCars.from`, {
            validate: (val) => {
                if ((toNumCarsValue !== 'any' || val !== 'any') && Number(val) > Number(toNumCarsValue)) {
                    return 'Min must be smaller than max';
                }
                clearErrors([fromNumCars.name, toNumCars.name]);
                return true;
            }
        });

        const toNumCars = register(`${currentCampaign}.targeting.numCars.to`, {
            validate: (val) => {
                if ((fromNumCarsValue !== 'any' || val !== 'any') && Number(val) < Number(fromNumCarsValue)) {
                    return 'Max must be greater than min';
                }
                clearErrors([fromNumCars.name, toNumCars.name]);
                return true;
            }
        });

        const fromNumTicketsValue = getValues(fromNumTickets.name);
        const toNumTicketsValue = getValues(toNumTickets.name);

        const fromNumAccidentsAtFaultValue = getValues(fromNumAccidentsAtFault.name);
        const toNumAccidentsAtFaultValue = getValues(toNumAccidentsAtFault.name);

        const fromNumCarsValue = getValues(fromNumCars.name);
        const toNumCarsValue = getValues(toNumCars.name);

        const fromNumAccidentsAtFaultErrors =
            customErrors[customKey] && customErrors[customKey]?.targeting?.numAccidentsAtFault?.from;

        const toNumAccidentsAtFaultErrors =
            customErrors[customKey] && customErrors[customKey]?.targeting?.numAccidentsAtFault?.to;

        const fromNumTicketsErrors = customErrors[customKey] && customErrors[customKey]?.targeting?.numTickets?.from;
        const toNumTicketsErrors = customErrors[customKey] && customErrors[customKey]?.targeting?.numTickets?.to;

        const fromNumCarsErrors = customErrors[customKey] && customErrors[customKey]?.targeting?.numCars?.from;
        const toNumCarsErrors = customErrors[customKey] && customErrors[customKey]?.targeting?.numCars?.to;

        return {
            id: `row${customKey}`,
            key: customKey,
            name: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <TextInput
                        id={`${currentCampaign}.campaign`}
                        labelText=""
                        {...register(`${currentCampaign}.campaign`, {
                            validate: (val) => validateName(val)
                        })}
                        defaultValue={getValues(`${currentCampaign}.campaign`) || ''}
                        invalid={customErrors ? customErrors[customKey] && !!customErrors[customKey]?.campaign : false}
                        invalidText={customErrors ? customErrors[customKey]?.campaign?.message : ''}
                    />
                </fieldset>
            ),
            bid: <BidField namespace={namespace} campaignKey={customKey} disabled={disabled} minBid={minBid} />,
            dailyCap: <CampaignDailyCapField namespace={namespace} campaignKey={customKey} disabled={disabled} />,
            age: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <Age currentCampaign={currentCampaign} customKey={customKey} customErrors={customErrors} />
                </fieldset>
            ),
            insured: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <Select
                        id={`${currentCampaign}.targeting.continuousCoverage`}
                        {...register(`${currentCampaign}.targeting.continuousCoverage`)}
                        defaultValue={String(custom.targeting.continuousCoverage)}
                    >
                        {options.coverage.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                </fieldset>
            ),
            accidents: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <Select
                        className={styles.incidentsFrom}
                        id={fromNumAccidentsAtFault.name}
                        name={fromNumAccidentsAtFault.name}
                        labelText="Min"
                        ref={fromNumAccidentsAtFault.ref}
                        defaultValue={custom.targeting.numAccidentsAtFault.from}
                        onChange={(evt) => {
                            fromNumAccidentsAtFault.onChange(evt);
                            setValue(fromNumAccidentsAtFault.name, evt.target.value, {
                                shouldDirty: true,
                                shouldValidate: true
                            });
                        }}
                        onBlur={fromNumAccidentsAtFault.onBlur}
                        invalid={!!fromNumAccidentsAtFaultErrors}
                        invalidText={fromNumAccidentsAtFaultErrors?.message}
                    >
                        {options.incidents.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                    <Select
                        className={styles.incidentsTo}
                        id={toNumAccidentsAtFault.name}
                        name={toNumAccidentsAtFault.name}
                        labelText="Max"
                        ref={toNumAccidentsAtFault.ref}
                        defaultValue={custom.targeting.numAccidentsAtFault.to}
                        onChange={(evt) => {
                            toNumAccidentsAtFault.onChange(evt);
                            setValue(toNumAccidentsAtFault.name, evt.target.value, {
                                shouldDirty: true,
                                shouldValidate: true
                            });
                        }}
                        onBlur={toNumAccidentsAtFault.onBlur}
                        invalid={!!toNumAccidentsAtFaultErrors}
                        invalidText={toNumAccidentsAtFaultErrors?.message}
                    >
                        {options.incidents.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                </fieldset>
            ),
            tickets: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <Select
                        className={styles.incidentsFrom}
                        id={fromNumTickets.name}
                        name={fromNumTickets.name}
                        ref={fromNumTickets.ref}
                        labelText="Min"
                        options={options.incidents}
                        defaultValue={custom.targeting.numTickets.from}
                        onChange={(evt) => {
                            fromNumTickets.onChange(evt);
                            setValue(fromNumTickets.name, evt.target.value, {
                                shouldDirty: true,
                                shouldValidate: true
                            });
                        }}
                        onBlur={fromNumTickets.onBlur}
                        invalid={!!fromNumTicketsErrors}
                        invalidText={fromNumTicketsErrors?.message}
                    >
                        {options.incidents.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                    <Select
                        className={styles.incidentsTo}
                        id={toNumTickets.name}
                        name={toNumTickets.name}
                        ref={toNumTickets.ref}
                        labelText="Max"
                        options={options.incidents}
                        defaultValue={custom.targeting.numTickets.to}
                        onChange={(evt) => {
                            toNumTickets.onChange(evt);
                            setValue(toNumTickets.name, evt.target.value, { shouldDirty: true, shouldValidate: true });
                        }}
                        onBlur={toNumTickets.onBlur}
                        invalid={!!toNumTicketsErrors}
                        invalidText={toNumTicketsErrors?.message}
                    >
                        {options.incidents.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                </fieldset>
            ),
            licensed: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <Select
                        id={`${currentCampaign}.targeting.licenseActive`}
                        {...register(`${currentCampaign}.targeting.licenseActive`)}
                        defaultValue={String(custom.targeting.licenseActive)}
                    >
                        {options.basic.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                </fieldset>
            ),
            dui: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <Select
                        id={`${currentCampaign}.targeting.dui`}
                        {...register(`${currentCampaign}.targeting.dui`)}
                        defaultValue={String(custom.targeting.dui)}
                    >
                        {options.basic.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                </fieldset>
            ),
            sr_22: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <Select
                        id={`${currentCampaign}.targeting.sr_22`}
                        {...register(`${currentCampaign}.targeting.sr_22`)}
                        defaultValue={String(custom.targeting.sr_22)}
                    >
                        {options.basic.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                </fieldset>
            ),
            homeowner: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <Select
                        id={`${currentCampaign}.targeting.homeOwnership`}
                        {...register(`${currentCampaign}.targeting.homeOwnership`)}
                        defaultValue={String(custom.targeting.homeOwnership)}
                    >
                        {options.basic.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                </fieldset>
            ),
            credit: (
                <MultiSelect
                    {...register(`${currentCampaign}.targeting.creditRating`)}
                    id={`${currentCampaign}.targeting.creditRating`}
                    items={options.credit.map((o) => ({ id: o, label: o }))}
                    sortItems={(items) => items}
                    disabled={disabled}
                    label=""
                    initialSelectedItems={getDefaultCreditRating()}
                    // does not expose event object
                    onChange={(e) => handleSelectChange(`${currentCampaign}.targeting.creditRating`, e)}
                />
            ),
            multiVehicle: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <Select
                        className={styles.incidentsFrom}
                        id={fromNumCars.name}
                        name={fromNumCars.name}
                        ref={fromNumCars.ref}
                        labelText="Min"
                        options={options.vehicles}
                        defaultValue={custom.targeting.numCars.from}
                        onChange={(evt) => {
                            fromNumCars.onChange(evt);
                            setValue(fromNumCars.name, evt.target.value, {
                                shouldDirty: true,
                                shouldValidate: true
                            });
                        }}
                        onBlur={fromNumCars.onBlur}
                        invalid={!!fromNumCarsErrors}
                        invalidText={fromNumCarsErrors?.message}
                    >
                        {options.vehicles.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                    <Select
                        className={styles.incidentsTo}
                        id={toNumCars.name}
                        name={toNumCars.name}
                        ref={toNumCars.ref}
                        labelText="Max"
                        options={options.vehicles}
                        defaultValue={custom.targeting.numCars.to}
                        onChange={(evt) => {
                            toNumCars.onChange(evt);
                            setValue(toNumCars.name, evt.target.value, {
                                shouldDirty: true,
                                shouldValidate: true
                            });
                        }}
                        onBlur={toNumCars.onBlur}
                        invalid={!!toNumCarsErrors}
                        invalidText={toNumCarsErrors?.message}
                    >
                        {options.vehicles.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                </fieldset>
            ),
            delete: (
                <Button
                    onClick={() => handleOpen(currentCampaign, custom.campaign)}
                    hasIconOnly
                    renderIcon={TrashCan16}
                    tooltipAlignment="center"
                    tooltipPosition="top"
                    iconDescription="Delete"
                    disabled={formDisabled}
                />
            ),
            isSelected: Boolean(enabled)
        };
    };

    // useMemo doesn't re-render if "customs" is supplied in the dependency array, it needs the enabled flag of each custom campaign to trigger a re-render
    const customsEnabled = customs.map((c) => c.enabled);

    const rowData = useMemo(() => customs.map((c, i) => createRow(c, i)), [customsEnabled, formDisabled]);

    return (
        <>
            {!!customs.length && (
                <DataTable rows={rowData} headers={getHeaderData(dailyCapLevel === CAMPAIGN)}>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getSelectionProps,
                        getRowProps,
                        getTableProps,
                        selectRow,
                        selectAll
                    }) => (
                        <TableContainer className={styles.customsTable} style={{ marginTop: 20 }}>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        <TableSelectAll
                                            {...getSelectionProps()}
                                            onSelect={() => {
                                                handleCheckAll();
                                                selectAll();
                                            }}
                                            disabled={formDisabled}
                                        />
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({ header })}>
                                                <div className={styles.headerCell}>
                                                    {header.header}
                                                    {tooltips[header.header] && (
                                                        <TooltipIcon
                                                            tooltipText={tooltips[header.header]}
                                                            direction="top"
                                                        >
                                                            <Information16 />
                                                        </TooltipIcon>
                                                    )}
                                                </div>
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <RenderTableBody
                                    rows={rows}
                                    getRowProps={getRowProps}
                                    formDisabled={formDisabled}
                                    selectRow={selectRow}
                                    getSelectionProps={getSelectionProps}
                                    rowData={rowData}
                                    namespace={namespace}
                                />
                            </Table>
                        </TableContainer>
                    )}
                </DataTable>
            )}
            {!formDisabled && (
                <Button
                    renderIcon={Add16}
                    onClick={() => {
                        const customsCreated = customs ? customs.length + 1 : 1;
                        const campaignKey = `new_custom_${customsCreated}`;
                        const defaultNew = {
                            campaign: `Custom ${customsCreated}`,
                            enabled: true,
                            custom: 1,
                            bid: '',
                            dailyCap: 0,
                            targeting: {
                                age: {
                                    from: any,
                                    to: any
                                },
                                continuousCoverage: 'any',
                                numAccidentsAtFault: {
                                    from: 'any',
                                    to: 'any'
                                },
                                numTickets: {
                                    from: 'any',
                                    to: 'any'
                                },
                                licenseActive: 'any',
                                dui: 'any',
                                sr_22: 'any',
                                homeOwnership: 'any',
                                creditRating: ['Excellent', 'Good', 'Average', 'Below average', 'Poor'],
                                numCars: {
                                    from: 'any',
                                    to: 'any'
                                }
                            },
                            customId: campaignKey
                        };
                        setValue(`${namespace}.${campaignKey}`, defaultNew, { shouldDirty: true });
                    }}
                    disabled={customs.length >= CUSTOM_LIMIT}
                    style={{ marginTop: 20 }}
                >
                    Add Custom
                </Button>
            )}
            <Modal
                size="xs"
                open={deleteModal.open}
                modalHeading="Delete Confirmation"
                primaryButtonText="Delete"
                secondaryButtonText="Cancel"
                onRequestClose={handleClose}
                onRequestSubmit={deleteCustom}
                danger
            >
                <p>Are you sure you want to delete {deleteModal.campaignName}?</p>
            </Modal>
        </>
    );
};

const RenderTableBody = ({ rows, getRowProps, formDisabled, selectRow, getSelectionProps, rowData, namespace }) => {
    const {
        register,
        setValue,
        clearErrors,
        trigger,
        formState: { errors }
    } = useFormContext();
    const [root, product, section] = namespace.split('.');
    const customErrors = errors[root]?.[product]?.[section] || {};

    const setDefaultTargeting = (val) => {
        setValue(val, 'any');
        clearErrors(val);
    };

    const setValuesToDefault = (rowData) => {
        const { key } = rowData;
        const currentCampaign = `${namespace}.${key}`;
        const fromNumAccidentsAtFault = `${currentCampaign}.targeting.numAccidentsAtFault.from`;
        const toNumAccidentsAtFault = `${currentCampaign}.targeting.numAccidentsAtFault.to`;
        const fromNumTickets = `${currentCampaign}.targeting.numTickets.from`;
        const toNumTicket = `${currentCampaign}.targeting.numTickets.to`;
        const name = `${currentCampaign}.name`;

        const accidentsAtFaultErrors = customErrors[key]?.targeting?.numAccidentsAtFault;
        const ticketsErrors = customErrors[key]?.targeting?.numTickets;

        if (accidentsAtFaultErrors?.from) {
            setDefaultTargeting(fromNumAccidentsAtFault);
        }
        if (accidentsAtFaultErrors?.to) {
            setDefaultTargeting(toNumAccidentsAtFault);
        }
        if (ticketsErrors?.from) {
            setDefaultTargeting(fromNumTickets);
        }
        if (ticketsErrors?.to) {
            setDefaultTargeting(toNumTicket);
        }
        if (customErrors[key]?.name) {
            setValue(name, `Custom ${getRandomId(5)}`);
            clearErrors(name);
        }
    };

    const handleSelectCampaign = ({ target }, row) => {
        const { name, checked } = target;
        if (!checked && customErrors && customErrors[row.key]) {
            setValuesToDefault(row);
        }
        setValue(name, target.checked, {
            shouldValidate: true,
            shouldDirty: true
        });
        trigger(`${namespace}.${row.key}`, { shouldFocus: true });
        selectRow(row.id);
    };

    return (
        <TableBody>
            {rows.map((row, i) => {
                if (!rowData[i]) {
                    return null;
                }

                const key = rowData[i]?.key;
                const name = `${namespace}.${key}.enabled`;
                register(name);

                return (
                    <Fragment key={key}>
                        <TableRow {...getRowProps({ row })}>
                            <TableSelectRow
                                {...getSelectionProps({ row })}
                                name={name}
                                checked={row.isSelected}
                                onSelect={(evt) => handleSelectCampaign(evt, rowData[i])}
                                disabled={formDisabled}
                            />
                            {row.cells.map((cell) => {
                                return (
                                    <TableCell key={cell.id} className={styles.tableCell}>
                                        <div className={`${styles.dataCell} ${styles[cell.info.header] || ''}`}>
                                            {cell.value}
                                        </div>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </Fragment>
                );
            })}
        </TableBody>
    );
};

const Age = ({ currentCampaign, customKey, customErrors }) => {
    const { register, setValue, getValues, trigger } = useFormContext();

    const fromAgeName = `${currentCampaign}.targeting.age.from`;
    const toAgeName = `${currentCampaign}.targeting.age.to`;

    useEffect(() => {
        if (getValues(fromAgeName) === 'any') {
            setValue(fromAgeName, any);
        }
        if (getValues(toAgeName) === 'any') {
            setValue(toAgeName, any);
        }
    }, [register]);

    const validateAgeFrom = (val) => {
        const maxVal = getValues(toAgeName);
        const errorMsg = validateAge(val);

        if (errorMsg) {
            return errorMsg;
        }
        if (
            val &&
            (maxVal.toString().toLowerCase() !== 'any' || val.toString().toLowerCase() !== 'any') &&
            Number(val) > Number(maxVal)
        ) {
            return 'Min must be smaller than max';
        }
        return true;
    };

    const validateAgeTo = (val) => {
        const minVal = getValues(fromAgeName);
        const errorMsg = validateAge(val);

        if (errorMsg) {
            return errorMsg;
        }
        if (
            val &&
            (minVal.toString().toLowerCase() !== 'any' || val.toString().toLowerCase() !== 'any') &&
            Number(val) < Number(minVal)
        ) {
            return 'Max must be greater than min';
        }
        return true;
    };

    const fromAge = register(fromAgeName, {
        validate: validateAgeFrom
    });

    const toAge = register(toAgeName, {
        validate: validateAgeTo
    });

    const handleOnBlur = (evt, age) => {
        age.onBlur(evt);
        const val = evt.target.value;

        if (!val) {
            evt.target.value = any;
        }
        else if (/^[0-9]+$/.test(Number(evt.target.value))) {
            evt.target.value = Math.trunc(Number(evt.target.value));
        }
        setValue(age.name, evt.target.value, { shouldDirty: true });
        if (customErrors?.[customKey]?.targeting?.age?.from || customErrors?.[customKey]?.targeting?.age?.to) {
            trigger([fromAgeName, toAgeName]);
        }
    };

    return (
        <>
            <TextInput
                id={fromAge.name}
                labelText="Min"
                name={fromAge.name}
                ref={fromAge.ref}
                onChange={fromAge.onChange}
                onBlur={(evt) => handleOnBlur(evt, fromAge)}
                defaultValue={getValues(fromAge.name) || ''}
                invalid={
                    customErrors ? customErrors[customKey] && !!customErrors[customKey]?.targeting?.age?.from : false
                }
                invalidText={customErrors ? customErrors[customKey]?.targeting?.age?.from?.message : ''}
            />
            <TextInput
                id={toAge.name}
                labelText="Max"
                name={toAge.name}
                ref={toAge.ref}
                onChange={toAge.onChange}
                onBlur={(evt) => handleOnBlur(evt, toAge)}
                defaultValue={getValues(toAge.name) || ''}
                invalid={
                    customErrors ? customErrors[customKey] && !!customErrors[customKey]?.targeting?.age?.to : false
                }
                invalidText={customErrors ? customErrors[customKey]?.targeting?.age?.to?.message : ''}
            />
        </>
    );
};

const headerData = [
    {
        header: 'Type',
        key: 'name'
    },
    {
        header: 'Daily Cap',
        key: 'dailyCap'
    },
    {
        header: 'Bid',
        key: 'bid'
    },
    {
        header: 'Age',
        key: 'age'
    },
    {
        header: 'Insured',
        key: 'insured'
    },
    {
        header: 'Accidents',
        key: 'accidents'
    },
    {
        header: 'Tickets',
        key: 'tickets'
    },
    {
        header: 'Licensed',
        key: 'licensed'
    },
    {
        header: 'DUI',
        key: 'dui'
    },
    {
        header: 'SR-22',
        key: 'sr_22'
    },
    {
        header: 'Homeowner',
        key: 'homeowner'
    },
    {
        header: 'Credit',
        key: 'credit'
    },
    {
        header: 'Multi-Vehicle',
        key: 'multiVehicle'
    },
    {
        header: '',
        key: 'delete'
    }
];

const options = {
    basic: [
        {
            label: 'Any',
            value: 'any'
        },
        {
            label: 'Yes',
            value: 1
        },
        {
            label: 'No',
            value: 0
        }
    ],
    coverage: [
        {
            label: 'Any',
            value: 'any'
        },
        {
            label: '0 Months (Uninsured)',
            value: 0
        },
        {
            label: '6+ Months',
            value: 6
        },
        {
            label: '12+ Months',
            value: 12
        }
    ],
    credit: ['Excellent', 'Good', 'Average', 'Below average', 'Poor'],
    incidents: [
        {
            label: 'Any',
            value: 'any'
        },
        {
            label: '0',
            value: 0
        },
        {
            label: '1',
            value: 1
        },
        {
            label: '2',
            value: 2
        },
        {
            label: '3',
            value: 3
        }
    ],
    vehicles: [
        {
            label: 'Any',
            value: 'any'
        },
        {
            label: '1',
            value: 1
        },
        {
            label: '2',
            value: 2
        },
        {
            label: '3',
            value: 3
        }
    ]
};

export { CustomAutoCampaigns as default, options };
