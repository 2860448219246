import styles from './MobileTile.module.scss';

const MobileTile = ({ outputModifiers, headers, rowData, hiddenColumns, updateRowData }) => {
    return (
        <div className={styles.mobileTile}>
            {headers.map((cell, i) => {
                const { header, key } = cell;
                const mobilePrimaryRow = i === 0;
                const cellContent = outputModifiers[key] ? outputModifiers[key](rowData, updateRowData) : rowData[key];

                if (hiddenColumns.includes(key)) return null;

                return (
                    <div className={mobilePrimaryRow ? styles.primary : styles.row} key={`tileRow${i}`}>
                        {mobilePrimaryRow ? (
                            cellContent
                        ) : (
                            <>
                                <span className={styles.header}>{header}</span>
                                <span className={styles.data}>{cellContent}</span>
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default MobileTile;
