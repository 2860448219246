import { Checkmark32 } from '@carbon/icons-react';
import { Button, Column, Form, FormGroup, PasswordInput, Row, ToastNotification } from 'carbon-components-react';
import PasswordRequirements, { regex } from 'components/common/PasswordRequirements';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'util/axios';
import { formatError } from 'util/format-error';
import styles from './UpdatePassword.module.scss';

const UpdatePassword = () => {
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const initialValues = {
        currentPassword: '',
        password: '',
        password2: ''
    };

    const onSubmit = async (values) => {
        const { currentPassword, password } = values;

        setSuccess(false);
        setError(false);

        try {
            await axios.post('profile.json?action=update_password', {
                password: currentPassword,
                newPassword: password
            });

            setSuccess('Password Updated Successfully');
        }
        catch (err) {
            const { message } = formatError(err);
            setError(message);
        }
        finally {
            reset();
        }
    };

    const handleClose = () => {
        setSuccess(null);
        setError(null);
    };

    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isValid, errors },
        watch,
        reset
    } = useForm({
        mode: 'onTouched',
        defaultValues: {
            ...initialValues
        }
    });

    const status = error ? 'Error' : 'Success';

    return (
        <>
            {(error || success) && (
                <ToastNotification
                    kind={error ? 'error' : 'success'}
                    onCloseButtonClick={handleClose}
                    title={status}
                    lowContrast
                    iconDescription="Close"
                    statusIconDescription={error ? 'Error' : 'Success'}
                    caption={error || success}
                    timeout={4000}
                />
            )}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup legendText="">
                    <Row className={styles.rowItem}>
                        <Column lg={3}>
                            <PasswordInput
                                labelText="Current Password"
                                {...register('currentPassword', {
                                    required: 'Please enter your current password'
                                })}
                                id={'currentPassword'}
                                defaultValue={initialValues['currentPassword']}
                                invalid={!!errors.currentPassword}
                                invalidText={errors.currentPassword?.message}
                            />
                        </Column>
                    </Row>
                    <Row className={styles.rowItem}>
                        <Column lg={3}>
                            <PasswordInput
                                labelText="New Password"
                                {...register('password', {
                                    required: 'Please enter a new password',
                                    minLength: {
                                        value: 8,
                                        message: 'Password must be at least 8 characters'
                                    },
                                    validate: (val) => validatePassword(val)
                                })}
                                id={'password'}
                                defaultValue={initialValues['password']}
                                invalid={!!errors.password}
                                invalidText={errors.password?.message}
                            />
                        </Column>
                    </Row>
                    <Row>
                        <Column lg={3}>
                            <PasswordInput
                                labelText="Confirm Password"
                                {...register('password2', {
                                    validate: (val) => val === watch('password') || 'Passwords do not match'
                                })}
                                id={'password2'}
                                defaultValue={initialValues['password2']}
                                invalid={!!errors.password2}
                                invalidText={errors.password2?.message}
                            />
                        </Column>
                    </Row>
                    <PasswordRequirements
                        className={styles.passwordRequirements}
                        password={watch('password')}
                        password2={watch('password2')}
                    />
                    <Button type="submit" renderIcon={Checkmark32} disabled={isSubmitting || !isValid}>
                        Update Password
                    </Button>
                </FormGroup>
            </Form>
        </>
    );
};

const validatePassword = (val) => {
    if (!val) return false;
    const { upperCase, lowerCase, number, special } = regex;
    const firstCondition = upperCase.test(val) && lowerCase.test(val) && number.test(val) && special.test(val);
    const secondCondition = upperCase.test(val) && lowerCase.test(val) && number.test(val);
    const thirdCondition = upperCase.test(val) && lowerCase.test(val);

    if (
        (val.length < 12 && !firstCondition) ||
        (val.length < 16 && !secondCondition) ||
        (val.length < 20 && !thirdCondition)
    ) {
        return 'Password requirements not met';
    }
    return true;
};

export default UpdatePassword;
