import { PrivacyContent } from 'components/legal';
import Layout from 'layouts/Centered';
import { Helmet as Head } from 'react-helmet-async';

const Privacy = () => (
    <>
        <Head>
            <title>Privacy Policy</title>
        </Head>
        <Layout title="MediaAlpha for Agents Privacy Policy">
            <PrivacyContent />
        </Layout>
    </>
);

export default Privacy;
