import { Error16, Pause16, Play16, WarningAltFilled16 } from '@carbon/icons-react';
import { Button, DatePicker, DatePickerInput, Modal, ToastNotification } from 'carbon-components-react';
import { addDays, addMonths } from 'date-fns';
import { motion } from 'framer-motion';
import useAccount, { statuses } from 'hooks/use-account';
import useOutsideClickToClose from 'hooks/use-outside-click-to-close';
import useUser from 'hooks/use-user';
import { useRef, useState } from 'react';
import { dateToDatePickerString, dateToString, serverTimeToZonedTime } from 'util/format-date';
import { AccountCancellationModal } from '../AccountStatus/AccountStatus';
import styles from './AccountStatusButton.module.scss';

const AccountStatusButton = ({ ...rest }) => {
    const { employee } = useUser();
    const { status, updateStatus, addStop, stopEndTime } = useAccount();
    const [updateStatusError, setUpdateStatusError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showPauseModal, setShowPauseModal] = useState(false);
    const [unpauseDate, setUnpauseDate] = useState(null);

    const pauseAccountMenuRef = useRef();
    const [pauseAccountMenuOpen, setPauseAccountMenuOpen] = useState(false);
    useOutsideClickToClose(pauseAccountMenuRef, pauseAccountMenuOpen, setPauseAccountMenuOpen);

    const enabled = status === statuses.ENABLED;
    const icon = enabled ? Pause16 : Play16;
    const text = enabled ? 'Pause Account' : 'Enable Account';
    const kind = enabled ? 'secondary' : 'primary';
    const stopEndTimeDate = dateToDatePickerString(serverTimeToZonedTime(stopEndTime)?.[0]);

    if (status === statuses.PENDING || status === statuses.UNSOLD || status === statuses.TEST) return null;

    const submitCancelStatus = async () => {
        setShowModal(false);
        const statusError = await updateStatus(statuses.CANCELED);
        statusError && setUpdateStatusError('Failed to cancel account. Please try again.');
    };

    const submitTogglePause = async () => {
        const statusError = await updateStatus();
        statusError && setUpdateStatusError(`Failed to update account status. Please try again.`);
    };

    const submitPauseWithDate = async () => {
        setShowPauseModal(false);
        const statusError = await addStop(unpauseDate);
        statusError && setUpdateStatusError('Failed to pause account. Please try again.');
    };

    const onPauseModalClose = () => {
        setShowPauseModal(false);
        setUnpauseDate(null);
    };

    const handleClick = () => (enabled ? setPauseAccountMenuOpen(true) : submitTogglePause());

    return (
        <>
            {updateStatusError && (
                <ToastNotification
                    title="Error"
                    caption={<span>{updateStatusError}</span>}
                    timeout={7000}
                    onClose={() => setUpdateStatusError('')}
                    lowContrast
                />
            )}

            {showModal && (
                <AccountCancellationModal
                    onRequestClose={() => setShowModal(false)}
                    onRequestSubmit={submitCancelStatus}
                />
            )}

            {showPauseModal && (
                <AccountPauseModal
                    onRequestClose={onPauseModalClose}
                    onRequestSubmit={submitPauseWithDate}
                    unpauseDate={unpauseDate}
                    setUnpauseDate={setUnpauseDate}
                />
            )}

            <div className={styles.accountControls}>
                {status === statuses.PAUSED && stopEndTime && (
                    <div className={styles.pausedMessage}>
                        <WarningAltFilled16 />
                        <span>Your account is currently paused until {stopEndTimeDate}.</span>
                    </div>
                )}

                <div className={styles.pauseAccBtn}>
                    <Button className="green" kind={kind} renderIcon={icon} onClick={handleClick} {...rest}>
                        <span>{text}</span>
                    </Button>

                    {pauseAccountMenuOpen && (
                        <motion.div className={styles.wrap} {...menuAnim} ref={pauseAccountMenuRef}>
                            <div className={styles.regAccRow}>
                                <Button
                                    className={styles.menuItem}
                                    onClick={() => {
                                        submitTogglePause();
                                        setPauseAccountMenuOpen(false);
                                    }}
                                >
                                    <span className={styles.linkText}>Pause Account</span>
                                </Button>
                            </div>
                            <div className={styles.regAccRow}>
                                <Button
                                    className={styles.menuItem}
                                    onClick={() => {
                                        setShowPauseModal(true);
                                        setPauseAccountMenuOpen(false);
                                    }}
                                >
                                    <span className={styles.linkText}>Pause Until...</span>
                                </Button>
                            </div>
                        </motion.div>
                    )}
                </div>

                {employee && status !== statuses.CANCELED && (
                    <Button kind="danger" renderIcon={Error16} onClick={() => setShowModal(true)} {...rest}>
                        Cancel Account
                    </Button>
                )}
            </div>
        </>
    );
};

const AccountPauseModal = ({ onRequestClose, onRequestSubmit, unpauseDate, setUnpauseDate }) => {
    const minDate = dateToDatePickerString(addDays(new Date(), 1));
    const maxDate = dateToDatePickerString(addMonths(new Date(), 1));
    const onChange = (evt) => {
        const formatted = dateToString(evt[0]);
        setUnpauseDate(formatted);
    };

    return (
        <Modal
            modalHeading="Pause Account Until"
            selectorPrimaryFocus=".bx--modal-close"
            onRequestClose={onRequestClose}
            onRequestSubmit={onRequestSubmit}
            primaryButtonText="Pause Account"
            primaryButtonDisabled={!unpauseDate}
            secondaryButtonText="Cancel"
            size="sm"
            open
            danger
        >
            <DatePicker datePickerType="single" maxDate={maxDate} minDate={minDate} onChange={onChange}>
                <DatePickerInput
                    placeholder="mm/dd/yyyy"
                    labelText="Unpause Account On"
                    id="unpauseDatePicker"
                    hideLabel
                />
            </DatePicker>
            <p style={{ marginTop: '16px' }}>Accounts can only be paused for up to 30 days in advance.</p>
        </Modal>
    );
};

const menuAnim = {
    initial: {
        opacity: 0,
        scale: 0,
        originX: 1,
        originY: 0
    },
    animate: {
        opacity: 1,
        scale: 1
    },
    transition: {
        duration: 0.1
    }
};

export default AccountStatusButton;
