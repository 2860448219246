import { AccountForm } from 'components/accounts';
import { PageHeader } from 'components/common';
import useAccount from 'hooks/use-account';
import { useEffect } from 'react';
import { Helmet as Head } from 'react-helmet-async';

const NewAccount = ({ title, carrier }) => {
    const { account, setAccount } = useAccount();

    // Employees should have their selected account cleared when they visit this page.
    useEffect(() => {
        if (account) setAccount(null);
    }, []);

    const pageTitle = title || `Create New Account`;

    return (
        <>
            <Head>
                <title>{pageTitle}</title>
            </Head>

            <PageHeader title={pageTitle} />
            <AccountForm carrier={carrier} />
        </>
    );
};

export default NewAccount;
