import { MediaAlphaLogo } from 'components/layout';
import { createDomMotionComponent } from 'framer-motion';
import styles from 'styles/layouts/Centered.module.scss';

// IE11 Motion Support
const motion = {
    span: createDomMotionComponent('span'),
    h1: createDomMotionComponent('h1')
};

const Centered = ({ title, children }) => (
    <>
        <header className={styles.header}>
            <motion.span {...fadeIn}>
                <MediaAlphaLogo width={175} />
            </motion.span>
        </header>
        <main className={styles.main}>
            {title && (
                <motion.h1 className={styles.title} {...fadeIn}>
                    {title}
                </motion.h1>
            )}
            {children}
        </main>
    </>
);

const fadeIn = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1
    },
    transition: {
        ease: 'easeOut',
        duration: 1
    }
};

export default Centered;
