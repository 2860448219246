import { CheckmarkFilled20, List20, PauseFilled20, PlayFilledAlt20 } from '@carbon/icons-react';
import { ToastNotification } from 'carbon-components-react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import useAccount, { statuses } from 'hooks/use-account';
import useDevice from 'hooks/use-device';
import useUser from 'hooks/use-user';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AccountInfo from './AccountInfo';
import styles from './AccountMenu.module.scss';

const AccountMenu = ({ onMenuToggle }) => {
    const { account, accounts, recentAccounts, setAccount, canManageAccount } = useAccount();
    const { employee } = useUser();
    const { mobile } = useDevice();
    const history = useHistory();

    const additionalAccounts = accounts.filter((acc) => acc.id !== account.id);

    const showAccountStatusLink = canManageAccount;
    const showRecentAccounts = employee && Boolean(recentAccounts?.length);
    const showAdditionalAccounts = !employee && Boolean(additionalAccounts.length);
    const showAllAccountsLink = employee && !mobile;

    const handleAccountSelect = (selectedAccount) => {
        if (selectedAccount.id !== account.id) {
            setAccount(selectedAccount);
        }
        if (mobile) {
            onMenuToggle();
        }
        history.push('/p/leads');
    };

    // On tablet or desktop, listen for any click to close the menu.
    useEffect(() => {
        if (!mobile) {
            document.addEventListener('click', onMenuToggle);
            return () => document.removeEventListener('click', onMenuToggle);
        }
    }, [mobile, onMenuToggle]);

    return (
        <motion.div className={styles.wrap} {...menuAnim}>
            <MenuAccount account={account} selected={true} onClick={() => handleAccountSelect(account)} />
            {showAccountStatusLink && <AccountStatusLink />}
            {showRecentAccounts && (
                <AlternateAccounts
                    headerText="Recent Accounts"
                    accounts={recentAccounts}
                    onSelect={handleAccountSelect}
                />
            )}
            {showAdditionalAccounts && (
                <AlternateAccounts
                    headerText="Additional Accounts"
                    accounts={additionalAccounts}
                    onSelect={handleAccountSelect}
                />
            )}
            {showAllAccountsLink && (
                <MenuLink text="All Accounts" icon={List20} onClick={() => history.push('/p/accounts')} />
            )}
        </motion.div>
    );
};

const MenuAccount = ({ account, selected, alternate, ...rest }) => {
    return (
        <div className={clsx(!alternate && styles.menuItem, alternate && styles.alternateItem)} {...rest}>
            {selected && <CheckmarkFilled20 className={styles.checkIcon} />}
            <AccountInfo account={account} />
        </div>
    );
};

const MenuLink = ({ text, icon: Icon, ...rest }) => {
    return (
        <div className={clsx(styles.menuItem, styles.menuLink)} {...rest}>
            <Icon className={styles.icon} />
            {text}
        </div>
    );
};

const AccountStatusLink = () => {
    const { account, status, updateStatus } = useAccount();
    const [updateStatusError, setUpdateStatusError] = useState('');

    const enabled = status === statuses.ENABLED;
    const icon = enabled ? PauseFilled20 : PlayFilledAlt20;
    const text = enabled ? 'Pause Account' : 'Enable Account';

    if (!account || ![statuses.ENABLED, statuses.PAUSED].includes(status)) return null;

    return (
        <>
            {updateStatusError && (
                <ToastNotification
                    title="Error"
                    caption={<span>{updateStatusError}</span>}
                    timeout={7000}
                    onCloseButtonClick={() => setUpdateStatusError('')}
                    lowContrast
                />
            )}
            <MenuLink
                icon={icon}
                text={text}
                onClick={async (evt) => {
                    evt.stopPropagation();
                    const statusError = await updateStatus();
                    statusError && setUpdateStatusError('Failed to update status');
                }}
            />
        </>
    );
};

const AlternateAccounts = ({ headerText, accounts, onSelect }) => {
    return (
        <div className={styles.alternateAccounts}>
            <div className={styles.alternateAccountsHeader} onClick={(evt) => evt.stopPropagation()}>
                {headerText}
            </div>
            <div className={styles.alternateAccountItems}>
                {accounts.map((acc, i) => (
                    <MenuAccount account={acc} alternate={true} onClick={() => onSelect(acc)} key={i} />
                ))}
            </div>
        </div>
    );
};

const menuAnim = {
    initial: {
        opacity: 0,
        scale: 0,
        originX: 0,
        originY: 0
    },
    animate: {
        opacity: 1,
        scale: 1
    },
    transition: {
        duration: 0.1
    }
};

export default AccountMenu;
