import {
    DataTable,
    DataTableSkeleton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableSelectAll,
    TableSelectRow,
    TableToolbar
} from 'carbon-components-react';
import { DateFilter, TablePlaceholder } from 'components/tables';
import useDevice from 'hooks/use-device';
import useTableFilters from 'hooks/use-table-filters';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import styles from './DefaultTable.module.scss';
import LeadBatchActions from './LeadBatchActions';
import MobileTile from './MobileTile/MobileTile';
import SearchToolbar from './SearchToolbar/SearchToolbar';

const DefaultTable = (props) => {
    const { mobile, desktop } = useDevice();

    const {
        data,
        dateRangeOptions,
        error,
        filters = {},
        hasBatchActions = false,
        headers,
        hiddenColumns = [],
        initialDates,
        itemsPerPage = 50,
        loading,
        outputModifiers,
        record = 'record',
        setExportParams = () => {},
        shouldPaginate = true,
        showLoading = false,
        sortRow
    } = props;

    const { filterOptions, filteredRows, filterValues, processing, updateRowData } = useTableFilters(
        data,
        filters,
        loading
    );

    // Related to pagination
    const [firstRowIndex, setFirstRowIndex] = useState(0);
    const [currentPageSize, setCurrentPageSize] = useState(shouldPaginate ? itemsPerPage : data.length);

    const showDateFilters = useMemo(() => Boolean(initialDates), [initialDates]);

    const rowsById = useMemo(() => {
        const obj = {};
        data.forEach((row) => {
            obj[row.id] = { ...row };
        });
        return obj;
    }, [data]);

    useEffect(() => {
        setExportParams(filterValues);
    }, [filterValues]);

    useEffect(() => {
        setFirstRowIndex(0);
    }, [filteredRows]);

    if (error) {
        return <TablePlaceholder message={error.message} description={error.description} />;
    }

    return (
        <>
            {loading || processing ? (
                <DataTableSkeleton />
            ) : (
                <DataTable rows={filteredRows} headers={headers} isSortable sortRow={sortRow}>
                    {({
                        rows,
                        headers,
                        getTableProps,
                        getHeaderProps,
                        getRowProps,
                        getSelectionProps,
                        getTableContainerProps,
                        getToolbarProps,
                        getBatchActionProps,
                        selectedRows,
                        onInputChange
                    }) => (
                        <TableContainer {...getTableContainerProps()} className={styles.tableContainer}>
                            {showDateFilters && !desktop && (
                                <div className={styles.dateFiltersExpanded}>
                                    <DateFilter initialDates={initialDates} dateRangeOptions={dateRangeOptions} />
                                </div>
                            )}
                            <TableToolbar {...getToolbarProps()}>
                                <SearchToolbar
                                    dateRangeOptions={dateRangeOptions}
                                    filters={filters}
                                    filterValues={filterValues}
                                    filterOptions={filterOptions}
                                    firstRowIndex={firstRowIndex}
                                    initialDates={initialDates}
                                    onInputChange={onInputChange}
                                    record={record}
                                    rows={rows}
                                    setFirstRowIndex={setFirstRowIndex}
                                    showDateFilters={showDateFilters}
                                    showLoading={showLoading}
                                />
                                {hasBatchActions && (
                                    <LeadBatchActions
                                        getBatchActionProps={getBatchActionProps}
                                        rowsById={rowsById}
                                        selectedRows={selectedRows}
                                        updateRowData={updateRowData}
                                    />
                                )}
                            </TableToolbar>
                            {mobile ? (
                                rows.map(({ id }, i) => {
                                    const rowData = rowsById[id];

                                    if (!rowData) {
                                        return null;
                                    }
                                    else {
                                        return (
                                            <MobileTile
                                                headers={headers}
                                                hiddenColumns={hiddenColumns}
                                                key={`tile${i}`}
                                                rowData={rowData}
                                                outputModifiers={outputModifiers}
                                                updateRowData={updateRowData}
                                            />
                                        );
                                    }
                                })
                            ) : (
                                <Table {...getTableProps()}>
                                    <TableHead>
                                        <TableRow>
                                            {hasBatchActions && <TableSelectAll {...getSelectionProps()} />}
                                            {headers.map((header) => {
                                                if (hiddenColumns.includes(header.key)) {
                                                    return null;
                                                }
                                                else {
                                                    return (
                                                        <TableHeader {...getHeaderProps({ header })}>
                                                            {header.header}
                                                        </TableHeader>
                                                    );
                                                }
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.slice(firstRowIndex, firstRowIndex + currentPageSize).map((row, i) => {
                                            const rowData = rowsById[row.id];

                                            return (
                                                <TableRow {...getRowProps({ row })} key={`row${row.id}`}>
                                                    {hasBatchActions && (
                                                        <TableSelectRow
                                                            {...getSelectionProps({
                                                                row,
                                                                className: 'data-suppress-navigation'
                                                            })}
                                                        />
                                                    )}

                                                    {row.cells.map((cell) => {
                                                        if (!rowData) {
                                                            return null;
                                                        }

                                                        const { header } = cell.info;

                                                        if (hiddenColumns.includes(header)) {
                                                            return null;
                                                        }

                                                        const cellContent = outputModifiers[header]
                                                            ? outputModifiers[header](rowData, updateRowData, i)
                                                            : cell.value;

                                                        return <TableCell key={cell.id}>{cellContent}</TableCell>;
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            )}
                            {!processing && isEmpty(filteredRows) && (
                                <TablePlaceholder message={`No ${record}s to display`} style={{ margin: 30 }} />
                            )}
                            {shouldPaginate && (
                                <Pagination
                                    backwardText="Previous Page"
                                    forwardText="Next Page"
                                    itemsPerPageText="Items per page"
                                    key={rows.length}
                                    onChange={({ page, pageSize }) => {
                                        if (pageSize !== currentPageSize) {
                                            setCurrentPageSize(pageSize);
                                        }
                                        setFirstRowIndex(pageSize * (page - 1));
                                    }}
                                    pageSize={currentPageSize}
                                    pageSizes={[50, 100, 200, 250]}
                                    totalItems={rows?.length}
                                />
                            )}
                        </TableContainer>
                    )}
                </DataTable>
            )}
        </>
    );
};

export { DefaultTable };
