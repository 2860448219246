import { AccountFeatures, AccountProducts } from 'components/accounts';
import { PageHeader } from 'components/common';
import { Helmet as Head } from 'react-helmet-async';

const Features = () => {
    return (
        <>
            <Head>
                <title>Account Features</title>
            </Head>
            <PageHeader title="Account Features" />
            <AccountFeatures />
            <AccountProducts />
        </>
    );
};

export default Features;
