import { ExportButton } from 'components/buttons';
import { PageHeader } from 'components/common';
import { TransactionList } from 'components/transactions';
import useAccount from 'hooks/use-account';
import { useState } from 'react';
import { Helmet as Head } from 'react-helmet-async';
import styles from 'styles/pages/Transactions.module.scss';

const Transactions = () => {
    const [exportData, setExportData] = useState({});
    const { account, subsidyAccount } = useAccount();

    const showTaxDisclaimer = subsidyAccount && account?.state === 'TX';

    return (
        <>
            <Head>
                <title>Transactions</title>
            </Head>
            <PageHeader title="Transactions">
                {exportData.visible && <ExportButton api="transaction" params={exportData.params} />}
            </PageHeader>
            <TransactionList setExportData={setExportData} />
            {showTaxDisclaimer && (
                <div className={styles.taxDisclaimer}>
                    <em>
                        We are required by the Texas Comptroller of Public Accounts to collect and remit sales tax on
                        80% of the amount billed for leads and licensing fees. The cost of the lead is inclusive of the
                        sales tax so the tax will NOT appear as a separate line item. The sales tax rate is based on the
                        billing address and is inclusive of state, county, and any other assessments that apply to your
                        specific location.
                    </em>
                </div>
            )}
        </>
    );
};

export default Transactions;
