import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import useQueryParams from 'hooks/use-query-params';

const useTableFilters = (data, filters, loading) => {
    const [rowData, setRowData] = useState([]);
    const [processing, setProcessing] = useState(true);
    const { query } = useQueryParams();

    useEffect(() => {
        if (loading && !processing) {
            // Sets processing to true if another API call starts
            setProcessing(true);
        }
    }, [loading]);

    useEffect(() => {
        setRowData(data);

        if (!loading && isEmpty(data)) {
            // If there are no results, set processing to false
            setProcessing(false);
        }
    }, [data]);

    /**
     * Returns a filterValues object when the query string changes
     * This is the source of truth for what filters are active
     */
    const filterValues = useMemo(() => {
        if (isEmpty(filters)) return {};

        const params = new URLSearchParams(query);
        const filterValues = {};

        Object.keys(filters).forEach((filter) => {
            const { type, queryString, valueType } = filters[filter];
            const queryValues = params.get(queryString);

            // Convert string values to properly filter data
            if (queryValues) {
                if (type === 'multiSelect') {
                    filterValues[filter] = queryValues.split(',');
                }
                else if (valueType === 'number') {
                    filterValues[filter] = Number(queryValues);
                }
                else if (valueType === 'boolean') {
                    filterValues[filter] = queryValues === 'true' ? true : false;
                }
                else {
                    filterValues[filter] = queryValues;
                }
            }
        });

        return filterValues;
    }, [window.location.search]);

    /**
     * This function will update rowData to reflect status/revisitLater changes since
     * we do not re-get the lead list after every action.
     * rows will be the updated row(s) object with the correct values
     */
    const updateRowData = (rows) => {
        let updated;
        if (Array.isArray(rows)) {
            // This is a batch action with possibly multiple rows to update
            updated = [...rowData];
            rows.forEach((row) => {
                const index = updated.findIndex(({ leadId }) => leadId === row.leadId);
                updated[index] = row;
            });
        }
        else {
            // This is a non-batch action update, which for now is the revisitLater flag
            updated = rowData.map((lead) => (lead.leadId === rows.leadId ? rows : lead));
        }

        setRowData(updated);
    };

    const filterOptions = useMemo(() => {
        const filterNames = Object.keys(filters);
        const filterOptions = {};

        filterNames.forEach((filter) => (filterOptions[filter] = []));

        rowData.forEach((row) => {
            filterNames.forEach((name) => {
                // Skip filters that have defined options
                if (filters[name].options) {
                    return;
                }

                const value = row[name];

                // Skip undefined/null/""
                // Example of this is accounts that don't have a set CSM/SM
                if (value !== '' && !isNil(value)) {
                    const option = { value: value, label: value };
                    if (!filterOptions[name].find(({ value }) => value === option.value)) {
                        // Add option if it doesn't exist
                        filterOptions[name].push(option);
                    }
                }
            });
        });

        return filterOptions;
    }, [rowData]);

    /**
     * This is the data that is passed to the table to be rendered
     */
    const filteredRows = useMemo(() => {
        let updated = rowData;
        const filterNames = Object.keys(filterValues);

        filterNames.forEach((name) => {
            let values = filterValues[name];

            if (Array.isArray(values)) {
                // Do not filter if nothing is selected
                if (isEmpty(values)) return;

                updated = updated.filter((row) => values.indexOf(row[name]) !== -1);
            }
            else {
                // Do not filter if All is selected
                if (values === '') return;

                updated = updated.filter((row) => row[name] === values);
            }
        });

        if (processing && !loading && !isEmpty(rowData)) {
            // Set processing to false after we've filtered rows
            setProcessing(false);
        }

        return updated;
    }, [rowData, filterValues]);

    return {
        filterOptions,
        filteredRows,
        filterValues,
        processing,
        updateRowData
    };
};

export default useTableFilters;
