import { TextInput } from 'carbon-components-react';
import { motion } from 'framer-motion';
import useAuth from 'hooks/use-auth';
import useUser from 'hooks/use-user';
import Layout from 'layouts/Centered';
import { useState } from 'react';
import { Helmet as Head } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import styles from 'styles/pages/ForgotPassword.module.scss';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    email: Yup.string().required('Please enter your email address').email('Please enter a valid email address')
});

const ForgotPassword = () => {
    const { forgotPassword, loading } = useAuth();
    const { user } = useUser();

    const [email, setEmail] = useState('');
    const [clientErrors, setClientErrors] = useState({});
    const [clientInfos, setClientInfos] = useState('');

    if (user) {
        return <Redirect to="/p" />;
    }

    const onSubmit = async (evt) => {
        evt.preventDefault();

        try {
            await validationSchema.validate(
                {
                    email
                },
                { abortEarly: false }
            );
            setClientErrors({});
        }
        catch (errors) {
            const all = errors.inner.reduce((acc, curr) => {
                return { ...acc, [curr.path]: curr.message };
            }, {});
            setClientErrors(all);
            return;
        }
        setClientInfos(1);
        forgotPassword({
            email
        });
    };

    return (
        <>
            <Head>
                <title>Forgot Password</title>
            </Head>
            <Layout title="Forgot Password">
                <div className={styles.wrap}>
                    <motion.form className={styles.form} onSubmit={onSubmit} {...formAnim} noValidate>
                        <TextInput
                            id="email"
                            type="email"
                            labelText="Email"
                            placeholder="Enter your email address"
                            autoComplete="username"
                            onChange={(evt) => setEmail(evt.target.value)}
                            autoFocus
                            disabled={loading}
                        />
                        {clientErrors.email && (
                            <motion.p className={`${styles.error}`} {...messageAnim}>
                                {clientErrors.email}
                            </motion.p>
                        )}
                        {clientInfos ? (
                            <motion.div {...messageAnim}>
                                <div className={styles.info}>
                                    Submitted. If an account for your email address exists, you will receive a reset
                                    email shortly.
                                </div>
                            </motion.div>
                        ) : (
                            <motion.button type="submit" className={styles.button} disabled={loading} {...buttonAnim}>
                                Reset
                            </motion.button>
                        )}
                    </motion.form>
                </div>
            </Layout>
        </>
    );
};

const formAnim = {
    initial: {
        opacity: 0,
        y: 20
    },
    animate: {
        opacity: 1,
        y: 0
    },
    transition: {
        ease: 'easeOut',
        duration: 1
    }
};

const messageAnim = {
    initial: {
        opacity: 0,
        y: 5
    },
    animate: {
        opacity: 1,
        y: 0
    },
    transition: {
        ease: 'easeOut',
        duration: 0.5
    }
};

const buttonAnim = {
    whileTap: { scale: 0.98 }
};

export default ForgotPassword;
