import { Flag16, FlagFilled16 } from '@carbon/icons-react';
import { Button, ButtonSkeleton, Select, SelectItem, ToastNotification } from 'carbon-components-react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'util/axios';
import LeadFlag from './LeadFlag';
import LeadStatusBadge from './LeadStatusBadge/LeadStatusBadge';

/**
 * LeadDisposition handles both lead status updates and revisit later.
 * It's used on the lead detail page.
 */
const LeadDisposition = ({ meta, setMeta }) => {
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);
    const { status, revisitLater } = meta;
    const { id, adGroupId } = useParams();

    const updateDisposition = async (type, value) => {
        setProcessing(true);
        const payload = {
            lead_id: id,
            ad_group_id: adGroupId
        };
        payload[type] = value;

        try {
            await axios.post(`/lead.json?action=update_status`, {
                action: 'update_status',
                leads: [payload]
            });

            const newValue = type === 'revisit_later' ? { revisitLater: value } : { status: value };
            setMeta(Object.assign(meta, newValue));
        }
        catch (err) {
            setError(true);
        }
        finally {
            setProcessing(false);
        }
    };

    if (processing) {
        return <ButtonSkeleton style={{ width: '180px' }} />;
    }

    return (
        <>
            {error && (
                <ToastNotification
                    caption="Something went wrong. Please try again later."
                    kind="error"
                    title="Error"
                    hideCloseButton
                    timeout={3000}
                />
            )}

            <Select
                children={dropdownItems}
                value={status}
                id="lead-disposition"
                labelText="Lead Status"
                onChange={({ target }) => updateDisposition('status', target.value)}
                style={{ backgroundColor: '#fff' }}
                size="xl"
                hideLabel
                inline
            />

            <Button
                hasIconOnly
                tooltipAlignment="center"
                tooltipPosition="bottom"
                iconDescription={revisitLater === 1 ? 'Remove Flag' : 'Flag'}
                kind="primary"
                onClick={() => updateDisposition('revisit_later', revisitLater === 1 ? 0 : 1)}
                renderIcon={revisitLater === 1 ? FlagFilled16 : Flag16}
                style={{
                    backgroundColor: !revisitLater ? '#999' : null
                }}
            />
        </>
    );
};

const leadDispositions = [
    {
        label: 'New',
        value: 'new'
    },
    {
        label: 'Contacted',
        value: 'contacted'
    },
    {
        label: 'Quoted',
        value: 'quoted'
    },
    {
        label: 'Sold',
        value: 'sold'
    },
    {
        label: 'Unsold',
        value: 'unsold'
    }
];

const dispositionLabels = {
    new: 'New',
    contacted: 'Contacted',
    quoted: 'Quoted',
    sold: 'Sold',
    unsold: 'Unsold'
};

const dropdownItems = leadDispositions.map((item) => (
    <SelectItem key={item.value} text={item.label} value={item.value} />
));

const leadProducts = [
    {
        label: 'Auto',
        value: 'auto'
    },
    {
        label: 'Home',
        value: 'home'
    }
];

export {
    LeadFlag,
    LeadStatusBadge, LeadDisposition as default, dispositionLabels, dropdownItems, leadDispositions, leadProducts
};

