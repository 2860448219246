import chromeIcon from 'img/browsers/chrome.svg';
import edgeIcon from 'img/browsers/edge.svg';
import Layout from 'layouts/Centered';
import styles from './NotSupported.module.scss';

const NotSupported = () => {
    return (
        <Layout>
            <div className={styles.wrap}>
                <h1 className={styles.headline}>Unsupported Browser</h1>
                <div className={styles.description}>
                    Your browser does not fully support all the features of MediaAlpha for Agents.
                    <br />
                    Please download and use one of the following recommended browsers:
                </div>
                <div className={styles.browsers}>
                    <a
                        href="https://www.microsoft.com/en-us/edge"
                        className={styles.browserLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={edgeIcon} alt="Microsoft Edge" />
                        <span>Microsoft Edge</span>
                    </a>
                    <a
                        href="https://www.google.com/chrome/"
                        className={styles.browserLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={chromeIcon} alt="Google Chrome" />
                        <span>Google Chrome</span>
                    </a>
                </div>
            </div>
        </Layout>
    );
};

export default NotSupported;
