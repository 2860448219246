import { Information16, Renew16 } from '@carbon/icons-react';
import {
    Button,
    ButtonSkeleton,
    DataTable,
    DataTableSkeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    ToastNotification,
    TooltipDefinition,
    TooltipIcon
} from 'carbon-components-react';
import clsx from 'clsx';
import { PageButtons } from 'components/common';
import { FatalError } from 'components/errors';
import { CarrierLogos, OpportunitiesFilters } from 'components/opportunities';
import BuyNow, { errorTypes } from 'components/opportunities/BuyNow';
import CallNow, { eventNames } from 'components/opportunities/CallNow';
import { TablePlaceholder } from 'components/tables';
import useAccount from 'hooks/use-account';
import useOpportunitiesFilters, { filterOperations } from 'hooks/use-opportunities-filters';
import NoRecordsImage from 'img/no_records.svg';
import { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'util/axios';
import { relativePrettyString, toUserTimeZone } from 'util/format-date';
import { formatError } from 'util/format-error';
import { handleCustomSort } from 'util/table-helpers';
import styles from './OpportunitiesList.module.scss';

const DEFAULT_INTERVAL = 5 * 60 * 1000; // 5 minutes
const SORTABLE_COLUMNS_LIST = ['creationTime', 'contact', 'carrierAttempts', 'yourAttempts', 'lastCall'];
const feedButtonTypes = {
    BUY_NOW: 'buyNow',
    CALL_NOW: 'callNow'
};

const TOAST_NOTIFICATIONS = {
    REFRESH: 'refresh',
    FILTERS_APPLIED: 'filtersApplied',
    FILTERS_RESET: 'filtersReset'
};

const OpportunitiesList = () => {
    const { id } = useAccount();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [leads, setLeads] = useState([]);

    const refreshInterval = useRef();
    const timer = useRef();
    const mounting = useRef(true);

    const [refreshing, setRefreshing] = useState(false);
    const [toastProps, setToastProps] = useState('');
    const { leadFeedFilters, lastOperation } = useOpportunitiesFilters();

    useEffect(() => {
        refreshInterval.current = DEFAULT_INTERVAL;
        getLeads();

        return () => clearTimeout(timer.current);
    }, []);

    useEffect(() => {
        // Avoid running effect on component mount
        if (mounting.current) {
            mounting.current = false;
            return;
        }

        setRefreshing(true);
        const param = {
            notify:
                lastOperation === filterOperations.FILTERS_RESET
                    ? TOAST_NOTIFICATIONS.FILTERS_RESET
                    : TOAST_NOTIFICATIONS.FILTERS_APPLIED
        };

        getLeads(param);
    }, [leadFeedFilters]);

    const onCallNowEvent = (name, data = {}) => {
        const { leadId, adGroupId } = data;
        switch (name) {
        case eventNames.CALL_STARTED:
            if (timer.current) clearTimeout(timer.current); // stop feed auto refresh when on a call
            break;
        case eventNames.CALL_FINISHED:
            setRefreshTimer(refreshInterval.current); // restart feed auto refresh
            break;
        case eventNames.QUALIFIED:
            onLeadUpdated(leadId, data); // update row data to change button, hide BuyNow button
            break;
        case eventNames.PURCHASED_CLICK:
            onPurchasedClick(data); // row purchased button go to lead
            break;
        case eventNames.QUALIFIED_CLICK:
            window.open(`/p/leads/${leadId}/${adGroupId}`, '_blank'); // open lead in new tab
            break;
        case eventNames.UNQUALIFIED:
            onLeadUpdated(leadId, data); // update row data to disable button
            break;
        case eventNames.ERROR:
            onError(data);
            break;
        default:
        }
    };

    const getLeads = async ({ notify } = {}) => {
        try {
            const { data } = await axios.post(`/lead-feed.json?action=get_leads&advertiser_id=${id}`, leadFeedFilters);
            const { leads = [], refreshInterval: ri } = data;

            setLeads(leads);
            notify && showNotification(notify);
            setError(false);

            if (ri) refreshInterval.current = ri * 1000; // api returns in seconds
        }
        catch (err) {
            setError(formatError(err));
        }
        finally {
            if (loading) setLoading(false);

            setRefreshing(false);
            setRefreshTimer(refreshInterval.current);
        }
    };

    const setRefreshTimer = (delay) => {
        clearTimeout(timer.current);
        timer.current = window.setTimeout(() => {
            setRefreshing(true);
            getLeads({ notify: TOAST_NOTIFICATIONS.REFRESH });
        }, delay);
    };

    const refreshFeeds = () => {
        setRefreshing(true);
        if (timer.current) clearTimeout(timer.current);

        getLeads({ notify: TOAST_NOTIFICATIONS.REFRESH });
    };

    const onLeadUpdated = (leadId, update) => {
        // Update this lead in-place based on leadId
        const updatedLeads = leads.map((lead) => (lead.id === leadId ? { ...lead, ...update } : lead));

        setLeads(updatedLeads);
    };

    const onError = ({ message }) => {
        if (message === errorTypes.LEAD_UNAVAILABLE || message === errorTypes.ALREADY_PURCHASED) {
            window.setTimeout(refreshFeeds, 2000);
        }
    };

    const onPurchasedClick = ({ leadId, adGroupId }) => history.replace(`/p/leads/${leadId}/${adGroupId}`);

    const showNotification = (notificationType) => {
        setToastProps(notificationType);
    };

    const getCellContent = (cell, row, index) => {
        const { header } = cell.info;
        const leadId = cell.id.split(':')[0];
        const lead = leads.find((lead) => lead.id === row.id);
        const {
            adGroupId,
            callDisabled,
            callDisabledReason,
            contact,
            leadDisabled,
            leadDisabledReason,
            myCalls,
            purchasedBy
        } = lead || {};

        switch (header) {
        case 'leadBid':
            return (
                <>
                    {purchasedBy === feedButtonTypes.CALL_NOW ? null : (
                        <BuyNow
                            leadId={leadId}
                            leadDisabled={leadDisabled}
                            leadDisabledReason={leadDisabledReason}
                            adGroupId={adGroupId || ''}
                            cost={cell.value}
                            onSuccess={onLeadUpdated}
                            onPurchasedClick={onPurchasedClick}
                            onError={onError}
                        />
                    )}
                </>
            );
        case 'callBid':
            return (
                <>
                    {purchasedBy === feedButtonTypes.BUY_NOW ? null : (
                        <CallNow
                            adGroupId={adGroupId || ''}
                            callDisabled={callDisabled}
                            callDisabledReason={callDisabledReason}
                            cost={cell.value}
                            contact={contact}
                            leadId={leadId}
                            myCalls={myCalls}
                            onEvent={onCallNowEvent}
                            purchased={Boolean(purchasedBy)}
                        />
                    )}
                </>
            );
        case 'creationTime':
            return (
                <TooltipDefinition
                    align="start"
                    children={relativePrettyString(cell.value, true)}
                    direction="top"
                    triggerClassName={styles.timestamp}
                    tooltipText={toUserTimeZone(cell.value)}
                />
            );
        case 'contact':
            return (
                <Link
                    to={
                        !!purchasedBy
                            ? `/p/leads/${leadId}/${adGroupId}`
                            : `/p/opportunities/${leadId}?advertiser_id=${id}`
                    }
                >
                    {cell.value}
                </Link>
            );
        case 'sharedWith':
            return <CarrierLogos carriers={cell.value} />;
        case 'lastCall':
            return (
                myCalls > 0 && (
                    <TooltipDefinition
                        align="start"
                        children={relativePrettyString(cell.value, true) || ''}
                        direction="top"
                        triggerClassName={styles.timestamp}
                        tooltipText={toUserTimeZone(cell.value) || ''}
                    />
                )
            );
        default:
            return cell.value;
        }
    };

    if (loading) {
        return <DataTableSkeleton />;
    }

    if (error) {
        return <FatalError error={error} />;
    }

    return (
        <div className={styles.opportunitiesList}>
            <PageButtons justify="flex-end" position="top">
                <OpportunitiesFilters />
                {refreshing ? (
                    <ButtonSkeleton className={styles.btnSkeleton} />
                ) : (
                    <Button renderIcon={Renew16} onClick={refreshFeeds} kind="primary">
                        <strong>Refresh</strong>
                    </Button>
                )}
                <LeadFeedToastNotification toastProps={toastProps} handleOnClose={setToastProps} />
            </PageButtons>

            {leads.length === 0 ? (
                <TablePlaceholder message={`No leads to display`} image={NoRecordsImage} />
            ) : (
                <>
                    <DataTable rows={leads} headers={headers} isSortable sortRow={handleCustomSort}>
                        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
                            <TableContainer>
                                <Table {...getTableProps()} className={styles.opportunitiesListTable}>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header) => (
                                                <TableHeader
                                                    {...getHeaderProps({ header, isSortable: isColSortable(header) })}
                                                >
                                                    {header.header}
                                                    {tooltips[header.header] && (
                                                        <TooltipIcon
                                                            align={header.key === 'leadBid' ? 'end' : 'center'}
                                                            className={styles.tooltipIcon}
                                                            direction="bottom"
                                                            tooltipText={tooltips[header.header]}
                                                        >
                                                            <Information16 />
                                                        </TooltipIcon>
                                                    )}
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => (
                                            <TableRow {...getRowProps({ row })}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <TableCell
                                                            key={cell.id}
                                                            className={clsx(styles[cell.info.header] || '')}
                                                        >
                                                            {getCellContent(cell, row, index)}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </DataTable>
                </>
            )}
        </div>
    );
};

const tooltips = {
    'Shared With':
        'This column lets you know what other agents have purchased each lead. A green check mark indicates that the agent also made contact.',
    'Cost per Connection': 'You will ONLY be charged for the lead if the consumer picks up. Calls may be recorded.',
    'Cost per Lead': 'You will be charged as soon as you click below.'
};

/**
 * Check if the column is sortable
 *
 * @param {object} header The current table header object
 * @returns {boolean} True if sortable, false otherwise
 */
const isColSortable = (header) => {
    const sortableColumns = SORTABLE_COLUMNS_LIST;
    return sortableColumns.includes(header.key);
};

const headers = [
    {
        key: 'creationTime',
        header: 'Time'
    },
    {
        key: 'contact',
        header: 'Initials'
    },
    {
        key: 'zip',
        header: 'Zip'
    },
    {
        key: 'sharedWith',
        header: 'Shared With'
    },
    {
        key: 'lastCall',
        header: 'Last Call'
    },
    {
        key: 'myCalls',
        header: 'My Calls'
    },
    {
        key: 'callBid',
        header: 'Cost per Connection'
    },
    {
        key: 'leadBid',
        header: 'Cost per Lead'
    }
];

const LeadFeedToastNotification = ({ toastProps, handleOnClose }) => {
    if (!toastProps) return null;

    let message = '';

    if (toastProps === TOAST_NOTIFICATIONS.REFRESH) {
        message = 'Leads refreshed.';
    }
    else if (toastProps === TOAST_NOTIFICATIONS.FILTERS_APPLIED) {
        message = 'Filters applied.';
    }
    else if (toastProps === TOAST_NOTIFICATIONS.FILTERS_RESET) {
        message = 'Filters reset.';
    }

    return (
        <ToastNotification
            title={message}
            kind="success"
            caption={''}
            timeout={3000}
            hideCloseButton
            onClose={() => handleOnClose('')}
            lowContrast
        />
    );
};

export { OpportunitiesList as default, feedButtonTypes };
