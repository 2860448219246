import { AccountProvider } from 'hooks/use-account';
import { AuthProvider } from 'hooks/use-auth';
import { DeviceProvider } from 'hooks/use-device';
import { OpportunitiesFilterProvider } from 'hooks/use-opportunities-filters';
import { UserProvider } from 'hooks/use-user';
import { HelmetProvider } from 'react-helmet-async';

const Providers = ({ children }) => (
    <AuthProvider>
        <UserProvider>
            <AccountProvider>
                <HelmetProvider>
                    <DeviceProvider>
                        <OpportunitiesFilterProvider>{children}</OpportunitiesFilterProvider>
                    </DeviceProvider>
                </HelmetProvider>
            </AccountProvider>
        </UserProvider>
    </AuthProvider>
);

export default Providers;
