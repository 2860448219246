import truncate from 'lodash/truncate';

const startCase = (text) => {
    if (!text) return text;

    const words = text.split(' ').filter((word) => !!word);
    const capitalized = words.map((word) => `${word[0].toUpperCase()}${word.slice(1)}`);

    return capitalized.join(' ');
};

const lowerCase = (text) => (text ? String(text).toLowerCase() : text);

const truncateText = (text, chars = 38) => truncate(text, { length: chars, separator: ' ' });

const capitalizeFirstLetter = (text) => {
    // return text if it contains only digits
    if (/^[0-9]+$/.test(Number(text))) return text;
    
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export { startCase, lowerCase, truncateText, capitalizeFirstLetter };
