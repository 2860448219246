import { Dropdown, MultiSelect } from 'carbon-components-react';
import useQueryParams from 'hooks/use-query-params';
import { useMemo } from 'react';

const FilterItem = (props) => {
    const { filterValue, filterName, type, valueType, queryString, items, label, ...rest } = props;
    const { updateQueryParam } = useQueryParams();

    const filterItems = useMemo(() => {
        const filterItems = [...items];
        if (type === types.DROPDOWN) {
            filterItems.unshift({ value: '', label: 'All' }); // Dropdowns need an 'All' option
        }
        return filterItems;
    }, [items]);

    const selected = useMemo(() => {
        if (filterValue === undefined) {
            return type === types.MULTISELECT ? [] : filterItems[0];
        }

        if (type === types.MULTISELECT) {
            return filterItems.filter(({ value }) => filterValue.includes(value));
        }
        else {
            return filterItems.find(({ value }) => value === filterValue);
        }
    }, [filterItems, filterValue]);

    return type === types.DROPDOWN ? (
        <Dropdown
            items={filterItems}
            label={label}
            onChange={({ selectedItem }) => {
                updateQueryParam(queryString, selectedItem?.value, { replace: true });
            }}
            selectedItem={selected}
            {...rest}
        />
    ) : (
        <MultiSelect
            items={filterItems}
            label={label}
            onChange={({ selectedItems }) => {
                const values = selectedItems.map(({ value }) => value);
                // Avoid updating query string for an empty array
                updateQueryParam(queryString, Boolean(values.length) ? values : '', { replace: true });
            }}
            initialSelectedItems={selected}
            {...rest}
        />
    );
};

const types = { DROPDOWN: 'dropdown', MULTISELECT: 'multiSelect' };

export default FilterItem;
