import TableImage from 'img/table.svg';
import styles from './TablePlaceholder.module.scss';

const TablePlaceholder = ({ message, description, image, ...rest }) => {
    return (
        <div className={styles.wrap} {...rest}>
            <img className={styles.image} src={image || TableImage} alt="Table" />
            {message && (
                <div>
                    <div className={styles.message}>{message}</div>
                    {description && <div className={styles.description}>{description}</div>}
                </div>
            )}
        </div>
    );
};

export { TablePlaceholder };
