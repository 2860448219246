import { Loading } from 'carbon-components-react';
import { MediaAlphaLogo } from 'components/layout';
import { motion } from 'framer-motion';
import styles from './AppLoading.module.scss';

const AppLoading = () => (
    <motion.div className={styles.wrap} {...slideIn}>
        <MediaAlphaLogo width={200} fill="#555" />
        <Loading className={styles.spinner} withOverlay={false} small />
    </motion.div>
);

const slideIn = {
    initial: {
        opacity: 0,
        x: -20
    },
    animate: {
        opacity: 1,
        x: 0
    },
    transition: {
        ease: 'easeOut',
        duration: 0.5
    }
};

export default AppLoading;
