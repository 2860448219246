import { Button, Grid, Row } from 'carbon-components-react';
import clsx from 'clsx';
import { RangeSlider } from 'components/forms';
import { differenceInDays, subDays } from 'date-fns';
import useOpportunitiesFilters from 'hooks/use-opportunities-filters';
import { useState } from 'react';
import { dateToString, stringToDate } from 'util/format-date';
import styles from './HistoryFilter.module.scss';

const HistoryFilter = ({ setHistoryFilterOpen }) => {
    const { historyFilter, setHistoryFilter } = useOpportunitiesFilters();

    const [filters, setFilters] = useState({ ...historyFilter });
    const { sharedWithAgents, myCalls, dateFrom, dateTo } = filters;

    const handleCancel = () => {
        setHistoryFilterOpen(false);
    };

    const handleApply = () => {
        setHistoryFilter({ ...filters });
        setHistoryFilterOpen(false);
    };

    const setSharedWithAgentsList = ([from, to]) => {
        setFilters({
            ...filters,
            sharedWithAgents: {
                to,
                from
            }
        });
    };

    const setMyCallsList = ([from, to]) => {
        setFilters({
            ...filters,
            myCalls: {
                to,
                from
            }
        });
    };

    const sharedWithAgentsList = Object.values(sharedWithAgents);
    const myCallsList = Object.values(myCalls);

    const date1 = differenceInDays(new Date(), stringToDate(dateTo));
    const date2 = differenceInDays(new Date(), stringToDate(dateFrom));
    const leadAge = [date1, date2];

    const setDateRange = ([to, from]) => {
        setFilters({
            ...filters,
            dateFrom: dateToString(subDays(new Date(), from)),
            dateTo: dateToString(subDays(new Date(), to))
        });
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.filterItem}>
                <Grid>
                    <strong className={styles.historyTitle}>Lead Age</strong>
                    <Row className={styles.slider}>
                        <RangeSlider domain={[0, 7]} values={leadAge} setValues={setDateRange} labelTextLeft="Days" />
                    </Row>
                    <strong className={clsx(styles.historyTitle, styles.otherBuyers, styles.sliderMargin)}>
                        Shared with other Agents
                    </strong>
                    <label className={clsx('bx--label', styles.buyersLabel)}>
                        For captive agents, this only includes buyers from other carriers. Your feed never includes
                        leads already purchased by a buyer from your carrier.
                    </label>
                    <Row className={styles.slider}>
                        <RangeSlider
                            domain={[0, 3]}
                            values={sharedWithAgentsList}
                            setValues={setSharedWithAgentsList}
                            labelTextLeft="Agents"
                        />
                    </Row>
                    {/* The following block may be required later, so leaving it commented for now. */}
                    {/* <b className={clsx(styles.historyTitle, styles.otherBuyers)}>All Connect Attempts</b>
                    <Row className={styles.slider}>
                        <RangeSlider
                            domain={[0, 3]}
                            values={connAttemptsRange}
                            setValues={setConnAttemptsRange}
                            labelText=""
                        />
                        <span className={styles.attemptsLabel}>connection attempts by</span>
                        <div className={styles.connectAttempts}>
                            <Select id="connectAttempts" labelText="" options={connectAttempts}>
                                {connectAttempts.map((item, i) => (
                                    <SelectItem value={item.value} key={i} text={item.label} />
                                ))}
                            </Select>
                        </div>
                    </Row> */}
                    <strong className={clsx(styles.historyTitle, styles.callAttempts)}>My Call Attempts</strong>
                    <Row className={styles.slider}>
                        <RangeSlider domain={[0, 3]} values={myCallsList} setValues={setMyCallsList} />
                        <span className={styles.attemptsLabel}>Call Attempts by my agency</span>
                    </Row>
                    <div className={styles.filterBtns}>
                        <Button className={styles.cancelBtn} kind="secondary" onClick={handleCancel}>
                            <strong>Cancel</strong>
                        </Button>
                        <Button onClick={handleApply}>
                            <strong>Apply</strong>
                        </Button>
                    </div>
                </Grid>
            </div>
        </div>
    );
};

// const connectAttempts = [
//     {
//         label: 'All Agents',
//         value: 'all'
//     },
//     {
//         label: 'Agents from My Carrier',
//         value: 'myCarrier'
//     },
//     {
//         label: 'Agents from Other Carriers',
//         value: 'otherCarriers'
//     }
// ];

export default HistoryFilter;
