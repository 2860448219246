import { AccountForm, AccountStatusButton } from 'components/accounts';
import { modes } from 'components/accounts/AccountForm';
import { PageHeader } from 'components/common';
import carrierConf from 'data/carrier-conf';
import useAccount from 'hooks/use-account';
import { Helmet as Head } from 'react-helmet-async';

const Account = () => {
    const { account, canManageAccount } = useAccount();
    const { subsidyAdvertiserId } = account;
    const title = 'Account Settings';

    return (
        <>
            <Head>
                <title>{title}</title>
            </Head>

            <PageHeader title={title}>{canManageAccount && <AccountStatusButton />}</PageHeader>
            <AccountForm mode={modes.UPDATE} carrier={Number(subsidyAdvertiserId) && carrierConf['State Farm']} />
        </>
    );
};

export default Account;
