import { useLocation, useHistory } from 'react-router-dom';
import isNil from 'lodash/isNil';

const useQueryParams = () => {
    const [queryString, encrypted] = useLocation().search.split(';');

    const query = new URLSearchParams(queryString);
    const history = useHistory();

    const updateQueryParam = (key, value, options = {}) => {
        const { replace = false } = options;

        if (!key) return;

        if (isNil(value) || value === '') {
            query.delete(key);
        }
        else {
            query.set(key, value);
        }

        if (replace) {
            history.replace({
                search: query.toString()
            });
        }
        else {
            history.push({
                search: query.toString()
            });
        }
    };

    return {
        query,
        encrypted,
        updateQueryParam
    };
};

const makeParamString = (params) => {
    return new URLSearchParams(params).toString();
};

export { useQueryParams as default, makeParamString };
