import { ExportButton } from 'components/buttons';
import { HeaderBadge, PageHeader } from 'components/common';
import { LeadDetail, LeadDisposition } from 'components/leads';
import useUser from 'hooks/use-user';
import { useState } from 'react';
import { Helmet as Head } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import styles from 'styles/pages/Lead.module.scss';
import { getExchangeBaseUrl } from 'util/common';
import { toUserTimeZone } from 'util/format-date';
import { truncateText } from 'util/format-text';

const Lead = () => {
    const [meta, setMeta] = useState({});
    const [showExport, setShowExport] = useState(true);

    const { id, adGroupId } = useParams();

    const { name, returned } = meta;
    const metaTitle = name ? `${name} | ${id}` : `${id}`;

    const subtitle = <SubTitle meta={meta} />;
    const badges = <HeaderBadges meta={meta} />;

    return (
        <>
            <Head>
                <title>{metaTitle}</title>
            </Head>
            <PageHeader
                title={truncateText(meta?.name) || 'Lead Details'}
                subtitle={subtitle}
                badges={badges}
                mobileWrap
            >
                <div className={styles.leadMetaWrap}>
                    {returned === null && <LeadDisposition meta={meta} setMeta={setMeta} />}
                    {showExport && <ExportButton api="lead" params={{ id, ad_group_id: adGroupId }} />}
                </div>
            </PageHeader>
            <LeadDetail id={id} adGroupId={adGroupId} setMeta={setMeta} setShowExport={setShowExport} />
        </>
    );
};

const SubTitle = ({ meta }) => {
    const { sellTime, time, leadId } = meta;
    const { employee } = useUser();

    const displayTime = sellTime || time;
    if (!displayTime) return null;

    const leadBrowserLink = `${getExchangeBaseUrl()}/#analytics/leads?query=${leadId}`;

    return (
        <>
            <span>{toUserTimeZone(displayTime)}</span>
            {employee ? (
                <a className={styles.leadBrowserLink} href={leadBrowserLink} target="_blank" rel="noreferrer">
                    {leadId}
                </a>
            ) : (
                <div className={styles.leadId}>{leadId}</div>
            )}
        </>
    );
};

const HeaderBadges = ({ meta }) => {
    const { campaign, product, returned } = meta;

    return (
        <>
            {product && <HeaderBadge color="blue">{product}</HeaderBadge>}
            {campaign && <HeaderBadge>{campaign}</HeaderBadge>}
            {returned && <HeaderBadge color="red">Returned</HeaderBadge>}
        </>
    );
};

export { Lead as default, SubTitle, HeaderBadges };
