import { Fragment, useEffect, useState } from 'react';
import styles from './RangeSliderHelpers.module.scss';

const TooltipRail = ({ activeHandleID, disabled = false, getEventData, getRailProps }) => {
    const [value, setValue] = useState(null);
    const [percent, setPercent] = useState(null);

    const onMouseEnter = () => {
        document.addEventListener('mousemove', onMouseMove);
    };

    const onMouseLeave = () => {
        setValue(null);
        setPercent(null);
        document.removeEventListener('mousemove', onMouseMove);
    };

    const onMouseMove = (e) => {
        if (activeHandleID) {
            setValue(null);
            setPercent(null);
        }
        else {
            getEventData(e);
        }
    };

    useEffect(() => {
        if (activeHandleID) {
            setValue(value);
            setPercent(percent);
        }
    }, [activeHandleID]);

    return (
        <Fragment>
            {!activeHandleID && value ? (
                <div
                    style={{
                        left: `${percent}%`,
                        position: 'absolute',
                        marginLeft: '-11px',
                        marginTop: '-35px'
                    }}
                >
                    <div className={styles.tooltip}>
                        <span className={styles.tooltiptext}>Value: {value}</span>
                    </div>
                </div>
            ) : null}
            <div
                className={styles.railStyle}
                {...getRailProps({
                    onMouseEnter,
                    onMouseLeave
                })}
            />
            <div className={styles.railCenterStyle} />
        </Fragment>
    );
};

const Handle = (props) => {
    // const [mouseOver, setMouseOver] = useState(true);
    const mouseOver = true;

    // const onMouseEnter = () => {
    //     setMouseOver(true);
    // };

    // const onMouseLeave = () => {
    //     setMouseOver(false);
    // };

    const {
        domain: [min, max],
        handle: { id, value, percent },
        isActive,
        getHandleProps,
        disabled = false
    } = props;

    return (
        <Fragment>
            {(mouseOver || isActive) && !disabled ? (
                <div
                    style={{
                        left: `${percent}%`,
                        position: 'absolute',
                        marginLeft: '-11px',
                        marginTop: '-35px'
                    }}
                >
                    <div className={styles.tooltip}>
                        <span className={styles.tooltiptext}>{value}</span>
                    </div>
                </div>
            ) : null}
            <div
                role="slider"
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                style={{
                    left: `${percent}%`,
                    position: 'absolute',
                    marginLeft: '-11px',
                    marginTop: '-6px',
                    zIndex: 400,
                    width: 24,
                    height: 24,
                    cursor: 'pointer',
                    border: 0,
                    borderRadius: '50%',
                    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.4)',
                    backgroundColor: disabled ? '#666' : '#3e1db3'
                }}
                {...getHandleProps(id)}
            />
        </Fragment>
    );
};

const Track = ({ source, target, getTrackProps, disabled = false }) => {
    return (
        <div
            style={{
                position: 'absolute',
                height: 14,
                zIndex: 1,
                backgroundColor: disabled ? '#999' : '#3e1db3',
                borderRadius: 7,
                cursor: 'pointer',
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`
            }}
            {...getTrackProps()}
        />
    );
};

export { TooltipRail, Handle, Track };
