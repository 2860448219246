import applyCaseMiddleware from 'axios-case-converter';
import axios from 'axios';

const endpoint = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_ENDPOINT : '/api';
const withCredentials = process.env.NODE_ENV === 'development';

const createInstance = () =>
    applyCaseMiddleware(
        axios.create({
            baseURL: endpoint,
            withCredentials
        })
    );

const instance = createInstance();

instance.interceptors.response.use(null, (err) => {
    const status = err.response?.status;

    if (status === 401 || status === 403) {
        if (window.location.pathname.startsWith('/p/')) {
            window.setTimeout(() => {
                window.location.pathname !== '/sign-in' && window.location.replace('/sign-in');
            }, 1000);
        }
    }
    else if (status === 503) {
        window.location.replace('/maintenance.html');
    }

    throw err;
});

export { instance as default, createInstance };
