import {
    Checkbox,
    Column,
    InlineNotification,
    MultiSelect,
    Row,
    Select,
    SelectItem,
    TextInput
} from 'carbon-components-react';
import states from 'data/us-states';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { products } from 'util/constants';
import { capitalizeFirstLetter } from 'util/format-text';
import styles from './Products.module.scss';

const productData = {
    [products.AUTO]: [{ labelText: 'State', name: 'state', kind: 'dropdown' }],
    [products.HOME]: [{ labelText: 'State', name: 'state', kind: 'dropdown' }],
    [products.HEALTH]: [
        {
            labelText: 'License State',
            name: 'license.state',
            kind: 'dropdown'
        },
        {
            labelText: 'License Number',
            name: 'license.number',
            kind: 'text'
        },
        {
            labelText: 'National Producer Number',
            name: 'license.nationalProducerNumber',
            kind: 'text'
        },
        { labelText: 'States', name: 'states', kind: 'multiselect' }
    ],
    [products.LIFE]: [{ labelText: 'States', name: 'states', kind: 'multiselect' }]
};

const NonSubProducts = ({ namespace, formDisabled, activatedProducts = [] }) => {
    const {
        clearErrors,
        formState: { submitCount },
        getValues,
        setError,
        unregister
    } = useFormContext();
    const productValues = getValues(namespace);
    const isAccountCreation = !activatedProducts.length;
    const [selectedProducts, setSelectedProducts] = useState([]);

    useEffect(() => {
        if (!isAccountCreation) return;

        if (!productValues) {
            setError('noProductsEnabled', {
                type: 'manual'
            });
        }
        else {
            clearErrors('noProductsEnabled');
        }
    }, [productValues]);

    const showSection = (type) => selectedProducts.includes(type);
    const handleCheckboxClick = (checked, product) => {
        if (checked) {
            setSelectedProducts([...selectedProducts, product]);
            return;
        }
        else {
            unregister(`${namespace}.${product}`);
            const updated = selectedProducts.filter((item) => item !== product);
            setSelectedProducts(updated);
        }
    };

    const availableProducts = Object.keys(productData);

    return (
        <>
            {isAccountCreation && !selectedProducts.length && (
                <InlineNotification
                    id="noProductsEnabled"
                    kind={submitCount > 0 ? 'error' : 'info'}
                    title=""
                    subtitle="At least one product must be enabled."
                    hideCloseButton
                    style={{ marginTop: 0 }}
                    lowContrast
                />
            )}
            {availableProducts.map((product, index) => {
                const isActivated = activatedProducts.includes(product);
                const isDisabled = formDisabled || isActivated;

                return (
                    <div key={`row-${index}`}>
                        <Checkbox
                            checked={showSection(product) || isActivated}
                            disabled={isDisabled}
                            id={product}
                            labelText={capitalizeFirstLetter(product)}
                            onChange={handleCheckboxClick}
                        />
                        {showSection(product) && !isActivated && (
                            <ProductFields namespace={namespace} product={product} />
                        )}
                    </div>
                );
            })}
        </>
    );
};

const ProductFields = ({ namespace, product }) => {
    const { getFieldState, register, setValue } = useFormContext();
    const fields = productData[product];

    const handleMultiSelectChange = (id, { selectedItems }) =>
        setValue(
            id,
            selectedItems.map((item) => item.value),
            { shouldDirty: true }
        );

    return (
        <motion.div {...rowAnimation}>
            <Row className={styles.productFieldsRow}>
                {fields.map((field, i) => {
                    const { error } = getFieldState(`${namespace}.${product}.${field.name}`);
                    const id = `${namespace}.${product}.${field.name}`;

                    return (
                        <Column lg={3} key={`${product}-col-${i}`}>
                            {field.kind === 'text' ? (
                                <TextInput
                                    {...register(id, {
                                        required: `${field.labelText} is required.`
                                    })}
                                    id={id}
                                    labelText={field.labelText}
                                    invalid={!!error}
                                    invalidText={error?.message}
                                />
                            ) : field.kind === 'dropdown' ? (
                                <Select
                                    {...register(id, {
                                        required: `${field.labelText} is required.`
                                    })}
                                    defaultValue=""
                                    id={id}
                                    labelText={field.labelText}
                                    invalid={!!error}
                                    invalidText={error?.message}
                                >
                                    <SelectItem disabled value="" text="State" />
                                    {states.map((state, i) => (
                                        <SelectItem value={state.value} key={i} text={state.label} />
                                    ))}
                                </Select>
                            ) : (
                                <MultiSelect
                                    {...register(id, {
                                        required: `${field.labelText} is required.`
                                    })}
                                    className={styles.multiSelect}
                                    defaultValue=""
                                    direction="top"
                                    id={id}
                                    items={states}
                                    label={field.labelText}
                                    onChange={(e) => handleMultiSelectChange(id, e)}
                                    titleText={field.labelText}
                                    invalid={!!error}
                                    invalidText={error?.message}
                                />
                            )}
                        </Column>
                    );
                })}
            </Row>
        </motion.div>
    );
};

const rowAnimation = {
    initial: {
        opacity: 0,
        scale: 0,
        originX: 0,
        originY: 0
    },
    animate: {
        opacity: 1,
        scale: 1
    },
    transition: {
        duration: 0.1
    }
};

export { NonSubProducts };
