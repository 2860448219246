import { dollarAmount } from 'util/format-number';
import styles from './LeadMetrics.module.scss';

const LeadMetrics = ({ data }) => {
    const { lsale, spend, cpl, returnRate } = data;
    
    const columns = [
        {
            label: 'Leads Purchased',
            value: lsale
        },
        {
            label: 'Lead Spend',
            value: dollarAmount(Number(spend))
        },
        {
            label: 'Average Cost Per Lead',
            value: dollarAmount(Number(cpl))
        },
        {
            label: 'Return Percentage',
            value: `${returnRate}%`
        }
    ];

    return (
        <div className={styles.wrap}>
            {columns.map((col, i) => {
                return (
                    <div className={styles.column} key={i}>
                        <div className={styles.label}>{col.label}</div>
                        <div className={styles.value}>{col.value}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default LeadMetrics;
