import { PageHeader } from 'components/common';
import { ProfileForm } from 'components/profiles';
import { Helmet as Head } from 'react-helmet-async';

const Profile = () => {
    return (
        <>
            <Head>
                <title>My Profile</title>
                <script src={process.env.REACT_APP_MONETRA_URL}></script>
            </Head>

            <PageHeader title="My Profile" />
            <ProfileForm />
        </>
    );
};

export default Profile;
