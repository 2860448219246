import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Content.module.scss';

const PrivacyContent = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const section = document.querySelector(location.hash);
            section && section.scrollIntoView();
        }
    }, []);

    return (
        <div className={styles.content}>
            <p>Effective: January 1, 2023</p>

            <p>
                QuoteLab, LLC, which does business as MediaAlpha, provides online services in the form of a lead
                acquisition marketplace (which we call MediaAlpha for Agents) for insurance carriers and their agents to
                acquire leads of consumers who have requested (through the websites we own and operate and our webforms
                that are embedded in certain third-party websites) to connect with and learn more about the products and
                services of such carriers and agents and to receive a quote directly from them so the consumer can
                decide if they want to make a purchase. Below are some key things to know about this Privacy Policy, us,
                and the services we provide:
            </p>

            <ul>
                <li>
                    <p>
                        <strong>
                            We refer to the MediaAlpha for Agents website(s) and our associated services collectively as
                            “our services”.
                        </strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>
                            This Privacy Policy is applicable to any visitor to or user of our services. We refer to
                            such visitors and users as “you” or “your”.
                        </strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>
                            Authorized users of MediaAlpha for Agents include insurance carriers and their agents. We
                            refer to all these businesses as “carriers and agents” or “our network of carriers and
                            agents”.
                        </strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>
                            Our websites and web forms provide the consumer the ability to connect with our network of
                            carriers and agents (including with third parties who can connect the consumer with carriers
                            and agents) to learn more about their various products and services and to request quotes
                            and rates directly from them so they can decide if they want to make a purchase.
                        </strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>
                            When consumers use our websites and webforms, the consumers will share their information
                            with us and ask us to share their information with our network of carriers and agents and to
                            request quotes or rates from them.
                        </strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>
                            Each lead contains the details of the consumer's request for quotes or rates, including the
                            consumer's personal information (also known as personal data) and, depending on the type of
                            insurance products or services involved, may also contain the personal information of others
                            including their family members or dependents.
                        </strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>
                            We do not provide rates, quotes, or insurance, or finance products or services. The rates
                            and quotes shown consumers are from participating advertisers.
                        </strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>
                            We do not act as an agent for carriers and agents nor do we sell the products or services of
                            carriers and agents.
                        </strong>
                    </p>
                </li>
            </ul>

            <p>
                With the above in mind, we wrote this Privacy Policy to describe the information we collect, how we use
                it, when and who we share it with, and the controls available to you to manage your information in a way
                that is easier to read and understand. Of course, if you have questions, feel free to contact our us at{' '}
                <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or toll free at
                1-855-959-4165.
            </p>

            <p>
                Please read our entire Privacy Policy. If you only have a few minutes and want to know how to exercise
                your rights to manage the personal information we may have about you, we recommend you review{' '}
                <a href="#your-choices-and-controls">Your Choices and Controls</a> to understand some of the basics. If
                you're a California or Nevada resident or wish to exercise rights on behalf of someone else, read the
                additional information in <a href="#your-privacy-rights">Your Privacy Rights</a>. If you're outside the
                United States, we also recommend you read the additional information{' '}
                <a href="#visitors-outside-the-us">here</a> and <a href="#european-visitors">here</a>.
            </p>

            <p>
                Lastly, before you dive in, remember this Privacy Policy only{' '}
                <span className={styles.underline}>
                    applies to our MediaAlphia for Agents website(s) and our associated services
                </span>{' '}
                that link to or reference this Privacy Policy. This Privacy Policy{' '}
                <span className={styles.underline}>does not apply</span> to our consumer facing websites and webforms or
                any other websites maintained by other companies that we may link to or those that can be accessed
                through our websites and webforms or the websites on which our webforms are located. This means that the
                privacy policy of such companies applies to any information you provide directly to them or is collected
                automatically by them from you.
            </p>

            <p id="information-we-collect">
                <strong>INFORMATION WE COLLECT</strong>
            </p>

            <p>This Privacy Policy applies only to the following basic categories of information we collect:</p>

            <ul>
                <li>
                    <p>
                        <strong>Information you provide to us through our services.</strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Information we collect automatically when you use our services.</strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Information you provide to us when you call us or when we call you.</strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>
                            Information we get from your carrier, associated agents, third parties, and offline sources.
                        </strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Any other information you choose to provide to us.</strong>
                    </p>
                </li>
            </ul>

            <p>
                For each of these categories, we use the information to provide our services to you, to communicate with
                you, to process your request(s), to understand how our services are used, to improve our services, and
                to comply with applicable laws. Additional details on each category are provided below.
            </p>

            <p>
                <strong>Information You Provide to Us Through Our Services</strong>
            </p>

            <p>
                When you use our services, we collect information that you choose to provide to us to create an account
                and to use our services including to buy leads. For example, when you elect to create an account, we may
                allow you to provide your name, address, telephone number, email, carrier affiliation, payment
                information (such as a credit card) and other information in order to create your account and grant you
                access to our services. You may also use the functionality provided in our services to provide us with
                your lead filtering parameters for geography and consumer types (e.g., premium, preferred, standard,
                non-standard, etc.) and to set your lead purchase schedule and daily limits.
            </p>

            <p id="information-we-collect-automatically">
                <strong>Information We Collect Automatically When You Use Our Services</strong>
            </p>

            <p>
                When you use our services, we collect information about your use of our services and how you've used
                them, in addition to information about your activity, browser, and device. Like most online services, we
                may collect such information by using online tracking technologies, such as cookies, pixels, tags, and
                other unique identifiers (go to allaboutcookies.org or youronlinechoices.eu to learn more about such
                tracking technologies). Below is a more detailed explanation of the types of information we collect
                automatically when you use our services:
            </p>

            <ul>
                <li>
                    <p>
                        <strong>Usage Information.</strong> We collect information about your activity through our
                        services. For example, we may collect information about:
                    </p>
                </li>

                <ul>
                    <li>
                        <p>how you interact with our services;</p>
                    </li>

                    <li>
                        <p>
                            which pages you have visited and which ones you visited immediately before or after
                            navigating to our website;
                        </p>
                    </li>

                    <li>
                        <p>
                            Your access and use of your account including the lead filtering parameters for geography
                            and consumer types (e.g., premium, preferred, standard, non-standard, etc.), the lead
                            purchase schedule and daily limits functionality, the leads you bid on, your bid price,
                            which leads you buy, and which leads you return; and
                        </p>
                    </li>

                    <li>
                        <p>other demographic and usage information.</p>
                    </li>
                </ul>
            </ul>

            <ul>
                <li>
                    <p>
                        <strong>Device, Browser, and Location Information.</strong> We collect information from and
                        about the devices and browsers you use and about your location. For example, we collect:
                    </p>
                </li>

                <ul>
                    <li>
                        <p>
                            device type, browser type, IP Address (which is treated as personal information in some
                            jurisdictions, but which we use only to identify your geographic area and to comply with
                            applicable laws); and
                        </p>
                    </li>

                    <li>
                        <p>
                            identifiers associated with cookies or other technologies that may uniquely identify your
                            device or browser.
                        </p>
                    </li>
                </ul>
            </ul>

            <ul>
                <li>
                    <p>
                        <strong>Interactions with Emails and other Communications.</strong> We may also collect
                        information when you interact with our emails and other communications that we send to you
                        (including whether you opened, forwarded or selected a link or content in such email). For
                        example, we may use information you submit to us through our services placed on third-party
                        websites to show you more relevant leads and information on the use of our services or other
                        services we offer that you may be interested in.
                    </p>
                </li>
            </ul>

            <p>
                Most web browsers are set to accept cookies by default. If you prefer, you can usually remove or reject
                browser cookies through the settings on your browser or device. Keep in mind, though, that removing or
                rejecting cookies could affect the availability and functionality of our services. For instance, some
                cookies are essential (or are required) for you to access and use our services. For example, if you
                reject all cookies, then you will not be able to access and use our services as the technology we use
                will not be able to authenticate you as an authorized user. To learn more about your choices, please
                check out <a href="#cookies-and-other-technologies">Cookies and Other Technologies</a>.
            </p>

            <p>
                <strong>Information You Provide to Us When You Call Us or When We Call You</strong>
            </p>

            <p>
                We may collect information from you when you call us or when we call you. The categories of information
                we collect from you or that you can provide to us through phone calls depends on the nature of the
                purpose of your call and our services you are calling about.
            </p>

            <p>
                <strong>Information We Receive from Suppliers, Third Parties, and other Sources</strong>
            </p>

            <p>
                We may receive information about you from suppliers, those businesses that provide us services (such as
                service providers, contractors, and consultants), third parties, or other online or offline sources. We
                may combine such information with the information you have provided to us in order to provide and
                improve our services including, among other ways, to filter or select the best leads to show to you or
                to measure the performance of services to you.
            </p>

            <p>
                <strong>Any Other Information You Choose to Provide to Us</strong>
            </p>

            <p>
                When you contact us, including by mail or email, or communicate with us in any other way, we'll collect
                whatever information you volunteer or that we need to resolve your question or request. The categories
                of information we collect from you or that you can provide to us through mail, email, or other means
                depends on the purpose of your communication to us.
            </p>

            <p id="how-we-use-your-information">
                <strong>HOW WE USE YOUR INFORMATION</strong>
            </p>

            <p>We use your information to:</p>

            <ul>
                <li>
                    <p>Create your account and authenticate you to our services.</p>
                </li>
                <li>
                    <p>Process and fulfill your request for leads.</p>
                </li>
                <li>
                    <p>
                        Communicate with you, including by phone calls, email, and mail about our services (including
                        additional services we think may interest you), your use of our services (including your bids
                        and purchases), or to respond to your inquiries. For example, if you request to be added to any
                        of our mailing lists, we will periodically send you newsletters and other emails with offers and
                        promotions. If you bid on a lead, we will communicate with you to process your bid request. Our
                        emails will always identify MediaAlpha as the sender. You may unenroll from our mailing lists
                        and newsletters any time by selecting “unsubscribe” in our emails.{' '}
                        <strong>
                            Keep in mind, we may continue to communicate with you for the purpose of communicating
                            information relating to your account, your use of our services, your participation in our
                            marketplace, general information regarding MediaAlpha, as well as to respond to any inquiry
                            or request made by you.
                        </strong>
                    </p>
                </li>
                <li>
                    <p>Provide and improve our services and understand how our services are used.</p>
                </li>
                <li>
                    <p>
                        To determine and provide ad placement, ad targeting, and ad campaign success and measurement of
                        our marketing materials and advertisements to you, including through the use of your location
                        information, both on and off our services. See{' '}
                        <a href="#cookies-and-other-technologies">Cookies and Other Technologies</a> to learn more about
                        our advertising practices and your choices.
                    </p>
                </li>
                <li>
                    <p>Develop, operate, improve, deliver, maintain, and protect our services.</p>
                </li>
                <li>
                    <p>Monitor and analyze trends and usage of our services.</p>
                </li>
                <li>
                    <p>
                        Enhance our services and your experience with them by using information we collect automatically
                        by cookies and other technologies.
                    </p>
                </li>
                <li>
                    <p>Enhance the safety and security of our services.</p>
                </li>
                <li>
                    <p>Verify your identity and prevent fraud or other unauthorized or illegal activity.</p>
                </li>
                <li>
                    <p>
                        Enforce our MediaAlpha for Agents <a href="/terms">Terms and Conditions</a> and other usage
                        policies.
                    </p>
                </li>
                <li>
                    <p>Comply with legal requirements, including applicable laws, rules, regulations, and orders.</p>
                </li>
            </ul>

            <p>
                We may also combine your information with other information we collected from you or you provided to us
                with information collected from you or you provided directly to our third-party partners and service
                providers. We use this information as detailed in this Privacy Policy.{' '}
            </p>

            <p>
                Keep in mind that information collected through cookies and other online tracking technologies is also
                used along with other information we collect for the purposes outlined in this Privacy Policy including:
                remembering your settings and choices and providing you with a customized experience; monitoring,
                analyzing, and for improving the operation, delivery, performance, and usability of our services, and
                for providing you with personalized ads based on your activity on our services and on other sites and
                services not operated by us.
            </p>

            <p id="when-and-how-we-share-information">
                <strong>WHEN AND HOW WE SHARE INFORMATION</strong>
            </p>

            <p>We may share the information we have about you in the following ways:</p>

            <ul>
                <li>
                    <p>
                        <strong>With Our Service Providers and Other Third Parties.</strong> To provide and protect our
                        services, we may share the information we have about you with those businesses that provide
                        services to us including our third-party vendors, suppliers, service providers, and other third
                        parties under the following circumstances:
                    </p>
                </li>

                <ul>
                    <li>
                        <p>
                            To measure and optimize the performance of our services. For more about the information
                            collected by third parties on our websites and webforms, visit{' '}
                            <a href="#cookies-and-other-technologies">Cookies and Other Technologies</a>.
                        </p>
                    </li>

                    <li>
                        <p>
                            To optimize our ability to reach our target audience and markets by sharing aggregated,
                            non-personally identifiable, de-identified, and aggregated information (i.e., information
                            about you and other users collectively, but not specifically identifiable to you) with such
                            providers and third parties that provide services to us or perform business purposes for us.
                        </p>
                    </li>

                    <li>
                        <p>
                            To help us and others prevent fraud, which may include sharing device, usage, and other
                            information we have about you.
                        </p>
                    </li>

                    <li>
                        <p>
                            For legal, safety, and security reasons. This means we may share information about you if we
                            reasonably believe that disclosing the information is needed to:
                        </p>
                    </li>

                    <ul>
                        <li>
                            <p>
                                Comply with any valid legal process, governmental or law enforcement request, or
                                applicable law, rule, or regulation (e.g., subpoenas, court orders, federal/state audits
                                or similar circumstances, etc.).
                            </p>
                        </li>

                        <li>
                            <p>
                                Investigate, remedy, or enforce potential violations of our polices, applicable law, and
                                our MediaAlpha for Agents <a href="/terms">Terms and Conditions</a>.
                            </p>
                        </li>

                        <li>
                            <p>
                                Protect our rights, property, or safety or those of the carriers and agents, our
                                suppliers, or others.
                            </p>
                        </li>
                        <li>
                            <p>Detect and resolve any fraud or security concerns.</p>
                        </li>
                    </ul>
                </ul>

                <li>
                    <p>
                        As part of a merger or acquisition, asset sale, financing, liquidation, or bankruptcy. If we
                        become involved in any merger, asset sale, financing, liquidation or bankruptcy, or other
                        acquisition of all or some portion of our business by another company, we may share your
                        information with that company before and after the transaction closes.
                    </p>
                </li>
            </ul>

            <p>
                Note that we have agreements with our third-party vendors, suppliers, and service providers requiring
                them to secure any personal information we provide to them, limit how they use such information, and
                comply with national, state, and other applicable privacy regulations.
            </p>

            <p>
                <strong>THIRD-PARTY CONTENT, LINKS, AND INTEGRATIONS</strong>
            </p>

            <p>
                As noted above, our services may contain third-party content, links, and integrations. Through these
                integrations, you will be providing information to other third parties, as well as to us. We are not
                responsible for how any such third parties collect or use your information. As always, we encourage you
                to read the privacy policies of every third-party service that you visit or use.
            </p>

            <p id="how-long-we-keep-information">
                <strong>HOW LONG WE KEEP YOUR INFORMATION</strong>
            </p>

            <p>
                How long we keep the information we collect about you depends on the amount, nature, and sensitivity of
                the information, the purpose for which it was provided and the purposes for which the information is
                used, whether we are able to obtain the business objective by other means, the potential risk of harm
                from unauthorized use or disclosure of the information, our legal, regulatory, tax, accounting and other
                applicable obligations, and other similar factors. For example, we keep your information:
            </p>

            <ul>
                <li>
                    <p>Until you ask us to delete it.</p>
                </li>
                <li>
                    <p>
                        For the length of time reasonably needed to fulfill the purposes for which it was collected and
                        those outlined in this Privacy Policy (including for as long as need to provide you with our
                        services), unless a longer retention period is required or permitted by applicable laws, rules,
                        or regulations.
                    </p>
                </li>
                <li>
                    <p>For as long as necessary to resolve disputes and/or enforce our rights and agreements.</p>
                </li>
                <li>
                    <p>
                        If it is aggregated, anonymized, de-identified (i.e., not capable of identifying you) then
                        indefinitely.
                    </p>
                </li>
                <li>
                    <p>
                        In our backup or disaster recovery systems for a limited period of time or as required by
                        applicable law.
                    </p>
                </li>
            </ul>

            <p>
                If necessary, however, we may retain the information we collect about you for longer periods of time
                after the set retention periods and deadlines expire such as where we are required to do so in
                accordance with legal, tax, and/or accounting requirements set by a legislature, regulator, or other
                government authority.
            </p>

            <p>
                If you ever decide to stop using our services by closing your account, you can ask us to delete your
                information (see <a href="#your-choices-and-controls">Your Choices and Controls</a> to learn more) and
                we will process your request where and to the extent possible subject to the terms of this Privacy
                Policy, the MediaAlpha for Agents <a href="/terms">Terms and Conditions</a>, and any exceptions under
                applicable law.
            </p>

            <p>
                Keep in mind, when we process your request, while our systems are designed to carry out deletion
                automatically, we cannot promise or guarantee that deletion will occur within a specific timeframe.
                Also, as noted above, there may be legal (to comply with law or enforce our rights) and contractual
                requirements to keep and not delete your information when you make a deletion request to us including to
                fulfill our obligations to you under the MediaAlpha for Agents <a href="/terms">Terms and Conditions</a>
                . This means we may suspend deletion of or not delete your information if we receive valid legal process
                asking us to preserve your information, if we receive reports of abuse or violations of MediaAlpha for
                Agents <a href="/terms">Terms and Conditions</a>, or if your use of our services (including your use of
                any lead) or your information is flagged by our automated systems for abuse or violations of MediaAlpha
                for Agents <a href="/terms">Terms and Conditions</a>.
            </p>

            <p>
                <strong>HOW WE KEEP YOUR INFORMATION SECURE</strong>
            </p>

            <p>
                The security of your personal information is very important, and we take a number of steps to safeguard
                it. For example, we have implemented technical and organizational security measures that are designed to
                help protect your information from unauthorized access, disclosure, use, and modification. We also
                maintain physical, electronic, and procedural safeguards that comply with or exceed federal standards to
                guard your information, and we anonymize or expunge data when it is no longer necessary to provide
                services to our users or substantiate records for an advertiser. From time-to-time, we review our
                security procedures to consider appropriate new technologies and methods.
            </p>

            <p>
                Even so, despite our efforts, no security measures are perfect, impenetrable, or 100% secure. We cannot
                ensure, and do not warrant or guarantee, that the information you transmit to us will remain secure, nor
                do we guarantee that this information will not be accessed, disclosed, altered, destroyed or used in an
                unauthorized manner. These are the risks of being online and conducting business online.
            </p>

            <p>
                Of course, if we learn of a security breach, we may attempt to notify you electronically so that you can
                take appropriate protective steps. We may also post a notice on our services if a security breach
                occurs. Depending on where you live, you may have a legal right to receive a notice of a security breach
                in writing. If you have any questions about the security of your information, please email us at{' '}
                <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or call us at
                1-855-959-4165.
            </p>

            <p id="cookies-and-other-technologies">
                <strong>COOKIES AND OTHER TECHNOLOGIES</strong>
            </p>

            <p>
                As discussed <a href="#information-we-collect-automatically">above</a>, our service providers (including
                any other third parties working on our behalf), and any other businesses (third parties) who collect
                information on our services, may automatically receive certain information associated with you and/or
                your device(s) as part of your use of our websites and webforms through browser or device-based tracking
                technologies such as cookies, pixels, tags, beacons, scripts, or other technology.
            </p>

            <p>
                Many devices and browsers give you the option to manage and/or disable cookies and other online tracking
                technologies. To opt-out of the use of these technologies, you can change your browser options to stop
                accepting cookies or to prompt you before accepting cookies. To assist you, we compiled directions for
                some commonly used browsers and devices in the table below. Keep in mind that not all devices and
                browsers are the same so the methods described below may not work for your browser, browser version, or
                device and may change at any time. We encourage you to refer to the relevant instructions for your
                particular device or browser.
            </p>

            <p>
                <strong>
                    Before you dive in, please note, if you opt out of the collection and use of information via cookies
                    and other tracking technologies, you may still see customized advertising displayed by advertising
                    networks from which you have not opted out
                </strong>
                . In addition, your opt-out will not prevent any advertising network from displaying advertising that is
                not customized to you and your interests and, consequently, may be less relevant to you. You will need
                to make such choices on each browser and device you may use to visit our services.
            </p>

            <ul>
                <li>
                    <p>
                        <strong>Disabling Cookies in Browsers</strong>. When you elect to opt-out on a website,
                        including our websites, an opt-out cookie is placed on your device. Then when you visit the same
                        website, the opt-out cookie tells the website you opted out. This means that blocking all
                        cookies may then prevent the websites from operating as expected and may also prevent your
                        consent choices from being stored. This may mean that if you opt-out and then block cookies, we
                        may not know about, or be able to honor, your opt-out. You should also be aware that blocking
                        cookies on your computer will not affect your consent choices on a different device, such as a
                        mobile device. You may want to keep this in mind when determining your browser cookie settings.
                        For more information on how to disable cookies on the most common browsers see below.
                    </p>

                    <table border="1" cellSpacing="0" cellPadding="0" width="100%" style={{ marginBottom: 15 }}>
                        <thead>
                            <tr>
                                <td valign="bottom">
                                    <p>
                                        <strong>Browser Name</strong>
                                    </p>
                                </td>
                                <td valign="bottom">
                                    <p>
                                        <strong>Instructions</strong>
                                    </p>
                                </td>
                                <td valign="bottom">
                                    <p>
                                        <strong>Source</strong>
                                    </p>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td valign="top">
                                    <p>
                                        <strong>Google Chrome</strong>
                                    </p>
                                </td>
                                <td valign="top">
                                    <ol start="1" type="1">
                                        <li>At the top right, click “⋮” icon, and then Settings.</li>
                                        <li>At the bottom, click Advanced.</li>
                                        <li>Under “Privacy and security”, click Site settings and then Cookies.</li>
                                        <li>Turn Allow sites to save and read cookie data on or off.</li>
                                    </ol>
                                </td>
                                <td valign="top">
                                    <p>
                                        <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;amp;hl=en-GB">
                                            Google Support
                                        </a>
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">
                                    <p>
                                        <strong>Apple Safari</strong>
                                    </p>
                                </td>
                                <td valign="top">
                                    <p>On Mac:</p>

                                    <ul>
                                        <li>Safari &gt; Preferences, click “Privacy”</li>
                                    </ul>

                                    <p>On iOS:</p>

                                    <ul>
                                        <li>Settings &gt; Safari, then turn on “Block All Cookies”</li>
                                    </ul>
                                </td>
                                <td valign="top">
                                    <p>
                                        <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                                            Apple Mac Support
                                        </a>
                                    </p>
                                    <p>
                                        <a href="https://support.apple.com/en-us/HT201265">Apple iOS Support</a>
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">
                                    <p>
                                        <strong>Mozilla Firefox</strong>
                                    </p>
                                </td>
                                <td valign="top">
                                    <ol start="1" type="1">
                                        <li>Click the menu button “☰” and select “Preferences”.</li>
                                        <li>
                                            Select the “Privacy &amp; Security” panel and go to the “Cookies and Site
                                            Data” section.
                                        </li>
                                    </ol>
                                </td>
                                <td valign="top">
                                    <p>
                                        <a href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox">
                                            Mozilla Support
                                        </a>
                                    </p>
                                </td>
                            </tr>

                            <tr>
                                <td valign="top">
                                    <p>
                                        <strong>Microsoft Edge</strong>
                                    </p>
                                </td>
                                <td valign="top">
                                    <ol start="1" type="1">
                                        <li>More button “⋯” &gt; Settings.</li>
                                        <li>Select “View Advanced Settings”.</li>
                                        <li>Under “Privacy and Services” &gt; “Cookies”</li>
                                    </ol>
                                </td>
                                <td valign="top">
                                    <p>
                                        <a href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">
                                            Microsoft Support
                                        </a>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </li>

                <li>
                    <p>
                        <strong>Other Options for Disabling Online Tracking</strong>. Blocking cookies in your browser
                        may not prevent cross-site tracking of your online activity using other online tracking
                        technologies. In addition to managing your cookie settings for your browser, you can opt-out of
                        tracking directly with the businesses that operate the tracking technologies. To opt-out of
                        certain analytic tracking by our service providers visit the links below:
                    </p>
                </li>

                <table border="1" cellSpacing="0" cellPadding="0" width="100%">
                    <thead>
                        <tr>
                            <td width="17%" valign="bottom">
                                <p>
                                    <strong>Service Provider</strong>
                                </p>
                            </td>
                            <td width="82%" valign="bottom">
                                <p>
                                    <strong>Helpful Links</strong>
                                </p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="17%" valign="top">
                                <p>Google Analytics</p>
                            </td>
                            <td width="82%" valign="top">
                                <p>
                                    <a href="https://tools.google.com/dlpage/gaoptout">
                                        https://tools.google.com/dlpage/gaoptout
                                    </a>
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <td width="17%" valign="top">
                                <p>Bing</p>
                            </td>
                            <td width="82%" valign="top">
                                <p>
                                    <a href="https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads">
                                        https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads
                                    </a>
                                </p>

                                <p>
                                    <a href="http://choice.microsoft.com/">http://choice.microsoft.com/</a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <li>
                    <p>
                        <strong>Do Not Track</strong>. Some web browsers may transmit “do not track” signals (go{' '}
                        <a href="https://allaboutdnt.com/">here</a> to learn more). Web browsers may incorporate or
                        activate these features differently, making it unclear if users have consciously activated them.
                        As a result, we do not take steps to respond to such signals.
                    </p>
                </li>

                <li>
                    <p id="european-visitors">
                        <strong>European Visitors</strong>. In connection with the General Data Protection Regulation
                        (GDPR) applicable in the European Economic Area (EEA), we eliminate or limit the use of cookies
                        and similar technologies with visitors from the EEA (excluding our corporate website available
                        at www.mediaalpha.com, which continues to use cookies for all users with proper consent (see
                        more information below)). Specifically:
                    </p>
                </li>

                <ul>
                    <li>
                        <p>
                            If you visit a third-party site that serves our advertisements, we will not place any
                            cookies on your device unless you have provided your consent and been provided with notice
                            of our privacy practices and your ability to opt-out at any time.
                        </p>
                    </li>

                    <li>
                        <p>
                            We will not seek your consent to use cookies in this instance, though the advertiser or
                            their website publisher may do so.
                        </p>
                    </li>

                    <li>
                        <p>
                            If you visit our services, we will only place limited third-party cookies, such as those
                            made available for analytics purposes by Google and Bing. In this case, we will seek your
                            consent to use these third-party cookies and will only do so after you indicate your
                            consent. If you do not consent, then no cookies will be written to your device.
                        </p>
                    </li>

                    <li>
                        <p>
                            If you are visiting our corporate website, mediaalpha.com, for the first time, or the first
                            time after we make a material change, we will seek your consent to use cookies.
                        </p>
                    </li>

                    <li>
                        <p>
                            If you do not consent, you will still be able to use our services, but certain functionality
                            may be limited. To enable this feature, we use third-party tools to determine your
                            geographic location; if these tools indicate that you are in the EEA, then we treat your
                            visit as above. If you feel that you are subject to GDPR, but we have nonetheless used
                            cookies in connection with your visit without consent (which may be due to a failure of the
                            third party geolocation tools, your use of a proxy server, your location outside of the EEA
                            at the time of your visit, or some other factor beyond our control) please notify us at{' '}
                            <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or call us at
                            1-855-959-4165 and make sure to include any relevant details so that we can ensure proper
                            treatment of your data under GDPR.
                        </p>
                    </li>
                </ul>
            </ul>

            <p id="your-privacy-rights">
                <strong>YOUR PRIVACY RIGHTS</strong>
            </p>

            <p>
                To manage or make choices about the personal information (as known as personal data) we have about you,
                review <a href="#your-choices-and-controls">Your Choices and Controls</a>. If you are a resident of
                California, Nevada, Colorado, Connecticut, Utah, Virginia, or a citizen of a European Economic Area
                (EEA) Member State, review the applicable sections below to learn more about the privacy rights that may
                be available to you. If you are a parent or legal guardian or a representative, review the sections
                identified below to learn more about how you may exercise rights on behalf of your child or another
                person.
            </p>

            <p>
                When we use the term “personal information” (also known as personal data) in this Policy, we mean
                information that identifies, relates to, describes, is reasonably capable of being associated with, or
                could reasonably be linked, directly or indirectly, to an individual or household. It does not include
                aggregated, anonymized, or de-identified information (subject to applicable laws, rules, or regulations)
                that is maintained in a form that is not capable of being associated with or reasonably linked to an
                individual.
            </p>

            <ul>
                <li>
                    <p>
                        <strong>State Specific Disclosures</strong>. The following State Specific Disclosures supplement
                        the above information in this Policy by providing additional information about our personal
                        information practices relating to individual residents of the States of California, Colorado,
                        Connecticut, Nevada, Utah, and Virginia. Unless otherwise expressly stated, all terms defined
                        and used in this Policy retain the same meaning in these disclosures.
                    </p>
                    <ul>
                        <li>
                            <p>
                                <span className={styles.underline}>
                                    Rights Regarding the Personal Information We Have About You
                                </span>
                                . Depending on your state of residency and the laws, rules, and regulations that apply,
                                you may have the following rights:
                            </p>
                            <ul>
                                <li>
                                    <p>Right to know about the personal information we have collected about you;</p>
                                </li>
                                <li>
                                    <p>Right to delete personal information we have collected about you;</p>
                                </li>
                                <li>
                                    <p>Right to correct inaccurate personal information we have collected about you;</p>
                                </li>
                                <li>
                                    <p>
                                        Right to access and receive a copy of the personal information we collected
                                        about you;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Right to opt-out of the sale or sharing of the personal information we have
                                        collected about you;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Right to limit the use or disclosure of the sensitive personal information we
                                        have collected about you; and
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Right to know certain information about our data practices in the preceding 12
                                        months.{' '}
                                    </p>
                                </li>
                            </ul>
                            <p>
                                <strong>Your Right to Know</strong>. Depending on your state of residence and the
                                applicable laws, rules, and regulations, you may have the right to know about what
                                personal information we collect and the sources from which we collect, how we use the
                                personal information we collect and for what business purposes we use it, how, when, and
                                with whom we share the personal information we collect, and the criteria we use to
                                determine the length of time we intend to retain personal information. We provide this
                                information in this Policy (with additional details provided below). We may also provide
                                additional notices and disclosures to you at the point of collection. For example, when
                                you sign up for an account or in your account. In this Policy, to learn more about:
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        What categories of personal information we collect and the categories of sources
                                        from which we collect such information, review{' '}
                                        <a href="#information-we-collect">Information We collect</a>.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        How we use and the business purposes for which we use the categories of
                                        information we collect, review{' '}
                                        <a href="#how-we-use-your-information">How We Use Your Information</a>.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        How, when, and with what categories of parties we share the information we
                                        collect, review{' '}
                                        <a href="#when-and-how-we-share-information">
                                            When and How We share Your Information
                                        </a>
                                        .
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The criteria we use to determine the length of time we intend to retain each
                                        category of personal information we collect about you, review{' '}
                                        <a href="#how-long-we-keep-information">How Long We Keep Your Information</a>.
                                    </p>
                                </li>
                            </ul>
                            <p>
                                <strong>
                                    Your Right to Access and Receive a Copy of the Personal Information We Have About
                                    You
                                </strong>
                                . Depending on your state of residence, you may have the right to request the following
                                from us:
                            </p>
                            <ul>
                                <li>
                                    <p>Specific pieces of personal information we collected about you;</p>
                                </li>
                                <li>
                                    <p>Categories of personal information we collected about you;</p>
                                </li>
                                <li>
                                    <p>Categories of sources from which the personal information is collected;</p>
                                </li>
                                <li>
                                    <p>
                                        Categories of personal information we sold or disclosed for a business purpose
                                        about you;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Categories of third parties to whom the personal information was sold or
                                        disclosed for a business business purpose; and
                                    </p>
                                </li>
                                <li>
                                    <p>The business purpose for collecting or selling personal information.</p>
                                </li>
                            </ul>
                            <p>
                                <strong>WE DO NOT SELL OR SHARE YOUR PERSONAL INFORMATION</strong>. Depending on your
                                state of residence and subject to certain exceptions, the right to opt-out may include
                                the right to direct a business that collects your personal information to not “sell”
                                your personal information to third parties for monetary or other valuable consideration
                                or “share” your personal information to third parties for cross-context behavioral
                                advertising purposes and targeted advertising purposes. As described in{' '}
                                <a href="#information-we-collect">Information We collect</a> and{' '}
                                <a href="#when-and-how-we-share-information">When and How We share Your Information</a>,
                                and subject to the limitations we describe in this Policy, we may share your personal
                                information for the described business purposes with strict restrictions on how our
                                business partners and other third parties can use and disclose the information we
                                provide, at your direction, or in ways that are otherwise in accordance with applicable
                                laws, rules, and regulations. We do not sell or share any of your personal information.
                            </p>
                            <p>
                                <strong>LIMIT THE USE OR DISCLOSURE OF YOUR SENSITIVE PERSONAL INFORMATION</strong>.
                                Depending on your state of residence and subject to certain exceptions, you may have the
                                right to limit the use or disclosure of the categories of sensitive personal information
                                a business collects about you. We do not collect sensitive personal information as part
                                of your use of our services.
                            </p>
                            <p>
                                <strong>Right to Non-Discrimination</strong>. Depending on your state of residence and
                                subject to certain exceptions, should you wish to exercise your rights, we will not
                                discriminate against you by showing you different products or services based solely upon
                                your request.
                            </p>
                            <p>
                                <strong>Shine the Light (California Only)</strong>. California residents may, no more
                                than once per calendar year, request from us information about our disclosure of certain
                                categories of personal information to third parties for direct marketing purposes. To be
                                valid, requests must be submitted to us by contacting our Customer Success Team at{' '}
                                <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or call us
                                at 1-855-959-4165. Please identify that you are submitting a “Shine the Light Request”
                                request. We will provide you within 30 days of receiving your request, a list of the
                                categories of personal information (also known as personal data) disclosed to third
                                parties for direct marketing purposes during the immediately preceding calendar year and
                                the names and addresses of the third parties.
                            </p>
                        </li>
                        <li>
                            <p>
                                <span className={styles.underline}>How to Exercise Your Rights With Us</span>. To
                                exercise any of these rights, please contact our Customer Success Team at{' '}
                                <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or call us
                                at 1-855-959-4165 to submit your request. In the request, please specify which right you
                                are seeking to exercise and the scope of the request. We will confirm receipt of your
                                request within 10 days. We may also ask you for specific information to verify and
                                process your request. Kindly note, if we do not have information that permits us to
                                verify your identity, we may deny your requests to know or delete.
                            </p>
                            <p>
                                Keep in mind, you are responsible for keeping your account information up-to-date and
                                accurate. If you wish to update or correct your account information (name, email, phone,
                                credit card, etc.) you can <a href="/sign-in">log into your account</a> to update your
                                information in your account settings at any time. Additionally, if your credit card
                                information is lost or stolen or your account credentials are compromised contact our
                                Customer Success Team at{' '}
                                <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or call us
                                at 1-855-959-4165.
                            </p>
                        </li>
                        <li>
                            <p>
                                <span className={styles.underline}>
                                    How to Use an Authorized Agent to Submit Your Rights Requests
                                </span>
                                . In certain circumstances, you may be permitted to use an authorized agent to submit
                                your rights requests to us on your behalf through the designated methods details in this
                                Policy where we can verify the authorized agent's authority to act on your behalf. In
                                order to verify the authorized agent's authority, we generally require evidence of
                                either: (i) a valid power of attorney; or (ii) a signed letter containing your name and
                                contact information, the name and contact information of the authorized agent, and a
                                statement of authorization for the request. Depending on the evidence provided and your
                                state of residency, we may still need to separately reach out to you to confirm the
                                authorized agent has permission to act on your behalf and to verify your identity in
                                connection with the request.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p id="visitors-outside-the-us">
                        <strong>Visitors Outside the United States</strong>. This Privacy Policy is intended to cover
                        collection of information on our services from residents of the United States. The data
                        protection and other laws of the United States might not be as comprehensive as those in your
                        country. By visiting our services or otherwise using our services in any other way, you
                        acknowledge and agree that your information may be transferred to our facilities and those third
                        parties with whom we share it as described in this Privacy Policy, regardless of the laws of
                        your country of residence. To the extent the laws of another jurisdiction apply to your data or
                        use of our services, we will comply with such laws. For example, for European Visitors, read the{' '}
                        <a href="#european-visitors">European Visitors</a> section of{' '}
                        <a href="#cookies-and-other-technologies">Cookies and Other Technologies</a> to learn more about
                        our use of cookies and similar technologies with visitors from the EEA.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Children's Information</strong>. As noted in the MediaAlpha for Agents{' '}
                        <a href="/terms">Terms and Conditions</a>, you must be at least 18 years old to use our
                        services. We do not knowingly collect, use, or disclose personal information about visitors
                        under 18 years of age. If you are under 18, you may not use our services.
                    </p>
                </li>
            </ul>

            <p id="your-choices-and-controls">
                <strong>YOUR CHOICES AND CONTROLS</strong>
            </p>

            <p>
                We want you to be in control of your information, so we provide you with the following ways to exercise
                control over the information we have about you. Keep in mind that each option is subject to applicable
                legal limitations. Additionally, because your privacy is important to us, we will ask you to verify your
                identity/request or provide additional information before we let you exercise your choices over the
                information we have about you. We may also reject your request for several reasons, including, by way of
                example only, if the request risks the privacy of other persons or is unlawful.
            </p>

            <p>
                For each of the following, when you contact us, please identify to us which right you are seeking to
                exercise and the scope of the request.
            </p>

            <ul>
                <li>
                    <p>
                        <strong>Access and Portability.</strong> You can access and receive a copy of the information we
                        have about you by contacting us at{' '}
                        <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or toll free at
                        1-855-959-4165.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Correction or Modify (also known as rectification).</strong> You are responsible for
                        keeping your account information up-to-date and accurate. If you wish to update or correct your
                        account information first <a href="/sign-in">log into your account</a> to update your
                        information in your account settings at any time. For any information that cannot be updated
                        through your account or if your credit card information is lost or stolen or your account
                        credentials are compromised or if you need assistance contact us at{' '}
                        <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or at
                        1-855-959-4165.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Deletion (also known as erasure). </strong>If for some reason you ever want to delete
                        the information we have about you, by contacting us at{' '}
                        <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or at
                        1-855-959-4165.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Revoke Consent or Object to Processing. </strong>You can revoke the consent you gave to
                        us or object to our processing of the information we have about you by contacting us at{' '}
                        <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or at
                        1-855-959-4165.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Marketing and Communication Preferences.</strong> If you wish to unsubscribe to any
                        email communications we send to you, simply select “unsubscribe” in the emails. If you wish to
                        unsubscribe or change/update any communication preferences with us including calls or mail,
                        contacting us at <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or
                        at 1-855-959-4165.{' '}
                        <span className={styles.underline}>
                            Please note that we only control our own mailing and marketing lists/subscriptions and
                            policies. Third parties that maintain their own marketing lists may send communications that
                            advertise our services. You must contact these parties directly in order to stop receiving
                            their communications
                        </span>
                        .{' '}
                        <strong>
                            Keep in mind, we may continue to communicate with you for the purpose of communicating
                            information relating to your account, your use of our services, your participation in our
                            marketplace, general information regarding MediAlpha, as well as to respond to any inquiry
                            or request made by you.
                        </strong>
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Cookies and other Tracking Technologies.</strong> To learn more about your choices and
                        how to exercise control over the information we collect from you automatically using cookies and
                        other online tracking technologies, read{' '}
                        <a href="#cookies-and-other-technologies">Cookies and Other Technologies</a>.
                    </p>
                </li>
            </ul>

            <p>
                If you have any questions or need assistance contact us at{' '}
                <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or at 1-855-959-4165.
            </p>

            <p>
                <strong>REVISIONS TO THE PRIVACY POLICY</strong>
            </p>

            <p>
                We may change this Privacy Policy from time to time. When we do, we'll let you know by revising the date
                at the top of the Privacy Policy. We may also provide you with additional notice (such as adding a
                statement to our services' homepages or adding “Updated” to the footer links to our Privacy Policy
                (e.g., Updated Privacy Policy), or pop-up alerts in your account. We encourage you to review this
                Privacy Policy whenever you use any of our services to ensure you are aware of our current privacy
                practices.
            </p>

            <p>
                <strong>QUESTIONS AND SUGGESTIONS</strong>
            </p>

            <p>
                If you have any questions, concerns, or suggestions about our Privacy Policy, you may contact us at{' '}
                <a href="mailto:agentsupport@mediaalpha.com">agentsupport@mediaalpha.com</a> or toll free at
                1-855-959-4165 or by mail at:
            </p>

            <p>MediaAlpha</p>

            <p>Attention: DPO</p>

            <p>700 S. Flower St., Suite 640</p>

            <p>Los Angeles, CA 90017</p>
        </div>
    );
};

export { PrivacyContent };
