import {
    Account24,
    CaretLeft16,
    CaretRight16,
    Collaborate24,
    Currency24,
    PiggyBank24,
    Rss24,
    Settings24,
    Undo24,
    UserMultiple24
} from '@carbon/icons-react';
import clsx from 'clsx';
import { AccountManager } from 'components/accounts';
import useAccount, { statuses } from 'hooks/use-account';
import useUser from 'hooks/use-user';
import { Link, NavLink } from 'react-router-dom';
import styles from './Sidebar.module.scss';

const Sidebar = ({ collapsed, onCollapseToggle }) => {
    const { status, accountManager, features, canManageAccount } = useAccount();
    const { employee } = useUser();

    const getLinks = () => {
        if (!employee && [statuses.PENDING, statuses.UNSOLD].includes(status)) {
            return links.PENDING;
        }
        else {
            const showLeadFeed = features?.leadFeedCall || features?.leadFeedLead;
            const showEmployee = employee && canManageAccount;

            return [...(showLeadFeed ? links.LEAD_FEED : []), ...links.ACTIVE, ...(showEmployee ? links.EMPLOYEE : [])];
        }
    };

    const sidebarLinks = getLinks();

    const CollapseToggle = () => (
        <div className={styles.collapseToggle} onClick={onCollapseToggle}>
            {collapsed ? (
                <CaretRight16 className={clsx(styles.caret)} />
            ) : (
                <CaretLeft16 className={clsx(styles.caret, styles.collapse)} />
            )}
        </div>
    );

    return (
        <aside className={clsx(styles.sidebar, collapsed && styles.collapsed)}>
            {sidebarLinks.map((link) => (
                <SidebarLink collapsed={collapsed} {...link} key={link.to} />
            ))}
            <div className={styles.bottom}>
                {accountManager && <AccountManager accountManager={accountManager} collapsed={collapsed} />}
                {!collapsed && <LegalLinks />}
            </div>
            <CollapseToggle />
        </aside>
    );
};

const SidebarLink = ({ text, to, icon: Icon, collapsed }) => {
    const { id } = useAccount();
    return (
        <NavLink
            to={`${to}?advertiser_id=${id}`}
            className={clsx(styles.link, collapsed && styles.collapsed)}
            activeClassName={styles.selected}
            exact
        >
            <Icon className={styles.icon} />
            <div className={styles.text}>{text}</div>
        </NavLink>
    );
};

const LegalLinks = () => {
    return (
        <div className={styles.legal}>
            <Link to="/terms" className={styles.legalLink}>
                Updated Agent Terms
            </Link>
            <Link to="/privacy" className={styles.legalLink}>
                Privacy Policy
            </Link>
        </div>
    );
};

const links = {
    PENDING: [
        {
            text: 'Deposits',
            to: '/p/deposits',
            icon: PiggyBank24
        },
        {
            text: 'Settings',
            to: '/p/account',
            icon: Settings24
        }
    ],
    ACTIVE: [
        {
            text: 'My Leads',
            to: '/p/leads/purchased',
            icon: UserMultiple24
        },
        {
            text: 'Returns',
            to: '/p/leads/returned',
            icon: Undo24
        },
        {
            text: 'Transactions',
            to: '/p/transactions',
            icon: Currency24
        },
        {
            text: 'Referrals',
            to: '/p/referrals',
            icon: Collaborate24
        },
        {
            text: 'Deposits',
            to: '/p/deposits',
            icon: PiggyBank24
        },
        {
            text: 'Settings',
            to: '/p/account',
            icon: Settings24
        }
    ],
    LEAD_FEED: [
        {
            text: 'Opportunities',
            to: '/p/opportunities',
            icon: Rss24
        }
    ],
    EMPLOYEE: [
        {
            text: 'Features',
            to: '/p/features',
            icon: Account24
        }
    ]
};

export { LegalLinks, Sidebar as default };

