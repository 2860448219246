import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

const usePrompt = (handleBlockedNavigation, hasUnsavedChanges) => {
    const history = useHistory();
    const unblock = useRef(null);

    useEffect(() => {
        unblock.current = hasUnsavedChanges ? history.block(handleBlockedNavigation) : null;

        return () => {
            unblock.current && unblock.current();
        };
    }, [hasUnsavedChanges, history, handleBlockedNavigation]);
};

/**
 * Custom hook used for preventing navigation with unsaved changes
 * Show warning modal for unsaved changes when user tries to navigate away
 * If clicked on "Keep Editing" button on modal, modal is closed and user can keep on editing
 * If clicked on "Discard Changes", unsaved changes are discarded and user is taken to the requested route
 *
 * @param {boolean} hasUnsavedChanges
 * @returns {object} {isNavigationPromptOpen, confirmDiscardChanges, confirmNavigation, cancelNavigation}
 */
const useNavigationGuard = (hasUnsavedChanges) => {
    const [blockedPath, setBlockedPath] = useState('');
    const [isNavigationBlocked, setIsNavigationBlocked] = useState(false);
    const [confirmDiscardChanges, setConfirmDiscardChanges] = useState(false);

    const history = useHistory();

    // Warn if there exists unsaved changes when browser refresh button is clicked or when url is modified
    if (hasUnsavedChanges && !confirmDiscardChanges) {
        window.onbeforeunload = () => ''; // Currently modern browsers do not support custom messages
    }
    else {
        window.onbeforeunload = null; // destroy the window.onbeforeunload event
    }

    const cancelNavigation = useCallback(() => {
        setIsNavigationBlocked(false);
    }, []);

    const handleBlockedNavigation = useCallback(
        (nextLocation) => {
            if (!confirmDiscardChanges) {
                setIsNavigationBlocked(true);
                setBlockedPath(nextLocation.pathname);
                return false;
            }
            return true;
        },
        [confirmDiscardChanges]
    );

    const confirmNavigation = useCallback(() => {
        setIsNavigationBlocked(false);
        setConfirmDiscardChanges(true);
    }, []);

    useEffect(() => {
        // If changes are discarded, navigate to the path clicked
        // This also supports browser back button click
        if (confirmDiscardChanges && blockedPath) {
            history.replace(blockedPath);
        }
    }, [confirmDiscardChanges, blockedPath]);

    usePrompt(handleBlockedNavigation, hasUnsavedChanges);

    return { isNavigationBlocked, confirmNavigation, cancelNavigation };
};

export default useNavigationGuard;
