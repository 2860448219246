import { AGENT_BASE_URLS, ENVIRONMENTS, EXCHANGE_BASE_URLS } from './constants';

const getEnvironment = (url) => {
    const host = url ? (new URL(url)).host : window.location.host;
    const hostParts = host.split('.');

    if (process.env.NODE_ENV === 'development' || hostParts.length === 4 || hostParts[0].includes('agent-alpha')) {
        return ENVIRONMENTS.DEVELOPMENT;
    }

    return hostParts[0].includes('agent-beta') ? ENVIRONMENTS.BETA : ENVIRONMENTS.PRODUCTION;
};

const getExchangeBaseUrl = (environment = getEnvironment()) => {
    return EXCHANGE_BASE_URLS[environment] || '';
};

const getAgentBaseUrl = (environment = getEnvironment()) => {
    return AGENT_BASE_URLS[environment] || '';
};

const getSearchParam = (param) => {
    try {
        const params = new URLSearchParams(window.location.search);
        return params.has(param) ? params.get(param) : null;
    }
    catch (err) {
        return null;
    }
};

export { getEnvironment, getAgentBaseUrl, getExchangeBaseUrl, getSearchParam };
