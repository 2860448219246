import { Add16 } from '@carbon/icons-react';
import { Button, Row } from 'carbon-components-react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import useOutsideClickToClose from 'hooks/use-outside-click-to-close';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './CreateAccountMenu.module.scss';

const CreateAccountMenu = () => {
    const createAccountMenuRef = useRef();
    const [createAccountMenuOpen, setCreateAccountMenuOpen] = useState(false);

    useOutsideClickToClose(createAccountMenuRef, createAccountMenuOpen, setCreateAccountMenuOpen);

    return (
        <span className={styles.createAccBtn}>
            <Button renderIcon={Add16} onClick={() => setCreateAccountMenuOpen(true)}>
                New Account
            </Button>
            {createAccountMenuOpen && (
                <motion.div className={styles.wrap} {...menuAnim} ref={createAccountMenuRef}>
                    <Row className={clsx(styles.regAccRow)}>
                        <Link to="/p/accounts/new" className={clsx(styles.menuItem)}>
                            <div className={styles.linkText}>Regular Account</div>
                        </Link>
                    </Row>
                    <Row>
                        <Link to="/p/accounts/new-sf-subsidy" className={clsx(styles.menuItem, styles.carrierText)}>
                            <div className={clsx(styles.linkText)}>State Farm Subsidy Account</div>
                        </Link>
                    </Row>
                </motion.div>
            )}
        </span>
    );
};

const menuAnim = {
    initial: {
        opacity: 0,
        scale: 0,
        originX: 1,
        originY: 0
    },
    animate: {
        opacity: 1,
        scale: 1
    },
    transition: {
        duration: 0.1
    }
};

export default CreateAccountMenu;
