import { Add16, Information16, TrashCan16 } from '@carbon/icons-react';
import {
    Button,
    DataTable,
    Modal,
    Select,
    SelectItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableSelectAll,
    TableSelectRow,
    TextInput,
    TooltipIcon
} from 'carbon-components-react';
import clsx from 'clsx';
import { Fragment, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getRandomId } from 'util/table-helpers';
import { BidField, getMinBid } from './BidField';
import { CampaignDailyCapField } from './Campaigns';
import styles from './Campaigns.module.scss';
import { tooltips } from './campaign-conf';

const CUSTOM_LIMIT = 10;
const CAMPAIGN = 'campaign';

const getHeaderData = (showDailyCapLevel = false) =>
    showDailyCapLevel ? headerData : headerData.filter((col) => col.key !== 'dailyCap');

const CustomLifeCampaigns = ({ namespace, formDisabled, product }) => {
    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        clearErrors,
        unregister,
        setDeletedFields
    } = useFormContext();

    const campaignsData = getValues(namespace);
    const customs = Object.values(campaignsData).filter((c) => !!c.custom);

    const [root, , section] = namespace.split('.');
    const customErrors = errors[root]?.[product]?.[section] || {};
    const dailyCapLevel = getValues(`${namespace}.dailyCapLevel`);

    const [deleteModal, setDeleteModal] = useState({ open: false, campaignKey: null, campaignName: '' });

    const handleOpen = (campaignKey, campaignName) => {
        setDeleteModal({ open: true, campaignName, campaignKey });
    };

    const handleClose = () => {
        setDeleteModal({ open: false, campaignKey: null, campaignName: '' });
    };

    const deleteCustom = () => {
        setDeletedFields(true);
        unregister(deleteModal.campaignKey);
        handleClose();
    };

    const handleCheckAll = () => {
        const someEnabled = customs.some((c) => c.enabled);
        customs.forEach((c) => {
            const { campaignId, customId } = c;
            setValue(`${namespace}.${campaignId ? campaignId : customId}.enabled`, !someEnabled, {
                shouldDirty: true,
                shouldValidate: true
            });
        });
    };

    const createRow = (custom) => {
        const { enabled, campaignId, customId, targeting } = custom;
        // existing custom campaigns will have a campaignId
        // new custom campaigns will have a customId
        const customKey = campaignId || customId;
        const disabled = formDisabled || !enabled;
        const currentCampaign = `${namespace}.${customKey}`;
        const minBid = getMinBid(targeting.coverageType);

        const validateName = (val) => {
            if (!enabled) {
                return true;
            }
            if (val.length > 25) {
                return 'Max 25 characters';
            }
            return true;
        };

        const fromAge = register(`${currentCampaign}.targeting.age.from`, {
            validate: (val) => {
                if ((toAgeValue !== 'any' || val !== 'any') && Number(val) > Number(toAgeValue)) {
                    return 'Min must be smaller than max';
                }
                clearErrors([fromAge.name, toAge.name]);
                return true;
            }
        });

        const toAge = register(`${currentCampaign}.targeting.age.to`, {
            validate: (val) => {
                if ((fromAgeValue !== 'any' || val !== 'any') && Number(val) < Number(fromAgeValue)) {
                    return 'Min must be smaller than max';
                }
                clearErrors([fromAge.name, toAge.name]);
                return true;
            }
        });

        const fromCoverage = register(`${currentCampaign}.targeting.coverageAmount.from`, {
            validate: (val) => {
                if ((toCoverageValue !== 'any' || val !== 'any') && Number(val) > Number(toCoverageValue)) {
                    return 'Min must be smaller than max';
                }
                clearErrors([fromCoverage.name, toCoverage.name]);
                return true;
            }
        });

        const toCoverage = register(`${currentCampaign}.targeting.coverageAmount.to`, {
            validate: (val) => {
                if ((fromCoverageValue !== 'any' || val !== 'any') && Number(val) < Number(fromCoverageValue)) {
                    return 'Min must be smaller than max';
                }
                clearErrors([fromCoverage.name, toCoverage.name]);
                return true;
            }
        });

        const fromAgeValue = getValues(fromAge.name);
        const toAgeValue = getValues(toAge.name);
        const fromCoverageValue = getValues(fromCoverage.name);
        const toCoverageValue = getValues(toCoverage.name);

        const fromAgeErrors = customErrors[customKey] && customErrors[customKey]?.targeting?.age?.from;
        const toAgeErrors = customErrors[customKey] && customErrors[customKey]?.targeting?.age?.to;
        const fromCoverageErrors = customErrors[customKey] && customErrors[customKey]?.targeting?.coverageAmount?.from;
        const toCoverageErrors = customErrors[customKey] && customErrors[customKey]?.targeting?.coverageAmount?.to;

        return {
            id: `row${customKey}`,
            key: customKey,
            name: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <TextInput
                        id={`${currentCampaign}.campaign`}
                        labelText=""
                        {...register(`${currentCampaign}.campaign`, {
                            validate: (val) => validateName(val)
                        })}
                        defaultValue={getValues(`${currentCampaign}.campaign`) || ''}
                        invalid={customErrors ? customErrors[customKey] && !!customErrors[customKey]?.campaign : false}
                        invalidText={customErrors ? customErrors[customKey]?.campaign?.message : ''}
                    />
                </fieldset>
            ),
            bid: <BidField namespace={namespace} campaignKey={customKey} disabled={disabled} minBid={minBid} />,
            dailyCap: <CampaignDailyCapField namespace={namespace} campaignKey={customKey} disabled={disabled} />,
            coverageType: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <Select
                        {...register(`${currentCampaign}.targeting.coverageType`)}
                        id={`${currentCampaign}.targeting.coverageType`}
                        labelText=""
                        defaultValue={targeting.coverageType}
                    >
                        {options.coverageType.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                </fieldset>
            ),
            age: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <Select
                        className={styles.ageSelect}
                        id={fromAge.name}
                        name={fromAge.name}
                        labelText="Min"
                        ref={fromAge.ref}
                        defaultValue={targeting.age.from}
                        onChange={(evt) => {
                            fromAge.onChange(evt);
                            setValue(fromAge.name, evt.target.value, {
                                shouldDirty: true,
                                shouldValidate: true
                            });
                        }}
                        onBlur={fromAge.onBlur}
                        invalid={!!fromAgeErrors}
                        invalidText={fromAgeErrors?.message}
                    >
                        {options.age.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                    <Select
                        className={styles.ageSelect}
                        id={toAge.name}
                        name={toAge.name}
                        labelText="Max"
                        ref={toAge.ref}
                        defaultValue={targeting.age.to}
                        onChange={(evt) => {
                            toAge.onChange(evt);
                            setValue(toAge.name, evt.target.value, {
                                shouldDirty: true,
                                shouldValidate: true
                            });
                        }}
                        onBlur={toAge.onBlur}
                        invalid={!!toAgeErrors}
                        invalidText={toAgeErrors?.message}
                    >
                        {options.age.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                </fieldset>
            ),
            coverageAmount: (
                <fieldset className="bx--fieldset" disabled={disabled}>
                    <Select
                        className={styles.coverageAmount}
                        id={fromCoverage.name}
                        name={fromCoverage.name}
                        labelText="Min"
                        ref={fromCoverage.ref}
                        defaultValue={targeting.coverageAmount.from}
                        onChange={(evt) => {
                            fromCoverage.onChange(evt);
                            setValue(fromCoverage.name, evt.target.value, {
                                shouldDirty: true,
                                shouldValidate: true
                            });
                        }}
                        onBlur={fromCoverage.onBlur}
                        invalid={!!fromCoverageErrors}
                        invalidText={fromCoverageErrors?.message}
                    >
                        {options.coverageAmount.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                    <Select
                        className={styles.coverageAmount}
                        id={toCoverage.name}
                        name={toCoverage.name}
                        labelText="Max"
                        ref={toCoverage.ref}
                        defaultValue={targeting.coverageAmount.to}
                        onChange={(evt) => {
                            toCoverage.onChange(evt);
                            setValue(toCoverage.name, evt.target.value, {
                                shouldDirty: true,
                                shouldValidate: true
                            });
                        }}
                        onBlur={toCoverage.onBlur}
                        invalid={!!toCoverageErrors}
                        invalidText={toCoverageErrors?.message}
                    >
                        {options.coverageAmount.map((item, i) => (
                            <SelectItem value={item.value} key={i} text={item.label} />
                        ))}
                    </Select>
                </fieldset>
            ),
            delete: (
                <Button
                    onClick={() => handleOpen(currentCampaign, custom.campaign)}
                    hasIconOnly
                    renderIcon={TrashCan16}
                    tooltipAlignment="center"
                    tooltipPosition="top"
                    iconDescription="Delete"
                    disabled={formDisabled}
                />
            ),
            isSelected: Boolean(enabled)
        };
    };

    const createCustomCampaign = () => {
        const customsCreated = customs ? customs.length + 1 : 1;
        const campaignKey = `new_custom_${customsCreated}`;
        const defaultNew = {
            campaign: `Custom ${customsCreated}`,
            enabled: true,
            custom: 1,
            bid: '',
            dailyCap: 0,
            targeting: {
                coverageType: 'any',
                age: {
                    from: 'any',
                    to: 'any'
                },
                coverageAmount: {
                    from: 'any',
                    to: 'any'
                }
            },
            customId: campaignKey
        };
        setValue(`${namespace}.${campaignKey}`, defaultNew, { shouldDirty: true });
    };

    // useMemo doesn't re-render if "customs" is supplied in the dependency array, it needs the enabled flag of each custom campaign to trigger a re-render
    const customsEnabled = customs.map((c) => c.enabled);

    const rowData = useMemo(() => customs.map((c, i) => createRow(c, i)), [customsEnabled, formDisabled]);

    return (
        <>
            {!!customs.length && (
                <DataTable rows={rowData} headers={getHeaderData(dailyCapLevel === CAMPAIGN)}>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getSelectionProps,
                        getRowProps,
                        getTableProps,
                        selectRow,
                        selectAll
                    }) => (
                        <TableContainer className={styles.customsTable} style={{ marginTop: 20 }}>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        <TableSelectAll
                                            {...getSelectionProps()}
                                            onSelect={() => {
                                                handleCheckAll();
                                                selectAll();
                                            }}
                                            disabled={formDisabled}
                                        />
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({ header })}>
                                                <div className={styles.headerCell}>
                                                    {header.header}
                                                    {tooltips[header.header] && (
                                                        <TooltipIcon
                                                            tooltipText={tooltips[header.header]}
                                                            direction="top"
                                                        >
                                                            <Information16 />
                                                        </TooltipIcon>
                                                    )}
                                                </div>
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <RenderTableBody
                                    rows={rows}
                                    getRowProps={getRowProps}
                                    formDisabled={formDisabled}
                                    selectRow={selectRow}
                                    getSelectionProps={getSelectionProps}
                                    rowData={rowData}
                                    namespace={namespace}
                                />
                            </Table>
                        </TableContainer>
                    )}
                </DataTable>
            )}
            {!formDisabled && (
                <Button
                    renderIcon={Add16}
                    onClick={createCustomCampaign}
                    disabled={customs.length >= CUSTOM_LIMIT}
                    style={{ marginTop: 20 }}
                >
                    Add Custom
                </Button>
            )}
            <Modal
                size="xs"
                open={deleteModal.open}
                modalHeading="Delete Confirmation"
                primaryButtonText="Delete"
                secondaryButtonText="Cancel"
                onRequestClose={handleClose}
                onRequestSubmit={deleteCustom}
                danger
            >
                <p>Are you sure you want to delete {deleteModal.campaignName}?</p>
            </Modal>
        </>
    );
};

const RenderTableBody = ({ rows, getRowProps, formDisabled, selectRow, getSelectionProps, rowData, namespace }) => {
    const {
        register,
        setValue,
        clearErrors,
        trigger,
        formState: { errors }
    } = useFormContext();
    const [root, product, section] = namespace.split('.');
    const customErrors = errors[root]?.[product]?.[section] || {};

    const setDefaultTargeting = (val) => {
        setValue(val, 'any');
        clearErrors(val);
    };

    const setValuesToDefault = (rowData) => {
        const { key } = rowData;
        const currentCampaign = `${namespace}.${key}`;
        const fromAge = `${currentCampaign}.targeting.age.from`;
        const toAge = `${currentCampaign}.targeting.age.to`;
        const fromCoverage = `${currentCampaign}.targeting.coverageAmount.from`;
        const toCoverage = `${currentCampaign}.targeting.coverageAmount.to`;
        const name = `${currentCampaign}.name`;

        const ageErrors = customErrors[key]?.targeting?.age;
        const coverageErrors = customErrors[key]?.targeting?.coverageAmount;

        if (ageErrors?.from) {
            setDefaultTargeting(fromAge);
        }
        if (ageErrors?.to) {
            setDefaultTargeting(toAge);
        }
        if (coverageErrors?.from) {
            setDefaultTargeting(fromCoverage);
        }
        if (coverageErrors?.to) {
            setDefaultTargeting(toCoverage);
        }
        if (customErrors[key]?.name) {
            setValue(name, `Custom ${getRandomId(5)}`);
            clearErrors(name);
        }
    };

    const handleSelectCampaign = ({ target }, row) => {
        const { name, checked } = target;
        if (!checked && customErrors && customErrors[row.key]) {
            setValuesToDefault(row);
        }
        setValue(name, target.checked, {
            shouldValidate: true,
            shouldDirty: true
        });
        trigger(`${namespace}.${row.key}`, { shouldFocus: true });
        selectRow(row.id);
    };

    return (
        <TableBody>
            {rows.map((row, i) => {
                if (!rowData[i]) {
                    return null;
                }

                const key = rowData[i]?.key;
                const name = `${namespace}.${key}.enabled`;
                register(name);

                return (
                    <Fragment key={rowData[i].key}>
                        <TableRow {...getRowProps({ row })}>
                            <TableSelectRow
                                {...getSelectionProps({ row })}
                                name={name}
                                checked={row.isSelected}
                                onSelect={(evt) => handleSelectCampaign(evt, rowData[i])}
                                disabled={formDisabled}
                            />
                            {row.cells.map((cell) => {
                                return (
                                    <TableCell key={cell.id} className={styles.tableCell}>
                                        <div
                                            className={clsx(
                                                styles.dataCell,
                                                styles[cell.info.header],
                                                styles.healthLifeDataCell
                                            )}
                                        >
                                            {cell.value}
                                        </div>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </Fragment>
                );
            })}
        </TableBody>
    );
};

const headerData = [
    {
        header: 'Type',
        key: 'name'
    },
    {
        header: 'Daily Cap',
        key: 'dailyCap'
    },
    {
        header: 'Bid',
        key: 'bid'
    },
    {
        header: 'Coverage Type',
        key: 'coverageType'
    },
    {
        header: 'Coverage Amount',
        key: 'coverageAmount'
    },
    {
        header: 'Age',
        key: 'age'
    },
    {
        header: '',
        key: 'delete'
    }
];

const options = {
    coverageType: [
        {
            label: 'All Types',
            value: 'any'
        },
        {
            label: 'Final Expense',
            value: 'final_expense'
        },
        {
            label: 'Term/Whole Life',
            value: 'term_whole_life'
        }
    ],
    age: [
        {
            label: 'No Limit',
            value: 'any'
        },
        {
            label: '20',
            value: 20
        },
        {
            label: '25',
            value: 25
        },
        {
            label: '30',
            value: 30
        },
        {
            label: '35',
            value: 35
        },
        {
            label: '40',
            value: 40
        },
        {
            label: '45',
            value: 45
        },
        {
            label: '50',
            value: 50
        },
        {
            label: '55',
            value: 55
        },
        {
            label: '60',
            value: 60
        },
        {
            label: '65',
            value: 65
        },
        {
            label: '70',
            value: 70
        },
        {
            label: '75+',
            value: 75
        }
    ],
    coverageAmount: [
        {
            label: 'No Limit',
            value: 'any'
        },
        {
            label: '$10,000',
            value: 10000
        },
        {
            label: '$25,000',
            value: 25000
        },
        {
            label: '$50,000',
            value: 50000
        },
        {
            label: '$100,000',
            value: 100000
        },
        {
            label: '$150,000',
            value: 150000
        },
        {
            label: '$200,000',
            value: 200000
        },
        {
            label: '$250,000',
            value: 250000
        },
        {
            label: '$500,000',
            value: 500000
        },
        {
            label: '$1,000,000',
            value: 1000000
        },
        {
            label: '$2,000,000+',
            value: 2000000
        }
    ]
};

export { CustomLifeCampaigns };
